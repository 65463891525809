import { ModuleWithProviders } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { BoxesComponent } from "./boxes.component";
import { CreateBoxComponent } from "./create-box/create-box.component";
import { UpdateBoxComponent } from "./update-box/update-box.component";
import { UpdateMileageComponent } from "./update-mileage/update-mileage.component";
import { VehiclesComponent } from "./vehicles/vehicles.component";
import { CreateVehicleComponent } from "./vehicles/create-vehicle/create-vehicle.component";
import { ProviderType, OemType } from "app/base/provider-oem-type"
import { UpdateBoxAccessComponent } from "./update-box-access/update-box-access.component";
import { AutoLoggBoxesModule } from "./boxes.module";
import { AutologgConnectedModule } from "app/AutologgConnected/AutloggConnected.module";
import { CreateConnectedComponent } from "app/AutologgConnected/create-connected/create-connected.component";
import { LockedAutologgAuthGuard } from "app/locked-auth-guard.service";

const routes: Routes = [
  {
    path: "boxes",
    component: BoxesComponent,
    canActivate: [LockedAutologgAuthGuard],
    data: { providerType: ProviderType.Native, oemType: OemType.Box },
  },
  {
    path: "boxes/create/add/:providerType/:oemType",
    component: CreateBoxComponent,
    canActivate: [LockedAutologgAuthGuard],
    data: { providerType: ProviderType.Native, oemType: OemType.Box },
  },
  {
    path: "boxes/:id/edit",
    component: UpdateBoxComponent,
    canActivate: [LockedAutologgAuthGuard],
    data: { providerType: ProviderType.Native, oemType: OemType.Box },
  },
  {
    path: "boxes/:id/updateMileage",
    component: UpdateMileageComponent,
    canActivate: [LockedAutologgAuthGuard],
    data: { providerType: ProviderType.Native, oemType: OemType.Box },
  },
  {
    path: "boxes/:id/updateAccess",
    component: UpdateBoxAccessComponent,
    canActivate: [LockedAutologgAuthGuard],
    data: { providerType: ProviderType.Native, oemType: OemType.Box },
  },
  {
    path: "boxes/:id/vehicles",
    component: VehiclesComponent,
    canActivate: [LockedAutologgAuthGuard],
    data: { providerType: ProviderType.Native, oemType: OemType.Box },
  },
  {
    path: "boxes/:id/vehicles/create",
    component: CreateVehicleComponent,
    canActivate: [LockedAutologgAuthGuard],
    data: {
      ProviderType: ProviderType.Native,
      oemType: OemType.Box,
      isFirst: false,
    },
  },
  {
    path: "boxes/:id/vehicles/createFirst",
    component: CreateVehicleComponent,
    canActivate: [LockedAutologgAuthGuard],
    data: {
      ProviderType: ProviderType.Native,
      oemType: OemType.Box,
      isFirst: true,
    },
  },

  {
    path: "boxes",
    outlet: "dialog",
    component: BoxesComponent,
    canActivate: [LockedAutologgAuthGuard],
    data: { providerType: ProviderType.Native, oemType: OemType.Box },
  },
  {
    path: "boxes/create/add/:providerType/:oemType",
    outlet: "dialog",
    component: CreateBoxComponent,
    canActivate: [LockedAutologgAuthGuard],
    data: { providerType: ProviderType.Native, oemType: OemType.Box },
  },
  {
    path: "boxes/:id/edit",
    outlet: "dialog",
    component: UpdateBoxComponent,
    canActivate: [LockedAutologgAuthGuard],
    data: { providerType: ProviderType.Native, oemType: OemType.Box },
  },
  {
    path: "boxes/:id/updateMileage",
    outlet: "dialog",
    component: UpdateMileageComponent,
    canActivate: [LockedAutologgAuthGuard],
    data: { providerType: ProviderType.Native, oemType: OemType.Box },
  },
  {
    path: "boxes/:id/updateAccess",
    outlet: "dialog",
    component: UpdateBoxAccessComponent,
    canActivate: [LockedAutologgAuthGuard],
    data: { providerType: ProviderType.Native, oemType: OemType.Box },
  },
  {
    path: "boxes/:id/vehicles",
    outlet: "dialog",
    component: VehiclesComponent,
    canActivate: [LockedAutologgAuthGuard],
    data: { providerType: ProviderType.Native, oemType: OemType.Box },
  },
  {
    path: "boxes/:id/vehicles/create",
    outlet: "dialog",
    component: CreateVehicleComponent,
    canActivate: [LockedAutologgAuthGuard],
    data: {
      ProviderType: ProviderType.Native,
      oemType: OemType.Box,
      isFirst: false,
    },
  },
  {
    path: "boxes/:id/vehicles/createFirst",
    outlet: "dialog",
    component: CreateVehicleComponent,
    canActivate: [LockedAutologgAuthGuard],
    data: {
      ProviderType: ProviderType.Native,
      oemType: OemType.Box,
      isFirst: true,
    },
  },
];

export const AutoLoggBoxesRouting: ModuleWithProviders<AutoLoggBoxesModule> =
  RouterModule.forChild(routes);

const connectedRoutes: Routes = [
  {
    path: "connectedboxes",
    component: BoxesComponent,
    canActivate: [LockedAutologgAuthGuard],
    data: { providerType: ProviderType.HighMobility },
  },
  {
    path: "connectedboxes/create",
    component: CreateConnectedComponent,
    canActivate: [LockedAutologgAuthGuard],
  },

  {
    path: "connectedboxes/create/add/:providerType",
    component: CreateBoxComponent,
    canActivate: [LockedAutologgAuthGuard],
  },
  {
    path: "connectedboxes/create/add/:providerType/:oemType",
    component: CreateBoxComponent,
    canActivate: [LockedAutologgAuthGuard],
  },
  {
    path: "connectedboxes/:id/edit",
    component: UpdateBoxComponent,
    canActivate: [LockedAutologgAuthGuard],
  },
  {
    path: "connectedboxes/:id/updateAccess",
    component: UpdateBoxAccessComponent,
    canActivate: [LockedAutologgAuthGuard],
  },
  {
    path: "connectedboxes/:id/vehicles",
    component: VehiclesComponent,
    canActivate: [LockedAutologgAuthGuard],
  },
  {
    path: "connectedboxes/:id/vehicles/create",
    component: CreateVehicleComponent,
    canActivate: [LockedAutologgAuthGuard],
  },

  {
    path: "connectedboxes",
    outlet: "dialog",
    component: BoxesComponent,
    canActivate: [LockedAutologgAuthGuard],
  },
  {
    path: "connectedboxes/create",
    outlet: "dialog",
    component: CreateConnectedComponent,
    canActivate: [LockedAutologgAuthGuard],
  },

  {
    path: "connectedboxes/create/add/:providerType",
    outlet: "dialog",
    component: CreateBoxComponent,
    canActivate: [LockedAutologgAuthGuard],
    data: { isDialog: true },
  },
  {
    path: "connectedboxes/create/add/:providerType/:oemType",
    outlet: "dialog",
    component: CreateBoxComponent,
    canActivate: [LockedAutologgAuthGuard],
    data: { isDialog: true },
  },
  {
    path: "connectedboxes/:id/edit",
    outlet: "dialog",
    component: UpdateBoxComponent,
    canActivate: [LockedAutologgAuthGuard],
  },
  {
    path: "connectedboxes/:id/updateAccess",
    outlet: "dialog",
    component: UpdateBoxAccessComponent,
    canActivate: [LockedAutologgAuthGuard],
  },
  {
    path: "connectedboxes/:id/vehicles",
    outlet: "dialog",
    component: VehiclesComponent,
    canActivate: [LockedAutologgAuthGuard],
  },
  {
    path: "connectedboxes/:id/vehicles/create",
    outlet: "dialog",
    component: CreateVehicleComponent,
    canActivate: [LockedAutologgAuthGuard],
  },
];

export const AutoLoggConnectedBoxesRouting: ModuleWithProviders<AutologgConnectedModule> =
  RouterModule.forChild(connectedRoutes);
