import { Hotspot } from "./hotspot.model";

export class HotspotTableEntry {
  id: number;
  created_at: string;
  title: string;
  address: string;
  latitude: number;
  longitude: number;
  note: string;
  shared: boolean;

  static fromHotspot(source: Hotspot) {
    const val = new HotspotTableEntry();
    val.id = +source.itemid;
    val.created_at = source.created_at;
    val.title = source.title;
    val.address = source.address;
    val.latitude = source.latitude;
    val.longitude = source.longitude;
    val.note = source.note;
    return val;
  }
}
