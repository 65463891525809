import { DatePipe } from "@angular/common";
import { Helper } from "app/base/helper";
import { TripTypeString } from "app/trips/table-editor/trip-table-entry";

const datePipe = new DatePipe(Helper.getBrowserLang());

export enum TripType {
  OPEN = 0,
  BUSINESS = 1,
  PRIVATE = 2,
  MIXED_TRIP = 3,
  COMMUTE = 4,
  GAP = 5,
}

export class Trip {
  public itemid: string;
  public vehicle_id: number;
  public start_lat: number;
  public start_lng: number;
  public start_time: string;
  public start_address: string;
  public start_km: number;
  public end_lat: number;
  public end_lng: number;
  public end_time: string;
  public end_address: string;
  public end_km: number;
  public partner: string;
  public purpose: string;
  public note: string;
  public type_trip: number;
  public type_trip_string: TripTypeString;
  public connected_trips: Array<Trip>;
  public last_edit: string;
  public manual_inserted: boolean;
  public has_connectedtrips: boolean;

  public trip_start: Date;
  public trip_end: Date;

  public editable: boolean;
  public duration: string;
  public distance: number;
  public date: string;

  public car: string;
  public crossed: boolean;

  public end_time_display: string;
  public start_time_display: string;
  public weekday_display: string;
  public distance_display: string;
  public date_display: string;
  public date_end_display: string;

  public driver_user_id?: number;
  public driver_name: string;

  public active_model_name: string;
  public active_model_plate: string;
  public job: string;

  public is_mutable: boolean;

  // TODO: meeh.. refactor to own class holding additional info? just saying..
  public afterPropertiesSet() {
    if (this.start_time) {
      const startTimeAsDate = new Date(this.start_time);
      this.start_time_display = datePipe
        .transform(startTimeAsDate, "shortTime")
        .toString();
      this.weekday_display = datePipe
        .transform(startTimeAsDate, "EEEE")
        .toString();
      this.date_display = datePipe.transform(startTimeAsDate).toString();
      this.trip_start = startTimeAsDate;
    }
    if (this.end_time) {
      const endTimeAsDate = new Date(this.end_time);
      this.end_time_display = datePipe
        .transform(endTimeAsDate, "shortTime")
        .toString();

      this.date_end_display = datePipe.transform(endTimeAsDate).toString();
      this.trip_end = endTimeAsDate;
    }
    this.distance_display = (this.distance <= 0 ? "<1" : this.distance) + "";

    if (this.connected_trips && this.connected_trips.length > 0) {
      this.connected_trips = this.connected_trips
        .map((json) => Object.assign(new Trip(), json))
        .map((val) => val.afterPropertiesSet());
    }

    return this;
  }
}
