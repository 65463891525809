import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Steps } from "./first-steps";

@Injectable()
export class DialogService {
  private messageSource = new BehaviorSubject(Steps.future);
  currentMessage = this.messageSource.asObservable();

  constructor() {}

  changeMessage(message: Steps) {
    this.messageSource.next(message);
  }
}
