import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";

import { environment } from "../../../environments/environment";

import { finalize, tap } from "rxjs/operators";

@Injectable()
export class LoggingInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (environment.production) {
      return next.handle(req);
    }

    return this.handleInternal(req, next);
  }

  handleInternal(req: HttpRequest<any>, next: HttpHandler) {
    const started = Date.now();
    let ok: string;

    return next.handle(req).pipe(
      tap(
        (event) => {
          const isHttpResponse = event instanceof HttpResponse;
          ok = isHttpResponse ? "succeeded" : "";
        },
        (error) => {
          ok = "failed";
        }
      ),
      finalize(() => {
        const elapsed = Date.now() - started;
        const msg = `${req.method} "${req.urlWithParams}" ${ok} in ${elapsed} ms.`;
        console.log(msg);
      })
    );
  }
}
