<mat-progress-bar *ngIf="loading" color="accent" mode="indeterminate"></mat-progress-bar>

<div class="container-fluid">
  <div class="row">
    <div class="col-12" *ngIf="loading">
      <h1 translate>groups.manage.user.title</h1>
    </div>

    <div class="col-12" *ngIf="!loading">
      <h1>{{ user.name }}</h1>
      <h3 translate>groups.manage.user.title</h3>
    </div>
  </div>
</div>

<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <div class="button-row">
        <a mat-raised-button routerLink="/groups/{{groupId}}/manage" title="{{ 'groups.manage.title' | translate }}">
          <mat-icon class="icon-custom">arrow_back</mat-icon>
          <span class="mat-button-span" class="mat-button-span" translate>groups.manage.title</span>
        </a>
      </div>
    </div>
  </div>


  <div class="row" *ngIf="loading">
    <div class="col-12">
      <mat-spinner style="margin:0 auto;" mode="indeterminate"></mat-spinner>
    </div>
  </div>

  <div class="row pt-2" *ngIf="!loading">
    <div class="col-12">

      <mat-tab-group>
        <!--mat-tab label="Dashboard">
          <ng-template matTabContent>

          </ng-template>
        </mat-tab-->

        <!--mat-tab label="{{ 'groups.manage.events.title' | translate }}">
          <ng-template matTabContent>
            <app-groups-list-group-events [events]="events"></app-groups-list-group-events>
          </ng-template>
        </mat-tab-->
        <!--mat-tab label="Einstellungen">
          {{ group | json }}


        </mat-tab-->
      </mat-tab-group>
    </div>
  </div>

  <div class="row mt-5"></div>
</div>