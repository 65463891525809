import { NgModule, LOCALE_ID, APP_INITIALIZER } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FlexLayoutModule } from "@angular/flex-layout";

import { registerLocaleData } from "@angular/common";
import localeDe from "@angular/common/locales/de";
import localeDeExtra from "@angular/common/locales/extra/de";
import localeEn from "@angular/common/locales/en";
import localeEnExtra from "@angular/common/locales/extra/en";

import { CookieService } from "ngx-cookie-service";

import { AutoLoggCommonModule } from "./common/autologg-common.module";

import { AutoLoggHotspotsModule } from "./hotspots/hotspots.module";
import { AutoLoggAccountModule } from "./profile/account.module";
import { AutoLoggGroupsModule } from "./groups/groups.module";
import { AutoLoggBoxesModule } from "./boxes/boxes.module";
import { NgxChartsModule } from "@swimlane/ngx-charts";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

import { ChartBarComponent } from "./home/chart-bar/chart-bar.component";
import { ChartDoughnutComponent } from "./home/chart-doughnut/chart-doughnut.component";
import { ExportComponent } from "./export/export.component";
import { DialogExportWarningComponent } from "./export/dialog-export-warning.component";
import { FullTextSearchPipe } from "./base/fulltext-search-pipe.component";

import { PageNotFoundComponent } from "./not-found.component";

import { HomeComponent } from "./home/home.component";
import { VehicleCardComponent } from "./home/vehicle-card/vehicle-card.component";

import { FrequentHotspotsComponent } from "./home/hotspots/frequent-hotspots.component";

import { httpInterceptorProviders } from "./api/http-interceptors/index";

import { AutologgConnectedModule } from "./AutologgConnected/AutloggConnected.module";
import { OverlayLoadingService } from "./utils/overlay-loading.service";
import { OverlayLoadingTextService } from "./utils/overlay-loading-text.service";
import { ScriptService } from "./services/script.service";
import { ConnectedLoginModule } from "./AutologgConnected/connected-login/connected-login.module";
import { MapComponent } from "./map/map.component";
import { ClickCursorDirective } from "./base/style.directive";
import { FileSaverModule } from "ngx-filesaver";
import { OverlayComponent } from "./utils/overlay/overlay.component";
import { FirstStepsComponent } from "./first-steps/first-steps.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { MatStepperModule } from "@angular/material/stepper";

import { OAuth2RedirectComponent } from "./oauth2-redirect/oauth2-redirect.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { DialogService } from "./services/dialog.service";
import { GapService } from "./services/gap.service";

import { CommonModule } from "@angular/common";
import { FirstGroupStepsComponent } from "./first-steps/first-group-steps/first-group-steps/first-group-steps.component";
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { environment } from "environments/environment";
import { KeyCloakAutologgAuthGuard } from "./keycloak-auth-guard.service";
import { LockedAutologgAuthGuard } from "./locked-auth-guard.service";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatListModule } from "@angular/material/list";
import deMessages from "devextreme/localization/messages/de.json";
import enMessages from "devextreme/localization/messages/en.json";
import { loadMessages } from "devextreme/localization";

registerLocaleData(localeDe, localeDeExtra);
registerLocaleData(localeEn, localeEnExtra);

loadMessages(deMessages);
loadMessages(enMessages);

function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: {
        url: environment.idServer + '/auth',
        realm: environment.realm,
        clientId: 'angular'
      },
      enableBearerInterceptor: true,
      initOptions: {
        checkLoginIframe: false,
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri:
          window.location.origin + '/assets/silent-check-sso.html'
      },
      bearerExcludedUrls: ['/assets']
    }).catch(e => {
      console.error(e);
      initializeKeycloak(keycloak);
    });
}

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    HomeComponent,
    VehicleCardComponent,
    FrequentHotspotsComponent,
    ChartBarComponent,
    ChartDoughnutComponent,
    ExportComponent,
    DialogExportWarningComponent,
    FullTextSearchPipe,
    MapComponent,
    ClickCursorDirective,
    OverlayComponent,
    FirstStepsComponent,
    OAuth2RedirectComponent,
    FirstGroupStepsComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxChartsModule,
    FlexLayoutModule,
    CommonModule,
    AutoLoggCommonModule,
    AutoLoggAccountModule,
    AutoLoggBoxesModule,
    AutoLoggHotspotsModule,
    AutoLoggGroupsModule,
    AppRoutingModule,
    ConnectedLoginModule,
    AutologgConnectedModule,
    FileSaverModule,
    BrowserAnimationsModule,
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    KeycloakAngularModule,
    NgbModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'de' },
    KeyCloakAutologgAuthGuard,
    LockedAutologgAuthGuard,
    // ApiService <-- used `providedIn: root`,
    CookieService,
    httpInterceptorProviders,
    OverlayLoadingService,
    OverlayLoadingTextService,
    ScriptService,
    DialogService,
    GapService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService]
    }
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
  constructor(scriptService: ScriptService) {
    scriptService.loadScript("gtm.start");
  }
}
