<mat-spinner *ngIf="loading" style="margin:0 auto;" mode="indeterminate"></mat-spinner>
<mat-progress-bar *ngIf="updating" color="accent" mode="indeterminate" style="position: absolute; left: 0; right: 0;"></mat-progress-bar>

<div class="container-fluid">
  <div class="row p-4">

    <div class="col-12">
      <h3 translate>global.features</h3>
    </div>

    <div class="col-12" [hidden]="loading">
      <div *ngFor="let featureWithFlag of featuresWithEnabledFlag">
        <mat-card class="mb-4">
          <div style="float: right">
            <mat-slide-toggle [checked]="featureWithFlag.userEnabled" labelPosition="after" (change)="toggleFeature(featureWithFlag)"
              [disabled]="updating || !featureWithFlag.feature.enable_by_user">
              <mat-icon class="vertical-align-middle" *ngIf="featureWithFlag.userEnabled">check</mat-icon>
              <mat-icon class="vertical-align-middle" *ngIf="!featureWithFlag.userEnabled">cancel</mat-icon>
              <ng-container></ng-container>
            </mat-slide-toggle>
          </div>
          <mat-card-header>
            <mat-card-title>
              <h4>
                <ng-container [ngSwitch]="featureWithFlag.userEnabled">
                  <ng-container *ngSwitchCase="true">
                    <ng-container [ngSwitch]="!!appFeatureRouterLinks[featureWithFlag.feature.code]">
                      <ng-container *ngSwitchCase="true">
                        <a routerLink="{{ appFeatureRouterLinks[featureWithFlag.feature.code] }}">
                          {{ featureWithFlag.feature.name | translate }}
                        </a>
                      </ng-container>
                      <ng-container *ngSwitchDefault>
                        {{ featureWithFlag.feature.name | translate }}
                      </ng-container>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngSwitchDefault>
                    {{ featureWithFlag.feature.name | translate}}
                  </ng-container>
                </ng-container>
                <sub style="font-size: 50%;" *ngIf="featureWithFlag.feature.beta">&nbsp;BETA</sub>
              </h4>

            </mat-card-title>
            <mat-card-subtitle>
              <p [hidden]="!featureWithFlag.feature.description">
                {{ featureWithFlag.feature.description | translate}}
              </p>
            </mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>

            <p *ngIf="!featureWithFlag.feature.enable_by_user" class="mat-small">
              <span translate>features.enabled_by_support</span>
            </p>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</div>