import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { GroupsComponent } from './groups.component';
import { ManageGroupMembershipComponent } from './manage-membership/manage-group-membership.component';
import { CreateGroupComponent } from './create-group/create-group.component';
import { UpdateGroupComponent } from './update-group/update-group.component';
import { CreateInvitationComponent } from './invitations/create-invitation/create-invitation.component';
import { AutoLoggGroupsModule } from './groups.module';
import { KeyCloakAutologgAuthGuard } from 'app/keycloak-auth-guard.service';

const routes: Routes = [
  { path: 'groups', component: GroupsComponent, canActivate: [KeyCloakAutologgAuthGuard] },
  { path: 'groups/create', component: CreateGroupComponent, canActivate: [KeyCloakAutologgAuthGuard] },
  { path: 'groups/:id/edit', component: UpdateGroupComponent, canActivate: [KeyCloakAutologgAuthGuard] },
  { path: 'groups/:id/invitations/create', component: CreateInvitationComponent, canActivate: [KeyCloakAutologgAuthGuard] },

  { path: 'groups/:id/membership', component: ManageGroupMembershipComponent, canActivate: [KeyCloakAutologgAuthGuard] },
];

export const AutoLoggGroupsRouting: ModuleWithProviders<AutoLoggGroupsModule> = RouterModule.forChild(routes);
