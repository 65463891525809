<mat-progress-bar *ngIf="loading" color="accent" mode="indeterminate"></mat-progress-bar>

<div class="container-fluid">

  <div class="row">
    <div class="col-12 p-3">
      <h1 translate>groups.invitations.create.title</h1>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="button-row">
        <a mat-button routerLink="{{ '/groups/' + groupId + '/manage' }}" title="{{ 'global.back' | translate }}">
          <mat-icon class="icon-custom">arrow_back</mat-icon>
          <span translate>global.back</span>
        </a>
      </div>
    </div>
  </div>

  <form #addInvitationForm="ngForm" (ngSubmit)="submitForm()">

    <div class="row">
      <div class="col-12 p-3 pt-2">
        <mat-form-field class="full-width">
          <mat-label translate>groups.invitations.create.fields.emails.label</mat-label>
          <textarea matInput rows="5" #descriptionField="ngModel" [(ngModel)]="emails" name="emails" placeholder="{{ 'groups.invitations.create.fields.emails.placeholder' | translate }}">
        </textarea>
          <mat-hint>
            <span translate>groups.invitations.create.fields.emails.hint</span> Noch {{ numberOfMailsLeft }}.</mat-hint>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-12 p-3">
        <mat-checkbox [(ngModel)]="groupAdmin" name="groupAdmin">
          <span translate>groups.invitations.create.fields.groupAdmin.label</span>
        </mat-checkbox>
        <br />
        <mat-hint translate>groups.invitations.create.fields.groupAdmin.hint</mat-hint>
      </div>
      <!--div class="col-12 col-md-12 p-3">
        <mat-checkbox [(ngModel)]="auto_hotspot_sharing_enabled" name="auto_hotspot_sharing_enabled">
          <span translate>groups.invitations.create.fields.auto_hotspot_sharing_enabled.label</span>
        </mat-checkbox>
        <br />
        <mat-hint translate>groups.invitations.create.fields.auto_hotspot_sharing_enabled.hint</mat-hint>
      </div-->
    </div>

    <div class="d-flex flex-row justify-content-around flex-wrap m-auto">
      <div class="d-flex mt-3 mb-5">
        <button mat-raised-button color="accent" type="submit" [disabled]="!isSubmitButtonEnabled()" class="m-auto">
          <span translate>groups.invitations.create.submit</span>
        </button>
      </div>
    </div>
  </form>

  <div class="row mt-5"></div>
</div>