import { throwError as observableThrowError, Observable } from "rxjs";

import { catchError, map, flatMap } from "rxjs/operators";
import { EventEmitter, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { formatDate } from "@angular/common";

import { CookieService } from "ngx-cookie-service";

import { environment } from "../environments/environment";
import {
  MoreHttpHeaders,
  CustomQueryHttpParameterCodec,
} from "./api/api.utils";
import { ApiVersionHolder } from "./api/api-version-holder.component";

import { SimpleUser, User, UserSettings } from "./_models/user.model";
import { RegisterBody } from "./_models/register.model";
import {
  Group,
  GroupInvitation,
  GroupUserMembership,
  GroupUserBox,
  GroupUserHotspot,
  GroupEvent,
  UserGroupMembershipWithMetadata,
  GroupMembershipUpdateRequest,
  GroupUserBoxWithLatestTrip,
  CreateGroupBoxRequest,
  UpdateGroupBoxRequest,
} from "./_models/group.model";
import { GroupCreateRequest } from "./_models/group-create-request.model";
import { GroupUpdateRequest } from "./_models/group-update-request.model";
import { GroupCreateInvitationRequest } from "./_models/group-create-invitation-request.model";
import { Box } from "./_models/box.model";
import { BoxCreateRequest } from "./_models/box-create-request.model";
import { BoxUpdateRequest } from "./_models/box-update-request.model";

import { CarOem } from "./_models/car-oem.model";
import { CarSeries } from "./_models/car-series.model";
import { CarModel } from "./_models/car-model.model";
import { Hotspot } from "./_models/hotspot.model";
import { Statistics } from "./_models/statistics.model";
import { Car } from "./_models/car.model";
import { Trip } from "./_models/trip.model";
import { AppFeature, UserAppFeature } from "./_models/feature.model";
import { ProviderType } from "app/base/provider-oem-type";
import { TranslateService } from "@ngx-translate/core";
import { sha512 } from "js-sha512";
import { TrackingService } from "./tracking/tracking-service";
import { KeycloakService } from "keycloak-angular";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  private _user: User;
  public get user(): User {
    return this._user;
  }
  public set user(v: User) {
    this._user = v;
    this.userChange?.emit(v);
  }

  public registerStep: number;
  public register: RegisterBody;

  private apiUrl = environment.backendUrl;
  private options = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };

  private _isLoggedIn: boolean = true;
  public isAdmin: boolean;
  public get isLoggedIn(): boolean {
    return this._isLoggedIn;
  }
  public set isLoggedIn(v: boolean) {
    this._isLoggedIn = v;
    if (v != this._isLoggedIn) {
      this.isLoggedInChange?.emit(v);
    }
  }

  isLoggedInChange = new EventEmitter<any>();
  userChange = new EventEmitter<any>();

  redirectUrl: string;

  constructor(
    private http: HttpClient,
    private router: Router,
    private _cookieService: CookieService,
    private apiVersion: ApiVersionHolder,
    private trackingService: TrackingService,
    private translate: TranslateService,
    protected readonly keycloak: KeycloakService
  ) {
    this.register = new RegisterBody();
    this.registerStep = 1;
  }

  async init() {
    if (await this.keycloak.isLoggedIn()) {
      try {
        this.user = new User();
        this.getUserProfile().subscribe(
          async (user) => {
            await this.checkIfGroupAdmin();

            this._isLoggedIn = true;
            this.isLoggedInChange.emit(true);
          },
          async (error) => {
            console.log(error);
            await this.keycloak.logout();
          }
        );
      } catch (e) {
        console.log(e);
      }
    }
  }

  private createParams(): HttpParams {
    const params: HttpParams = new HttpParams({
      encoder: new CustomQueryHttpParameterCodec(),
    });
    return params;
  }

  private createParamsWithPaging(): HttpParams {
    const params: HttpParams = this.createParams()
      .set("pagingindex", "0")
      .set("pagingresults", "1000");
    return params;
  }

  public isValidMogreeResponseOrThrow(json: any) {
    if (json.statuscode !== 200) {
      if (json.statusocde === 20) {
        this.keycloak.logout();
      }
      throw new Error(json.errormessage);
    }
    return true;
  }

  returnTrueIfLoggedInAndNavigateToStatistics(): boolean {
    if (this.isLoggedIn) {
      this.router.navigate(["/statistics"]);
      return true;
    }

    return false;
  }

  public isNewerApiVersionAvailable() {
    return this.apiVersion.isNewerApiVersionAvailable();
  }

  async checkIfGroupAdmin() {
    try {
      var groups = await this.getUserGroups().toPromise();
      if (groups) {
        groups.forEach((val) => {
          if (val.group_user.membership.group_admin) {
            this.isAdmin = true;
          }
        });
      }
    } catch {}
  }

  refreshProfile() {
    this.getUserProfile().subscribe();
  }

  // GET /user
  getUserProfile(): Observable<User> {
    const url = `${this.apiUrl}user`;

    return this.http.get(url, this.options).pipe(
      map((json: any) => {
        this.isValidMogreeResponseOrThrow(json);

        const detailResponse = json.detailresponse;

        this.user.itemid = detailResponse.itemid;
        this.user.userid = parseInt(detailResponse.itemid, 10);
        this.user.firstname = detailResponse.firstname;
        this.user.lastname = detailResponse.lastname;
        this.user.imageurl = detailResponse.imageurl;
        this.user.avatar = detailResponse.avatar;
        this.user.phone_number = detailResponse.phone_number;
        this.user.street = detailResponse.street;
        this.user.town = detailResponse.town;

        this.user.features_enabled = detailResponse.features_enabled;
        this.user.general_features_enabled =
          detailResponse.general_features_enabled;
        this.user.manual_link = detailResponse.manual_link;
        this.user.demo = detailResponse.demo === true;
        this.user.open_invitations_count =
          detailResponse.open_invitations_count;
        this.user.vehicles_mileage_needed_count =
          detailResponse.vehicles_mileage_needed_count;
        this.user.locked = detailResponse.locked;

        this.user = this.user;

        if ("email" in detailResponse) {
          this.user.email = detailResponse.email;
        }

        // TODO: deprecated -> remove if possible
        if ("autolog_key" in detailResponse) {
          this.user.autolog_key = detailResponse.autolog_key;
        }
        if ("car" in detailResponse) {
          this.user.car = Object.assign(new Car(), detailResponse.car);
        }

        return this.user;
      }),
      catchError((error: any) => {
        return observableThrowError(this.asErrorMessage(error));
      })
    );
  }

  // GET /user/settings
  getUserSettings(): Observable<UserSettings> {
    const url = this.apiUrl + "user/settings";

    return this.http.get(url, this.options).pipe(
      map((json: any) => {
        return json;
      }),
      catchError((error: any) => {
        return observableThrowError(this.asErrorMessage(error));
      })
    );
  }

  // GET /user/settings
  postUserSettings(userSettings: UserSettings): Observable<UserSettings> {
    const url = `${this.apiUrl}user/settings`;

    return this.http.post(url, userSettings, this.options).pipe(
      map((json: any) => {
        return json;
      }),
      catchError((error: any) => {
        return observableThrowError(this.asErrorMessage(error));
      })
    );
  }

  // GET /feature
  getAppFeatures(): Observable<Array<AppFeature>> {
    const url = `${this.apiUrl}feature`;

    return this.doGetForListResponse(url, this.options);
  }

  // GET user/feature
  getUserAppFeatures(): Observable<Array<UserAppFeature>> {
    const url = `${this.apiUrl}user/feature`;

    return this.doGetForListResponse(url, this.options);
  }

  // POST /user/feature
  postUserAppFeature(userAppFeature: UserAppFeature): Observable<boolean> {
    const url = `${this.apiUrl}user/feature`;
    const body = JSON.stringify(userAppFeature);

    return this.doPostWithoutResult(url, body, this.options);
  }
  // DELETE /user/feature/:code
  deleteUserAppFeature(appFeature: AppFeature): Observable<boolean> {
    const url = `${this.apiUrl}user/feature/${appFeature.id}`;

    return this.doDeleteWithoutResult(url, this.options);
  }

  // POST /user/register
  postUserRegisterDemo(): Observable<User> {
    const body = JSON.stringify({
      email: "web-demo@autologg.com",
    });
    const url = `${this.apiUrl}user/register/demo`;
    return this.http.post(url, body, this.options).pipe(
      map((json: any) => {
        this.isValidMogreeResponseOrThrow(json);

        return this.onAuthDataReceived(json);
      }),
      catchError((error: any) => {
        return observableThrowError(this.asErrorMessage(error));
      })
    );
  }

  // POST /user/register
  postUserRegister(registerBody: RegisterBody): Observable<User> {
    const body = JSON.stringify(registerBody.asJson());
    const url = `${this.apiUrl}user/register`;
    return this.http.post(url, body, this.options).pipe(
      map((json: any) => {
        this.isValidMogreeResponseOrThrow(json);

        return this.onAuthDataReceived(json);
      }),
      catchError((error: any) => {
        return observableThrowError(this.asErrorMessage(error));
      })
    );
  }

  // POST /user/register
  postUserRegisterWithoutKey(registerBody: RegisterBody): Observable<User> {
    console.log("Register: " + registerBody);
    const body = JSON.stringify(registerBody.asJsonWithoutKey());
    console.log("Register: " + body);
    const url = `${this.apiUrl}user/register/withoutKey`;

    return this.http.post(url, body, this.options).pipe(
      map((json: any) => {
        this.isValidMogreeResponseOrThrow(json);

        const user = this.onAuthDataReceived(json);
        this.trackingService.register(user.userid.toString());

        return user;
      }),
      catchError((error: any) => {
        return observableThrowError(this.asErrorMessage(error));
      })
    );
  }

  public onAuthDataReceived(json: any): User {
    this.isValidMogreeResponseOrThrow(json);

    this.user = new User();
    this.isLoggedIn = true;

    const detailResponse = json.detailresponse;
    this.user.userid = detailResponse.userid;
    this.user.itemid = detailResponse.itemid;
    this.user.auth_token = detailResponse.auth_token;

    const userData = detailResponse.userdata;
    this.user.email = userData.email;
    this.user.firstname = userData.firstname;
    this.user.lastname = userData.lastname;
    this.user.phone_number = userData.phone_number;
    this.user.street = userData.street;
    this.user.town = userData.town;

    this.user.features_enabled = userData.features_enabled;
    this.user.general_features_enabled = userData.general_features_enabled;
    this.user.manual_link = userData.manual_link;
    this.user.open_invitations_count = userData.open_invitations_count;
    this.user.vehicles_mileage_needed_count =
      userData.vehicles_mileage_needed_count;
    this.user.demo = userData.demo === true;
    this.user.locked = userData.locked;

    // TODO: deprecated -> remove if possible
    if ("autolog_key" in userData) {
      this.user.autolog_key = userData.autolog_key;
    }
    if ("car" in userData) {
      this.user.car = Object.assign(new Car(), userData.car);
    }

    return this.user;
  }

  // GET /cars/oem
  getCarsOem(): Observable<Array<CarOem>> {
    const url = `${this.apiUrl}cars/oem`;
    return this.doGetForListResponse(url, this.options);
  }

  // GET /cars/series
  getCarsSeries(oemid: string): Observable<Array<CarSeries>> {
    const url = `${this.apiUrl}cars/series`;
    const params = this.createParams().set("oemid", oemid);
    this.options["params"] = params;
    return this.doGetForListResponse(url, this.options);
  }

  // GET /cars/models
  getCarsModels(oemid: string, seriesid: string): Observable<Array<CarModel>> {
    const url = `${this.apiUrl}cars/models`;
    const params = this.createParams()
      .set("oemid", oemid)
      .set("seriesid", seriesid);
    this.options["params"] = params;
    return this.doGetForListResponse(url, this.options);
  }

  // PUT /pwd/change
  postPasswordChange(
    newPassword: string,
    oldPassword: string
  ): Observable<boolean> {
    const params = this.createParams()
      .set("password", sha512.hex(newPassword))
      .set("old_password", sha512.hex(oldPassword));
    this.options["params"] = params;

    const body = JSON.stringify({
      password: sha512.hex(newPassword),
      old_password: sha512.hex(oldPassword),
    });

    const url = `${this.apiUrl}pwd/change`;
    return this.http.put(url, body, this.options).pipe(
      map((json: any) => {
        this.isValidMogreeResponseOrThrow(json);
        return true;
      }),
      catchError((error: any) => {
        return observableThrowError(this.asErrorMessage(error));
      })
    );
  }

  // POST /user/vehicle
  postUserVehicle(
    modelId: string,
    modelName: string,
    vehicleKey: string,
    plate: string,
    vehicleFreeText: string
  ): Observable<boolean> {
    const params = this.createParams()
      .set("modelid", modelId)
      .set("model_name", modelName)
      .set("vehiclekey", vehicleKey)
      .set("plate", plate)
      .set("freeText", vehicleFreeText);
    this.options["params"] = params;

    const url = `${this.apiUrl}user/vehicle`;
    return this.http.post(url, {}, this.options).pipe(
      map((json: any) => {
        this.isValidMogreeResponseOrThrow(json);

        return true;
      }),
      catchError((error: any) => {
        return observableThrowError(this.asErrorMessage(error));
      })
    );
  }

  getTrip(tripId: String): Observable<Trip> {
    const url = `${this.apiUrl}trips/${tripId}`;
    return this.doGetForDetailResponse<Trip>(url, {})
      .map((json) => Object.assign(new Trip(), json))
      .map((val) => val.afterPropertiesSet());
  }

  // GET trips/open
  getOpenTrips(
    limit: number,
    sortOrder = "ASC",
    startTime?: Date,
    endTime?: Date
  ): Observable<Array<Trip>> {
    let params = this.createParams()
      .set("pagingindex", "0")
      .set("pagingresults", "" + (limit >= 0 ? limit : 10))
      .set("pagingorder", sortOrder === "ASC" ? "ASC" : "DESC");

    if (startTime) {
      startTime.setHours(0);
      startTime.setMinutes(0);
      startTime.setSeconds(0);
      params = params.set("start_time", startTime.toISOString());
    }
    if (endTime) {
      endTime.setHours(23);
      endTime.setMinutes(59);
      endTime.setSeconds(59);
      params = params.set("end_time", endTime.toISOString());
    }

    this.options["params"] = params;

    const url = `${this.apiUrl}trips/open`;

    return this.doGetForListResponse<Trip>(url, this.options).map((trips) =>
      trips
        .map((json) => Object.assign(new Trip(), json))
        .map((val) => val.afterPropertiesSet())
    );
  }

  getUnassignedTrips(
    limit: number,
    sortOrder = "ASC",
    startTime?: Date,
    endTime?: Date
  ): Observable<Array<Trip>> {
    let params = this.createParams()
      .set("pagingindex", "0")
      .set("pagingresults", "" + (limit >= 0 ? limit : 10))
      .set("pagingorder", sortOrder === "ASC" ? "ASC" : "DESC");

    if (startTime) {
      startTime.setHours(0);
      startTime.setMinutes(0);
      startTime.setSeconds(0);
      params = params.set("start_time", startTime.toISOString());
    }
    if (endTime) {
      endTime.setHours(23);
      endTime.setMinutes(59);
      endTime.setSeconds(59);

      params = params.set("end_time", endTime.toISOString());
    }
    this.options["params"] = params;

    const url = `${this.apiUrl}trips/unassigned`;

    return this.doGetForListResponse<Trip>(url, this.options).map((trips) =>
      trips
        .map((json) => Object.assign(new Trip(), json))
        .map((val) => val.afterPropertiesSet())
    );
  }

  connectTrips(tripIds: Array<string>): Observable<Trip> {
    let params = this.createParams().set("type_trip", "0");
    tripIds.forEach((tripId: string) => {
      params = params.append("ids", `${tripId}`);
    });

    this.options["params"] = params;

    const url = `${this.apiUrl}trips/connect`;

    return this.doPostForDetailResponse<Trip>(url, null, this.options)
      .map((json) => Object.assign(new Trip(), json))
      .map((val) => val.afterPropertiesSet());
  }

  async unlinkTrips(tripId: string): Promise<boolean> {
    let params = this.createParams();

    this.options["params"] = params;

    const url = `${this.apiUrl}trips/${tripId}/split`;

    return await this.doPutWithoutResult(url, null, this.options).toPromise();
  }

  addTrip(trip: Trip): Observable<boolean> {
    const url = `${this.apiUrl}trips`;
    const body = JSON.stringify(trip);
    return this.doPostWithoutResult(url, body, this.options);
  }

  addTrips(trips: Array<Trip>): Observable<boolean> {
    const url = `${this.apiUrl}trips/bulk`;
    const body = JSON.stringify(trips);
    return this.doPostWithoutResult(url, body, this.options);
  }

  putTrip(trip: Trip): Observable<boolean> {
    return this.putTrips([trip]);
  }

  putTrips(trips: Array<Trip>): Observable<boolean> {
    const url = `${this.apiUrl}trips/bulk`;
    const body = JSON.stringify(trips);
    return this.doPutWithoutResult(url, body, this.options);
  }

  putAssignDriver(trip: Trip): Observable<boolean> {
    return this.putAssignDrivers([trip]);
  }

  putAssignDrivers(trips: Array<Trip>): Observable<boolean> {
    const url = `${this.apiUrl}trips/assignDriverUserId`;

    let data = new Array<any>();
    for (let trip of trips) {
      var entity = {
        id: trip.itemid,
        driverUserId: trip.driver_user_id ? trip.driver_user_id : null,
      };
      data.push(entity);
    }

    const body = JSON.stringify(data);
    return this.doPostWithoutResult(url, body, this.options);
  }

  // GET /hotspot/frequent
  getHotspotsWithFrequency(): Observable<Array<Hotspot>> {
    const url = `${this.apiUrl}hotspot/frequent`;
    return this.doGetForListResponse(url, this.options);
  }

  // GET /hotspot
  getUserHotspots(): Observable<Array<Hotspot>> {
    const params = this.createParamsWithPaging();
    this.options["params"] = params;

    const url = `${this.apiUrl}hotspot`;
    return this.doGetForListResponse(url, this.options);
  }

  // GET /hotspot/:id
  getUserHotspotById(hotspotId: number): Observable<Hotspot> {
    const url = `${this.apiUrl}hotspot/${hotspotId}`;
    return this.doGetForDetailResponse(url, this.options);
  }

  saveHotpots(hotspots: Hotspot[]) {
    const url = `${this.apiUrl}hotspot/import`;
    const body = JSON.stringify(hotspots);
    return this.doPostForDetailResponse(url, body, this.options);
  }

  // POST /hotspot
  createUserHotspot(hotspot: Hotspot): Observable<Hotspot> {
    const url = `${this.apiUrl}hotspot`;
    const body = JSON.stringify(hotspot);
    return this.doPostForDetailResponse(url, body, this.options);
  }

  // POST /hotspot/:id
  updateUserHotspot(hotspotId: number, hotspot: Hotspot): Observable<Hotspot> {
    const url = `${this.apiUrl}hotspot/${hotspotId}`;
    const body = JSON.stringify(hotspot);
    return this.doPostForDetailResponse(url, body, this.options);
  }

  // DELETE /hotspot/:id
  deleteUserHotspot(hotspotId: number): Observable<boolean> {
    const url = `${this.apiUrl}hotspot/${hotspotId}`;
    return this.doDeleteWithoutResult(url, this.options);
  }

  // GET /statistics
  getStatistics(): Observable<Statistics> {
    const url = `${this.apiUrl}statistics`;
    return this.doGetForDetailResponse(url, this.options);
  }

  // GET /statistics/years
  getStatisticsYear(year: number): Observable<Statistics> {
    const params = this.createParams().set("year", year.toString());
    this.options["params"] = params;

    const url = `${this.apiUrl}statistics/years`;
    return this.doGetForDetailResponse(url, this.options);
  }

  // GET /user/box/{id}/model
  getUserBoxModels(vehicelId: number): Observable<Array<Car>> {
    const url = `${this.apiUrl}user/box/${vehicelId}/model`;
    return this.doGetForListResponse(url, this.options);
  }

  // GET /user/box/{id}/model/active
  getUserBoxModelActive(vehicelId: number): Observable<Array<Car>> {
    const url = `${this.apiUrl}user/box/${vehicelId}/model`;
    return this.doGetForListResponse(url, this.options);
  }

  // GET /user/group
  getUserGroups(): Observable<Array<UserGroupMembershipWithMetadata>> {
    const params = this.createParamsWithPaging();
    this.options["params"] = params;

    const url = `${this.apiUrl}user/group`;
    return this.doGetForListResponse(url, this.options);
  }

  // POST /user/group
  createUserGroup(group: GroupCreateRequest): Observable<Group> {
    const url = `${this.apiUrl}user/group`;
    const body = JSON.stringify(group);

    return this.doPostForDetailResponse(url, body, this.options);
  }

  // GET /user/group/invitations
  getUserGroupInvitations(): Observable<Array<GroupInvitation>> {
    const params = this.createParamsWithPaging();
    this.options["params"] = params;

    const url = `${this.apiUrl}user/group/invitation`;

    return this.doGetForListResponse(url, this.options);
  }

  // POST user/group/:groupId/invitation/:invitationId/accept
  acceptGroupInvitation(invitationId: number): Observable<boolean> {
    const url = `${this.apiUrl}user/group/invitation/${invitationId}/accept`;
    return this.doPostWithoutResult(url, null, this.options);
  }

  // POST user/group/:groupId/invitation/:invitationId/decline
  declineGroupInvitation(invitationId: number): Observable<boolean> {
    const url = `${this.apiUrl}user/group/invitation/${invitationId}/decline`;
    return this.doPostWithoutResult(url, null, this.options);
  }

  // GET /user/group/:groupId/membership
  getGroupMembership(groupId: number): Observable<GroupUserMembership> {
    const url = `${this.apiUrl}user/group/${groupId}/membership`;
    return this.doGetForDetailResponse(url, this.options);
  }

  // POST /user/group/:groupId/membership
  updateGroupMembershipByMember(
    groupId: number,
    groupUpdateRequest: GroupMembershipUpdateRequest
  ): Observable<GroupUserMembership> {
    const url = `${this.apiUrl}user/group/${groupId}/membership`;

    const body = JSON.stringify(groupUpdateRequest);

    return this.doPostForDetailResponse(url, body, this.options);
  }

  // DELETE user/group/:id/membership
  leaveGroup(groupId: number): Observable<any> {
    const url = `${this.apiUrl}user/group/${groupId}/membership`;
    return this.doDeleteWithoutResult(url, this.options);
  }

  // GET /user/group/:groupId/box
  getUserGroupBox(groupId: number): Observable<Array<GroupUserBox>> {
    const url = `${this.apiUrl}user/group/${groupId}/box`;

    const params = this.createParamsWithPaging();
    this.options["params"] = params;

    return this.doGetForListResponse(url, this.options);
  }

  // GET /user/group/:groupId/hotspot
  getUserGroupHotspot(groupId: number): Observable<Array<GroupUserHotspot>> {
    const url = `${this.apiUrl}user/group/${groupId}/hotspot`;

    const params = this.createParamsWithPaging();
    this.options["params"] = params;

    return this.doGetForListResponse(url, this.options);
  }

  // POST /user/group/:groupId/box/
  userShareBoxWithGroup(
    groupId: number,
    request: CreateGroupBoxRequest
  ): Observable<boolean> {
    const url = `${this.apiUrl}user/group/${groupId}/box`;

    const body = JSON.stringify(request);

    return this.doPostWithoutResult(url, body, this.options);
  }

  // POST /user/group/:groupId/box/:boxId
  updateUserGroupBox(
    groupId: number,
    vehicleId: number,
    request: UpdateGroupBoxRequest
  ): Observable<boolean> {
    const url = `${this.apiUrl}user/group/${groupId}/box/${vehicleId}`;

    const body = JSON.stringify(request);

    return this.doPostWithoutResult(url, body, this.options);
  }

  // DELETE /user/group/:groupId/box/:boxId/share
  userUnshareBoxWithGroup(
    groupId: number,
    vehicleId: number
  ): Observable<boolean> {
    const url = `${this.apiUrl}user/group/${groupId}/box/${vehicleId}`;
    return this.doDeleteWithoutResult(url, this.options);
  }

  // POST /user/group/:groupId/hotspot/:hotspotId/share
  userShareHotspotWithGroup(
    groupId: number,
    hotspotId: number
  ): Observable<boolean> {
    const url = `${this.apiUrl}user/group/${groupId}/hotspot/${hotspotId}/share`;
    return this.doPostWithoutResult(url, null, this.options);
  }

  // DELETE /user/group/:groupId/hotspot/:hotspotId/share
  userUnshareHotspotWithGroup(
    groupId: number,
    hotspotId: number
  ): Observable<boolean> {
    const url = `${this.apiUrl}user/group/${groupId}/hotspot/${hotspotId}/share`;
    return this.doDeleteWithoutResult(url, this.options);
  }

  // GET /group/{id}
  getGroupById(groupId: number): Observable<Group> {
    const url = `${this.apiUrl}group/${groupId}`;
    return this.doGetForDetailResponse(url, this.options);
  }

  // DELETE /group/:groupId
  deleteGroup(groupId: number): Observable<boolean> {
    const url = `${this.apiUrl}user/group/${groupId}`;
    return this.doDeleteWithoutResult(url, this.options);
  }

  // POST /group/{groupId}
  updateGroup(
    groupId: number,
    groupUpdateRequest: GroupUpdateRequest
  ): Observable<Group> {
    const url = `${this.apiUrl}group/${groupId}`;

    const body = JSON.stringify(groupUpdateRequest);

    return this.doPostForDetailResponse(url, body, this.options);
  }

  // POST /group/:id/invitation
  createGroupInvitation(
    groupId: number,
    request: GroupCreateInvitationRequest
  ): Observable<any> {
    const url = `${this.apiUrl}group/${groupId}/invitation`;

    const body = JSON.stringify(request);

    return this.doPostWithoutResult(url, body, this.options);
  }

  // GET group/:id/invitation
  getGroupInvitation(groupId: number): Observable<Array<GroupInvitation>> {
    const url = `${this.apiUrl}group/${groupId}/invitation`;

    const params = this.createParamsWithPaging();
    this.options["params"] = params;

    return this.doGetForListResponse(url, this.options);
  }

  // GET group/:id/membership
  getGroupMemberships(groupId: number): Observable<Array<GroupUserMembership>> {
    const url = `${this.apiUrl}group/${groupId}/membership`;

    const params = this.createParamsWithPaging();
    this.options["params"] = params;

    return this.doGetForListResponse(url, this.options);
  }

  // POST /group/:groupId/membership
  updateGroupMembershipByAdmin(
    groupId: number,
    membershipId: number,
    groupUpdateRequest: GroupMembershipUpdateRequest
  ): Observable<GroupUserMembership> {
    const url = `${this.apiUrl}group/${groupId}/membership/${membershipId}`;

    const body = JSON.stringify(groupUpdateRequest);

    return this.doPostForDetailResponse(url, body, this.options);
  }

  // GET group/:id/box
  getGroupBoxes(groupId: number): Observable<Array<GroupUserBox>> {
    const url = `${this.apiUrl}group/${groupId}/box`;

    const params = this.createParamsWithPaging();
    this.options["params"] = params;

    return this.doGetForListResponse(url, this.options);
  }

  // GET group/:groupId/box/:vehicleId
  getGroupUserGroupBoxById(
    groupId: number,
    vehicleId: number
  ): Observable<GroupUserBox> {
    const url = `${this.apiUrl}group/${groupId}/box/${vehicleId}`;
    return this.doGetForDetailResponse(url, this.options);
  }

  // GET group/:id/box/trip/latest
  getGroupBoxesWithLatestTrip(
    groupId: number
  ): Observable<Array<GroupUserBoxWithLatestTrip>> {
    const url = `${this.apiUrl}group/${groupId}/box/trip/latest`;

    const params = this.createParamsWithPaging();
    this.options["params"] = params;

    return this.doGetForListResponse(url, this.options);
  }

  // GET group/:id/box/:vehicleId/trip/open
  getGroupBoxOpenTrips(
    groupId: number,
    vehicleId: number
  ): Observable<Array<Trip>> {
    const params = this.createParamsWithPaging();
    this.options["params"] = params;

    const url = `${this.apiUrl}group/${groupId}/box/${vehicleId}/trip/open`;

    return this.doGetForListResponse(url, this.options);
  }

  // GET group/:id/hotspot
  getGroupHotspots(groupId: number): Observable<Array<GroupUserHotspot>> {
    const url = `${this.apiUrl}group/${groupId}/hotspot`;

    const params = this.createParamsWithPaging().set("pagingresults", "10000");
    this.options["params"] = params;

    return this.doGetForListResponse(url, this.options);
  }

  // GET group/:id/invitation
  getGroupEvent(groupId: number): Observable<Array<GroupEvent>> {
    const url = `${this.apiUrl}group/${groupId}/event`;

    const params = this.createParamsWithPaging();
    this.options["params"] = params;

    return this.doGetForListResponse(url, this.options);
  }

  // DELETE /group/:groupId/box/:boxId
  removeBoxFromGroup(groupId: number, vehicleId: number): Observable<boolean> {
    const url = `${this.apiUrl}group/${groupId}/box/${vehicleId}`;
    return this.doDeleteWithoutResult(url, this.options);
  }

  // DELETE /group/:groupId/membership/:membershipId
  removeMembershipFromGroup(
    groupId: number,
    membershipId: number
  ): Observable<boolean> {
    const url = `${this.apiUrl}group/${groupId}/membership/${membershipId}`;
    return this.doDeleteWithoutResult(url, this.options);
  }

  // DELETE /group/:groupId/invitation/:invitationId
  removeInvitationsFromGroup(
    groupId: number,
    invitationId: number
  ): Observable<boolean> {
    const url = `${this.apiUrl}group/${groupId}/invitation/${invitationId}`;
    return this.doDeleteWithoutResult(url, this.options);
  }

  // DELETE /group/:groupId/hotspot/:hotspotId
  removeHotspotFromGroup(
    groupId: number,
    hotspotId: number
  ): Observable<boolean> {
    const url = `${this.apiUrl}group/${groupId}/hotspot/${hotspotId}`;
    return this.doDeleteWithoutResult(url, this.options);
  }

  // GET /group/:groupId/export/pdf
  getGroupExportPdfLink(
    groupId: number,
    vehicleIds: Array<number>,
    dateFrom: Date,
    dateUntil: Date,
    tripType: number,
    showDriver: boolean
  ): string {
    return this.getGroupExportLink(
      "pdf",
      groupId,
      vehicleIds,
      dateFrom,
      dateUntil,
      tripType,
      showDriver
    );
  }

  // GET /group/:groupId/export/csv
  getGroupExportCsvLink(
    groupId: number,
    vehicleIds: Array<number>,
    dateFrom: Date,
    dateUntil: Date,
    tripType: number,
    showDriver: boolean
  ): string {
    return this.getGroupExportLink(
      "csv",
      groupId,
      vehicleIds,
      dateFrom,
      dateUntil,
      tripType,
      showDriver
    );
  }

  // GET /group/:groupId/export/:exportType
  getGroupExportLink(
    exportType: string,
    groupId: number,
    vehicleIds: Array<number>,
    dateFrom: Date,
    dateUntil: Date,
    tripType: number,
    showDriver: boolean
  ): string {
    const userId = this.user.itemid.toString();

    const vehicleIdParams = vehicleIds
      .map((val) => "vehicleId=" + val)
      .join("&");
    const queryParams = [
      "type_trip=" + tripType,
      "start_date=" + this.formatDateForExport(dateFrom),
      "end_date=" + this.formatDateForExport(dateUntil),
      "show_driver=" + showDriver,
      `${MoreHttpHeaders.HEADER_PARAM_UID}=${userId}`,
      vehicleIdParams,
    ];

    const baseUrl = `${this.apiUrl}group/${groupId}/export/${exportType}`;
    return baseUrl + "?" + queryParams.join("&");
  }

  // GET /export
  getGroupExportPreview(
    groupId: number,
    vehicleId: number,
    dateFrom: Date,
    dateUntil: Date,
    tripType: number
  ): Observable<Car[]> {
    const params = this.createParams()
      .set("vehicleId", String(vehicleId))
      .set("type_trip", String(tripType))
      .set("start_date", this.formatDateForExport(dateFrom))
      .set("end_date", this.formatDateForExport(dateUntil));
    this.options["params"] = params;

    const url = `${this.apiUrl}group/${groupId}/export`;
    return this.doGetForListResponse(url, this.options);
  }

  // GET /user/box
  getAllUserBoxes(): Observable<Array<Box>> {
    const url = `${this.apiUrl}user/box`;
    return this.doGetForListResponse(url, this.options);
  }

  // GET /user/group/adminbox
  getAdministrableBoxes(): Observable<Array<Box>> {
    const url = `${this.apiUrl}user/group/adminbox`;
    return this.doGetForListResponse(url, this.options);
  }

  // GET /user/box
  getUserBoxes(providerType: ProviderType): Observable<Array<Box>> {
    const url = `${this.apiUrl}user/box?provider_type=${providerType}`;
    return this.doGetForListResponse(url, this.options);
  }

  // GET /user/box
  getUserBoxById(vehicleId: number): Observable<Box> {
    const url = `${this.apiUrl}user/box/${vehicleId}`;
    return this.doGetForDetailResponse(url, this.options);
  }

  // GET /user/box/active
  getUserBoxActive(): Observable<Box> {
    const url = `${this.apiUrl}user/box/active`;
    return this.doGetForDetailResponse(url, this.options);
  }

  // POST /user/box
  createUserBox(box: BoxCreateRequest): Observable<Box> {
    const url = `${this.apiUrl}user/box`;
    const body = JSON.stringify(box);
    return this.doPostForDetailResponse(url, body, this.options);
  }

  // POST /user/box
  updateUserBox(vehicleId: number, box: BoxUpdateRequest): Observable<Box> {
    const url = `${this.apiUrl}user/box/${vehicleId}`;
    const body = JSON.stringify(box);
    return this.doPostForDetailResponse(url, body, this.options);
  }

  // POST /user/box/:vehicleId/mileage
  updateUserBoxMileage(
    vehicleId: number,
    mileage: number
  ): Observable<boolean> {
    const url = `${this.apiUrl}user/box/${vehicleId}/mileage?mileage=${mileage}`;
    const body = JSON.stringify(mileage);
    return this.doPostForDetailResponse(url, body, this.options);
  }

  // DELETE /user/box/:vehicleId/model/:modelId
  deleteModelFromBox(vehicleId: number, modelId: number): Observable<boolean> {
    const url = `${this.apiUrl}user/box/${vehicleId}/model/${modelId}`;
    return this.doDeleteWithoutResult(url, this.options);
  }

  // GET /user/box/{boxId}/activate
  activateBoxForUser(box: Box): Observable<any> {
    const url = `${this.apiUrl}user/box/${box.vehicle_id}/activate`;
    return this.doPostWithoutResult(url, null, this.options);
  }

  // GET /user/currentcar
  getTripsLast(): Observable<any> {
    const url = `${this.apiUrl}user/currentcar`;
    return this.http.get(url, this.options).pipe(
      map((json: any) => {
        if (json.statuscode !== 200) {
          if (json.statuscode === 80) {
            return {};
          }
          throw new Error(json.errormessage);
        } else {
          const detailResponse = json.detailresponse;

          return {
            address: detailResponse.address,
            lat: detailResponse.latitude,
            lng: detailResponse.longitude,
            km: detailResponse.km,
            plate: detailResponse.plate,
            years: detailResponse.years,
          };
        }
      }),
      catchError((error: any) => {
        return observableThrowError(this.asErrorMessage(error));
      })
    );
  }

  // POST /pwd/forget
  postPasswordReset(email: string): Observable<string> {
    const params = this.createParams().set("mail", email);

    this.options["params"] = params;

    const url = `${this.apiUrl}pwd/forget`;
    return this.http.post(url, null, this.options).pipe(
      map((json: any) => {
        this.isValidMogreeResponseOrThrow(json);
        return "Password reset success";
      }),
      catchError((error: any) => {
        return observableThrowError(this.asErrorMessage(error));
      })
    );
  }

  // POST /user/avatar
  postUserAvatar(imgBase64: string): Observable<boolean> {
    const body = JSON.stringify({
      base64: imgBase64,
    });
    const url = `${this.apiUrl}user/avatar`;
    return this.http
      .post(url, body, this.options)
      .pipe(
        flatMap((json: any) => {
          this.isValidMogreeResponseOrThrow(json);

          return this.getUserProfile();
        })
      )
      .pipe(
        map((foo) => true),
        catchError((error: any) => {
          return observableThrowError(this.asErrorMessage(error));
        })
      );
  }

  // PUT /user/edit
  postUserEdit(): Observable<User> {
    const body = JSON.stringify({
      email: this.user.email,
      firstname: this.user.firstname,
      lastname: this.user.lastname,
      street: this.user.street,
      town: this.user.town,
      phone_number: this.user.phone_number,
    });

    const url = `${this.apiUrl}user/edit`;
    return this.http.put(url, body, this.options).pipe(
      map((json: any) => {
        this.isValidMogreeResponseOrThrow(json);

        const detailResponse = json.detailresponse;

        this.user.email = detailResponse.email;
        this.user.firstname = detailResponse.firstname;
        this.user.lastname = detailResponse.lastname;
        this.user.street = detailResponse.street;
        this.user.town = detailResponse.town;
        this.user.phone_number = detailResponse.phone_number;

        return this.user;
      }),
      catchError((error: any) => {
        return observableThrowError(this.asErrorMessage(error));
      })
    );
  }

  // GET /export/pdf
  getExportPdfLink(
    dateFrom: Date,
    dateUntil: Date,
    tripType: number,
    displayPrivateData: boolean,
    showDriver: boolean
  ): string {
    const userId = this.user.itemid.toString();

    const queryParams = [
      "type_trip=" + tripType,
      "start_date=" + this.formatDateForExport(dateFrom),
      "end_date=" + this.formatDateForExport(dateUntil),
      "display_private_data=" + displayPrivateData,
      "show_driver=" + showDriver,
      `${MoreHttpHeaders.HEADER_PARAM_UID}=${userId}`,
    ];

    return this.apiUrl + "export/pdf?" + queryParams.join("&");
  }

  // GET /export/csv
  getExportCsvLink(
    dateFrom: Date,
    dateUntil: Date,
    tripType: number,
    displayPrivateData: boolean,
    showDriver: boolean
  ): string {
    const userId = this.user.itemid.toString();

    const queryParams = [
      "type_trip=" + tripType,
      "start_date=" + this.formatDateForExport(dateFrom),
      "end_date=" + this.formatDateForExport(dateUntil),
      "display_private_data=" + displayPrivateData,
      "show_driver=" + showDriver,
      `${MoreHttpHeaders.HEADER_PARAM_UID}=${userId}`,
    ];

    return this.apiUrl + "export/csv?" + queryParams.join("&");
  }

  // GET /export
  getExportPreview(
    dateFrom: Date,
    dateUntil: Date,
    tripType: number
  ): Observable<Car[]> {
    const params = this.createParams()
      .set("type_trip", String(tripType))
      .set("start_date", this.formatDateForExport(dateFrom))
      .set("end_date", this.formatDateForExport(dateUntil));
    this.options["params"] = params;

    const url = this.apiUrl + "export";

    return this.doGetForListResponse(url, this.options);
  }

  // GET /user/autologkey
  checkAutoLoggKey(autologgKey: string): Observable<any> {
    const params = this.createParams().set("autologgkey", autologgKey);
    this.options["params"] = params;

    const url = `${this.apiUrl}user/autologgkey`;
    return this.doGetWithoutResult(url, this.options);
  }

  private doPutWithoutResult(url, body, options): Observable<boolean> {
    return this.http.put(url, body, options).pipe(
      map((json: any) => {
        this.isValidMogreeResponseOrThrow(json);

        return true;
      }),
      catchError((error: any) => {
        return observableThrowError(this.asErrorMessage(error));
      })
    );
  }

  private doPostWithoutResult(url, body, options): Observable<boolean> {
    return this.http.post(url, body, options).pipe(
      map((json: any) => {
        this.isValidMogreeResponseOrThrow(json);

        return true;
      }),
      catchError((error: any) => {
        return observableThrowError(this.asErrorMessage(error));
      })
    );
  }

  private doPostForDetailResponse<T>(url, body, options): Observable<T> {
    return this.http.post(url, body, options).pipe(
      map((json: any) => {
        this.isValidMogreeResponseOrThrow(json);
        return json.detailresponse;
      }),
      catchError((error: any) => {
        return observableThrowError(this.asErrorMessage(error));
      })
    );
  }

  private doGetWithoutResult(url, options): Observable<boolean> {
    return this.http.get(url, options).pipe(
      map((json: any) => {
        this.isValidMogreeResponseOrThrow(json);
        return true;
      }),
      catchError((error: any) => {
        return observableThrowError(this.asErrorMessage(error));
      })
    );
  }

  private doGetForDetailResponse<T>(url, options): Observable<T> {
    return this.http.get(url, options).pipe(
      map((json: any) => {
        this.isValidMogreeResponseOrThrow(json);
        return json.detailresponse;
      }),
      catchError((error: any) => {
        return observableThrowError(this.asErrorMessage(error));
      })
    );
  }

  private doGetForListResponse<T>(url, options): Observable<Array<T>> {
    return this.http.get(url, options).pipe(
      map((json: any) => {
        this.isValidMogreeResponseOrThrow(json);
        return json.listresponse;
      }),
      catchError((error: any) => {
        return observableThrowError(this.asErrorMessage(error));
      })
    );
  }

  private doDeleteWithoutResult(url, options): Observable<boolean> {
    return this.http.delete(url, options).pipe(
      map((json: any) => {
        this.isValidMogreeResponseOrThrow(json);
        return true;
      }),
      catchError((error: any) => {
        return observableThrowError(this.asErrorMessage(error));
      })
    );
  }

  public formatDateForExport(_date) {
    let date = _date;
    if (date instanceof Date === false) {
      date = new Date();
    }

    // this is stupid.. nobody has ever seen dates formatted this way..
    return formatDate(date, "dd-MM-yyyy", "de");
  }

  private asErrorMessage(error: any): string {
    return error.errormessage || error.message || "Server error";
  }

  // GET trips/open
  getDrivers(limit: number, sortOrder = "ASC"): Observable<Array<SimpleUser>> {
    const params = this.createParams()
      .set("pagingindex", "0")
      .set("pagingresults", "" + (limit >= 0 ? limit : 10))
      .set("pagingorder", sortOrder === "ASC" ? "ASC" : "DESC");
    this.options["params"] = params;

    const url = `${this.apiUrl}user/group/users`;

    return this.doGetForListResponse<SimpleUser>(url, this.options).map(
      (trips) => trips.map((json) => Object.assign(new SimpleUser(), json))
    );
  }
}
