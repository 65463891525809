import { AfterViewInit, Component, OnInit, Input } from "@angular/core";
import { DatePipe } from "@angular/common";

import { GroupUserBox } from "../../../_models/group.model";
import { Trip } from "../../../_models/trip.model";
import { BaseComponent } from "../../../base/base.component";
import { Helper } from "app/base/helper";

@Component({
  selector: "app-groups-manage-box-open-trips",
  templateUrl: "./open-trips.component.html",
})
export class ManageGroupBoxOpenTripsComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  private static datePipe = new DatePipe(Helper.getBrowserLang());

  @Input("groupId") groupId: number;
  @Input("userGroupBox") userGroupBox: GroupUserBox;

  loading: boolean = false;
  updating: boolean = false;

  openTrips: Array<Trip> = [];

  ngOnInit() {
    this.loadOpenTrips();
  }

  ngAfterViewInit() {}

  onSorted($event) {
    this.openTrips.sort((a, b) => {
      if ($event.sortDirection === "desc") {
        return a[$event.sortColumn] < b[$event.sortColumn] ? 1 : -1;
      } else {
        return a[$event.sortColumn] > b[$event.sortColumn] ? 1 : -1;
      }
    });
  }

  reload() {
    if (!this.userGroupBox.group_box.display_open_trips_enabled) {
      return;
    }

    this.updating = true;
    this.fetchOpenTrips().subscribe(
      (openTrips) => {
        this.openTrips = openTrips;
      },
      (err) => {
        this.updating = false;
        this.showError(err);
      },
      () => {
        this.updating = false;
      }
    );
  }

  private loadOpenTrips() {
    if (!this.userGroupBox.group_box.display_open_trips_enabled) {
      return;
    }

    this.loading = true;
    this.fetchOpenTrips().subscribe(
      (openTrips) => {
        this.openTrips = openTrips;
      },
      (err) => {
        this.loading = false;
        this.showError(err);
      },
      () => {
        this.loading = false;
      }
    );
  }

  private fetchOpenTrips() {
    const datePipe = ManageGroupBoxOpenTripsComponent.datePipe;
    return this._apiService
      .getGroupBoxOpenTrips(
        this.groupId,
        this.userGroupBox.group_box.vehicle_id
      )
      .do((trips) => {
        trips.forEach((trip) => {
          if (trip.start_time) {
            const startTimeAsDate = new Date(
              trip.start_time
            );
            trip.start_time_display = datePipe
              .transform(startTimeAsDate, "shortTime")
              .toString();
            trip.weekday_display = datePipe
              .transform(startTimeAsDate, "EEEE")
              .toString();
          }
          if (trip.end_time) {
            const endTimeAsDate = new Date(trip.end_time);
            trip.end_time_display = datePipe
              .transform(endTimeAsDate, "shortTime")
              .toString();
          }
        });
      });
  }
}
