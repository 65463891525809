import { AfterViewInit, Component, ViewChild, OnInit } from "@angular/core";
import { BaseComponent } from "../../base/base.component";
import { GroupUpdateRequest } from "../../_models/group-update-request.model";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-dialog-group-delete",
  template: `<h2 class="mb-3" translate>groups.update.delete.modal.title</h2>
    <form #deleteGroup="ngForm" (submit)="submitForm()">
      <p translate>groups.update.delete.modal.notice</p>
      <mat-form-field floatPlaceholder="always" class="w-100 mb-2">
        <input
          matInput
          #groupNameField="ngModel"
          type="text"
          [(ngModel)]="groupName"
          name="groupName"
          [pattern]="originalGroupName"
          placeholder=""
          required
          autofocus
          autocomplete="off"
        />
      </mat-form-field>
      <button
        mat-button
        color="warn"
        [disabled]="!deleteGroup.valid"
        type="submit"
      >
        <span translate>groups.update.delete.modal.submit</span>
      </button>
      <button mat-button type="button" (click)="closeDialog()">
        <span translate>global.cancel</span>
      </button>
    </form>`,
})
export class DialogGroupDeleteComponent {
  originalGroupName: string;
  groupName: string;

  constructor(public dialogRef: MatDialogRef<UpdateGroupComponent>) {}

  submitForm() {
    const groupNameMatches =
      this.groupName && this.groupName === this.originalGroupName;
    if (groupNameMatches) {
      this.dialogRef.close({ deleteGroup: true });
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }
}

@Component({
  templateUrl: "./update-group.component.html",
  styleUrls: ["update-group.component.css"],
})
export class UpdateGroupComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  @ViewChild("addGroupForm", { static: false }) form;

  loading: boolean = true;

  groupId: number;

  originalGroupName: string = "";
  name: string = "";
  description: string = "";

  descriptionMaxCharacterCount: number = 1024;
  descriptionRemainingCharacterCount: number = 0;

  ngOnInit() {
    this.groupId = +this.route.snapshot.paramMap.get("id");

    this.loading = true;
    this._apiService.getGroupById(this.groupId).subscribe(
      (group) => {
        this.originalGroupName = group.name || "";
        this.name = group.name || "";
        this.description = group.description || "";
      },
      (err) => {
        this.loading = false;
        this.showError(err);
      },
      () => {
        this.loading = false;
      }
    );
  }

  ngAfterViewInit() {
    this.form.control.valueChanges.subscribe((values) => {
      this.formControlValueChanged();
    });
    this.setupNewForm();
  }

  formControlValueChanged() {
    this.updateDescriptionRemainingCharacterCount();
  }

  updateDescriptionRemainingCharacterCount() {
    this.descriptionRemainingCharacterCount =
      this.descriptionMaxCharacterCount - (this.description || "").length;
  }

  isSubmitButtonEnabled() {
    return this.name.length > 1;
  }

  setupNewForm() {
    this.form.resetForm();

    this.name = "";
    this.description = "";
  }

  submitForm() {
    if (!this.isSubmitButtonEnabled()) {
      throw new Error("Form is in invalid state and cannot be submitted");
    }

    const updateRequest = new GroupUpdateRequest();
    updateRequest.description = this.description;
    updateRequest.name = this.name;

    this.loading = true;

    this._apiService.updateGroup(this.groupId, updateRequest).subscribe(
      (foo) => {
        this.showSuccess("Group updated");
        this.setupNewForm();
        this.router.navigate(["/groups"]);
      },
      (err) => {
        this.loading = false;
        this.showError(err);
      },
      () => {
        this.loading = false;
      }
    );
  }

  openDeleteGroupDialog() {
    const dialogRef = this.dialog.open(DialogGroupDeleteComponent);

    const instance = dialogRef.componentInstance;
    instance.originalGroupName = this.originalGroupName;

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.deleteGroup === true) {
        this.loading = true;
        this._apiService.deleteGroup(this.groupId).subscribe(
          (foo) => {
            this.router.navigate(["/groups"]);
            this.showSuccess("groups.update.delete.modal.success");
          },
          (err) => {
            this.loading = false;
            this.showError(err);
          },
          () => {
            this.loading = false;
          }
        );
      }
    });
  }
}
