<div class="row">

  <div class="col-sm-12"  [ngClass]="{'col-lg-6': !isDialog, 'col-lg-12': isDialog, 'col-md-6': !isDialog, 'col-md-12': isDialog}">
    <div class="card margin" style="margin: 5px">
      <div class="card-body">
        <ng-container *ngIf="providerType == ProviderType.HighMobility">

          <h5 class="card-title" translate> {{ loginTitle }}</h5>
          <app-oauth2-login style="padding: 20px" #oauth2Login [(credentials)]="connectedCredentials"
            (oauth2Completed)="onOauth2Completed($event)" (oauth2Failed)="onOauth2Failed($event)" [providerType]="providerType"
            [oemType]="oemType" [brandType]="oemType" [(vin)]="vin"></app-oauth2-login>
        </ng-container>
        <ng-container *ngIf="oemType == OemType.Dongle">
          <app-autoaid-login (onVehiclesUpdated)="onDongleVehiclesUpdate($event)"></app-autoaid-login>
        </ng-container>        
        <ng-container *ngIf="oemType == OemType.Mercedes">
          <app-connected-mercedes-login (onVehiclesUpdated)="onDongleVehiclesUpdate($event)"></app-connected-mercedes-login>
        </ng-container>
        <ng-container *ngIf="providerType != ProviderType.HighMobility && oemType != OemType.Dongle && oemType != OemType.Mercedes">
          <h5 class="card-title" translate> {{ loginTitle }}</h5>
          <app-oauth-login style="padding: 20px" #oauthLogin [(credentials)]="connectedCredentials"></app-oauth-login>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="!isDialog">
    <div class="card margin flex-1 hide-dialog" style="margin: 5px; min-width: 15rem;">
      <div class="card-body bg-gray-100">
        <div class="flex px-8 py-4">
          <mat-icon color="accent">show_chart</mat-icon>
          <p class="ml-8 text-gray-700" translate>
            connected_boxes.vehicle_login_info_data
          </p>
        </div>
        <div class="flex px-8 text-gray-700" *ngIf="oemType != OemType.Dongle && oemType != OemType.Mercedes">
          <mat-icon color="accent">security</mat-icon>
          <p class="ml-8" translate>
            connected_boxes.vehicle_login_info_security
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="margin-hint" *ngIf="providerType == ProviderType.HighMobility && !isDialog">
  <h5 translate> connected_boxes.howToConnect </h5>
  <div class="row center dialog-box margin-hint" style="justify-content: left">
    <div *ngFor="let image of connectedHints">
      <div style="width: 20rem;">
        <div class="h-20">
          <p class="ml-8 hint">
            {{ image.translationKey | translate:param }}
          </p>
        </div>
        <div>
          <img src={{image.imagePath}} />
        </div>
      </div>
    </div>
  </div>
  <p style="margin-top: 50px" translate> connected_boxes.vinHint </p>
</div>

<dx-popup [maxWidth]=" 550" [height]="250" [showTitle]="true" title="{{ 'connected_boxes.mfa.title' | translate }}"
  [dragEnabled]="false" [showCloseButton]="true" [(visible)]="mfaDialogVisible">
  <div *dxTemplate="let data of 'content'">
    <div class="column">
      <div class="field">
        <div class="label" translate>connected_boxes.mfa.info1</div>
      </div>
      <div class="field">
        <div class="label"><br /></div>
      </div>
      <div class="field">
        <div class="label" translate>connected_boxes.mfa.code</div>
        <div class="value">
          <dx-text-box [(value)]="mfaData.MfaCode"></dx-text-box>
        </div>
      </div>
      <div class="field">
        <div class="value">
          <dx-button (onClick)="sendMfa()" text="{{ 'connected_boxes.mfa.submit' | translate }}">
          </dx-button>
        </div>
      </div>
    </div>
  </div>
</dx-popup>