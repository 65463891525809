<form #formProfileEdit="ngForm" (submit)="submitForm();">


  <div class="container-fluid p-4">
    <div class="row">
      <div class="col-12 mb-3">
        <h2 class="hide-dialog" translate>user.account.profile.title</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <mat-form-field floatPlaceholder="always" class="w-100 p-2">
          <input matInput type="text" [(ngModel)]="_apiService.user.firstname" name="firstname"
            placeholder="{{ 'user.firstName' | translate }}" maxlength="100">
        </mat-form-field>
      </div>
      <div class="col-12 col-md-6">
        <mat-form-field floatPlaceholder="always" class="w-100 p-2">
          <mat-label>{{ 'user.lastName' | translate }}</mat-label>
          <input matInput type="text" [(ngModel)]="_apiService.user.lastname" name="lastname"
            placeholder="{{ 'user.lastName' | translate }}" maxlength="100">
        </mat-form-field>
      </div>
    </div>
    <div class="row hide-mobile">
      <div class="col-12 col-md-6">
        <mat-form-field floatPlaceholder="always" class="w-100 p-2">
          <input matInput type="text" [(ngModel)]="_apiService.user.email" #email="ngModel" name="email"
            placeholder="{{ 'user.email' | translate }}" pattern="^.+@.+(\.).+$" required disabled="true"
            maxlength="100">
          <mat-error *ngIf="email.touched && email.dirty" align="end">
            <span *ngIf="!email.valid">{{ 'errors.invalidEmail' | translate }}</span>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-12 col-md-6">
        <mat-form-field floatPlaceholder="always" floatPlaceholder="always" class="w-100 p-2">
          <input matInput type="text" [(ngModel)]="_apiService.user.phone_number" name="phone_number"
            placeholder="{{ 'user.phoneNumber' | translate }}" maxlength="50">
        </mat-form-field>
      </div>
    </div>
    <div class="row hide-mobile">
      <div class="col-12 col-md-6">
        <mat-form-field floatPlaceholder="always" class="w-100 p-2">
          <input matInput type="text" [(ngModel)]="_apiService.user.street" name="street"
            placeholder="{{ 'user.street' | translate }}" maxlength="100">
        </mat-form-field>
      </div>
      <!--<div class="col-6 col-md-3">-->
      <!--<mat-form-field  floatPlaceholder="always" class="w-100 p-2">-->
      <!--<input matInput type="text" [(ngModel)]="_apiService.user.zipCode" name="zipCode" placeholder="Zip code">-->
      <!--</mat-form-field>-->
      <!--</div>-->
      <div class="col-12 col-md-6">
        <mat-form-field floatPlaceholder="always" class="w-100 p-2">
          <input matInput type="text" [(ngModel)]="_apiService.user.town" name="town"
            placeholder="{{ 'user.town' | translate }}" maxlength="100">
        </mat-form-field>
      </div>
    </div>
    <div class="row hide-dialog">
      <!-- save BTN -->
      <div class="col-12 p-2 text-center">
        <button mat-raised-button color="accent" type="submit" [disabled]="!formProfileEdit.form.valid" translate>
          <span translate>user.account.profile.submit.title</span>
        </button>
      </div>
    </div>
    <div class="row hide-dialog">
      <!-- change PW BTN-->
      <div class="col-12 p-2 text-center">
        <a mat-button (click)="openChangePasswordDialog()">
          <span translate>user.account.profile.changePassword</span>
        </a>
      </div>
    </div>
  </div>
  <div class="row only-dialog first-step-btn" style="position: absolute; right: -5%; bottom: -40%;">
    <div class="col-12 p-2 text-center">
      <button mat-raised-button color="primary" type="submit" [disabled]="!formProfileEdit.form.valid" translate>
        <span translate>first-steps.next</span>
      </button>
    </div>
  </div>
</form>