import { ProviderType, OemType } from "app/base/provider-oem-type"

export class Box {
  public created_at: string;
  public name: string;
  public description: string;
  public vehicle_id: number;
  public vehicle_key: string;
  public primary: boolean = false;
  public activation_allowed: boolean = true;
  public vehicle_count: number = 0;
  public active_model_id: number;

  public active_model_needs_mileage: boolean = false;
  public active_model_mileage: number = 0;
  public active_model_plate: string;
  public active_model_name: string;
  public active_model_open_trip_count: number;
  public active_model_has_gap: boolean;

  public health_state: any = undefined;

  public connected_info: any;
  public connected_info_loading: boolean = true;
  public connected_not_found: any;

  public oem_type: OemType;
  public provider_type: ProviderType;

  public get isConnected(): boolean {
    if (this.oem_type && this.oem_type !== OemType.Box) {
      return true;
    }
    return false;
  }

  public get translationKey(): String {
    if (this.isConnected) {
      return "connected_boxes";
    }
    return "boxes";
  }
}
