export function CASE_INSENSITVE_SORTING_DATA_ACCESSOR(
  data: any,
  sortHeaderId: string
): string {
  if (typeof data[sortHeaderId] === "string") {
    return data[sortHeaderId].toLocaleLowerCase();
  }

  return data[sortHeaderId];
}
