<mat-progress-bar *ngIf="loading" color="accent" mode="indeterminate"></mat-progress-bar>

<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <h1 translate>export.title</h1>
      <h3 *ngIf="!!box">{{ box.name }}</h3>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <mat-spinner *ngIf="loading" style="margin:0 auto;" mode="indeterminate"></mat-spinner>
    </div>
  </div>

  <div class="row" *ngIf="!loading">
    <div class="col-12" *ngIf="!!box && box.vehicle_count > 1">
      <div class="alert alert-info">
        {{ 'export.notice_model_count' | translate:box }}
      </div>
    </div>
    <div class="col-12" *ngIf="!box">
      <div class="alert alert-warning">
        {{ 'export.notice_no_active_box' | translate }}
      </div>
    </div>
  </div>
</div>

<div class="container-fluid" *ngIf="!!box">

  <div class="row mt-3">
    <div class="col-12 p-3">
      <p class="text-justify" translate>export.desc</p>
    </div>
  </div>

  <form #exportForm="ngForm" class="form-container" [formGroup]="exportOptions">
    <div class="row mt-3">
      <div class="col-12 col-md-6">
        <mat-form-field class="full-width" hideRequiredMarker appearance="standard" (click)="dateStart.open()">
          <mat-label translate>export.dateFrom</mat-label>
          <input matInput formControlName="dateStart" [matDatepicker]="dateStart" [max]="dateStartMaxDate" (ngModelChange)="dateEndMinDate = $event"
            required>
          <mat-datepicker-toggle matSuffix [for]="dateStart"></mat-datepicker-toggle>
          <mat-datepicker type="datetime" touchUi #dateStart></mat-datepicker>

        </mat-form-field>
      </div>

      <div class="col-12 col-md-6">
        <mat-form-field class="full-width" hideRequiredMarker appearance="standard" (click)="dateEnd.open()">
          <mat-label translate>export.dateTo</mat-label>
          <input matInput formControlName="dateEnd" [matDatepicker]="dateEnd" [min]="dateEndMinDate" [max]="dateEndMaxDate" (ngModelChange)="dateStartMaxDate = $event"
            required>
          <mat-datepicker-toggle matSuffix [for]="dateEnd"></mat-datepicker-toggle>
          <mat-datepicker type="datetime" touchUi #dateEnd></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </form>

  <div class="row">
    <div class="col-12 col-md-4 offset-md-4 p-3">
      <mat-label translate>export.type</mat-label>
      <mat-select name="tripType" [(ngModel)]="tripType">
        <mat-option *ngFor="let type of tripTypes" [value]="type.id">
          {{ type.translation_key | translate }}
        </mat-option>
      </mat-select>
    </div>
  </div>

  <div class="row mt-3">                                   
    <div class="col-sm-3 p-3 text-center"></div>        
    <div class="col-sm-3 p-3 text-center">
      <mat-checkbox [(ngModel)]="displayPrivateDataInExport" name="displayPrivateDataInExport"> {{ 'export.displayPrivateDataInExport' | translate }}</mat-checkbox>
    </div>
    <div class="col-sm-3 p-3 text-center">
      <mat-checkbox [(ngModel)]="showDriver" name="showDriver" data-testid="checkbox-show-driver"> {{ 'export.showDriver' | translate }}</mat-checkbox>
    </div>
    <div class="col-sm-3 p-3 text-center"></div>
  </div>


  <div class="row">
    <div class="col-12 col-md-4 p-2 p-md-5 text-center">
      <button mat-raised-button color="accent" (click)="search()" [disabled]="!isSearchButtonEnabled()">
        <mat-icon class="icon-custom">search</mat-icon>
        <span translate>export.search</span>
      </button>
    </div>
    <div class="col-12 col-md-4 p-2 p-md-5 text-center">
      <button mat-raised-button color="primary" (click)="startExport('pdf')" [disabled]="!isPdfDownloadButtonEnabled()">
        <span translate>export.downloadPdf</span>
      </button>
    </div>
    <div class="col-12 col-md-4 p-2 p-md-5 text-center">
      <button mat-raised-button color="primary" (click)="startExport('csv')" [disabled]="!isCsvDownloadButtonEnabled()">
        <span translate>export.downloadCsv</span>
      </button>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <mat-progress-bar *ngIf="searching" color="accent" mode="indeterminate" style="position: absolute; left: 0; right: 0;"></mat-progress-bar>
    </div>
  </div>
  <div class="row" *ngFor="let car of cars">
    <div class="col-12 p-3">
      <mat-card>
        <span>
          <b translate>export.plate</b>: {{ car.plate }}
        </span>
        <br />
        <span>
          <b translate>export.amount_of_trips</b>: {{ car.trips.length | number:0 }}
        </span>
        <table class="mt-1 table table-sm table-striped" appSortableTable (sorted)="onSorted($event, car)">
          <thead>
            <tr class="smaller">
              <th class="text-segment pb-3" translate>export.weekday</th>
              <th class="text-segment pb-3" app-sortable-column="start_time" translate>export.date</th>
              <th class="text-segment pb-3" translate>export.startTime</th>
              <th class="text-segment pb-3" translate>export.endTime</th>
              <th class="text-segment pb-3" translate>export.startKm</th>
              <th class="text-segment pb-3" translate>export.endKm</th>
              <th class="text-segment pb-3" app-sortable-column="distance" translate>export.distance</th>
              <th class="text-segment pb-3" translate>export.start</th>
              <th class="text-segment pb-3" translate>export.end</th>
              <th class="text-segment pb-3" translate>export.partner</th>
              <th class="text-segment pb-3" translate>export.purpose</th>
              <th class="text-segment pb-3" app-sortable-column="type_trip" translate>export.type_short</th>
              <th  *ngIf="showDriver" class="text-segment pb-3" translate>export.driverName</th>
            </tr>
          </thead>

          <tbody>
            <tr style="display: none;"></tr>
            <tr *ngIf="car.trips.length === 0">
              <td colspan="11">
                <span translate>global.noResults</span>
              </td>
            </tr>
            <tr *ngFor="let trip of car.trips" class="smaller" [ngClass]="{'crossed': trip.crossed}">
              <td>{{ trip.weekday_display }}</td>
              <td style="white-space: nowrap">{{ trip.date }}</td>
              <td>{{ trip.start_time_display }}</td>
              <td>{{ trip.end_time_display}}</td>
                  <td class="text-right">{{ trip.start_km | number:0}}</td>
                  <td class="text-right">{{ trip.end_km | number:0}}</td>
                  <td class="text-right">{{ (trip.distance || 0)
                    < 1 ? '<1' : ((trip.distance || 0) | number:0) }} km</td>
                      <td>{{ trip.start_address}}</td>
                      <td>{{ trip.end_address}}</td>
                      <td>{{( trip.type_trip != 2 ? trip.partner : "" )}}</td>
                      <td>{{( trip.type_trip != 2 ? trip.purpose : "" )}}</td>
                      <td>{{tripTypesTranslation[trip.type_trip] | translate}}</td>
                  <td *ngIf="showDriver">{{ trip.driver_name }}</td>
            </tr>
          </tbody>
        </table>
      </mat-card>
    </div>
  </div>
</div>

<div class="minheight-10rem">
  <!-- this element exists so the loader does not display flickering scrollbar -->
</div>