<mat-progress-bar *ngIf="loading" color="accent" mode="indeterminate"></mat-progress-bar>

<div class="container-fluid">

  <div class="row">
    <div class="col-12">
      <h1 translate>{{ getTranslation('title') }}
      </h1>

    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="button-row">
        <a mat-raised-button color="primary" routerLink="/add-vehicle" title="{{ getTranslation('addBox') }}"
          [disabled]="loading">
          <mat-icon class="icon-custom">add_circle</mat-icon>
          <span class="mat-button-span" translate>{{ getTranslation('addBox') }}</span>
        </a>

        <a mat-raised-button color="primary" [attr.href]="'boxes.orderNewBoxLink.url' | translate"
          title="{{ 'boxes.orderNewBoxLink.text' | translate }}"
          target="{{ 'boxes.orderNewBoxLink.target' | translate }}" rel="noopener" style="float:right;">
          <mat-icon class="icon-custom">add_shopping_cart</mat-icon>
          <span class="mat-button-span" translate>boxes.orderNewBoxLink.text</span>
        </a>

      </div>
    </div>
  </div>
  

  <div class="row">
    <div class="col-12 pt-2">
      <p class="alert alert-info" translate>{{ getTranslation('notice1') }}</p>
    </div>
  </div>

  <hr />

  <div class="row">
    <div class="col-12">
      <mat-spinner *ngIf="loading" style="margin:0 auto;" mode="indeterminate"></mat-spinner>
      <mat-progress-bar *ngIf="updating" color="accent" mode="indeterminate"
        style="position: absolute; left: 0; right: 0;"></mat-progress-bar>
    </div>
  </div>

  <div class="row" [hidden]="loading">
    <div class="col-12 p-3">
      <div class="alert alert-info" *ngIf="boxes.length === 0">
        <span translate>{{ getTranslation('no_result_notice') }}</span>&nbsp;

        <a class="hover-underline" routerLink="/add-vehicle" title="{{ getTranslation('create_box_now')}}">
          <span translate>{{ getTranslation('create_box_now') }}</span>
        </a>
      </div>

      <div *ngFor="let box of boxes">
        <mat-card class="mb-4">

          <mat-card-header>
            <mat-card-title>
              <h4><img src="/assets/images/logos/{{getVehicleImage(box)}}"
                  style="height: 25px; display: inline-block;" /> {{box.name}} &nbsp;
                <a routerLink="./{{box.vehicle_id}}/edit" title="{{ getTranslation('edit') }}">
                  <mat-icon class="icon-custom" style="font-size: 18px !important;"
                    matTooltip="{{ getTranslation('edit') }}" matTooltipPosition="right">edit</mat-icon>
                </a>
              </h4>
            </mat-card-title>

            <mat-card-subtitle>
              <p [hidden]="!box.description">{{ box.description }}</p>
            </mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <ul>
              <li>
                <b translate>{{ getTranslation('vehicle_id') }}</b>: {{ box.vehicle_id }}
              </li>
              <ng-container *ngIf="box.connected_info">
                <ng-container *ngIf="box.connected_info.disabled == 1">
                  <li>
                    <ng-container *ngIf="box.connected_info.state == 'Pending'; else approvedElse">
                      <p class="alert alert-danger" translate>{{ getTranslation('vehicle_disabled_pending') }}</p>
                    </ng-container>
                    <ng-template #approvedElse>
                      <p class="alert alert-danger" translate>{{ getTranslation('vehicle_disabled') }}</p>
                    </ng-template>
                  </li>
                </ng-container>
                <ng-container *ngIf="box.connected_info.disabled == 0">
                  <li>
                    <b translate>{{ getTranslation('last_data_update') }}</b>: {{ box.connected_info.lastUpdate |
                    date:'medium'}}
                  </li>
                  <li *ngIf="box.connected_info.lastTrip">
                    <b translate>{{ getTranslation('last_detected_trip') }}</b>: {{
                    box.connected_info.lastTrip.EndTimeString | date:'medium'}}
                  </li>
                </ng-container>
                <ng-container *ngIf="box.connected_info.vehicleAuthState">
                  <ng-container [ngSwitch]="box.connected_info.vehicleAuthState">
                    <ng-container *ngSwitchCase="VehicleAuthState.PendingServiceActivation">
                      <p class="alert alert-info" translate>{{ getTranslation('vehicle_pending_service_activation') }}
                      </p>
                    </ng-container>
                    <ng-container *ngSwitchCase="VehicleAuthState.PendingMileageCheck">
                      <p class="alert alert-info" translate>{{ getTranslation('vehicle_pending_milagecheck') }}</p>
                    </ng-container>
                    <ng-container *ngSwitchCase="VehicleAuthState.MilageCheckFailed">
                      <p class="alert alert-danger" translate>{{ getTranslation('vehicle_milage_check_failed') }}</p>
                    </ng-container>
                    <ng-container *ngSwitchCase="VehicleAuthState.PendingRevoke">
                      <p class="alert alert-danger" translate>{{ getTranslation('vehicle_revoked') }}</p>
                    </ng-container>
                    <ng-container *ngSwitchCase="VehicleAuthState.Revoked">
                      <p class="alert alert-danger" translate>{{ getTranslation('vehicle_revoked') }}</p>
                    </ng-container>
                    <ng-container *ngSwitchCase="VehicleAuthState.ReMileageCheckNeeded">
                      <p class="alert alert-info" translate>{{ getTranslation('vehicle_re_mileage_check') }}</p>
                    </ng-container>
                    <ng-container *ngSwitchCase="VehicleAuthState.Ok">
                      <p class="alert alert-info" translate>{{ getTranslation('vehicle_up_and_running') }}</p>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="box.connected_not_found">
                <p class="alert alert-danger" translate>{{ getTranslation('vehicle_not_found') }}</p>
              </ng-container>
              <ng-container *ngIf="box.connected_info_loading">
                <div><span translate> global.loading</span>
                  <mat-spinner [diameter]="30" mode="indeterminate"></mat-spinner>
                </div>
              </ng-container>
            </ul>
            <p *ngIf="!(box.vehicle_count > 0) && box.activation_allowed"
              class="pt-2 pb-2 alert alert-warning">
              <span translate>{{ getTranslation('box_without_vehicles_notice') }}</span> &nbsp;
              <a class="hover-underline" routerLink="./{{box.vehicle_id}}/vehicles/create"
                title="{{ getTranslation('create_vehicle_now') }}">
                <span translate>{{ getTranslation('create_vehicle_now') }}</span>
              </a>
            </p>
            <p>
              <a mat-button *ngIf="!box.primary && box.activation_allowed"
                [disabled]="!(box.active_model_id > 0)" (click)="selectBoxAsPrimary(box)">
                <mat-icon>check_box_outline_blank</mat-icon>&nbsp;
                <span translate>{{ getTranslation('activate.submit') }}</span>
              </a>

              <a mat-button *ngIf="box.primary" class="success-message" style="color:white">
                <mat-icon>check_box</mat-icon>&nbsp;
                <span translate>{{ getTranslation('boxIsActiveInApp') }}</span>
              </a>
            </p>

          </mat-card-content>
          <mat-card-actions>
            <ng-container>
              <a mat-raised-button routerLink="./{{box.vehicle_id}}/vehicles"
                title="{{ getTranslation('showVehicles') }}" [matBadge]="box.vehicle_count | number:0"
                matBadgePosition="above after" [matBadgeColor]="!(box.vehicle_count > 0) ? 'accent' : 'primary'"
                style="margin-right: 10px">
                <mat-icon class="icon-custom">directions_car</mat-icon>
                <span class="mat-button-span" class="mat-button-span" translate>{{ getTranslation('showVehicles')
                  }}</span>
              </a>
            </ng-container>

            <ng-container *ngIf="box.oem_type !== OemType.Box && box.oem_type !== OemType.Dongle">
              <a *ngIf="updateCredentialsEnabled" mat-raised-button routerLink="./{{box.vehicle_id}}/updateAccess"
                style="margin-left: 10px" title="{{ getTranslation('updateAccess') }}">
                <mat-icon class="icon-custom">vpn_key</mat-icon>
                <span class="mat-button-span" translate>{{ getTranslation('updateAccess', box.provider_type) }}</span>
              </a>
            </ng-container>

            <ng-container *ngIf=box.active_model_needs_mileage>
              <a mat-raised-button routerLink="./{{box.vehicle_id}}/updateMileage"
                title="{{ getTranslation('updateMileage') }}" [matBadge]="'!'" matBadgePosition="above after"
                [matBadgeColor]="'accent'" style="margin-right: 10px">
                <mat-icon class="icon-custom">assignment</mat-icon>
                <span class="mat-button-span" class="mat-button-span" translate>{{ getTranslation('updateMileage')
                  }}</span>
              </a>
            </ng-container>

          </mat-card-actions>
        </mat-card>
      </div>
    </div>
  </div>

  <div class="row mt-5"></div>
</div>