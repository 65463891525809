import { AfterViewInit, Component, OnInit } from "@angular/core";
import { Hotspot } from "../../_models/hotspot.model";
import { BaseComponent } from "../../base/base.component";

@Component({
  selector: "app-autologg-frequent-hotspots",
  templateUrl: "./frequent-hotspots.component.html",
})
export class FrequentHotspotsComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  public hotspots: Array<Hotspot> = [];
  public loading: boolean = true;

  ngOnInit() {}

  ngAfterViewInit() {
    this.getHotspots();
  }

  getHotspots() {
    this._apiService.getHotspotsWithFrequency().subscribe(
      (hotspots) => {
        this.hotspots = hotspots;
      },
      (err) => {
        /* do not show errors as user my not have an active model */
        this.loading = false;
      },
      () => {
        this.loading = false;
      }
    );
  }
}
