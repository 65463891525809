<mat-progress-bar *ngIf="loading" color="accent" mode="indeterminate"></mat-progress-bar>

<div class="container-fluid">
  <div class="row desktop">
    <div class="col-12">
      <h1 class="hide-dialog" translate>user.account.title</h1>
    </div>
  </div>

  <div class="row mb-5">
    <div class="col-12">

      <mat-tab-group class="hide-dialog">
        <mat-tab label="{{ 'user.account.profile.title' | translate }}">
          <app-profile-form></app-profile-form>
        </mat-tab>
        <mat-tab label="{{ 'user.account.settings.title' | translate }}">
          <app-settings-form></app-settings-form>
        </mat-tab>
      </mat-tab-group>



      <!-- display in dialog (first steps) -->
      <div class="only-dialog">
        <h1 class="step-one title" translate>first-steps.complete_profile</h1>
        <app-profile-form>
        </app-profile-form>
      </div>

    </div>
  </div>
</div>