<mat-progress-bar *ngIf="loading" color="accent" mode="indeterminate"></mat-progress-bar>

<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <h1 translate>{{ getTranslation('update.mileageTitle') }}</h1>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="button-row">
        <a mat-button (click)="navigateBack()" title="{{ 'global.back' | translate }}">
          <mat-icon class="icon-custom">arrow_back</mat-icon>
          <span translate>global.back</span>
        </a>
      </div>
    </div>
  </div>

  <form #updateMileageForm="ngForm" (ngSubmit)="submitForm()">
    <div class="row">
      
      <ng-container *ngIf="name.length >= 0">
        <div class="col-12 p-3 pt-2">
          <mat-form-field  floatPlaceholder="always" class="full-width" appearance="fill" [ngClass]="{'mat-form-field-invalid': !nameField.valid}">
            <mat-label translate>boxes.update.fields.name.label</mat-label>
            <input matInput #nameField="ngModel" type="text" [(ngModel)]="name" name="name" placeholder="{{ 'boxes.update.fields.name.placeholder' | translate }}"
              pattern="(?=^.{1,}$).*$" readonly>

            <mat-hint translate>boxes.update.fields.name.hint</mat-hint>
          </mat-form-field>
        </div>
      </ng-container>
      <ng-container *ngIf="plate.length >= 0">
        <div class="col-12 p-3 pt-2">
          <mat-form-field  floatPlaceholder="always" class="full-width" appearance="fill" [ngClass]="{'mat-form-field-invalid': !plateField.valid}">
            <mat-label translate>boxes.update.fields.plate.label</mat-label>
            <input matInput #plateField="ngModel" type="text" [(ngModel)]="plate" name="plate" placeholder="{{ 'boxes.update.fields.plate.placeholder' | translate }}"
              pattern="(?=^.{1,}$).*$" readonly>

            <mat-hint translate>boxes.update.fields.plate.hint</mat-hint>
          </mat-form-field>
        </div>
      </ng-container>
      <div class="col-6 p-3 pt-2">
        <mat-form-field  floatPlaceholder="always" class="m-auto" class="full-width" [ngClass]="{'mat-form-field-invalid': !mileageField.valid && mileageField.dirty}">
          <mat-label translate>{{ getTranslation('update.fields.mileage.label') }}</mat-label>
          <input matInput #mileageField="ngModel" type="number" [(ngModel)]="mileage" name="mileage" placeholder="{{ getTranslation('update.fields.mileage.placeholder') }}"
            value="{{currentMileage}}" required>
          <mat-hint translate>{{  getTranslation('update.fields.mileage.hint') }}</mat-hint>
        </mat-form-field>
      </div>
    </div>

    <div class="d-flex flex-row justify-content-around flex-wrap m-auto">
      <div class="d-flex mt-3 mb-5">
        <button mat-raised-button color="accent" type="submit" [disabled]="!isSubmitButtonEnabled()" class="m-auto">
          <span translate>boxes.update.submit</span>
        </button>
      </div>
    </div>

  </form>

  <div class="row mt-5"></div>
</div>