<div *ngIf="groupedInvitations.open && groupedInvitations.open.length > 0">
  <h1 translate>groups.invitations.you_have_open_invitations</h1>

  <div *ngFor="let invitation of groupedInvitations.open">
    <mat-card class="pt-2 mb-4">
      <mat-card-header>
        <mat-card-title>
          <h4>{{ 'groups.invitation' | translate }}: {{invitation.group_name}}</h4>
        </mat-card-title>
        <mat-card-subtitle>
          <b>{{ 'global.created_at' | translate}}</b> {{invitation.created_at | date:'short'}}
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <ul>
          <!--li>
            <b translate>groups.share_my_hotspots</b>: {{(invitation.auto_hotspot_sharing_enabled ? 'global.yes' : 'global.no') | translate }}
          </li-->
          <li [hidden]="!invitation.group_admin">
            <b translate>groups.group_admin</b>: {{(invitation.group_admin ? 'global.yes' : 'global.no') | translate }}
          </li>
        </ul>
      </mat-card-content>
      <mat-card-actions>
        <button mat-raised-button color="primary" title="{{ 'groups.invitations.acceptInvitation' | translate }}"
          (click)="acceptInvitation(invitation)">
          <mat-icon class="icon-custom">check</mat-icon>
          <span class="mat-button-span" translate>groups.invitations.acceptInvitation</span>
        </button>
        <button mat-button color="warn" title="{{ 'groups.invitations.declineInvitation' | translate }}"
          (click)="declineInvitation(invitation)">
          <mat-icon class="icon-custom">close</mat-icon>
          <span translate>groups.invitations.declineInvitation</span>
        </button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>