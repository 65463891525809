import { Trip } from "./trip.model";

export class Car {
  public itemid: string;
  public dongleid: string; // deprecated -> use vehicle_id instead
  public vehicle_id: number;
  public model_id: number;
  public active: boolean = false;
  public km: number = 0;
  public plate: string;

  public model_name: string;
  public vehicle_key: string;

  public oemCode: string;
  public seriesCode: string;

  public created_at: string;
  public total_trip_count: number = 0;
  public open_trip_count: number = 0;
  public gap_count: number = 0;

  public trips: Trip[] = [];
}
