import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { OauthLoginComponent } from "./oauth-login.component";
import { MaterialModule } from "app/common/material.module";
import { FormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  declarations: [OauthLoginComponent],
  imports: [CommonModule, FormsModule, MaterialModule, TranslateModule],
  exports: [OauthLoginComponent],
})
export class OauthLoginModule {}
