<div class="container-fluid">
  <div *ngIf="!loading && !activeBox">
    <app-create-connected></app-create-connected>

  </div>

  <div *ngIf="activeBox">
    <div class="flex flex-row">
      <div>
        <h1 translate>home.active_vehicle_title</h1>
      </div>
    </div>
    <div class="row p-3 pt-3">
      <div class="supercard flex-1">
        <vehicle-card [box]="activeBox" [lastTrip]="lastTrip"></vehicle-card>
      </div>

      <div class="supercard flex-1 ml-3">
        <app-autologg-frequent-hotspots></app-autologg-frequent-hotspots>
      </div>
    </div>

    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <mat-spinner *ngIf="loading" style="margin:0 auto;" mode="indeterminate"></mat-spinner>
          <mat-progress-bar *ngIf="updating" color="accent" mode="indeterminate"
            style="position: absolute; left: 0; right: 0;"></mat-progress-bar>
        </div>

        <div class="alert alert-warning mt-4" *ngIf="(statisticsData.opentrips | number: 0) > 0">
          <p class="text-l m-0">
            {{ statisticsData.opentrips | number: 0 }}
            {{ "statistics.open_trips_label" | translate }}
            <a *ngIf="
                  _apiService?.user?.hasGeneralFeature('AUTOLOGG_TRIP_EDITOR') &&
                  _apiService?.user?.car &&
                  _apiService?.user?.car.model_id
                " class="hover-underline" routerLink="/trips/editor/table">
              - <span translate>home.assignTripsCta</span>
            </a>
          </p>
        </div>
      </div>

      <div *ngIf="!loading && !!activeBox">
        <div>
          <h2 class="mt-8" translate>
            {{ "statistics.title" | translate }}
          </h2>
          <div class="flex flex-row mt-6">
            <div class="flex flex-col">
              <div class="flex flex-row">
                <div class="supercard shadow-none border border-gray-900 p-4 mr-2">
                  <p class="overlineLabel text-center">
                    {{ "statistics.milage_this_week_label" | translate }}
                  </p>
                  <p class="text-4xl">
                    {{ statisticsData.distance_week | number: 0 }}
                    <span class="text-xs text-gray-500">km</span>
                  </p>
                </div>
                <div class="supercard shadow-none border border-gray-900 p-4 ml-2">
                  <p class="overlineLabel text-center">
                    {{ "statistics.milage_this_month_label" | translate }}
                  </p>
                  <p class="text-4xl">
                    {{ statisticsData.distance_month | number: 0 }}
                    <span class="text-xs text-gray-500">km</span>
                  </p>
                </div>
              </div>
              <div class="flex flex-col items-center mt-4">
                <div>
                  <div class="chart-images d-flex flex-column align-items-center justify-content-center">
                    <div class="d-flex blue">
                      <mat-icon class="icon-custom bus mr-2">bus</mat-icon>
                      {{ business_trips_percentage || 0 }}%
                    </div>
                    <div class="d-flex">
                      <mat-icon class="icon-custom sun mr-2">sun</mat-icon>
                      {{ private_trips_percentage || 0 }}%
                    </div>
                  </div>
                  <app-chart-doughnut style="display: block; " [doughnutChartData]="doughnutChartData">
                  </app-chart-doughnut>
                </div>

                <p class="text-gray-600 text-sm whitespace-pre-wrap text-center">
                  {{ "statistics.tripTypeBreakdown" | translate: {value: date.getFullYear()} }}
                </p>
              </div>
            </div>

            <div class="flex-auto ml-16">
              <mat-select placeholder="{{ year }}" [(ngModel)]="year" (selectionChange)="changeYear()">
                <mat-option *ngFor="let year of years" [value]="year">{{
                  year
                  }}</mat-option>
              </mat-select>

              <div *ngIf="showChart" style="height: 40vh">
                <app-chart-bar [barChartData]="barChartData" [isEmpty]="chartEmpty"></app-chart-bar>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>