export class Gap {
  public trip_pred_id: string;
  public trip_succ_id: string;
  public start_km: number;
  public end_km: number;
  public start_address: string;
  public end_address: string;
  public dongleid: string;
  public itemid: string;
}
