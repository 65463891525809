<mat-progress-bar class="position-absolute" *ngIf="loading || updating" color="accent" mode="indeterminate">
</mat-progress-bar>

<div class="mt-3">
  <div class="alert alert-info" *ngIf="!loading && userBoxes.length === 0">
    <span translate>global.noResults</span>
  </div>

  <div *ngFor="let box of userBoxes" class="mr-1 ml-1">
    <mat-card class="mb-4">
      <mat-card-header>
        <mat-card-title>
          <h4>{{ box.name }}</h4>
        </mat-card-title>
        <mat-card-subtitle [hidden]="!box.description">
          <p>{{ box.description }}</p>
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <ul>
          <li>
            <b translate>boxes.vehicle_id</b>: {{ box.vehicle_id }}
          </li>
        </ul>
        <ul *ngIf="box.oem_type !== OemType.Box">
          <li>
            <b translate>boxes.connected_vehicle</b>
          </li>
        </ul>
        <div *ngIf="isShared(box) && !!groupUserBoxMapById[box.vehicle_id]">
          <p *ngIf="_apiService?.user?.hasFeature('AUTOLOGG_GROUPS_DISPLAY_OPEN_TRIPS')">
            <mat-slide-toggle [checked]="groupUserBoxMapById[box.vehicle_id].group_box.display_open_trips_enabled"
              labelPosition="after" (change)="toggleDisplayOpenTripsEnabled(box)">
              <mat-icon class="vertical-align-middle"
                *ngIf="groupUserBoxMapById[box.vehicle_id].group_box.display_open_trips_enabled">check</mat-icon>
              <mat-icon class="vertical-align-middle"
                *ngIf="!groupUserBoxMapById[box.vehicle_id].group_box.display_open_trips_enabled">cancel</mat-icon>
              {{ (groupUserBoxMapById[box.vehicle_id].group_box.display_open_trips_enabled ?
              'groups.membership.manage.boxes.display_open_trips_enabled.enabled' :
              'groups.membership.manage.boxes.display_open_trips_enabled.disabled') | translate }}
            </mat-slide-toggle>

            <span [matTooltip]="'global.more_info' | translate" matTooltipPosition="left" style="float: right">
              <button mat-button class="btn-more-info"
                (click)="openInfoDialog('groups.membership.manage.boxes.display_open_trips_enabled.dialog.info')">
                <mat-icon class="vertical-align-middle">info</mat-icon>
              </button>
            </span>

          </p>
          <p *ngIf="_apiService?.user?.hasFeature('AUTOLOGG_GROUPS_DISPLAY_PRIVATE_TRIPS')">
            <mat-slide-toggle [checked]="groupUserBoxMapById[box.vehicle_id].group_box.display_private_trips_enabled"
              labelPosition="after" (change)="toggleDisplayPrivateTripsEnabled(box)">
              <mat-icon class="vertical-align-middle"
                *ngIf="groupUserBoxMapById[box.vehicle_id].group_box.display_private_trips_enabled">check</mat-icon>
              <mat-icon class="vertical-align-middle"
                *ngIf="!groupUserBoxMapById[box.vehicle_id].group_box.display_private_trips_enabled">cancel</mat-icon>
              {{ (groupUserBoxMapById[box.vehicle_id].group_box.display_private_trips_enabled ?
              'groups.membership.manage.boxes.display_private_trips_enabled.enabled' :
              'groups.membership.manage.boxes.display_private_trips_enabled.disabled') | translate }}
            </mat-slide-toggle>

            <span [matTooltip]="'global.more_info' | translate" matTooltipPosition="left" style="float: right">
              <button mat-button class="btn-more-info"
                (click)="openInfoDialog('groups.membership.manage.boxes.display_private_trips_enabled.dialog.info')">
                <mat-icon class="vertical-align-middle">info</mat-icon>
              </button>
            </span>
          </p>
        </div>
      </mat-card-content>
      <mat-card-actions>
        <button *ngIf="isShared(box)" mat-button
          title="{{ 'groups.membership.manage.boxes.unshare.submit' | translate }}" color="accent"
          (click)="unshareBoxWithGroup(box)">
          <mat-icon class="icon-custom">clear</mat-icon>

          <ng-container *ngIf="box.provider_type === ProviderType.Native">
            <span translate>groups.membership.manage.boxes.unshare.submit</span>
          </ng-container>
          <ng-container *ngIf="box.provider_type !== ProviderType.Native">
            <span translate>groups.membership.manage.connected_boxes.unshare.submit</span>
          </ng-container>
        </button>

        <button *ngIf="!isShared(box)" mat-raised-button
          title="{{ 'groups.membership.manage.boxes.share.submit' | translate }}" color="primary"
          (click)="shareBoxWithGroup(box)">
          <mat-icon class="icon-custom">add</mat-icon>

          <ng-container *ngIf="box.provider_type === ProviderType.Native">
            <span class="mat-button-span" translate>groups.membership.manage.boxes.share.submit</span>
          </ng-container>
          <ng-container *ngIf="box.provider_type !== ProviderType.Native">
            <span class="mat-button-span" translate>groups.membership.manage.connected_boxes.share.submit</span>
          </ng-container>
        </button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>