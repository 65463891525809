import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  EventEmitter,
  Output,
} from "@angular/core";
import { formatNumber } from "@angular/common";

import { Map, latLng, Marker, circle, Circle } from "leaflet";
import * as L from "leaflet";
import "leaflet-control-geocoder";

import { BaseComponent } from "../../base/base.component";
import { Hotspot } from "../../_models/hotspot.model";

import {
  leafletPrepareMap,
  leafletCreateOptions,
  leafletFitMapToMarkerBounds,
  leafletCreateMarker,
} from "../../common/leaflet-common.util";
import { Helper } from "app/base/helper";

@Component({
  selector: "app-geocoder-map",
  templateUrl: "./hotspot-map.component.html",
  styleUrls: ["hotspot-map.component.css"],
})
export class GeocoderMapComponent
  extends BaseComponent
  implements OnInit, OnChanges, AfterViewInit
{
  @Output("geocoderMarkgeocode") geocoderMarkgeocode = new EventEmitter();
  @Output("onMapClicked") onMapClicked = new EventEmitter();
  @Output("onMarkerDragEnd") onMarkerDragEnd = new EventEmitter();

  loading: boolean = false;

  leafletOptions: any;
  leafletLayers: any[] = [];
  marker: Marker[] = [];

  @Input("hotspots") hotspots: Array<Hotspot> = [];
  @Input("markerDraggable") markerDraggable: boolean = false;

  map: Map;

  ngOnInit() {
    this.leafletOptions = leafletCreateOptions();

    this.recreateMarkers();

    this.fitMapToMarkerBounds();
  }

  ngAfterViewInit(): void {
    if (this.map) {
      this.map.invalidateSize();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["hotspots"]) {
      this.onInputChanged();
    }
  }

  private onInputChanged() {
    this.recreateMarkers();
    this.fitMapToMarkerBounds();
  }

  onMapReady(_mapmap: Map) {
    this.map = leafletPrepareMap(_mapmap);

    this.map.on("click", (e) => {
      this.onMapClicked.emit(e);
    });

    this.fitMapToMarkerBounds();

    try {
      const geocodeOptions = Object.assign(
        {
          defaultMarkGeocode: false,
          collapsed: false,
          position: "topleft",
          placeholder: "Suche..",
          errorMessage: "Keine Ergebnisse",
        },
        {}
      );

      L.Control.geocoder(geocodeOptions)
        .on("markgeocode", (e) => {
          const center = e.geocode.center;
          if (center) {
            this.geocoderMarkgeocode.emit(e.geocode);
          }
        })
        .addTo(this.map);
    } catch (e) {
      // empty on purpose
      console.log("could not load plugin leaflet-control-geocoder.");
    }

    setTimeout(() => {
      this.map.invalidateSize();
      this.fitMapToMarkerBounds();
    }, 1);
  }

  private recreateMarkers() {
    const hotspotWithLatLng = this.hotspots.filter(
      (val) => val.latitude && val.longitude
    );

    this.marker = hotspotWithLatLng.map((val) =>
      this.createMarkerForHotspot(val)
    );

    const circles = hotspotWithLatLng.map((val) =>
      this.createCircleForHotspot(val)
    );

    this.leafletLayers = this.marker.concat(circles);
  }

  private fitMapToMarkerBounds() {
    leafletFitMapToMarkerBounds(this.map, this.marker);
  }

  private createCircleForHotspot(hotspot: Hotspot): Circle {
    const radius =
      hotspot.radius_in_meters > 0 && hotspot.radius_in_meters < 100_000
        ? hotspot.radius_in_meters
        : 100;

    return circle(latLng(hotspot.latitude, hotspot.longitude), radius);
  }

  private createMarkerForHotspot(hotspot: Hotspot) {
    const m = leafletCreateMarker(
      latLng(hotspot.latitude, hotspot.longitude),
      hotspot.title,
      {
        draggable: this.markerDraggable || false,
      }
    );

    m.on("dragend", (e) => this.onMarkerDragEnd.emit(e));

    const latLngString =
      formatNumber(hotspot.latitude, Helper.getBrowserLang(), "1.3-5") +
      "/" +
      formatNumber(hotspot.longitude, Helper.getBrowserLang(), "1.3-5");

    // somehow, css classes do not seem to work, hence 'style' is used - i am sorry :/
    m.bindPopup(
      (hotspot.title
        ? '<span class="h6" style="font-weight: 600;">' +
          hotspot.title +
          "</span><br />"
        : "") +
        '<span class="bold">Adresse</span>: ' +
        hotspot.address +
        "<br />" +
        '<span class="bold">Lat/Lng</span>: ' +
        latLngString +
        "<br />" +
        '<span class="bold">Radius</span>: ' +
        formatNumber(
          hotspot.radius_in_meters,
          Helper.getBrowserLang(),
          "1.0-0"
        ) +
        "m"
    );

    if (hotspot.title) {
      m.bindTooltip("" + hotspot.title);
    }

    return m;
  }
}
