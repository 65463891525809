import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { ApiService } from './api.service';
import { KeyCloakAutologgAuthGuard } from './keycloak-auth-guard.service'

@Injectable({
  providedIn: 'root'
})
export class LockedAutologgAuthGuard extends KeyCloakAutologgAuthGuard {
  constructor(
    protected readonly router: Router,
    protected readonly keycloak: KeycloakService,
    protected readonly apiService: ApiService
  ) {
    super(router, keycloak, apiService);
  }


  protected async checkAccessAllowed(): Promise<boolean> {

    if (this.apiService.user.locked === void 0 || this.apiService.user.locked === undefined) {
      return true;
    }

    if (this.apiService.user.locked) {
      this.router.navigate(["/"]);
      return false;
    }
    return true;

  }
}