import { NgModule } from "@angular/core";
import { AutoLoggCommonModule } from "../../common/autologg-common.module";
import { AutoLoggGroupsManageRouting } from "./manage-groups.routing";

import {
  GroupsListGroupEventsComponent,
  ManageGroupComponent,
} from "./manage-group.component";

import { GroupsListGroupUsersComponent } from "./groups-list-group-users.component";

import {
  GroupsListGroupInvitationsComponent,
  GroupsListGroupInvitationsOpenComponent,
} from "./groups-list-group-invitations.component";
import {
  GroupsListGroupBoxesComponent,
  GroupsListGroupBoxesMapComponent,
} from "./groups-list-group-boxes.component";

import { GroupsExportComponent } from "./groups-export.component";
import {
  GroupsListGroupHotspotsComponent,
  GroupsListGroupHotspotsMapComponent,
} from "./groups-list-group-hotspots.component";

import { ManageGroupUserComponent } from "./manage-user/manage-user.component";
import { ManageGroupBoxComponent } from "./manage-box/manage-box.component";
import { ManageGroupBoxVehiclesComponent } from "./manage-box/vehicles.component";
import { ManageGroupBoxOpenTripsComponent } from "./manage-box/open-trips.component";
import { ManageGroupBoxExportComponent } from "./manage-box/export.component";

@NgModule({
    imports: [
        AutoLoggCommonModule,
        AutoLoggGroupsManageRouting,
    ],
    declarations: [
        GroupsListGroupBoxesComponent,
        GroupsListGroupBoxesMapComponent,
        GroupsListGroupHotspotsComponent,
        GroupsListGroupHotspotsMapComponent,
        GroupsListGroupUsersComponent,
        GroupsListGroupEventsComponent,
        GroupsListGroupInvitationsOpenComponent,
        GroupsListGroupInvitationsComponent,
        GroupsExportComponent,
        ManageGroupUserComponent,
        ManageGroupBoxVehiclesComponent,
        ManageGroupBoxOpenTripsComponent,
        ManageGroupBoxExportComponent,
        ManageGroupBoxComponent,
        ManageGroupComponent
    ],
    exports: []
})
export class AutoLoggGroupsManageModule {}
