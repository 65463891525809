import { SimpleUser } from "./user.model";
import { Hotspot } from "./hotspot.model";
import { Box } from "./box.model";
import { Trip } from "./trip.model";

export class Group {
  public id: number;
  public created_at: string;
  public name: string;
  public description: string;
  public group_owner_user_id: number;
}

export class GroupInvitation {
  public created_at: string;
  public id: number;
  public user_id: number;
  public group_id: number;
  public group_name: string;
  public accepted_at: string;
  public declined_at: string;
  public invitation_mail_sent_at: string;
  public auto_hotspot_sharing_enabled: boolean;
  public group_admin: boolean;
}

export class GroupMembership {
  public created_at: string;
  public id: number;
  public user_id: number;
  public group_id: number;
  public auto_hotspot_sharing_enabled: boolean;
  public group_admin: boolean;
}

export class GroupBox {
  public created_at: string;
  public membership_id: number;
  public vehicle_id: number;
  public display_open_trips_enabled: boolean;
  public display_private_trips_enabled: boolean;
}

export class UserGroupMembershipWithMetadata {
  public group_user: GroupUserMembership;
  public invitation_count: number;
  public invitation_open_count: number;
  public invitation_accepted_count: number;
  public invitation_declined_count: number;
  public box_count: number;
  public hotspot_count: number;
}

export class GroupUserHotspot {
  public group: Group;
  public user: SimpleUser;
  public hotspot: Hotspot;
}

export class GroupUserMembership {
  public group: Group;
  public membership: GroupMembership;
  public user: SimpleUser;
}

export class GroupUserBox {
  public group_box: GroupBox;
  public group: Group;
  public box: Box;
  public user: SimpleUser;
}

export class GroupUserBoxWithLatestTrip {
  public group_user_box: GroupUserBox;
  public latest_trip: Trip;
}

export class GroupEvent {
  public created_at: string;
  public group_id: number;
  public user_id: number;
  public event_name: string;
  public text: string;
}

export class GroupMembershipUpdateRequest {
  public group_admin: boolean;
  public auto_hotspot_sharing_enabled: boolean;
  public locked: boolean;
}

export class CreateGroupBoxRequest {
  public group_id: number;
  public vehicle_id: number;
  public display_open_trips_enabled: boolean;
  public display_private_trips_enabled: boolean;
}

export class UpdateGroupBoxRequest {
  public group_id: number;
  public vehicle_id: number;
  public display_open_trips_enabled: boolean;
  public display_private_trips_enabled: boolean;
}
