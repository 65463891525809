import { Injectable } from "@angular/core";

// cdk
import { Overlay } from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";

// rxjs
import { Subject } from "rxjs";
import { scan, map } from "rxjs/operators";
import { OverlayComponent } from "./overlay/overlay.component";
import { OverlayLoadingTextService } from "./overlay-loading-text.service";

@Injectable({
  providedIn: "root",
})
export class OverlayLoadingService {
  private spinnerTopRef = this.cdkSpinnerCreate();

  spin$: Subject<boolean> = new Subject();

  constructor(
    private overlay: Overlay,
    private overlayLoadingTextService: OverlayLoadingTextService
  ) {
    this.spin$
      .asObservable()
      .pipe(
        map((val) => (val ? 1 : -1)),
        scan((acc, one) => (acc + one >= 0 ? acc + one : 0), 0)
      )
      .subscribe((res) => {
        if (res === 1) {
          this.showSpinner();
        } else if (res === 0) {
          if (this.spinnerTopRef.hasAttached()) {
            this.stopSpinner();
          }
        }
      });
  }

  public show(text: string = void 0) {
    this.overlayLoadingTextService.text = text;
    this.spin$.next(true);
  }
  public hide() {
    this.spin$.next(false);
    this.overlayLoadingTextService.text = void 0;
  }

  private cdkSpinnerCreate() {
    return this.overlay.create({
      hasBackdrop: true,
      backdropClass: "dark-backdrop",
      positionStrategy: this.overlay
        .position()
        .global()
        .centerHorizontally()
        .centerVertically(),
    });
  }

  private showSpinner() {
    this.spinnerTopRef.attach(new ComponentPortal(OverlayComponent));
  }

  private stopSpinner() {
    this.spinnerTopRef.detach();
  }
}
