import { Component, OnInit } from "@angular/core";
import { OverlayLoadingTextService } from "../overlay-loading-text.service";
import { OverlayLoadingService } from "../overlay-loading.service";

@Component({
  selector: "app-overlay",
  templateUrl: "./overlay.component.html",
  styleUrls: ["./overlay.component.css"],
})
export class OverlayComponent implements OnInit {
  public get text() {
    return this.overlayLoadingTextService.text;
  }

  constructor(private overlayLoadingTextService: OverlayLoadingTextService) {}

  ngOnInit(): void {}
}
