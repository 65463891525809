import { filter } from "rxjs/operators";
import {
  AfterViewInit,
  Component,
  ViewChild,
  Input,
  SimpleChanges,
  OnChanges,
  OnInit,
} from "@angular/core";

import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { MatTabGroup } from "@angular/material/tabs";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";

import { SelectionModel } from "@angular/cdk/collections";
import { Observable } from "rxjs";

import { BaseComponent } from "../../base/base.component";
import {
  Group,
  GroupInvitation,
  GroupUserHotspot,
  GroupEvent,
  GroupUserMembership,
  GroupUserBoxWithLatestTrip,
} from "../../_models/group.model";

@Component({
  templateUrl: "./manage-group.component.html",
  styleUrls: ["manage-group.component.css"],
})
export class ManageGroupComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  static tabInfos = [
    {
      slug: "users",
    },
    {
      slug: "boxes",
    },
    {
      slug: "invitations",
    },
    {
      slug: "hotspots",
    },
    {
      slug: "export",
    },
  ];

  loading: boolean = false;

  groupId: number;
  groupOwnerUserId: number;
  group: Group;

  @ViewChild("tabGroup", { static: false }) tabGroup: MatTabGroup;
  tabIndex = 0;

  users: Array<GroupUserMembership> = [];
  usersLoaded = false;

  boxesWithLatestTrip: Array<GroupUserBoxWithLatestTrip> = [];
  boxesWithLatestTripLoaded = false;

  invitations: Array<GroupInvitation> = [];
  invitationsLoaded = false;

  hotspots: Array<GroupUserHotspot> = [];
  hotspotsLoaded = false;
  /*events: Array<GroupEvent> = [];*/

  static getTabIndex(route: ActivatedRoute) {
    let r = route;
    while (r.firstChild) {
      r = r.firstChild;
    }
    const params = r.snapshot.params;
    if ("slug" in params) {
      const slug = params["slug"];
      return ManageGroupComponent.getTabIndexBySlug(slug);
    }
    return 0;
  }

  static getTabIndexBySlug(slug: string) {
    if (slug) {
      return Math.max(
        0,
        ManageGroupComponent.tabInfos.findIndex(
          (tabInfo) => tabInfo.slug === slug
        )
      );
    }
    return 0;
  }

  ngOnInit() {
    this.groupId = +this.route.snapshot.paramMap.get("id");
    this.load();

    const slug = this.route.snapshot.paramMap.get("slug");
    this.tabIndex = ManageGroupComponent.getTabIndexBySlug(slug);

    this.router.events
      .pipe(
        filter((event) => {
          return event instanceof NavigationEnd;
        })
      )
      .map((foo) => this.route)
      .map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      })
      .filter((route) => route.outlet === "primary")
      .subscribe((route) => {
        const newTabIndex = ManageGroupComponent.getTabIndex(route);
        if (this.tabIndex !== newTabIndex) {
          this.tabIndex = newTabIndex;
        }
      });
  }

  ngAfterViewInit() {}

  onTabSelectedIndexChange(newTabIndex: number) {
    if (this.tabIndex !== newTabIndex) {
      const tabInfo = ManageGroupComponent.tabInfos[newTabIndex];
      this.router.navigate([
        "/groups",
        this.groupId,
        "manage",
        "_tabs",
        tabInfo.slug,
      ]);
    }
  }

  load() {
    this.loading = true;
    this.fetchGroup().subscribe(
      (group) => {
        this.group = group;
        this.groupOwnerUserId = group.group_owner_user_id;
      },
      (err) => {
        this.loading = false;
        this.showError(err);
      },
      () => {
        this.loading = false;
      }
    );
  }

  fetchGroup(): Observable<Group> {
    return this._apiService.getGroupById(this.groupId);
  }

  onMembershipsChanged(memberships) {
    // reload all, because boxes and hotspots will change too!
    // this.load();
    this.users = memberships;
    this.usersLoaded = true;
  }

  onHotspotsChanged(hotspots) {
    this.hotspots = hotspots;
    this.hotspotsLoaded = true;
  }

  onInvitationsChanged(invitations) {
    this.invitations = invitations;
    this.invitationsLoaded = true;
  }

  onBoxesChanged(boxesWithLatestTrip) {
    this.boxesWithLatestTrip = boxesWithLatestTrip;
    this.boxesWithLatestTripLoaded = true;
  }
}

@Component({
  selector: "app-groups-list-group-events",
  templateUrl: "./groups-list-group-events.component.html",
  // styleUrls: [''],
})
export class GroupsListGroupEventsComponent
  implements OnInit, OnChanges, AfterViewInit
{
  loading: boolean = false;

  @Input("groupId") groupId: number;
  @Input("events") events: Array<GroupEvent> = [];

  displayedColumns: string[] = ["created_at", "event_name", "text"];
  dataSource = new MatTableDataSource<GroupEvent>([]);
  selection = new SelectionModel<GroupEvent>(true, []);

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.data = this.events;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["events"]) {
      this.dataSource.data = this.events;
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
