import {
  Inject,
  HostListener,
  Component,
  ChangeDetectionStrategy,
} from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "app-confirm-dialog",
  templateUrl: "./dialog-confirm.component.html",
  styleUrls: ["./dialog-confirm.component.css"],
})
export class DialogConfirmComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      text: string;
      submitButtonText: string;
      cancelButtonText: string;
    },
    private mdDialogRef: MatDialogRef<DialogConfirmComponent>
  ) {}

  public cancel() {
    this.mdDialogRef.close(false);
  }

  public confirm() {
    this.mdDialogRef.close(true);
  }

  @HostListener("keydown.esc")
  public onEsc() {
    this.cancel();
  }
}
