<mat-progress-bar *ngIf="loading" color="accent" mode="indeterminate">
</mat-progress-bar>

<form #addVehicleForm="ngForm" (ngSubmit)="submitNewVehicle()" autocomplete="off">

  <div class="container-fluid">

    <div class="row">
      <div class="col-12" >
        <ng-container *ngIf="!isFirstVehicle">
          <h1 translate>boxes.vehicles.create.title</h1>
        </ng-container>
        <ng-container *ngIf="isFirstVehicle">
          <h1 translate>boxes.vehicles.create.titleFirst</h1>
        </ng-container>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="button-row">
          <a mat-button (click)="navigateBack()" title="{{ 'global.back' | translate }}">
            <mat-icon class="icon-custom">arrow_back</mat-icon>
            <span translate>global.back</span>
          </a>
        </div>
      </div>
    </div>

    <ng-container *ngIf="!isFirstVehicle">
      <div class="row">
        <div class="col-12">
          <hr>
          <p class="alert alert-info" translate>boxes.vehicles.create.notice1</p>
          <p class="alert alert-info" translate>boxes.vehicles.create.notice2</p>

        </div>
      </div>
    </ng-container>

      <div class="row">
        <div class="col-12">
          <mat-form-field floatPlaceholder="always" class="full-width" appearance="fill">
            <mat-label translate>boxes.vehicles.create.fields.vehicleKey.label</mat-label>
            <input matInput #vehicleKeyField="ngModel" type="text" [(ngModel)]="vehicleKey" name="vehicleKey"
              placeholder="{{ 'boxes.vehicles.create.fields.vehicleKey.placeholder' | translate }}"
              pattern="(?=^.{1,}$).*$" disabled="disabled" required>
            <mat-error *ngIf="vehicleKeyField.dirty" align="end">
              <span *ngIf="!vehicleKeyField.valid">{{ 'errors.invalidAutologgKey' | translate }}</span>
            </mat-error>
            <mat-hint translate>boxes.vehicles.create.fields.vehicleKey.hint</mat-hint>
          </mat-form-field>
        </div>
      </div>
      <div class="row mt-3 mb-3">
        <div class="col-6">
          <mat-form-field floatPlaceholder="always" class="full-width"
            [ngClass]="{'mat-form-field-invalid': !plateField.valid  && plateField.dirty}">
            <mat-label translate>boxes.vehicles.create.fields.plate.label</mat-label>
            <input matInput id='license-plate' #plateField="ngModel" type="text" [(ngModel)]="plate" name="plate"
              placeholder="{{ 'boxes.vehicles.create.fields.plate.placeholder' | translate }}" maxlength="100" required
              autocomplete="off">
            <mat-error *ngIf="plateField.dirty" align="end">
              <span *ngIf="!plateField.valid">{{ 'errors.invalidPlate' | translate}}</span>
            </mat-error>
            <mat-hint align="end" translate>boxes.vehicles.create.fields.plate.hint</mat-hint>
          </mat-form-field>
        </div>
      </div>

    <ng-container *ngIf="box && !box.isConnected">
      <div class="row pb-3">
        <div class="col-6">
          <mat-form-field class="full-width hide-underline">
            <mat-label translate>boxes.vehicles.create.fields.oem.label</mat-label>
            <mat-select placeholder="{{ 'boxes.vehicles.create.fields.oem.placeholder' | translate }}" name="oem"
              #oem="ngModel" [(ngModel)]="carOem" (selectionChange)="onChangeOem()" [disabled]="myVehicleIsNotInList"
              class="w-100">
              <mat-option *ngFor="let car of carsOemList" [value]="car.itemid">{{ car.name }}</mat-option>
            </mat-select>
            <mat-hint translate>boxes.vehicles.create.fields.oem.hint</mat-hint>
          </mat-form-field>
        </div>

        <div class="col-6">
          <mat-form-field class="full-width hide-underline">
            <mat-label translate>boxes.vehicles.create.fields.series.label</mat-label>
            <mat-select placeholder="{{ 'boxes.vehicles.create.fields.series.placeholder' | translate }}" name="series"
              #series [(ngModel)]="carSeries" [disabled]="!carOem" (selectionChange)="onChangeSeries()" class="w-100">
              <mat-option *ngFor="let car of carsSeriesList" [value]="car.itemid">{{ car.name }}</mat-option>
            </mat-select>
            <mat-hint translate>boxes.vehicles.create.fields.series.hint</mat-hint>
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-form-field class="full-width hide-underline">
            <mat-label translate>boxes.vehicles.create.fields.model.label</mat-label>
            <mat-select placeholder="{{ 'boxes.vehicles.create.fields.model.placeholder' | translate }}" name="model"
              #model [(ngModel)]="carModel" (selectionChange)="onChangeModel()" [disabled]="!carSeries" class="w-100">
              <mat-option *ngFor="let car of carsModelList" [value]="{itemid: car.itemid, model_name: car.model_name}">
                {{ car.model_name }}
              </mat-option>
            </mat-select>
            <mat-hint translate>boxes.vehicles.create.fields.model.hint</mat-hint>
          </mat-form-field>
        </div>
        <div class="col-12 text-center">
          <mat-checkbox [(ngModel)]="myVehicleIsNotInList" name="myVehicleIsNotInList"
            (change)="formValueMyVehicleIsNotInListChanged()">
            <span translate>boxes.vehicles.create.fields.myVehicleIsNotInList.label</span>
          </mat-checkbox>
        </div>
      </div>

      <ng-container *ngIf="myVehicleIsNotInList">
        <div class="row">
          <div class="col-12">
            <mat-form-field floatPlaceholder="always" class="full-width"
              [ngClass]="{'mat-form-field-invalid': !vehicleFreeFieldeField.valid  && vehicleFreeFieldeField.dirty}">
              <mat-label translate>boxes.vehicles.create.fields.vehicleFreeField.label</mat-label>
              <input matInput id='vehicle-free-name' #vehicleFreeFieldeField="ngModel" type="text" [(ngModel)]="vehicleFreeField"
                name="vehicleFreeField"
                placeholder="{{ 'boxes.vehicles.create.fields.vehicleFreeField.placeholder' | translate }}"
                maxlength="100" required autocomplete="off">
              <mat-error *ngIf="vehicleFreeFieldeField.dirty" align="end">
                <span *ngIf="!vehicleFreeFieldeField.valid">{{ 'errors.invalidPlate' | translate}}</span>
              </mat-error>
              <mat-hint align="end" translate>boxes.vehicles.create.fields.vehicleFreeField.hint</mat-hint>
            </mat-form-field>
          </div>
        </div>
      </ng-container>
    </ng-container>



    <div class="d-flex flex-row justify-content-around flex-wrap m-auto">
      <div class="d-flex mt-3 mb-5">
        <button mat-raised-button color="accent" style="width: fit-content !important;" type="submit" [disabled]="!isSubmitButtonEnabled()" class="m-auto">
          <ng-container *ngIf="!isFirstVehicle">
            <span translate>boxes.vehicles.create.submit</span>
          </ng-container>

          <ng-container *ngIf="isFirstVehicle">
            <span translate>boxes.vehicles.create.submitFirst</span>
          </ng-container>
        </button>
      </div>
    </div>

    <div class="row mt-5"></div>
  </div>
</form>