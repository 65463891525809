import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { flatMap } from "rxjs/operators";
import { forkJoin } from "rxjs";

import { BaseComponent } from "../../base/base.component";
import { AppFeature, UserAppFeature } from "../../_models/feature.model";
import { SortableColumnComponent } from "../../sortable-table/sortable-column.component";

class AppFeatureWithUserEnabledFlag {
  feature: AppFeature;
  userEnabled: boolean;
}

@Component({
  selector: "app-settings-features-form",
  templateUrl: "./features.component.html",
})
export class FeaturesComponent extends BaseComponent implements OnInit {
  loading: boolean = false;
  updating: boolean = false;

  features: Array<AppFeature> = [];
  userFeatures: Array<UserAppFeature> = [];
  featuresWithEnabledFlag: Array<AppFeatureWithUserEnabledFlag> = [];

  appFeatureRouterLinks = {
    AUTOLOGG_GROUPS: "/groups",
    AUTOLOGG_TRIP_EDITOR: "/trips",
  };

  loadFeatures = () =>
    forkJoin(
      this._apiService.getAppFeatures(),
      this._apiService.getUserAppFeatures()
    ).do((array) => {
      this.features = array[0];
      this.userFeatures = array[1];

      const userFeaturesCodes = this.userFeatures.map((val) => val.code);

      this.featuresWithEnabledFlag = this.features
        .map((val) => {
          const f = new AppFeatureWithUserEnabledFlag();
          f.feature = val;
          f.userEnabled = userFeaturesCodes.indexOf(val.code) >= 0;
          return f;
        })
        .sort(this.orderAppFeatureWithUserEnabledFlagByFlagAndName);
    });

  ngOnInit() {
    this.loading = true;
    this.loadFeatures().subscribe(
      (foo) => {},
      (err) => {
        this.loading = false;
        this.showError(err);
      },
      () => {
        this.loading = false;
      }
    );
  }

  toggleFeature(featureWithEnabledFlag: AppFeatureWithUserEnabledFlag) {
    if (featureWithEnabledFlag.userEnabled) {
      this.disableFeature(featureWithEnabledFlag.feature);
    } else {
      this.enableFeature(featureWithEnabledFlag.feature);
    }
  }

  private enableFeature(feature: AppFeature) {
    this.updating = true;
    const userAppFeature = new UserAppFeature();
    userAppFeature.code = feature.code;
    userAppFeature.value = "1";

    this._apiService
      .postUserAppFeature(userAppFeature)
      .pipe(flatMap((foo) => this.loadFeatures()))
      .subscribe(
        (foo) => {
          this._apiService.refreshProfile();
        },
        (err) => {
          this.updating = false;
          this.showError(err);
        },
        () => {
          setTimeout(() => (this.updating = false), 250);
        }
      );
  }

  private disableFeature(feature: AppFeature) {
    this.updating = true;
    this._apiService
      .deleteUserAppFeature(feature)
      .pipe(flatMap((foo) => this.loadFeatures()))
      .subscribe(
        (foo) => {
          this._apiService.refreshProfile();
        },
        (err) => {
          this.updating = false;
          this.showError(err);
        },
        () => {
          setTimeout(() => (this.updating = false), 250);
        }
      );
  }

  private orderAppFeatureWithUserEnabledFlagByFlagAndName(
    a: AppFeatureWithUserEnabledFlag,
    b: AppFeatureWithUserEnabledFlag
  ) {
    if (a.feature.enable_by_user && !b.feature.enable_by_user) {
      return -1;
    }
    return a.feature.name.localeCompare(b.feature.name);
  }
}
