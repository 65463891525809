<h5 class="card-title"> {{ loginTitle }}</h5>

<div class="alert alert-warning" style="margin: 0; border-radius: 0;" *ngIf="useImeiInput">
    <span>Could not find VIN, please enter the IMEI of your Dongle!</span>
</div>

<div style="min-width: 50%">
    <mat-form-field class="w-100 mt-2" *ngIf="!useImeiInput">
        <input matInput  id="vin-for-dongle"  maxlength="17" minlength="17" type="text" [(ngModel)]="vin" name="vin"
            placeholder="{{ 'login.vin' | translate  }}" required autofocus>
        <mat-error *ngIf="vin">{{ 'login.vinHint' | translate }}</mat-error>
    </mat-form-field>
    <mat-form-field class="w-100 mt-2" *ngIf="useImeiInput">
        <input matInput id="imei" type="text" [(ngModel)]="imei" name="imei" placeholder="{{ 'login.imei' | translate  }} "
            required autofocus>
    </mat-form-field>

    <div class="col-12 text-center mt-4">
        <button mat-raised-button color="accent" type="button" (click)="checkAutoAidLogin()" class="w-25"  [disabled]="!vin || vin.length !== 17">
            <span translate>global.next</span>
        </button>
    </div>
    
    <p *ngIf="!useImeiInput" style="margin-top: 50px" translate> connected_boxes.vinHint </p>
    <p *ngIf="useImeiInput" style="margin-top: 50px" translate> connected_boxes.imeiHint </p>
</div>