import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HotspotsComponent } from './hotspots.component';
import { CreateHotspotComponent } from './create-hotspot/create-hotspot.component';
import { UpdateHotspotComponent } from './update-hotspot/update-hotspot.component';
import { AutoLoggHotspotsModule } from './hotspots.module';
import { ImportHotspotComponent } from './import-hotspot/import-hotspot.component';
import { KeyCloakAutologgAuthGuard } from 'app/keycloak-auth-guard.service';

const routes: Routes = [
    { path: 'hotspots', component: HotspotsComponent, canActivate: [KeyCloakAutologgAuthGuard] },
    { path: 'hotspots/create', component: CreateHotspotComponent, canActivate: [KeyCloakAutologgAuthGuard] },
    { path: 'hotspots/:id/edit', component: UpdateHotspotComponent, canActivate: [KeyCloakAutologgAuthGuard] },
    { path: 'hotspots/import', component: ImportHotspotComponent, canActivate: [KeyCloakAutologgAuthGuard] },
];

export const AutoLoggHotspotsRouting: ModuleWithProviders<AutoLoggHotspotsModule> =
  RouterModule.forChild(routes);
