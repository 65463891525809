import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
} from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { BaseComponent } from "../base/base.component";
import { DatePipe } from "@angular/common";
import { Car } from "../_models/car.model";
import { Box } from "../_models/box.model";
import { Helper } from "app/base/helper";
import { TrackingConstants } from "app/tracking/tracking-constants";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ApiService } from "app/api.service";
import { TrackingService } from "app/tracking/tracking-service";
import { OverlayLoadingService } from "app/utils/overlay-loading.service";
import { FileSaverService } from "ngx-filesaver";
import { HttpClient } from "@angular/common/http";

import { DialogExportWarningComponent } from "./dialog-export-warning.component";

export type LogbookType = "PDF" | "CSV";

@Component({
  templateUrl: "./export.component.html",
})
export class ExportComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  loading: boolean = false;
  searching: boolean = false;

  tripType: number;
  tripTypes: Array<any>;
  tripTypesTranslation: Array<string>;
  displayPrivateDataInExport: boolean = false;
  showDriver: boolean = false;

  dateEndMaxDate = new Date();
  dateEndMinDate = new Date();
  dateStartMaxDate = new Date();
  exportOptions: UntypedFormGroup;

  box: Box;
  cars: Array<Car> = [];

  constructor(
    _apiService: ApiService,
    router: Router,
    snackBar: MatSnackBar,
    dialog: MatDialog,
    formBuilder: UntypedFormBuilder,
    translate: TranslateService,
    route: ActivatedRoute,
    _changeDetectorRef: ChangeDetectorRef,
    _tracker: TrackingService,
    private overlayLoading: OverlayLoadingService,
    private _http: HttpClient,
    private _FileSaverService: FileSaverService
  ) {
    super(
      _apiService,
      router,
      snackBar,
      dialog,
      formBuilder,
      translate,
      route,
      _changeDetectorRef,
      _tracker
    );
  }

  ngOnInit() {
    const dateEnd = new Date();
    const dateStart = new Date(
      new Date(dateEnd).getTime() - 30 * 60 * 60 * 24 * 1000
    );
    this.dateEndMinDate = dateStart;

    this.exportOptions = this.formBuilder.group({
      dateStart: [dateStart, Validators.required],
      dateEnd: [dateEnd, Validators.required],
    });

    this.tripType = 0;
    this.tripTypesTranslation = [
      "export.tripTypes.any",
      "export.tripTypes.business",
      "export.tripTypes.private",
      "export.tripTypes.mixed",
      "export.tripTypes.commute",
    ];
    this.tripTypes = [
      { id: 0, translation_key: this.tripTypesTranslation[0] },
      { id: 1, translation_key: this.tripTypesTranslation[1] },
      { id: 2, translation_key: this.tripTypesTranslation[2] },
      { id: 3, translation_key: this.tripTypesTranslation[3] },
      { id: 4, translation_key: this.tripTypesTranslation[4] },
    ];

    this.loading = true;
    this._apiService.getUserBoxActive().subscribe(
      (box) => {
        this.box = box;
      },
      (err) => {
        /* do not display an error as a user may not have an active box*/
        this.loading = false;
      },
      () => {
        this.loading = false;
      }
    );

    this._tracker.track(TrackingConstants.Events.ExportPageView);
  }

  ngAfterViewInit() {}

  private isExportTimePeriodValid() {
    const startDate = new Date(this.exportOptions.value.dateStart);
    const endDate = new Date(this.exportOptions.value.dateEnd);

    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 0, 0);
    return startDate <= endDate;
  }

  private setCorrectTimeToDateValues() {
    const startDate = new Date(this.exportOptions.value.dateStart);
    const endDate = new Date(this.exportOptions.value.dateEnd);

    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 0, 0);

    this.exportOptions.value.dateStart = startDate;
    this.exportOptions.value.dateEnd = endDate;
  }

  async downloadPdf() {
    if (!this.isExportTimePeriodValid()) {
      this.showWarning("errors.start_date_must_be_before_end_date");
      return;
    }

    this._tracker.trackExport("PDF");
    this.overlayLoading.show(this._translate.instant("export.loading"));

    try {
      this.setCorrectTimeToDateValues();

      const downloadLink = this._apiService.getExportPdfLink(
        this.exportOptions.value.dateStart,
        this.exportOptions.value.dateEnd,
        this.tripType,
        this.displayPrivateDataInExport,
        this.showDriver
      );

      var response = await this._http
        .get(downloadLink, { responseType: "blob" })
        .toPromise();
      this._FileSaverService.save(response, "AutologgExport.pdf");
    } catch (error) {
      console.log(error);
    } finally {
      this.overlayLoading.hide();
    }
  }

  async downloadCsv() {
    if (!this.isExportTimePeriodValid()) {
      this.showWarning("errors.start_date_must_be_before_end_date");
      return;
    }

    this._tracker.trackExport("CSV");
    this.overlayLoading.show(this._translate.instant("export.loading"));

    try {
      this.setCorrectTimeToDateValues();

      const downloadLink = this._apiService.getExportCsvLink(
        this.exportOptions.value.dateStart,
        this.exportOptions.value.dateEnd,
        this.tripType,
        this.displayPrivateDataInExport,
        this.showDriver
      );
      var response = await this._http
        .get(downloadLink, { responseType: "blob" })
        .toPromise();
      this._FileSaverService.save(response, "AutologgExport.csv");
    } catch (error) {
      console.log(error);
    }
    this.overlayLoading.hide();
  }

  private userHasActiveBoxWithModels() {
    return this.box && this.box.vehicle_count > 0;
  }

  isSearchButtonEnabled() {
    return this.isExportTimePeriodValid() && this.userHasActiveBoxWithModels();
  }

  isCsvDownloadButtonEnabled() {
    return this.isExportTimePeriodValid() && this.userHasActiveBoxWithModels();
  }

  isPdfDownloadButtonEnabled() {
    return this.isExportTimePeriodValid() && this.userHasActiveBoxWithModels();
  }

  search() {
    if (!this.isExportTimePeriodValid()) {
      this.showWarning("errors.start_date_must_be_before_end_date");
      return;
    }
    this.overlayLoading.show();

    try {
      this._tracker.track(TrackingConstants.Events.SearchOnExport);

      this.searching = true;
      this._apiService
        .getExportPreview(
          this.exportOptions.value.dateStart,
          this.exportOptions.value.dateEnd,
          this.tripType
        )
        .subscribe(
          (cars) => {
            this.cars = cars;

            const datePipe = new DatePipe(Helper.getBrowserLang());
            for (const car of this.cars) {
              for (const trip of car.trips) {
                if (trip.start_time) {
                  const startTimeAsDate = new Date(trip.start_time);
                  trip.start_time_display = datePipe
                    .transform(startTimeAsDate, "shortTime")
                    .toString();
                  trip.weekday_display = datePipe
                    .transform(startTimeAsDate, "EEEE")
                    .toString();
                }
                if (trip.end_time) {
                  const endTimeAsDate = new Date(trip.end_time);
                  trip.end_time_display = datePipe
                    .transform(endTimeAsDate, "shortTime")
                    .toString();
                }
              }
            }

            this.overlayLoading.hide();
          },
          (err) => {
            this.searching = false;
            this.showError(err);

            this.overlayLoading.hide();
          },
          () => {
            this.searching = false;

            this.overlayLoading.hide();
          }
        );
    } catch {
      this.overlayLoading.hide();
    }
  }

  onSorted($event, car) {
    car.trips.sort((a, b) => {
      if ($event.sortDirection === "desc") {
        return a[$event.sortColumn] < b[$event.sortColumn] ? 1 : -1;
      } else {
        return a[$event.sortColumn] > b[$event.sortColumn] ? 1 : -1;
      }
    });
  }

  startExport(exportType: String) {
    if (
      this.box.active_model_open_trip_count > 0 ||
      this.box.active_model_has_gap
    ) {
      this.openExportWarningDialog(exportType);
    } else {
      this.downloadExport(exportType);
    }
  }

  downloadExport(exportType: String) {
    switch (exportType) {
      case "pdf": {
        this.downloadPdf();
        break;
      }
      case "csv": {
        this.downloadCsv();
        break;
      }
    }
  }

  openExportWarningDialog(exportType: String) {
    const dialogRef = this.dialog.open(DialogExportWarningComponent);

    const instance = dialogRef.componentInstance;
    instance.gaps[this.box.vehicle_id] = this.box.active_model_has_gap;
    instance.openTripCount[this.box.vehicle_id] =
      this.box.active_model_open_trip_count;

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.exportAnyway === true) {
        this.downloadExport(exportType);
      }
    });
  }
}
