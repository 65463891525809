import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { ManageGroupMembershipComponent } from "./manage-group-membership.component";

@Component({
  selector: "app-dialog-group-leave",
  template: `<h2 class="mb-3" translate>groups.membership.leave.modal.title</h2>
    <form #leaveGroup="ngForm" (submit)="submitForm()">
      <p translate>groups.membership.leave.modal.notice</p>
      <mat-form-field floatPlaceholder="always" class="w-100 mb-2">
        <input
          matInput
          #groupNameField="ngModel"
          type="text"
          [(ngModel)]="groupName"
          name="groupName"
          [pattern]="originalGroupName"
          placeholder=""
          required
          autofocus
          autocomplete="off"
        />
      </mat-form-field>
      <button
        mat-button
        color="warn"
        [disabled]="!leaveGroup.valid"
        type="submit"
      >
        <span translate>groups.membership.leave.modal.submit</span>
      </button>
      <button mat-button type="button" (click)="closeDialog()">
        <span translate>global.cancel</span>
      </button>
    </form>`,
})
export class DialogGroupLeaveComponent {
  originalGroupName: string;
  groupName: string;

  constructor(public dialogRef: MatDialogRef<ManageGroupMembershipComponent>) {}

  submitForm() {
    const groupNameMatches =
      this.groupName && this.groupName === this.originalGroupName;
    if (groupNameMatches) {
      this.dialogRef.close({ leaveGroup: true });
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
