import {
  AfterViewInit,
  Component,
  ViewChild,
  Input,
  EventEmitter,
  Output,
  SimpleChanges,
  OnChanges,
  OnInit,
} from "@angular/core";

import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { SelectionModel } from "@angular/cdk/collections";

import { Observable, zip } from "rxjs";

import { BaseComponent } from "../../base/base.component";
import { GroupInvitation } from "../../_models/group.model";

import { CASE_INSENSITVE_SORTING_DATA_ACCESSOR } from "../../common/table-common.util";

class GroupedGroupInvitations {
  open: Array<GroupInvitation> = [];
  declined: Array<GroupInvitation> = [];
  accepted: Array<GroupInvitation> = [];
}

@Component({
  selector: "app-groups-list-group-invitations",
  templateUrl: "./groups-list-group-invitations.component.html",
  // styleUrls: [''],
})
export class GroupsListGroupInvitationsComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  loading: boolean = false;
  invitations: Array<GroupInvitation> = [];
  groupedInvitations: GroupedGroupInvitations = new GroupedGroupInvitations();

  @Input("groupId") groupId: number;
  @Output("valueChange") valueChange = new EventEmitter();

  ngOnInit() {
    this.reloadInvitations();
  }

  ngAfterViewInit(): void {}

  fetchInvitations(): Observable<Array<GroupInvitation>> {
    return this._apiService.getGroupInvitation(this.groupId);
  }

  reloadInvitations() {
    this.loading = true;
    this.fetchInvitations().subscribe(
      (invitations) => this.onInvitationsLoaded(invitations),
      (err) => {
        this.loading = false;
        this.showError(err);
      },
      () => {
        this.loading = false;
      }
    );
  }

  onInvitationsLoaded(invitations) {
    this.invitations = invitations;
    this.groupedInvitations = this.groupByState(this.invitations);
    this.valueChange.emit(invitations);
  }

  groupByState(invitations: Array<GroupInvitation>): GroupedGroupInvitations {
    return {
      open: invitations.filter((i) => !i.accepted_at && !i.declined_at),
      declined: invitations.filter((i) => !i.accepted_at && !!i.declined_at),
      accepted: invitations.filter((i) => !!i.accepted_at && !i.declined_at),
    };
  }

  onInvitationsChanged(foo) {
    this.reloadInvitations();
  }
}

@Component({
  selector: "app-groups-list-group-invitations-open",
  templateUrl: "./groups-list-group-invitations-open.component.html",
  // styleUrls: [''],
})
export class GroupsListGroupInvitationsOpenComponent
  extends BaseComponent
  implements OnInit, OnChanges, AfterViewInit
{
  loading: boolean = false;

  @Input("invitations") invitations: Array<GroupInvitation> = [];
  @Output("valueChange") valueChange = new EventEmitter();

  displayedColumns: string[] = [
    "select",
    "created_at",
    "user_email",
    "user_exists",
    "group_admin",
    "auto_hotspot_sharing_enabled",
  ];
  dataSource = new MatTableDataSource<GroupInvitation>([]);
  selection = new SelectionModel<GroupInvitation>(true, []);

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  ngOnInit() {
    this.dataSource.sortingDataAccessor = CASE_INSENSITVE_SORTING_DATA_ACCESSOR;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["invitations"]) {
      this.selection = new SelectionModel<GroupInvitation>(true, []);
      this.dataSource.data = this.invitations;
    }
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.data = this.invitations;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  removeSelectedInvitations(selection: SelectionModel<GroupInvitation>) {
    if (selection.selected.length === 0) {
      return;
    }

    const observables = selection.selected.map((i) =>
      this._apiService.removeInvitationsFromGroup(i.group_id, i.id)
    );

    this.loading = true;
    zip(...observables).subscribe(
      (foo) => {
        this.valueChange.emit(foo);
        this.showSuccess(
          "groups.manage.invitations.remove_selected_invitations.success"
        );
      },
      (err) => {
        this.loading = false;
        this.showError(err);
      },
      () => {
        this.loading = false;
      }
    );
  }
}
