import {
  AfterViewInit,
  AfterViewChecked,
  Component,
  ViewChild,
  Input,
  OnInit,
  ChangeDetectorRef,
} from "@angular/core";
import { BaseComponent } from "./base/base.component";
import { MatSidenav } from "@angular/material/sidenav";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog";

import { NavigationEnd, Router, ActivatedRoute } from "@angular/router";
import { ApiService } from "./api.service";
import { UntypedFormBuilder } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { DeviceDetectorService } from "ngx-device-detector";
import { TrackingService } from "./tracking/tracking-service";
import { environment } from "environments/environment";
import { GapService } from "./services/gap.service";
import { KeycloakService } from "keycloak-angular";

declare var window: any;
declare let gtag: Function;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: [],
})
export class AppComponent
  extends BaseComponent
  implements OnInit, AfterViewInit, AfterViewChecked
{
  @Input() supportContact;
  @ViewChild("sidenav", { static: false }) sidenav: MatSidenav;

  /* img */
  private base64textString = "";
  isExpanded: boolean = true;
  isNotSupported: boolean = false;
  hasGaps = false;
  public get isAdmin(): boolean {
    return this._apiService.isAdmin;
  }
  public getAvatar(): string {
    if (this._apiService?.user?.avatar) {
      //the second semicolon is needed because ngstyles eats the first one
      return "data:image/png;;base64," + this._apiService.user.avatar;
    } else {
      return "../assets/images/avatars/default.png";
    }
    return;
  }

  constructor(
    _apiService: ApiService,
    router: Router,
    snackBar: MatSnackBar,
    dialog: MatDialog,
    formBuilder: UntypedFormBuilder,
    translate: TranslateService,
    route: ActivatedRoute,
    _changeDetectorRef: ChangeDetectorRef,
    _tracker: TrackingService,
    private deviceService: DeviceDetectorService,
    private gapService: GapService,
    protected readonly keycloak: KeycloakService
  ) {
    super(
      _apiService,
      router,
      snackBar,
      dialog,
      formBuilder,
      translate,
      route,
      _changeDetectorRef,
      _tracker
    );
  }

  async ngOnInit() {
    this._translate
      .get("menu.supporthotline.contact")
      .subscribe((result: string) => {
        this.supportContact = result;
      });

    this.isExpanded = !this.deviceService.isMobile();

    this.isNotSupported = this.deviceService.browser == "IE";

    await this._apiService.init();
  }

  ngAfterViewInit() {
    this.openOrCloseNavbarIfNecessary();

    this.router.events.subscribe(async (event) => {
      if (event instanceof NavigationEnd) {
        window.dataLayer = window.dataLayer || [];

        window.dataLayer.push({
          event: "ng-pageview",
          page_path: event.urlAfterRedirects,
        });

        gtag("config", environment.gaTrackingId, {
          page_path: event.urlAfterRedirects,
        });

        var hasGap = await this.gapService.hasGap();
        this.hasGaps = hasGap;
      }
    });
  }

  ngAfterViewChecked() {
    this.openOrCloseNavbarIfNecessary();
  }

  handleAvatarFileSelect(evt) {
    const files = evt.target.files;
    const file = files[0];
    if (files && file) {
      const reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  }

  logout() {
    this.sidenav.close();
    this.keycloak.logout();
  }

  private openOrCloseNavbarIfNecessary() {
    if (!this.sidenav) {
      return;
    }

    if (this._apiService.registerStep === 3 && this._apiService.isLoggedIn) {
      setTimeout(() => this.sidenav.close(), 1);
    } else if (!this.sidenav.opened && this._apiService.isLoggedIn) {
      setTimeout(() => this.sidenav.open(), 150);
    } else if (this.sidenav.opened && !this._apiService.isLoggedIn) {
      setTimeout(() => this.sidenav.close(), 1);
    }
  }

  private _handleReaderLoaded(readerEvt) {
    const binaryString = readerEvt.target.result;
    this.base64textString = btoa(binaryString);
    this._apiService.postUserAvatar(this.base64textString).subscribe(
      () => {
        window.location.reload(true);
        this.showSuccess("Profile image saved");
      },
      (err) => {
        this.showError(err);
      }
    );
  }

  private isUserNameDefined(): boolean {
    return (
      this._apiService?.user?.firstname !== null &&
      this._apiService?.user?.firstname !== undefined &&
      this._apiService?.user?.firstname !== " "
    );
  }
}
