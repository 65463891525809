<mat-progress-bar *ngIf="loading" color="accent" mode="indeterminate"></mat-progress-bar>

<div class="container-fluid">

  <div class="row">
    <div class="col-12">
      <h1 translate>groups.create.title</h1>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="button-row">
        <a mat-button routerLink="/groups" title="{{ 'global.back' | translate }}">
          <mat-icon class="icon-custom">arrow_back</mat-icon>
          <span translate>global.back</span>
        </a>
      </div>
    </div>
  </div>

  <form #addGroupForm="ngForm" (ngSubmit)="submitForm()">

    <div class="row">
      <div class="col-6 p-3 pt-2">
        <mat-form-field  floatPlaceholder="always" class="m-auto" class="full-width" [ngClass]="{'mat-form-field-invalid': !nameField.valid && nameField.dirty}">
          <mat-label translate>groups.create.fields.name.label</mat-label>
          <input matInput #nameField="ngModel" type="text" [(ngModel)]="name" name="name" placeholder="{{ 'groups.create.fields.name.placeholder' | translate }}"
            required>
          <mat-hint translate>groups.create.fields.name.hint</mat-hint>
          <mat-error *ngIf="nameField.dirty" align="end">
            <span *ngIf="!nameField.valid" translate>errors.invalidName</span>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-12 p-3 pt-2">
        <mat-form-field class="full-width">
          <mat-label translate>groups.create.fields.description.label</mat-label>
          <textarea matInput #descriptionField="ngModel" [(ngModel)]="description" name="description" placeholder="{{ 'groups.create.fields.description.placeholder' | translate }}"
            rows="3" [maxlength]="descriptionMaxCharacterCount" (ngModelChange)="updateDescriptionRemainingCharacterCount()"></textarea>
          <mat-hint>{{ 'groups.create.fields.description.hint' | translate }}: {{ descriptionRemainingCharacterCount | number:0 }}</mat-hint>
        </mat-form-field>
      </div>

      <!--div class="col-12 col-md-12 p-3">
        <mat-checkbox [(ngModel)]="auto_hotspot_sharing_enabled" name="auto_hotspot_sharing_enabled">
          <span translate>groups.create.fields.auto_hotspot_sharing_enabled.label</span>
        </mat-checkbox>
        <br />
        <mat-hint translate>groups.create.fields.auto_hotspot_sharing_enabled.hint</mat-hint>
      </div-->
    </div>

    <div class="d-flex flex-row justify-content-around flex-wrap m-auto">
      <div class="d-flex mt-3 mb-5">
        <button mat-raised-button color="accent" type="submit" [disabled]="!isSubmitButtonEnabled()" class="m-auto">{{ 'groups.create.submit' | translate }}
        </button>
      </div>
    </div>
  </form>

  <div class="row mt-5"></div>
</div>