<mat-progress-bar *ngIf="loading || updating" color="accent" mode="indeterminate"></mat-progress-bar>

<div class="mt-1 alert alert-info" *ngIf="!loading && !(dataSource.data.length > 0)">
  <span translate>global.noResults</span>
</div>

<div class="mt-1" [hidden]="!(dataSource.data.length > 0)">
  <mat-form-field class="full-width">
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="{{ 'global.Search' | translate }}">
  </mat-form-field>

  <table mat-table matSort [dataSource]="dataSource" class="mt-1 mb-1 full-width">
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="user_email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'groups.manage.users.table.user_email' | translate }} </th>
      <td mat-cell *matCellDef="let element">
        <!--a routerLink="/groups/{{groupId}}/user/{{element.user_id}}" title="{{ 'groups.manage.user.title' | translate }}">
          {{ element.user_email }}
        </a-->
        {{ element.user_email }}
      </td>
    </ng-container>
    <ng-container matColumnDef="user_first_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'groups.manage.users.table.user_first_name' | translate }} </th>
      <td mat-cell *matCellDef="let element"> {{ element.user_first_name }} </td>
    </ng-container>
    <ng-container matColumnDef="user_last_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'groups.manage.users.table.user_last_name' | translate }} </th>
      <td mat-cell *matCellDef="let element"> {{ element.user_last_name }} </td>
    </ng-container>
    <ng-container matColumnDef="membership_created_at">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'groups.manage.users.table.membership_created_at' | translate }} </th>
      <td mat-cell *matCellDef="let element"> {{ element.membership_created_at | date:'mediumDate' }} </td>
    </ng-container>
    <ng-container matColumnDef="membership_auto_hotspot_sharing_enabled">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'groups.manage.users.table.membership_auto_hotspot_sharing_enabled' | translate }} </th>
      <td mat-cell *matCellDef="let element" [ngClass]="{'color-success': element.membership_auto_hotspot_sharing_enabled, 'color-error': !element.membership_auto_hotspot_sharing_enabled }">
        <mat-icon class="vertical-align-middle" *ngIf="element.membership_auto_hotspot_sharing_enabled">check</mat-icon>
        <mat-icon class="vertical-align-middle" *ngIf="!element.membership_auto_hotspot_sharing_enabled">cancel</mat-icon>
        {{ (element.membership_auto_hotspot_sharing_enabled ? 'global.yes' : 'global.no') | translate }}
      </td>
    </ng-container>
    <ng-container matColumnDef="membership_group_admin">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'groups.manage.users.table.membership_group_admin' | translate }} </th>
      <td mat-cell *matCellDef="let element" [ngClass]="{'color-success': element.membership_group_admin, 'color-error': !element.membership_group_admin }">
        <mat-slide-toggle [checked]="element.membership_group_admin" labelPosition="after" (change)="toggleAdminRights(element)"
          [disabled]="element.user_id === userId || element.user_id === groupOwnerUserId">
          <mat-icon class="vertical-align-middle" *ngIf="element.membership_group_admin">check</mat-icon>
          <mat-icon class="vertical-align-middle" *ngIf="!element.membership_group_admin">cancel</mat-icon>
          {{ (element.membership_group_admin ? 'global.yes' : 'global.no') | translate }}
        </mat-slide-toggle>
      </td>
    </ng-container>

    <ng-container matColumnDef="user_enabled">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'groups.manage.users.table.user_enabled' | translate }} </th>
      <td mat-cell *matCellDef="let element" [ngClass]="{'color-success': !element.user_locked, 'color-error': element.user_locked }">
        <mat-slide-toggle [checked]="!element.user_locked" labelPosition="after" (change)="toggleLocked(element)"
          [disabled]="element.user_id === userId || element.user_id === groupOwnerUserId">
          <mat-icon class="vertical-align-middle" *ngIf="!element.user_locked">check</mat-icon>
          <mat-icon class="vertical-align-middle" *ngIf="element.user_locked">cancel</mat-icon>
          {{ (element.user_locked ? 'global.no' : 'global.yes') | translate }}
        </mat-slide-toggle>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
    </tr>
  </table>

  <mat-paginator [pageSizeOptions]="[50, 100, 200]" showFirstLastButtons></mat-paginator>


  <div class="button-row">
    <button mat-button color="warn" title="{{ 'groups.manage.users.remove_selected_users.submit' | translate }}"
      (click)="removeSelectedUsers(selection)" [disabled]="!selection.hasValue()">
      <mat-icon class="icon-custom">delete_sweep</mat-icon>
      <span translate>groups.manage.users.remove_selected_users.submit</span>
    </button>
  </div>
</div>