import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AccountComponent } from './account.component';
import { ProfileComponent } from './profile.component';
import { AutoLoggAccountModule } from './account.module';
import { KeyCloakAutologgAuthGuard } from 'app/keycloak-auth-guard.service';

const routes: Routes = [
  { path: 'account', component: AccountComponent, canActivate: [KeyCloakAutologgAuthGuard] },
  { path: 'account', outlet: 'dialog', component: AccountComponent, canActivate: [KeyCloakAutologgAuthGuard] },
  { path: 'account/profile', component: ProfileComponent, canActivate: [KeyCloakAutologgAuthGuard] },
  // path '/profile' is deprecated in favour of 'account/profile'
  { path: 'profile', component: ProfileComponent, canActivate: [KeyCloakAutologgAuthGuard] },
];

export const AutoLoggAccountRouting: ModuleWithProviders<AutoLoggAccountModule> =
  RouterModule.forChild(routes);
