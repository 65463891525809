<div>
  <mat-form-field class="w-100 mt-2" hideRequiredMarker>
    <input matInput #email="ngModel" type="text" [(ngModel)]="emailInput" name="email"
      placeholder="{{ 'login.email_placeholder' | translate  }} " pattern="^.+@.+(\.).+$" required autofocus
      autocomplete="email">
  </mat-form-field>

  <mat-form-field floatPlaceholder="always" class="w-100 mt-2" hideRequiredMarker>
    <input matInput #password="ngModel" type="password" [(ngModel)]="passwordInput" name="password"
      placeholder="{{ 'login.password_placeholder' | translate  }}" required autocomplete="current-password">

  </mat-form-field>

</div>