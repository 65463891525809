import { AfterViewInit, Component, OnInit } from "@angular/core";
import { TrackingConstants } from "app/tracking/tracking-constants";
import { BaseComponent } from "../base/base.component";
import { Box } from "../_models/box.model";
import { Gap } from "../_models/gap.model";
import { SingleStat, Statistics } from "../_models/statistics.model";

@Component({
  templateUrl: "./home.component.html",
  styleUrls: ["home.component.css"],
})
export class HomeComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  loading: boolean = true;
  updating: boolean = false;

  statisticsData: Statistics = new Statistics();
  showChart: boolean = false;
  date: any = new Date();
  year: number = this.date.getFullYear();
  years: Array<number> = [this.date.getFullYear()];

  interval: number = 1;
  intervals: Array<{ code: number; name: string }>;

  barChartData: any = { data: null };

  business_trips_percentage: number = 0;
  private_trips_percentage: number = 0;

  doughnutChartData: any[];

  chartEmpty = false;

  mobile = false;

  lastTrip: any = {
    address: null,
    lat: null,
    lng: null,
    km: null,
  };

  activeBox: Box;

  ngOnInit() {
    this.statisticsData.gap = new Gap();
    this.loadActiveBoxThenOtherData();
    this._tracker.track(TrackingConstants.Events.HomePageView);
  }

  ngAfterViewInit() {}

  changeYear() {
    this.loadStatisticsYear();
  }

  changeDiagram() {
    this.barChartData = this.extractDistances(
      this.statisticsData.year_statistics
    );

    if (
      this.statisticsData.private_trips + this.statisticsData.business_trips ==
      0
    ) {
      this.chartEmpty = true;
    }

    this.showChart = true;
  }

  private loadActiveBoxThenOtherData() {
    this.loading = true;
    this._apiService.getUserBoxActive().subscribe(
      (activeBox) => {
        this.activeBox = activeBox;
      },
      (err) => {
        this.loading = false;
      },
      () => {
        this.fetchLastTripAndStatistics();
      }
    );
  }

  private fetchLastTripAndStatistics() {
    this.loading = true;

    let requests = 2;
    const start = new Date().getTime();
    const decreaseLoadingIndicator = () => {
      const duration = new Date().getTime() - start;
      const delay =
        duration < 1000 ? Math.max(100, Math.min(duration, 300)) : 1;

      setTimeout(() => {
        this.loading = --requests > 0;
      }, delay);
    };

    this.fetchLastTrip().subscribe(
      (foo) => {},
      (err) => {
        decreaseLoadingIndicator();
      },
      () => {
        decreaseLoadingIndicator();
      }
    );
    this.fetchStatistics().subscribe(
      (foo) => {},
      (err) => {
        decreaseLoadingIndicator();
      },
      () => {
        decreaseLoadingIndicator();
      }
    );
  }

  private fetchLastTrip() {
    return this._apiService.getTripsLast().do((lastTrip) => {
      this.lastTrip = lastTrip;
      this.years = lastTrip.years;
    });
  }

  private fetchStatistics() {
    return this._apiService.getStatistics().do(
      (stats) => {
        this.statisticsData = stats;
        const sum =
          this.statisticsData.private_trips +
          this.statisticsData.business_trips;
        if (sum <= 0) {
          this.private_trips_percentage = 50;
          this.business_trips_percentage = 50;
        } else {
          this.private_trips_percentage = Math.round(
            (this.statisticsData.private_trips / sum) * 100
          );
          this.business_trips_percentage = Math.round(
            (this.statisticsData.business_trips / sum) * 100
          );
        }
        this.doughnutChartData = [
          {
            name: "Business trips",
            value: this.business_trips_percentage,
          },
          {
            name: "Private trips",
            value: this.private_trips_percentage,
          },
        ];
        this.loadStatisticsYear();
      },
      (err) => {
        // do not show error as user may not have a last trips
      },
      () => {}
    );
  }

  private loadStatisticsYear() {
    this._apiService.getStatisticsYear(this.year).subscribe(
      (data) => {
        this.statisticsData.year_statistics = data.year_statistics;
        this.changeDiagram();
      },
      (err) => {
        this.showError(err);
      }
    );
  }

  private extractDistances(array: Array<SingleStat>): any {
    return array.map((i) => ({ name: i.label, value: i.distance }));
  }
}
