import { AfterViewInit, Component, ViewChild, OnInit } from "@angular/core";
import { BaseComponent } from "../../base/base.component";
import { GroupCreateRequest } from "../../_models/group-create-request.model";

@Component({
  templateUrl: "./create-group.component.html",
  styleUrls: ["create-group.component.css"],
})
export class CreateGroupComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  private static DEFAULT_AUTO_HOTSPOTS_SHARING = false;
  @ViewChild("addGroupForm", { static: false }) form;

  loading: boolean = false;

  name: string = "";
  description: string = "";
  auto_hotspot_sharing_enabled: boolean =
    CreateGroupComponent.DEFAULT_AUTO_HOTSPOTS_SHARING;

  descriptionMaxCharacterCount: number = 1024;
  descriptionRemainingCharacterCount: number = 0;

  ngOnInit() {}

  setupNewForm() {
    this.form.resetForm();

    this.name = "";
    this.description = "";
    this.auto_hotspot_sharing_enabled =
      CreateGroupComponent.DEFAULT_AUTO_HOTSPOTS_SHARING;
  }

  ngAfterViewInit() {
    this.form.control.valueChanges.subscribe((values) => {
      this.formControlValueChanged();
    });
    this.setupNewForm();
  }

  formControlValueChanged() {
    this.updateDescriptionRemainingCharacterCount();
  }

  updateDescriptionRemainingCharacterCount() {
    this.descriptionRemainingCharacterCount =
      this.descriptionMaxCharacterCount - (this.description || "").length;
  }

  isSubmitButtonEnabled() {
    return this.name.length > 0;
  }

  submitForm() {
    if (!this.isSubmitButtonEnabled()) {
      throw new Error("Form is in invalid state and cannot be submitted");
    }

    const createRequest = new GroupCreateRequest();
    createRequest.description = this.description;
    createRequest.name = this.name;
    createRequest.auto_hotspot_sharing_enabled =
      this.auto_hotspot_sharing_enabled;

    this.loading = true;

    this._apiService.createUserGroup(createRequest).subscribe(
      (group) => {
        this.showSuccess("Group created");
        this.setupNewForm();
        this.router.navigate(["/groups/" + group.id + "/membership"]);
      },
      (err) => {
        this.loading = false;
        this.showError(err);
      },
      () => {
        this.loading = false;
      }
    );
  }
}
