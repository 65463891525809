<mat-progress-bar *ngIf="loading" color="accent" mode="indeterminate"></mat-progress-bar>

<div class="container-fluid">

  <div class="row">
    <div class="col-12">
      <h1 translate>connected_boxes.update_access_data.title</h1>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="button-row">
        <a mat-button routerLink=".." title="{{ 'global.back' | translate }}">
          <mat-icon class="icon-custom">arrow_back</mat-icon>
          <span translate>global.back</span>
        </a>
      </div>
    </div>
  </div>


  <form #addBoxForm="ngForm" autocomplete="off">
    <app-connected-login *ngIf="box" #connectedLogin [(connectedCredentials)]="connectedCredentials" [oemType]="oemType"
      [providerType]="providerType" [(vin)]="vin" (mfaUpdated)="onMfaUpdated($event)"
      (onVehiclesUpdated)="onVehiclesUpdated($event)" (oauth2Completed)="onOauth2Completed($event)"
      (oauth2Failed)="onOauth2Failed($event)">
    </app-connected-login>

    <ng-container
      *ngIf="providerType !== ProviderType.HighMobility && oemType !== OemType.Dongle && oemType !== OemType.Mercedes">
      <div class="row">
        <div class="col-8 text-center mt-2">
          <button mat-raised-button color="accent" type="submit" (click)="checkConnectedLogin()" class="w-25">
            <span translate>connected_boxes.update_access_data.update</span>
          </button>
        </div>
      </div>
    </ng-container>

  </form>


  <div class="row mt-5"></div>
</div>