import { Location } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { UntypedFormBuilder } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ApiService } from "app/api.service";
import { RegisterService } from "app/register/register.service";
import { TrackingConstants } from "app/tracking/tracking-constants";
import { TrackingService } from "app/tracking/tracking-service";
import { environment } from "environments/environment";
import { Box } from "../_models/box.model";
import { BaseBoxesComponent } from "./base-boxes.component";
import { OemType } from "app/base/provider-oem-type"

@Component({
  templateUrl: "./boxes.component.html",
  styleUrls: ["boxes.component.css"],
})
export class BoxesComponent
  extends BaseBoxesComponent
  implements OnInit, AfterViewInit {
  loading: boolean = true;
  updating: boolean = false;
  boxes: Array<Box> = [];

  updateCredentialsEnabled = false;

  constructor(
    _apiService: ApiService,
    router: Router,
    snackBar: MatSnackBar,
    dialog: MatDialog,
    formBuilder: UntypedFormBuilder,
    translate: TranslateService,
    route: ActivatedRoute,
    _changeDetectorRef: ChangeDetectorRef,
    _tracker: TrackingService,
    private registerService: RegisterService,
    private location: Location
  ) {
    super(
      _apiService,
      router,
      snackBar,
      dialog,
      formBuilder,
      translate,
      route,
      _changeDetectorRef,
      _tracker
    );

    this.updateCredentialsEnabled = environment.updateCredentialsEnabled;
  }

  ngOnInit() {
    this.loadUserBoxes();
    this._tracker.track(TrackingConstants.Events.VehiclesPageView);
  }

  ngAfterViewInit() { }

  selectBoxAsPrimary(box: Box) {
    if (box.primary || !box.activation_allowed || !(box.active_model_id > 0)) {
      return;
    }

    this.updating = true;
    this._apiService.activateBoxForUser(box).subscribe(
      (foo) => {
        this.boxes.forEach((val) => (val.primary = false));
        box.primary = true;

        this.showSuccess(this.getTranslation("activate.success"));
      },
      (err) => {
        this.updating = false;
        this.showError(err);
      },
      () => {
        this.updating = false;
      }
    );
  }

  private fetchUserBoxes() {
    let boxes = this._apiService
      .getAllUserBoxes()
      .map((boxes) => boxes.sort(this.orderBoxByName));
    return boxes;
  }

  private fetchAdministrableGroupBoxes() {
    return this._apiService
      .getAdministrableBoxes()
      .map((boxes) => boxes.sort(this.orderBoxByName));
  }

  private async loadUserBoxes() {
    this.loading = true;
    try {
      this.boxes = await this.fetchUserBoxes().toPromise();
      this.boxes = this.boxes.concat(
        await this.fetchAdministrableGroupBoxes().toPromise()
      );

      this.boxes.forEach((box) => {
        box.connected_info_loading = true;
        if (!box.oem_type) {
          box.connected_info_loading = false;
          return;
        }
        if (box.oem_type != OemType.Box) {
          this.registerService
            .getConnectedVehicleInformation(box.vehicle_id)
            .then((info) => {
              box.connected_info = info;
              box.connected_info_loading = false;
            })
            .catch((error) => {
              box.connected_not_found = {};
              box.connected_info_loading = false;
            });
        } else {
          box.connected_info_loading = false;
        }
      });
    } catch (error) {
      this.showError(error);
    }

    this.loading = false;
  }

  private orderBoxByName(a: Box, b: Box) {
    return a.name.localeCompare(b.name);
  }
}
