/* "Barrel" of Http Interceptors */
import { HTTP_INTERCEPTORS } from "@angular/common/http";

import { LoggingInterceptor } from "./logging-interceptor";
import { AppVersionHeaderInterceptor } from "./app-version-header-interceptor";

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: LoggingInterceptor, multi: true },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AppVersionHeaderInterceptor,
    multi: true,
  },
];
