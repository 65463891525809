declare interface Window {
  navigator: any;
}

declare const window: Window;

export class Helper {
  public static getBrowserLang(): string {
    if (
      typeof window === "undefined" ||
      typeof window.navigator === "undefined"
    ) {
      return undefined;
    }

    let browserLang: any = window.navigator.languages
      ? window.navigator.languages[0]
      : null;
    browserLang =
      browserLang ||
      window.navigator.language ||
      window.navigator.browserLanguage ||
      window.navigator.userLanguage;

    if (browserLang.indexOf("-") !== -1) {
      browserLang = browserLang.split("-")[0];
    }

    if (browserLang.indexOf("_") !== -1) {
      browserLang = browserLang.split("_")[0];
    }

    return browserLang;
  }

  public static processVin(enteredVin: string): string {
    return enteredVin
      .replace("I", "1")
      .replace("O", "0")
      .replace("Q", "0")
      .replace(/[^a-z0-9]/gi, "")
      .toUpperCase()
      .trim();
  }
}
