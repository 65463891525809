import { AfterViewInit, OnInit, ViewChild, Component } from "@angular/core";
import { forkJoin } from "rxjs";
import { BaseComponent } from "../../base/base.component";
import {
  Group,
  GroupUserMembership,
  GroupMembership,
  GroupMembershipUpdateRequest,
} from "../../_models/group.model";
import { DialogGroupLeaveComponent } from "./dialog-group-leave.component";

@Component({
  templateUrl: "./manage-group-membership.component.html",
  styleUrls: ["manage-group-membership.component.css"],
})
export class ManageGroupMembershipComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  @ViewChild("editMembershipForm", { static: false }) form;

  loading = false;
  reloading = false;

  userId: number;
  groupId: number;

  sharedBoxesCount: number = 0;
  shareableBoxesCount: number = 0;
  sharedHotspotsCount: number = 0;
  shareableHotspotsCount: number = 0;

  groupUser: GroupUserMembership;

  group: Group;
  membership: GroupMembership;
  originalGroupName = "";

  ngOnInit() {
    this.userId = this._apiService.user.userid;
    this.groupId = +this.route.snapshot.paramMap.get("id");
    this.loading = true;
  }

  ngAfterViewInit() {
    this.load();
  }

  onSharedBoxesCount(val) {
    this.sharedBoxesCount = val;
  }

  onShareableBoxesCount(val) {
    this.shareableBoxesCount = val;
  }

  onSharedHotspotsCount(val) {
    this.sharedHotspotsCount = val;
  }

  onShareableHotspotsCount(val) {
    this.shareableHotspotsCount = val;
  }

  load() {
    this.loading = true;

    forkJoin(this._apiService.getGroupMembership(this.groupId)).subscribe(
      (array) => {
        this.groupUser = array[0];

        this.group = this.groupUser.group;
        this.membership = this.groupUser.membership;

        this.originalGroupName = this.group.name || "";
      },
      (err) => {
        this.loading = false;
        this.showError(err);
      },
      () => {
        this.loading = false;
      }
    );
  }

  isGroupOwner() {
    return this.group && this.group.group_owner_user_id === this.userId;
  }

  isGroupAdmin() {
    return this.membership && this.membership.group_admin;
  }

  isSettingsSubmitButtonEnabled() {
    return true;
  }

  submitForm() {
    if (!this.isSettingsSubmitButtonEnabled()) {
      throw new Error("Form is in invalid state and cannot be submitted");
    }

    const request = new GroupMembershipUpdateRequest();
    request.auto_hotspot_sharing_enabled =
      this.membership.auto_hotspot_sharing_enabled;

    this._apiService
      .updateGroupMembershipByMember(this.membership.group_id, request)
      .subscribe(
        () => {},
        (err) => {
          this.reloading = false;
          this.showError(err);
        },
        () => {
          this.reloading = false;
          this.showSuccess("groups.membership.manage.settings.submit.success");
        }
      );
  }

  openLeaveGroupDialog() {
    const dialogRef = this.dialog.open(DialogGroupLeaveComponent);

    const instance = dialogRef.componentInstance;
    instance.originalGroupName = this.originalGroupName;

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.leaveGroup === true) {
        this.loading = true;
        this._apiService.leaveGroup(this.groupId).subscribe(
          () => {
            this.router.navigate(["/groups"]);
            this.showSuccess("groups.membership.leave.modal.success");
          },
          (err) => {
            this.loading = false;
            this.showError(err);
          },
          () => {
            this.loading = false;
          }
        );
      }
    });
  }
}
