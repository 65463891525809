import { Car } from "./car.model";

export class User {
  public itemid: string;
  public userid: number;
  public auth_token: string;
  public email: string;

  public firstname: string;
  public lastname: string;
  public imageurl: string;
  public avatar: string;
  public street: string;
  public town: string;
  public phone_number: string;
  // TODO: remove if possible
  public autolog_key: string;

  public car: Car;

  public demo: boolean;

  public open_invitations_count: number;
  public features_enabled: Map<string, string>;
  public general_features_enabled: Array<string>;
  public vehicles_mileage_needed_count: number;
  public manual_link: String;

  public locked: boolean;

  constructor() {
    this.car = new Car();
  }

  hasFeature(featureCode) {
    return (
      featureCode &&
      this.features_enabled &&
      !!this.features_enabled[featureCode]
    );
  }

  hasGeneralFeature(featureCode) {
    return (
      featureCode &&
      this.general_features_enabled &&
      this.general_features_enabled.includes(featureCode)
    );
  }
  hasFeatureOrGeneralFeature(featureCode) {
    return this.hasFeature(featureCode) || this.hasGeneralFeature(featureCode);
  }

  isDemoUser() {
    return this.demo === true;
  }
}

export class SimpleUser {
  public id: number;
  public email: string;
  public first_name: string;
  public last_name: string;
  public locked: boolean;

  public get fullName(): string {
    return this.last_name + " " + this.first_name;
  }
}

export class UserSettings {
  public enableBetaMode: boolean = false;
  public language: string;
  public enablePushNotification: boolean = false;
  public openTripSortDirectionAsc: boolean = false;
  public closedTripSortDirectionAsc: boolean = false;
}
