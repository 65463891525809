import { AfterViewInit, Component, OnInit, Input, ChangeDetectorRef } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { DatePipe } from "@angular/common";

import { BaseComponent } from "../../../base/base.component";
import { Car } from "../../../_models/car.model";
import { Box } from "../../../_models/box.model";
import { Helper } from "app/base/helper";
import { HttpClient } from "@angular/common/http";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ApiService } from "app/api.service";
import { TrackingService } from "app/tracking/tracking-service";
import { OverlayLoadingService } from "app/utils/overlay-loading.service";
import { FileSaverService } from "ngx-filesaver";

@Component({
  selector: "app-groups-manage-box-export",
  templateUrl: "./export.component.html",
})
export class ManageGroupBoxExportComponent
  extends BaseComponent
  implements OnInit, AfterViewInit {
  @Input("groupId") groupId: number;
  @Input("box") box: Box;

  loading: boolean = false;

  tripType: number;
  tripTypes: Array<any>;
  tripTypesTranslation: Array<string>;
  showDriver: boolean = false;

  dateEndMaxDate = new Date();
  dateEndMinDate = new Date();
  dateStartMaxDate = new Date();
  exportOptions: UntypedFormGroup;

  cars: Array<Car> = [];


  constructor(
    _apiService: ApiService,
    router: Router,
    snackBar: MatSnackBar,
    dialog: MatDialog,
    formBuilder: UntypedFormBuilder,
    translate: TranslateService,
    route: ActivatedRoute,
    _changeDetectorRef: ChangeDetectorRef,
    _tracker: TrackingService,
    private overlayLoading: OverlayLoadingService,
    private _http: HttpClient,
    private _FileSaverService: FileSaverService
  ) {
    super(
      _apiService,
      router,
      snackBar,
      dialog,
      formBuilder,
      translate,
      route,
      _changeDetectorRef,
      _tracker
    );
  }

  ngOnInit() {
    const dateEnd = new Date();
    const dateStart = new Date(
      new Date(dateEnd).getTime() - 30 * 60 * 60 * 24 * 1000
    );
    this.dateEndMinDate = dateStart;

    this.exportOptions = this.formBuilder.group({
      dateStart: [dateStart, Validators.required],
      dateEnd: [dateEnd, Validators.required],
    });

    this.tripType = 0;
    this.tripTypesTranslation = [
      "export.tripTypes.any",
      "export.tripTypes.business",
      "export.tripTypes.private",
    ];
    this.tripTypes = [
      { id: 0, translation_key: this.tripTypesTranslation[0] },
      { id: 1, translation_key: this.tripTypesTranslation[1] },
      { id: 2, translation_key: this.tripTypesTranslation[2] },
    ];
  }

  ngAfterViewInit() { }

  private isExportTimePeriodValid() {
    return (
      this.exportOptions.value.dateStart < this.exportOptions.value.dateEnd
    );
  }

  async downloadPdf() {
    if (!this.isExportTimePeriodValid()) {
      this.showWarning("errors.start_date_must_be_before_end_date");
      return;
    }
    if (this.exportOptions.invalid) {
      return;
    }

    const vehicleIds: Array<number> = [this.box.vehicle_id];
    try {
      const downloadLink = this._apiService.getGroupExportPdfLink(
        this.groupId,
        vehicleIds,
        this.exportOptions.value.dateStart,
        this.exportOptions.value.dateEnd,
        this.tripType,
        this.showDriver
      );
      var response = await this._http
        .get(downloadLink, { responseType: "blob" })
        .toPromise();
      this._FileSaverService.save(response, this._apiService.formatDateForExport(this.exportOptions.value.dateStart) + "-" + this._apiService.formatDateForExport(this.exportOptions.value.dateEnd) + ".zip");
    }
    finally {
      this.overlayLoading.hide();
    }

  }

  async downloadCsv() {
    if (!this.isExportTimePeriodValid()) {
      this.showWarning("errors.start_date_must_be_before_end_date");
      return;
    }
    if (this.exportOptions.invalid) {
      return;
    }
    const vehicleIds: Array<number> = [this.box.vehicle_id];

    try {
      const downloadLink = this._apiService.getGroupExportCsvLink(
        this.groupId,
        vehicleIds,
        this.exportOptions.value.dateStart,
        this.exportOptions.value.dateEnd,
        this.tripType,
        this.showDriver
      );
      var response = await this._http
        .get(downloadLink, { responseType: "blob" })
        .toPromise();
      this._FileSaverService.save(response, this._apiService.formatDateForExport(this.exportOptions.value.dateStart) + "-" + this._apiService.formatDateForExport(this.exportOptions.value.dateEnd) + ".zip");
    }
    finally {
      this.overlayLoading.hide();
    }
  }

  search() {
    if (!this.isExportTimePeriodValid()) {
      this.showWarning("errors.start_date_must_be_before_end_date");
      return;
    }
    if (this.exportOptions.invalid) {
      return;
    }

    this.loading = true;
    this._apiService
      .getGroupExportPreview(
        this.groupId,
        this.box.vehicle_id,
        this.exportOptions.value.dateStart,
        this.exportOptions.value.dateEnd,
        this.tripType
      )
      .subscribe(
        (cars) => {
          this.cars = cars;

          const datePipe = new DatePipe(Helper.getBrowserLang());
          for (const car of this.cars) {
            for (const trip of car.trips) {
              if (trip.start_time) {
                const startTimeAsDate = new Date(
                  trip.start_time
                );
                trip.start_time_display = datePipe
                  .transform(startTimeAsDate, "shortTime")
                  .toString();
                trip.weekday_display = datePipe
                  .transform(startTimeAsDate, "EEEE")
                  .toString();
              }
              if (trip.end_time) {
                const endTimeAsDate = new Date(
                  trip.end_time
                );
                trip.end_time_display = datePipe
                  .transform(endTimeAsDate, "shortTime")
                  .toString();
              }
            }
          }
        },
        (err) => {
          this.loading = false;
          this.showError(err);
        },
        () => {
          this.loading = false;
        }
      );
  }

  onSorted($event, car) {
    car.trips.sort((a, b) => {
      if ($event.sortDirection === "desc") {
        return a[$event.sortColumn] < b[$event.sortColumn] ? 1 : -1;
      } else {
        return a[$event.sortColumn] > b[$event.sortColumn] ? 1 : -1;
      }
    });
  }
}
