<mat-progress-bar *ngIf="loading" color="accent" mode="indeterminate"></mat-progress-bar>
<mat-progress-bar *ngIf="updating" color="accent" mode="indeterminate"></mat-progress-bar>

<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <h1 translate>hotspots.create.title</h1>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="button-row">
        <a mat-button routerLink="/hotspots" title="{{ 'global.back' | translate }}">
          <mat-icon class="icon-custom">arrow_back</mat-icon>
          <span translate>global.back</span>
        </a>
      </div>
    </div>

    <div class="col-12">
      <p class="alert alert-info" translate>hotspots.create.notice1</p>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-lg-6">
      <app-geocoder-map class="h-100" [hotspots]="hotspots" markerDraggable="true" (onMapClicked)="onMapClicked($event)"
        (onMarkerDragEnd)="onMarkerDragEnd($event)" (geocoderMarkgeocode)="onGeocoderMarkgeocode($event)">
      </app-geocoder-map>
    </div>
    <div class="col-12 col-lg-6">
      <form #addHotspotForm="ngForm" (ngSubmit)="submitForm()">
        <div class="row">

          <div class="col-12 p-3 pt-2">
            <mat-form-field floatPlaceholder="always" class="m-auto" class="full-width"
              [ngClass]="{'mat-form-field-invalid': !titleField.valid && titleField.dirty}">
              <mat-label translate>hotspots.create.fields.title.label</mat-label>
              <input matInput #titleField="ngModel" type="text" [(ngModel)]="title" name="title"
                placeholder="{{ 'hotspots.create.fields.title.placeholder' | translate }}" required>
              <mat-hint translate>hotspots.create.fields.title.hint</mat-hint>
              <mat-error *ngIf="titleField.dirty" align="end">
                <span *ngIf="!titleField.valid" translate>hotspots.create.fields.title.invalidMessage</span>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-12 p-3 pt-2">
            <mat-form-field floatPlaceholder="always" class="m-auto" class="full-width"
              [ngClass]="{'mat-form-field-invalid': !radiusField.valid && radiusField.dirty}">
              <mat-label translate>hotspots.create.fields.radius_in_meters.label</mat-label>
              <input matInput #radiusField="ngModel" type="number" [(ngModel)]="radius_in_meters"
                (ngModelChange)="onModelRadiusInMetersChanged()" name="radius"
                placeholder="{{ 'hotspots.create.fields.radius_in_meters.placeholder' | translate }}" required min="50"
                max="1000" (ngModelChange)="onRadiusChange()">
              <mat-hint translate>hotspots.create.fields.radius_in_meters.hint</mat-hint>
              <mat-error *ngIf="radiusField.dirty" align="end">
                <span *ngIf="!radiusField.valid" translate>hotspots.create.fields.radius_in_meters.invalidMessage</span>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-12 p-3 pt-2">
            <mat-form-field floatPlaceholder="always" class="m-auto" class="full-width"
              [ngClass]="{'mat-form-field-invalid': !addressField.valid && addressField.dirty}">
              <mat-label translate>hotspots.create.fields.address.label</mat-label>
              <input matInput #addressField="ngModel" type="text" [(ngModel)]="address" name="address"
                placeholder="{{ 'hotspots.create.fields.address.placeholder' | translate }}" required>
              <mat-hint translate>hotspots.create.fields.address.hint</mat-hint>
              <mat-error *ngIf="addressField.dirty" align="end">
                <span *ngIf="!addressField.valid" translate>hotspots.create.fields.address.invalidMessage</span>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-12 p-3 pt-2">
            <mat-form-field floatPlaceholder="always" class="m-auto" class="full-width"
              [ngClass]="{'mat-form-field-invalid': !partnerField.valid && partnerField.dirty}">
              <mat-label translate>hotspots.create.fields.partner.label</mat-label>
              <input matInput #partnerField="ngModel" type="text" [(ngModel)]="partner" name="partner"
                placeholder="{{ 'hotspots.create.fields.partner.placeholder' | translate }}">
              <mat-hint translate>hotspots.create.fields.partner.hint</mat-hint>
              <mat-error *ngIf="partnerField.dirty" align="end">
                <span *ngIf="!partnerField.valid" translate>hotspots.create.fields.partner.invalidMessage</span>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-12 p-3 pt-2">
            <mat-form-field floatPlaceholder="always" class="m-auto" class="full-width"
              [ngClass]="{'mat-form-field-invalid': !purposeField.valid && purposeField.dirty}">
              <mat-label translate>hotspots.create.fields.purpose.label</mat-label>
              <input matInput #purposeField="ngModel" type="text" [(ngModel)]="purpose" name="purpose"
                placeholder="{{ 'hotspots.create.fields.purpose.placeholder' | translate }}">
              <mat-hint translate>hotspots.create.fields.purpose.hint</mat-hint>
              <mat-error *ngIf="purposeField.dirty" align="end">
                <span *ngIf="!purposeField.valid" translate>hotspots.create.fields.purpose.invalidMessage</span>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-12 p-3 pt-2">
            <mat-form-field class="full-width">
              <mat-label translate>hotspots.create.fields.note.label</mat-label>
              <textarea matInput #noteField="ngModel" [(ngModel)]="note" name="note" [maxlength]="noteMaxCharacterCount"
                rows="3" placeholder="{{ 'hotspots.create.fields.note.placeholder' | translate }}"
                (ngModelChange)="onNoteChange()"></textarea>
              <mat-hint>{{ 'hotspots.create.fields.note.hint' | translate }}: {{ noteRemainingCharacterCount | number:0
                }}</mat-hint>
            </mat-form-field>
          </div>
        </div>

        <div class="d-flex flex-row justify-content-around flex-wrap m-auto">
          <div class="d-flex p-3">
            <button mat-raised-button color="accent" type="submit" [disabled]="!isSubmitButtonEnabled()" class="m-auto">
              <span translate>hotspots.create.submit</span>
            </button>
          </div>
        </div>

      </form>
    </div>

  </div>


  <div class="row mt-5"></div>
</div>