import { GroupUserBoxWithLatestTrip } from "../../_models/group.model";

export class TripInfo {
  box_name: string;
  end_lat: number;
  end_lng: number;
  end_address: string;
  end_mileage: number;
  end_time: string;
  user_first_name: string;
  user_last_name: string;

  public static fromGroupUserBoxWithLatestTrip(
    val: GroupUserBoxWithLatestTrip
  ): TripInfo {
    const result = new TripInfo();
    result.box_name = val.group_user_box.box.name;
    result.user_first_name = val.group_user_box.user.first_name;
    result.user_last_name = val.group_user_box.user.last_name;

    if (val.latest_trip) {
      result.end_time = val.latest_trip.end_time;
      result.end_address = val.latest_trip.end_address;
      result.end_mileage = val.latest_trip.end_km;
      result.end_lat = val.latest_trip.end_lat;
      result.end_lng = val.latest_trip.end_lng;
    }

    return result;
  }
}
