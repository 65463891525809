
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { CustomQueryHttpParameterCodec } from "./api/api.utils";

export class BaseService {

    constructor(
        private httpService: HttpClient,
        private baseUrl: string
    ) {
    }

    public createParams(): HttpParams {
        const params: HttpParams = new HttpParams({
            encoder: new CustomQueryHttpParameterCodec(),
        });
        return params;
    }

    private headers(
        withCredentials: boolean = false,
        additionalHeaders: any[] = []
    ): HttpHeaders {


        let headers = new HttpHeaders();
        headers.append("Content-Type", "application/json");

        additionalHeaders.forEach((a) => {
            headers.append(a.key, a.value);
        });

        return headers;
    }

    async get<T>(
        url: string,
        queryParams?: HttpParams,
        withCredentials: boolean = true
    ): Promise<T> {
        try {
            const response = await this.httpService
                .get(this.baseUrl + url, {
                    withCredentials: withCredentials,
                    headers: this.headers(withCredentials),
                    responseType: "json",
                    params: queryParams
                })
                .toPromise();

            return response as T;
        } catch (error) {
            throw error;
        }
    }

    async post<T>(
        url: string,
        body: any,
        withCredentials: boolean = true
    ): Promise<T> {
        try {
            const response = await this.httpService
                .post(this.baseUrl + url, body, {
                    withCredentials: withCredentials,
                    headers: this.headers(withCredentials),
                    responseType: "json",
                })
                .toPromise();

            return response as T;
        } catch (error) {
            throw error;
        }
    }
    async postMultiple<T>(
        url: string,
        body: any,
        withCredentials: boolean = true,
        headers: any = []
    ): Promise<Array<T>> {
        try {
            const response = await this.httpService
                .post(this.baseUrl + url, body, {
                    withCredentials: withCredentials,
                    headers: this.headers(withCredentials, headers),
                    responseType: "json",
                })
                .toPromise();

            return response as T[];
        } catch (error) {
            throw error;
        }
    }
}