import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ManageGroupComponent } from './manage-group.component';
import { ManageGroupUserComponent } from './manage-user/manage-user.component';
import { ManageGroupBoxComponent } from './manage-box/manage-box.component';
import { AutoLoggGroupsManageModule } from './manage-groups.module';
import { KeyCloakAutologgAuthGuard } from 'app/keycloak-auth-guard.service';

const routes: Routes = [
  { path: 'groups/:id/manage/user/:userId', component: ManageGroupUserComponent, canActivate: [KeyCloakAutologgAuthGuard] },
  { path: 'groups/:id/manage/box/:vehicleId', component: ManageGroupBoxComponent, canActivate: [KeyCloakAutologgAuthGuard] },
  { path: 'groups/:id/manage/_tabs/:slug', component: ManageGroupComponent, canActivate: [KeyCloakAutologgAuthGuard] },
  {
    path: "groups/:id/manage/user/:userId",
    component: ManageGroupUserComponent,
    canActivate: [KeyCloakAutologgAuthGuard],
  },
  {
    path: "groups/:id/manage/box/:vehicleId",
    component: ManageGroupBoxComponent,
    canActivate: [KeyCloakAutologgAuthGuard],
  },
  {
    path: "groups/:id/manage/_tabs/:slug",
    component: ManageGroupComponent,
    canActivate: [KeyCloakAutologgAuthGuard],
  },
  {
    path: "groups/:id/manage",
    redirectTo: "/groups/:id/manage/_tabs/users",
  },
];

export const AutoLoggGroupsManageRouting: ModuleWithProviders<AutoLoggGroupsManageModule> =
  RouterModule.forChild(routes);
