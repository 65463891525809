import { AfterViewInit, Component, OnInit } from "@angular/core";

import { BaseComponent } from "../../../base/base.component";
import { Box } from "../../../_models/box.model";
import { GroupUserBox } from "../../../_models/group.model";

@Component({
  templateUrl: "./manage-box.component.html",
})
export class ManageGroupBoxComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  loading: boolean = false;

  groupId: number;
  vehicleId: number;
  box: Box;
  userGroupBox: GroupUserBox;

  ngOnInit() {
    this.groupId = +this.route.snapshot.paramMap.get("id");
    this.vehicleId = +this.route.snapshot.paramMap.get("vehicleId");

    this.loadBoxAndModels();
  }

  ngAfterViewInit() {}

  private fetchBox() {
    return this._apiService
      .getGroupUserGroupBoxById(this.groupId, this.vehicleId)
      .do((userGroupBox) => {
        this.userGroupBox = userGroupBox;
        this.box = userGroupBox.box;
      });
  }

  private loadBoxAndModels() {
    this.loading = true;
    this.fetchBox().subscribe(
      (foo) => {},
      (err) => {
        this.loading = false;
        this.showError(err);
      },
      () => {
        this.loading = false;
      }
    );
  }
}
