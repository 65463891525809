import { RegisterService } from "app/register/register.service";
import { Injectable } from "@angular/core";
import { ConnectedPayload } from "./connected-payload";
import { ProviderType, OemType } from "app/base/provider-oem-type"

export class MfaAuthenticationRequired extends Error {
  constructor(message: string, public data: any) {
    super(message);
  }
}

@Injectable({
  providedIn: "root",
})
export class ConnectedHelper {
  constructor(private registerService: RegisterService) {}

  async sendMfa(apiType: OemType, mfaData: any) {
    try {
      // maybe create a interface for the payload here?
      const vehicles = await this.registerService.sendMfaCode({
        apiType: apiType,
        data: mfaData,
        is_mfa: true,
      });

      if (vehicles == null) {
        throw new Error("register.connected.couldNotLoadConnectedCars");
      }

      if (!vehicles || vehicles.length === 0) {
        throw new Error("register.connected.noConnectedCarAvailable");
      }

      return vehicles;
    } catch (error) {
      if (error.message && error.message.includes("register")) {
        throw error;
      }
      if (error.status === 401) {
        throw new Error("register.connected.invalidCredentials");
      } else {
        throw new Error("register.connected.couldNotLoadConnectedCars");
      }
    }
  }

  async loadVehicles(
    providerType: ProviderType,
    oemType: OemType,
    payload: ConnectedPayload
  ) {
    try {
      // maybe create a interface for the payload here?
      const vehicles = await this.registerService.getConnectedVehicles({
        providerType: providerType,
        oemType: oemType,
        data: payload,
      });

      if (vehicles == null) {
        throw new Error("register.connected.couldNotLoadConnectedCars");
      }

      if (!vehicles || vehicles.length === 0) {
        throw new Error("register.connected.noConnectedCarAvailable");
      }

      for (const vehicle of vehicles) {
        if (vehicle.compatible == undefined) {
          vehicle.compatible = true;
        }
      }

      return vehicles;
    } catch (error) {
      console.error(error);
      if (error && error.status == 302) {
        // enter MFA passcode
        throw new MfaAuthenticationRequired("MFA_CODE_REQUIRED", error.error);
      }
      if (error.message && error.message.includes("register")) {
        throw error;
      }
      if (error.error && error.error.errorType && error.error.errorType === "VIN_ALREADY_IN_USE") {
        throw new Error("register.connected.vinAlreadyInUse");
      }

      if (error.status === 401) {
        throw new Error("register.connected.invalidCredentials");
      } else if (error.status === 400) {
        throw error.error;
      } else if (error.status === 404) {
        throw error;
      } else {
        throw new Error("register.connected.couldNotLoadConnectedCars");
      }
    }
  }
}
