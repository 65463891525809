export class Hotspot {
  public itemid: string;
  public created_at: string;
  public title: string;
  public address: string;
  public partner: string;
  public purpose: string;
  public latitude: number;
  public longitude: number;
  public note: string;
  public radius_in_meters: number;

  public nr: number;
}
