import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-chart-doughnut",
  templateUrl: "./chart-doughnut.component.html",
})
export class ChartDoughnutComponent {
  @Input() doughnutChartData: number[] = [1, 2];

  view: any[] = [250, 250];

  // options
  isDoughnut: boolean = true;

  colorScheme = {
    domain: [
      "rgba(29, 160, 240, 1)",
      "rgba(251, 160, 30, 1)",
      "rgba(29, 160, 240, 1)",
      "rgba(251, 160, 30, 1)",
      "rgba(29, 160, 240, 1)",
      "rgba(251, 160, 30, 1)",
    ],
  };
}
