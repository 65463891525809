<mat-progress-bar class="position-absolute" *ngIf="updating" color="accent" mode="indeterminate"></mat-progress-bar>

<div class="container-fluid">

  <div class="row mt-3" *ngIf="loading">
    <div class="col-12">
      <mat-spinner style="margin:0 auto;" mode="indeterminate"></mat-spinner>
    </div>
  </div>

  <div class="row" *ngIf="!loading">
    <div class="col-12 p-3">
      <h3 translate>boxes.vehicles.actives.title</h3>

      <div class="alert alert-info" *ngIf="!activeModel">
        <span translate>global.noResults</span>
      </div>

      <mat-card class="mb-4" *ngIf="activeModel">
        <mat-card-header>
          <mat-card-title>
            <h6>
              <b translate>boxes.vehicles.plate</b>: {{activeModel.plate}}</h6>
          </mat-card-title>
          <mat-card-subtitle>
            <span translate>boxes.vehicles.created_at</span>: {{activeModel.created_at | date:'short'}}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content *ngIf="box.oem_type == OemType.Box && activeModel.oemCode != 'UNKNOWN'">
          <ul>
            <li>
              <b translate>boxes.vehicles.oem</b>: {{ activeModel.oemCode }} /
              <b translate>boxes.vehicles.series</b>: {{ activeModel.seriesCode }} /
              <b translate>boxes.vehicles.model</b>: {{ activeModel.model_name }}
            </li>
          </ul>
          <ul>
            <li>
              <b translate>boxes.vehicles.mileage</b>: {{ (activeModel.km || 0) | number:0 }} km
            </li>
            <li>
              <b translate>boxes.vehicles.open_trip_count</b>: {{ activeModel.open_trip_count | number:0 }}
            </li>
            <li>
              <b translate>boxes.vehicles.total_trip_count</b>: {{ activeModel.total_trip_count | number:0 }}
            </li>
          </ul>
        </mat-card-content>
        <mat-card-actions>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>

  <div class="row" *ngIf="!loading && archivedModels.length !== 0">
    <div class="col-12">
      <h3 translate>boxes.vehicles.history.title</h3>

      <div *ngFor="let model of archivedModels">
        <mat-card class="mb-4">
          <mat-card-header>
            <mat-card-title>
              <h6>
                <b translate>boxes.vehicles.plate</b>: {{model.plate}}</h6>
            </mat-card-title>
            <mat-card-subtitle>
              <span translate>boxes.vehicles.created_at</span>: {{model.created_at | date:'short'}}</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <ul *ngIf="model.oemCode != 'UNKNOWN'">
              <li>
                <b translate>boxes.vehicles.oem</b>: {{ model.oemCode }} /
                <b translate>boxes.vehicles.series</b>: {{ model.seriesCode }} /
                <b translate>boxes.vehicles.model</b>: {{ model.model_name }}
              </li>
            </ul>
            <ul>
              <li>
                <b translate>boxes.vehicles.total_trip_count</b>: {{ model.total_trip_count | number:0 }}
              </li>
            </ul>
          </mat-card-content>
          <mat-card-actions>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>
  </div>
</div>