import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class OverlayLoadingTextService {
  private _text: string;
  public get text(): string {
    return this._text;
  }

  public set text(value) {
    this._text = value;
  }
}
