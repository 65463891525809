import { AfterViewInit, Component, OnInit } from "@angular/core";
import { Hotspot } from "../_models/hotspot.model";
import { BaseComponent } from "../base/base.component";

import { TrackingConstants } from "app/tracking/tracking-constants";

@Component({
  templateUrl: "./hotspots.component.html",
  styleUrls: ["hotspots.component.css"],
})
export class HotspotsComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  loading: boolean = true;
  updating: boolean = false;
  hotspots: Array<Hotspot> = [];
  filterdHotspots: Array<Hotspot> = [];

  userSearchTerm: string = "";

  ngOnInit() {
    this.loadHotspots();

    this._tracker.track(TrackingConstants.Events.HotspotsPageView);
  }

  ngAfterViewInit() {}

  onUserSearchTermChanged(text: string) {
    this.applyFilter(text);
  }

  private applyFilter(userSearchTerm: string) {
    if (!userSearchTerm) {
      this.filterdHotspots = this.hotspots;
    } else {
      const searchTerm = userSearchTerm.toLocaleLowerCase();
      this.filterdHotspots = this.hotspots.filter(
        (val) =>
          JSON.stringify(val).toLocaleLowerCase().indexOf(searchTerm) > -1
      );
    }
  }

  private fetchUserHotspots() {
    return this._apiService
      .getUserHotspots()
      .map((hotspots) => hotspots.sort(this.orderHotspotByTitle));
  }

  private loadHotspots() {
    this.loading = true;
    this.fetchUserHotspots().subscribe(
      (hotspots) => {
        this.hotspots = hotspots;
        this.applyFilter(this.userSearchTerm);
      },
      (err) => {
        this.loading = false;
        this.showError(err);
      },
      () => {
        this.loading = false;
      }
    );
  }

  private orderHotspotByTitle(a: Hotspot, b: Hotspot) {
    return a.title.localeCompare(b.title);
  }
}
