import {
  Inject,
  HostListener,
  Component,
  ChangeDetectionStrategy,
} from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "app-info-dialog",
  templateUrl: "./dialog-info.component.html",
  styleUrls: ["./dialog-info.component.css"],
})
export class DialogInfoComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      text: string;
    },
    private mdDialogRef: MatDialogRef<DialogInfoComponent>
  ) {}

  public close() {
    this.mdDialogRef.close();
  }

  @HostListener("keydown.esc")
  public onEsc() {
    this.close();
  }
}
