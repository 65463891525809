import { Car } from "./car.model";
import { sha512 } from "js-sha512";
import { ProviderType, OemType } from "app/base/provider-oem-type"

export enum VehicleAuthState {
  Unknown = 0,
  PendingAuthorization = 1,
  PendingMileageCheck = 2,
  PendingServiceActivation = 3,
  PendingRevoke = 4,
  Ok = 10,
  ReMileageCheckNeeded = 11,
  Revoked = 100,
  MilageCheckFailed = 101,
}
export class RegisterBody {
  public email: string;
  public password: string;
  public firstName: string;
  public lastName: string;

  public car: Car;
  public providerType: ProviderType = ProviderType.Native;
  public oemType: OemType = OemType.Unknown;

  public showIndicatorBar = true;

  constructor() {
    this.car = new Car();
  }

  asJson() {
    return {
      firstname: " ",
      lastname: " ",
      email: this.email,
      password: sha512.hex(this.password),
      autolog_key: this.car.dongleid,
      imageurl: "",
      street: "",
      town: "",
      phone_number: "",
      provider_type: this.providerType,
    };
  }

  asJsonWithoutKey() {
    return {
      firstname: " ",
      lastname: " ",
      email: this.email,
      password: sha512.hex(this.password),
      imageurl: "",
      street: "",
      town: "",
      phone_number: "",
      provider_type: this.providerType,
      oem_type: this.oemType,
    };
  }
}
