import {
  AfterViewInit,
  Component,
  ViewChild,
  Input,
  OnInit,
  ChangeDetectorRef,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { SelectionModel } from "@angular/cdk/collections";
import { Observable } from "rxjs";

import { BaseComponent } from "../../base/base.component";
import { GroupUserBox } from "../../_models/group.model";
import { BoxTableEntry } from "./group-box-table-entry.model";

import { CASE_INSENSITVE_SORTING_DATA_ACCESSOR } from "../../common/table-common.util";
import { OemType } from "app/base/provider-oem-type"

import { DialogExportWarningComponent } from "../../export/dialog-export-warning.component";
import { HttpClient } from "@angular/common/http";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ApiService } from "app/api.service";
import { TrackingService } from "app/tracking/tracking-service";
import { OverlayLoadingService } from "app/utils/overlay-loading.service";
import { FileSaverService } from "ngx-filesaver";

@Component({
  selector: "app-groups-export",
  templateUrl: "./groups-export.component.html",
})
export class GroupsExportComponent
  extends BaseComponent
  implements OnInit, AfterViewInit {
  OemType = OemType;

  loading: boolean = false;

  @Input("groupId") groupId: number;

  boxes: Array<GroupUserBox> = [];
  gaps: { [vehicleId: number]: boolean } = {};
  openTripCount: { [vehicleId: number]: number } = {};

  displayedColumns: string[] = [
    "select",
    "oem_type",
    "box_vehicle_id",
    "box_name",
    "user_fullname",
    "box_description",
  ];
  dataSource = new MatTableDataSource<BoxTableEntry>([]);
  selection = new SelectionModel<BoxTableEntry>(true, []);

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  tripType: number;
  tripTypes: Array<any>;
  tripTypesTranslation: Array<string>;
  showDriver: boolean = false;

  dateEndMaxDate = new Date();
  dateEndMinDate = new Date();
  dateStartMaxDate = new Date();
  exportOptions: UntypedFormGroup;

  constructor(
    _apiService: ApiService,
    router: Router,
    snackBar: MatSnackBar,
    dialog: MatDialog,
    formBuilder: UntypedFormBuilder,
    translate: TranslateService,
    route: ActivatedRoute,
    _changeDetectorRef: ChangeDetectorRef,
    _tracker: TrackingService,
    private overlayLoading: OverlayLoadingService,
    private _http: HttpClient,
    private _FileSaverService: FileSaverService
  ) {
    super(
      _apiService,
      router,
      snackBar,
      dialog,
      formBuilder,
      translate,
      route,
      _changeDetectorRef,
      _tracker
    );
  }

  ngOnInit() {
    this.dataSource.sortingDataAccessor = CASE_INSENSITVE_SORTING_DATA_ACCESSOR;

    const dateEnd = new Date();
    const dateStart = new Date(
      new Date(dateEnd).getTime() - 30 * 60 * 60 * 24 * 1000
    );
    this.dateEndMinDate = dateStart;

    this.exportOptions = this.formBuilder.group({
      dateStart: [dateStart, Validators.required],
      dateEnd: [dateEnd, Validators.required],
    });

    this.tripType = 0;
    this.tripTypesTranslation = [
      "export.tripTypes.any",
      "export.tripTypes.business",
      "export.tripTypes.private",
    ];
    this.tripTypes = [
      { id: 0, translation_key: this.tripTypesTranslation[0] },
      { id: 1, translation_key: this.tripTypesTranslation[1] },
      { id: 2, translation_key: this.tripTypesTranslation[2] },
    ];
    this.reloadBoxes();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.data = this.boxes.map((b) =>
      BoxTableEntry.fromGroupUserBox(b)
    );
    this.dataSource.paginator = this.paginator;
  }

  private fetchBoxes(): Observable<Array<GroupUserBox>> {
    return this._apiService
      .getGroupBoxes(this.groupId)
      .do((val) => this.onBoxesLoaded(val));
  }

  private reloadBoxes() {
    this.loading = true;
    this.fetchBoxes().subscribe(
      (foo) => { },
      (err) => {
        this.loading = false;
        this.showError(err);
      },
      () => {
        this.loading = false;
      }
    );
  }

  private onBoxesLoaded(boxes) {
    this.boxes = boxes;

    this.selection = new SelectionModel<BoxTableEntry>(true, []);
    this.dataSource.data = this.boxes.map((b) =>
      BoxTableEntry.fromGroupUserBox(b)
    );
    setTimeout(() => {
      // only the lord knows, why this must be wrapped within setTimeout - otherwise it does not work.
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }, 0);

    this.openTripCount = {};
    this.gaps = {};

    this.boxes.forEach((box) => {
      this.openTripCount[box.box.vehicle_id] =
        box.box.active_model_open_trip_count;

      this.gaps[box.box.vehicle_id] = box.box.active_model_has_gap;
    });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  isDownloadButtonEnabled() {
    return this.dataSource.data.length > 0;
  }

  private isExportTimePeriodValid() {
    return (
      this.exportOptions.value.dateStart < this.exportOptions.value.dateEnd
    );
  }

  async downloadPdf() {
    if (this.selection.isEmpty()) {
      this.showWarning("groups.manage.export.no_boxes_selected");
      return;
    }

    if (!this.isExportTimePeriodValid()) {
      this.showWarning("errors.start_date_must_be_before_end_date");
      return;
    }

    const vehicleIds: Array<number> = this.selection.selected.map(
      (s) => s.box_vehicle_id
    );
    this.overlayLoading.show(this._translate.instant("export.loading"));
    try {
      const downloadLink = this._apiService.getGroupExportPdfLink(
        this.groupId,
        vehicleIds,
        this.exportOptions.value.dateStart,
        this.exportOptions.value.dateEnd,
        this.tripType,
        this.showDriver
      );
      var response = await this._http
        .get(downloadLink, { responseType: "blob" })
        .toPromise();
      this._FileSaverService.save(response, this._apiService.formatDateForExport(this.exportOptions.value.dateStart) + "-" + this._apiService.formatDateForExport(this.exportOptions.value.dateEnd) + ".zip");
    }
    finally {
      this.overlayLoading.hide();
    }
  }

  async downloadCsv() {
    if (this.selection.isEmpty()) {
      this.showWarning("groups.manage.export.no_boxes_selected");
      return;
    }

    if (!this.isExportTimePeriodValid()) {
      this.showWarning("errors.start_date_must_be_before_end_date");
      return;
    }

    const vehicleIds: Array<number> = this.selection.selected.map(
      (s) => s.box_vehicle_id
    );
    try {
      const downloadLink = this._apiService.getGroupExportCsvLink(
        this.groupId,
        vehicleIds,
        this.exportOptions.value.dateStart,
        this.exportOptions.value.dateEnd,
        this.tripType,
        this.showDriver
      );
      var response = await this._http
        .get(downloadLink, { responseType: "blob" })
        .toPromise();
      this._FileSaverService.save(response, this._apiService.formatDateForExport(this.exportOptions.value.dateStart) + "-" + this._apiService.formatDateForExport(this.exportOptions.value.dateEnd) + ".zip");
    }
    finally {
      this.overlayLoading.hide();
    }
  }

  hasOpenTrips(vehicleIds: Array<number>): boolean {
    let openTrips = false;
    vehicleIds.forEach((vehicleId) => {
      if (this.openTripCount[vehicleId] > 0) {
        openTrips = true;
      }
    });

    return openTrips;
  }

  hasGaps(vehicleIds: Array<number>): boolean {
    let gaps = false;
    vehicleIds.forEach((vehicleId) => {
      if (this.gaps[vehicleId]) {
        gaps = true;
      }
    });

    return gaps;
  }

  async startExport(exportType: String) {
    const vehicleIds: Array<number> = this.selection.selected.map(
      (s) => s.box_vehicle_id
    );

    if (
      this.hasOpenTrips(vehicleIds) == true ||
      this.hasGaps(vehicleIds) == true
    ) {
      await this.openExportWarningDialog(exportType);
    } else {
      await this.downloadExport(exportType);
    }
  }

  async downloadExport(exportType: String) {
    switch (exportType) {
      case "pdf": {
        await this.downloadPdf();
        break;
      }
      case "csv": {
        await this.downloadCsv();
        break;
      }
    }
  }

  async openExportWarningDialog(exportType: String) {
    const dialogRef = this.dialog.open(DialogExportWarningComponent);

    const instance = dialogRef.componentInstance;
    instance.gaps = this.gaps;
    instance.openTripCount = this.openTripCount;

    await dialogRef.afterClosed().subscribe(async (result) => {
      if (result && result.exportAnyway === true) {
        await this.downloadExport(exportType);
      }
    });
  }
}
