export abstract class TrackingConstants {
  public static readonly Events = {
    BoxesPageView: "PageView: Boxes",
    VehiclesPageView: "PageView: Vehicles",
    HomePageView: "PageView: Home",
    HotspotsPageView: "PageView: Hotspots",
    ConnectedPageView: "PageView: Connected",
    ExportPageView: "PageView: Export",
    AssignTrip: "Trip assigned",
    ConnectTrip: "Trip connected",
    HotspotCreated: "Hotspot created",
    DownloadLogbook: "Download logbook",
    SearchOnExport: "Search on export",
    RegisterModelType: "RegisterModelType",
  };

  public static readonly EventProperties = {
    TimeToAssign: "Time to assign (ms)",
    NumberOfSubtrips: "Number of subtrips",
    LogbookType: "Logbook Type",
    ModelType: "Model Type",
    IsFreeText: "IsFreeText",
  };
}
