import {
  AfterViewInit,
  Component,
  ViewChild,
  Input,
  EventEmitter,
  Output,
  OnInit,
  SimpleChanges,
  OnChanges,
} from "@angular/core";
import { formatDate, formatNumber } from "@angular/common";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { SelectionModel } from "@angular/cdk/collections";

import { Observable, zip } from "rxjs";
import { flatMap } from "rxjs/operators";

import { Map, latLng, Marker } from "leaflet";
import {
  leafletPrepareMap,
  leafletCreateOptions,
  leafletFitMapToMarkerBounds,
  leafletCreateMarker,
} from "../../common/leaflet-common.util";

import { BaseComponent } from "../../base/base.component";
import { GroupUserBoxWithLatestTrip } from "../../_models/group.model";
import { BoxTableEntry } from "./group-box-table-entry.model";
import { TripInfo } from "./group-trip-info.model";

import { DialogConfirmComponent } from "../../common/dialog-confirm/dialog-confirm.component";
import { CASE_INSENSITVE_SORTING_DATA_ACCESSOR } from "../../common/table-common.util";
import { ProviderType } from "app/base/provider-oem-type"
import { Helper } from "app/base/helper";

@Component({
  selector: "app-groups-list-group-boxes-map",
  templateUrl: "./groups-list-group-boxes-map.component.html",
  styleUrls: ["groups-list-group-boxes-map.component.css"],
})
export class GroupsListGroupBoxesMapComponent
  extends BaseComponent
  implements OnInit, OnChanges, AfterViewInit
{
  @Input("boxesWithLatestTrip")
  boxesWithLatestTrip: Array<GroupUserBoxWithLatestTrip> = [];
  tripInfos: Array<TripInfo>;

  loading: boolean = false;
  updating: boolean = false;

  leafletOptions: any;
  leafletLayers: any[];

  map: Map;

  ngOnInit() {
    this.leafletOptions = leafletCreateOptions();

    this.recreateMarkers();
    this.fitMapToMarkerBounds();
  }

  ngAfterViewInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes["boxesWithLatestTrip"]) {
      this.onTripInfosChanged();
    }
  }

  onTripInfosChanged() {
    this.recreateMarkers();
    this.fitMapToMarkerBounds();
  }

  onMapReady(_mapmap: Map) {
    this.map = leafletPrepareMap(_mapmap);
    this.fitMapToMarkerBounds();
  }

  private fitMapToMarkerBounds() {
    leafletFitMapToMarkerBounds(this.map, this.leafletLayers);
  }

  private recreateMarkers() {
    this.tripInfos = this.boxesWithLatestTrip
      .filter((b) => !!b.latest_trip)
      .map((b) => TripInfo.fromGroupUserBoxWithLatestTrip(b));

    const markerArray = this.tripInfos
      .filter((d) => d.end_lat && d.end_lng)
      .map((d) => this.createMarkerForTrip(d));

    this.leafletLayers = markerArray;
  }

  // tslint:disable: max-line-length
  private createMarkerForTrip(tripInfo: TripInfo): Marker {
    const m = leafletCreateMarker(
      latLng(tripInfo.end_lat, tripInfo.end_lng),
      tripInfo.box_name
    );

    // somehow, css classes do not seem to work, hence 'style' is used - i am sorry :/
    m.bindPopup(
      '<span class="h6" style="font-weight: 600;">' +
        tripInfo.box_name +
        "</span><br />" +
        '<span class="bold">' +
        this._translate.instant("groups.manage.map.trip_info.end_address") +
        "</span>: " +
        tripInfo.end_address +
        "<br />" +
        '<span class="bold">' +
        this._translate.instant("groups.manage.map.trip_info.end_mileage") +
        "</span>: " +
        formatNumber(tripInfo.end_mileage, Helper.getBrowserLang(), "1.0-0") +
        " km" +
        "<br />" +
        '<span class="bold">' +
        this._translate.instant("groups.manage.map.trip_info.end_time") +
        "</span>: " +
        formatDate(
          tripInfo.end_time,
          this._translate.instant("global.dateTimeFormat"),
          Helper.getBrowserLang()
        ) +
        "<br />" +
        '<span class="bold">' +
        this._translate.instant("groups.manage.map.trip_info.shared_from") +
        "</span>: " +
        tripInfo.user_last_name +
        ", " +
        tripInfo.user_first_name
    );

    m.bindTooltip("" + tripInfo.box_name);
    return m;
  }
  // tslint:enable: max-line-length
}

@Component({
  selector: "app-groups-list-group-boxes",
  templateUrl: "./groups-list-group-boxes.component.html",
  styleUrls: ["groups-list-group-boxes.component.css"],
})
export class GroupsListGroupBoxesComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  ProviderType = ProviderType;

  loading: boolean = false;
  updating: boolean = false;

  @Input("groupId") groupId: number;
  @Output("valueChange") valueChange = new EventEmitter();
  boxesWithLatestTrip: Array<GroupUserBoxWithLatestTrip> = [];

  displayedColumns: string[] = [
    "select",
    "provider_type",
    "box_vehicle_id",
    "box_name",
    "latest_trip_end_mileage",
    "user_fullname",
    "box_description",
  ];
  dataSource = new MatTableDataSource<BoxTableEntry>([]);
  selection = new SelectionModel<BoxTableEntry>(true, []);

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  ngOnInit() {
    this.dataSource.sortingDataAccessor = CASE_INSENSITVE_SORTING_DATA_ACCESSOR;
    this.loadBoxesWithLatestTrip();

    if (
      !this._apiService?.user?.hasFeatureOrGeneralFeature("AUTOLOGG_HIDE_MAP")
    ) {
      this.displayedColumns.push("latest_trip_end_address");
    }

    console.log(this._apiService?.user);
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.data = this.boxesWithLatestTrip.map((b) =>
      BoxTableEntry.fromGroupUserBoxWithLatestTrip(b)
    );
  }

  private fetchBoxesWithLatestTrip(): Observable<
    Array<GroupUserBoxWithLatestTrip>
  > {
    return this._apiService
      .getGroupBoxesWithLatestTrip(this.groupId)
      .do((val) => this.onBoxesWithLatestTrip(val));
  }

  private loadBoxesWithLatestTrip() {
    this.loading = true;
    this.fetchBoxesWithLatestTrip().subscribe(
      (foo) => {},
      (err) => {
        this.loading = false;
        this.showError(err);
      },
      () => {
        this.loading = false;
      }
    );
  }

  private onBoxesWithLatestTrip(boxesWithLatestTrip) {
    this.boxesWithLatestTrip = boxesWithLatestTrip.sort(this.orderBoxByName);

    this.valueChange.emit(boxesWithLatestTrip);

    this.selection = new SelectionModel<BoxTableEntry>(true, []);
    this.dataSource.data = this.boxesWithLatestTrip.map((b) =>
      BoxTableEntry.fromGroupUserBoxWithLatestTrip(b)
    );
  }

  private orderBoxByName(
    a: GroupUserBoxWithLatestTrip,
    b: GroupUserBoxWithLatestTrip
  ) {
    return a.group_user_box.box.name.localeCompare(b.group_user_box.box.name);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  removeSelectedBoxes(selection: SelectionModel<BoxTableEntry>) {
    if (selection.selected.length === 0) {
      this.showWarning("groups.manage.boxes.no_box_selected");
      return;
    }

    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: {
        title: "groups.manage.boxes.remove_selected_boxes.confirm.title",
        text: "groups.manage.boxes.remove_selected_boxes.confirm.text",
        submitButtonText:
          "groups.manage.boxes.remove_selected_boxes.confirm.submitButtonText",
        cancelButtonText:
          "groups.manage.boxes.remove_selected_boxes.confirm.cancelButtonText",
      },
    });

    dialogRef.afterClosed().subscribe((confirmed) => {
      if (!confirmed) {
        return;
      }

      const observables = selection.selected.map((b) =>
        this._apiService.removeBoxFromGroup(b.group_id, b.box_vehicle_id)
      );

      this.updating = true;
      zip(...observables)
        .pipe(flatMap((val) => this.fetchBoxesWithLatestTrip()))
        .subscribe(
          (foo) => {
            this.showSuccess(
              "groups.manage.boxes.remove_selected_boxes.success"
            );
          },
          (err) => {
            this.updating = false;
            this.showError(err);
          },
          () => {
            this.updating = false;
          }
        );
    });
  }
}
