import { Box } from "app/_models/box.model";
import {
  Input,
  Component,
  SimpleChanges,
  OnInit,
  OnChanges,
  AfterViewInit,
} from "@angular/core";
import {
  leafletCreateOptions,
  leafletFitMapToMarkerBounds,
  leafletPrepareMap,
  leafletCreateMarker,
} from "app/common/leaflet-common.util";
import { Map, latLng } from "leaflet";
import { MapComponent } from "app/map/map.component";
import { BaseComponent } from "app/base/base.component";

@Component({
  selector: "vehicle-card",
  templateUrl: "./vehicle-card.component.html",
})
export class VehicleCardComponent
  extends BaseComponent
  implements OnInit, OnChanges, AfterViewInit
{
  @Input("lastTrip") lastTrip: any = {
    address: null,
    lat: null,
    lng: null,
    km: null,
  };
  @Input() box: Box;

  leafletOptions: any;
  leafletLayers: any[];

  map: Map;

  ngOnInit() {
    this.leafletOptions = leafletCreateOptions();

    this.recreateMarkers();
    this.fitMapToMarkerBounds();
  }

  ngAfterViewInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes["lastTrip"]) {
      this.onTripInfosChanged();
    }
  }

  onMapClick() {
    this.dialog.open(MapComponent, {
      data: { markerPosition: this.lastTrip },
      height: "50vh",
      width: "70vw",
    });
  }

  onMapReady(_mapmap: Map) {
    this.map = leafletPrepareMap(_mapmap, false);

    this.fitMapToMarkerBounds();
  }

  private onTripInfosChanged() {
    this.recreateMarkers();
    this.fitMapToMarkerBounds();
  }

  private fitMapToMarkerBounds() {
    leafletFitMapToMarkerBounds(this.map, this.leafletLayers);
  }

  private recreateMarkers() {
    this.leafletLayers = this.createMarkerArrayForTrip();
  }

  private createMarkerArrayForTrip() {
    if (!this.lastTrip || !this.lastTrip.lat) {
      return [];
    }

    const m = leafletCreateMarker(
      latLng(this.lastTrip.lat, this.lastTrip.lng),
      this.lastTrip.address
    );
    return [m];
  }
}
