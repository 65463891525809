<mat-progress-bar *ngIf="loading" color="accent" mode="indeterminate"></mat-progress-bar>

<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <h1 translate>groups.update.title</h1>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="button-row">
        <a mat-button routerLink="/groups"  title="{{ 'global.back' | translate }}">
          <mat-icon class="icon-custom">arrow_back</mat-icon>
          <span translate>global.back</span>
        </a>
        <span class="m-1" style="float:right;">
          <button mat-button color="warn" type="button" (click)="openDeleteGroupDialog()">
            <mat-icon class="icon-custom">delete_forever</mat-icon>
            <span translate>groups.update.delete.title</span>
          </button>
        </span>
      </div>
    </div>
  </div>

  <form #addGroupForm="ngForm" (ngSubmit)="submitForm()">
    <div class="row">
      <div class="col-6 p-3 pt-2">
        <mat-form-field  floatPlaceholder="always" class="m-auto" class="full-width" [ngClass]="{'mat-form-field-invalid': !nameField.valid && nameField.dirty}">
          <mat-label translate>groups.update.fields.name.label</mat-label>
          <input matInput #nameField="ngModel" type="text" [(ngModel)]="name" name="name" placeholder="{{ 'groups.update.fields.name.placeholder' | translate }}"
            required>
          <mat-hint translate>groups.update.fields.name.hint</mat-hint>
          <mat-error *ngIf="nameField.dirty" align="end">
            <span *ngIf="!nameField.valid">{{ 'errors.invalidname' | translate}}</span>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-12 p-3 pt-2">
        <mat-form-field class="full-width">
          <mat-label translate>groups.update.fields.description.label</mat-label>
          <textarea matInput #descriptionField="ngModel" [(ngModel)]="description" name="description" placeholder="{{ 'groups.update.fields.description.placeholder' | translate }}"
            rows="3" [maxlength]="descriptionMaxCharacterCount" (ngModelChange)="updateDescriptionRemainingCharacterCount()"></textarea>
          <mat-hint>{{ 'groups.update.fields.description.hint' | translate }}: {{ descriptionRemainingCharacterCount | number:0 }}</mat-hint>
        </mat-form-field>
      </div>
    </div>

    <div class="d-flex flex-row justify-content-around flex-wrap m-auto">
      <div class="d-flex mt-3 mb-5">
        <button mat-raised-button color="accent" type="submit" [disabled]="!isSubmitButtonEnabled()" class="m-auto">{{ 'groups.update.submit' | translate }}
        </button>
      </div>
    </div>
  </form>

  <div class="row mt-5"></div>
</div>