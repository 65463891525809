import { Component, OnInit } from "@angular/core";
import { BaseComponent } from "app/base/base.component";
import { OemType } from "app/base/provider-oem-type"
import { environment } from "environments/environment";

@Component({
  selector: "app-create-connected",
  templateUrl: "./create-connected.component.html",
  styleUrls: ["./create-connected.component.css"],
})
export class CreateConnectedComponent extends BaseComponent implements OnInit {
  public isTeslaOnly = false;
  username: string = this._apiService?.user?.firstname;

  public oemType = null;

  public emulatorEnabled = environment.enableEmulator;
  public autoaidEnabled = environment.enableAutoaid;
  public experimentEnabled = environment.enableExperiment;

  public get nativeEnabled(): boolean {
    if (!this.oemType) {
      return true;
    }
    return this.oemType == OemType.Box;
  }

  ngOnInit(): void {
    this.oemType = localStorage.getItem("oemType");

    if (this.oemType === OemType.Box) {
      this.oemType = void 0;
    }

    localStorage.removeItem("oemType");
  }
}
