import {
  AfterViewInit,
  Component,
  OnInit,
  OnChanges,
  Input,
  EventEmitter,
  Output,
  SimpleChanges,
} from "@angular/core";
import {
  GroupInvitation,
  UserGroupMembershipWithMetadata,
} from "../_models/group.model";
import { BaseComponent } from "../base/base.component";
import { forkJoin } from "rxjs";

@Component({
  templateUrl: "./groups.component.html",
  styleUrls: ["groups.component.css"],
})
export class GroupsComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  loading: boolean = false;
  groups: Array<UserGroupMembershipWithMetadata> = [];
  invitations: Array<GroupInvitation> = [];

  ngOnInit() {
    this.load();
  }

  ngAfterViewInit() {}

  load() {
    this.loading = true;
    forkJoin(
      this._apiService.getUserGroups(),
      this._apiService.getUserGroupInvitations()
    ).subscribe(
      (array) => {
        this.groups = (array[0] || []).sort(this.orderMembershipByGroupName);
        this.invitations = array[1] || [];
      },
      (err) => {
        this.loading = false;
        this.showError(err);
      },
      () => {
        this.loading = false;
      }
    );
  }

  onInvitationChanged(foo) {
    this.load();
    // reload profile to get new 'open_invitations_count'
    this._apiService.refreshProfile();
  }

  private orderMembershipByGroupName(
    a: UserGroupMembershipWithMetadata,
    b: UserGroupMembershipWithMetadata
  ) {
    return a.group_user.group.name.localeCompare(b.group_user.group.name);
  }
}

@Component({
  selector: "app-groups-list-user-groups",
  templateUrl: "./groups-list-user-groups.component.html",
  styleUrls: ["groups.component.css"],
})
export class GroupsListUserGroupsComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  @Input("loading") loading: boolean = false;
  @Input("groups") groups: Array<UserGroupMembershipWithMetadata> = [];

  userId: number;
  ngOnInit() {
    this.userId = this._apiService.user.userid;
  }

  ngAfterViewInit() {}
}

class GroupedGroupInvitations {
  open: Array<GroupInvitation> = [];
  declined: Array<GroupInvitation> = [];
  accepted: Array<GroupInvitation> = [];
}

@Component({
  selector: "app-groups-list-user-invitations",
  templateUrl: "./groups-list-user-invitations.component.html",
  styleUrls: ["groups.component.css"],
})
export class GroupsListUserInvitationsComponent
  extends BaseComponent
  implements OnInit, OnChanges, AfterViewInit
{
  @Output("valueChange") valueChange = new EventEmitter();

  @Input("loading") loading: boolean = false;
  userId: number;

  @Input("invitations") invitations: Array<GroupInvitation> = [];
  groupedInvitations: GroupedGroupInvitations = new GroupedGroupInvitations();

  ngOnInit() {
    this.userId = this._apiService.user.userid;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["invitations"]) {
      this.groupedInvitations = this.groupByState(this.invitations);
    }
  }

  ngAfterViewInit() {}

  groupByState(invitations: Array<GroupInvitation>): GroupedGroupInvitations {
    return {
      open: invitations.filter((i) => !i.accepted_at && !i.declined_at),
      declined: invitations.filter((i) => !i.accepted_at && !!i.declined_at),
      accepted: invitations.filter((i) => !!i.accepted_at && !i.declined_at),
    };
  }

  acceptInvitation(invitation: GroupInvitation) {
    this.loading = true;
    this._apiService.acceptGroupInvitation(invitation.id).subscribe(
      (foo) => {
        this._apiService.refreshProfile();
        this.router.navigate([
          "/groups/" + invitation.group_id + "/membership",
        ]);
        this.showSuccess("groups.invitations.acceptedInvitation");
      },
      (err) => {
        this.loading = false;
        this.showError(err);
      },
      () => {
        this.loading = false;
      }
    );
  }

  declineInvitation(invitation: GroupInvitation) {
    this.loading = true;
    this._apiService.declineGroupInvitation(invitation.id).subscribe(
      (foo) => {
        this.valueChange.emit(invitation);
        this.showSuccess("groups.invitations.declinedInvitation");
      },
      (err) => {
        this.loading = false;
        this.showError(err);
      },
      () => {
        this.loading = false;
      }
    );
  }
}
