import { AfterViewInit, Component, OnInit } from "@angular/core";
import { forkJoin } from "rxjs";
import { flatMap } from "rxjs/operators";

import { Car } from "../../_models/car.model";
import { Box } from "../../_models/box.model";
import { DialogConfirmComponent } from "../../common/dialog-confirm/dialog-confirm.component";
import { BaseBoxesComponent } from "../base-boxes.component";
import { OemType } from "app/base/provider-oem-type"

@Component({
  templateUrl: "./vehicles.component.html",
})
export class VehiclesComponent
  extends BaseBoxesComponent
  implements OnInit, AfterViewInit
{
  OemType = OemType;

  vehicleId: number;
  box: Box;

  allModels: Array<Car> = [];
  archivedModels: Array<Car> = [];
  activeModel: Car;

  loading: boolean = true;
  updating: boolean = true;

  ngOnInit() {
    this.vehicleId = +this.route.snapshot.paramMap.get("id");

    this.loadBoxAndModels();
  }

  ngAfterViewInit() {}

  navigateBack() {
    this.router.navigate(["../.."], { relativeTo: this.route });
  }

  private fetchBoxAndModels() {
    const loadBox = this._apiService
      .getUserBoxById(this.vehicleId)
      .map((box) => {
        this.box = box;
        return box;
      });

    const loadModels = this._apiService
      .getUserBoxModels(this.vehicleId)
      .map((models) => {
        this.allModels = models;

        return models;
      });

    return forkJoin(loadBox, loadModels).do((foo) => {
      this.archivedModels = this.allModels.filter(
        (val) => val.model_id !== this.box.active_model_id
      );
      const activeModels = this.allModels.filter(
        (val) => val.model_id === this.box.active_model_id
      );
      this.activeModel = activeModels.length > 0 ? activeModels[0] : null;
    });
  }

  private loadBoxAndModels() {
    this.loading = true;
    this.fetchBoxAndModels().subscribe(
      (foo) => {},
      (err) => {
        this.loading = false;
        this.showError(err);
      },
      () => {
        this.loading = false;
      }
    );
  }

  removeModel(model: Car) {
    if (model.total_trip_count !== 0) {
      this.showError("Cannot delete model with existing trips");
      return;
    }

    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: {
        title: "boxes.vehicles.remove_vehicle.confirm.title",
        text: "boxes.vehicles.remove_vehicle.confirm.text",
        submitButtonText:
          "boxes.vehicles.remove_vehicle.confirm.submitButtonText",
        cancelButtonText:
          "boxes.vehicles.remove_vehicle.confirm.cancelButtonText",
      },
    });

    dialogRef.afterClosed().subscribe((confirmed) => {
      if (!confirmed) {
        return;
      }

      this.updating = true;
      this._apiService
        .deleteModelFromBox(this.box.vehicle_id, model.model_id)
        .pipe(flatMap((foo) => this.fetchBoxAndModels()))
        .subscribe(
          (foo) => {
            this.showSuccess("boxes.vehicles.remove_vehicle.success");
          },
          (err) => {
            this.updating = false;
            this.showError(err);
          },
          () => {
            this.updating = false;
          }
        );
    });
  }
}
