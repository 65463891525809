import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";

import { tap } from "rxjs/operators";

import { ApiVersionHolder } from "../api-version-holder.component";

@Injectable()
export class AppVersionHeaderInterceptor implements HttpInterceptor {
  constructor(private apiVersionHolder: ApiVersionHolder) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          const appVersion: string = event.headers.get("X-App-Version");
          if (!appVersion) {
            return;
          }

          this.apiVersionHolder.update(appVersion);
        }
      })
    );
  }
}
