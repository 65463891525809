import { NgModule } from "@angular/core";
import { AutoLoggCommonModule } from "../common/autologg-common.module";
import { AutoLoggHotspotsRouting } from "./hotspots.routing";

import { HotspotsComponent } from "./hotspots.component";
import { HotspotMapComponent } from "./map/hotspot-map.component";
import { GeocoderMapComponent } from "./map/geocoder-map.component";
import { CreateHotspotComponent } from "./create-hotspot/create-hotspot.component";
import { UpdateHotspotComponent } from "./update-hotspot/update-hotspot.component";
import { ImportHotspotComponent } from "./import-hotspot/import-hotspot.component";
import {
  DxDataGridModule,
  DxBulletModule,
  DxTemplateModule,
  DxPopupModule,
  DxButtonModule,
} from "devextreme-angular";
import { TranslateModule } from "@ngx-translate/core";
import { NgxFileDropModule } from "ngx-file-drop";

@NgModule({
    imports: [
        AutoLoggCommonModule,
        AutoLoggHotspotsRouting,
        TranslateModule,
        NgxFileDropModule,
        DxDataGridModule,
        DxTemplateModule,
        DxBulletModule,
        DxPopupModule,
        DxButtonModule
    ],
    declarations: [
        HotspotsComponent,
        HotspotMapComponent,
        GeocoderMapComponent,
        CreateHotspotComponent,
        UpdateHotspotComponent,
        ImportHotspotComponent,
    ],
    exports: []
})
export class AutoLoggHotspotsModule {}
