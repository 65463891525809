import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Helper } from "app/base/helper";
import { OverlayLoadingService } from "app/utils/overlay-loading.service";
import { ProviderType, OemType } from "app/base/provider-oem-type"
import { environment } from "environments/environment";
import { OAuth2Helper } from "../oauth2/oauth2-helper";

@Component({
  selector: "app-oauth2-login",
  templateUrl: "./oauth2-login.component.html",
  styleUrls: ["./oauth2-login.component.css"],
})
export class Oauth2LoginComponent implements OnInit {
  OemType = OemType;
  ProviderType = ProviderType;

  constructor(private overlayLoading: OverlayLoadingService) {}

  private _vinInput: string;
  public get vinInput(): string {
    return this._vinInput;
  }
  public set vinInput(v: string) {
    v = Helper.processVin(v);

    this._vinInput = v;
    this.vin = v;
  }

  private _vin: string;

  @Input()
  public get vin(): string {
    return this._vin;
  }
  public set vin(v: string) {
    this._vin = v;

    this.vinChange.emit(v);
  }

  @Output()
  vinChange: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  credentialsChange: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  oauth2Completed: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  oauth2Failed: EventEmitter<any> = new EventEmitter<any>();

  private _credentials: any;
  @Input()
  public get credentials(): any {
    return this._credentials;
  }
  public set credentials(v: any) {
    this._credentials = v;

    this.credentialsChange.emit(v);
  }

  @Input()
  providerType: ProviderType;

  @Input()
  brandType: OemType = OemType.Unknown;

  public isValid() {
    return true;
  }

  public isLoginDisabled() {
    if (this.providerType == ProviderType.HighMobility) {
      return !this.vin || this.vin.length != 17;
    }

    return false;
  }

  ngOnInit(): void {}

  async openLogin() {
    switch (this.providerType) {
      case ProviderType.Native:
        if (this.brandType == OemType.Tesla) await this.openTeslaLogin();
      case ProviderType.HighMobility:
        await this.openHighMobilityLogin(this.brandType);
    }
  }

  async openHighMobilityLogin(oemType: OemType): Promise<String> {
    if (!this.vin) {
      return;
    }

    this.overlayLoading.show();
    const redirectUri = location.protocol + "//" + location.host + "/oauth2";

    var query = `app_id=${environment.hmAppId}&client_id=${environment.hmClientId}`;

    if (this.brandType != OemType.Unknown && this.brandType != OemType.Box) {
      var useApiType = oemType;

      query += `&brand=${useApiType.toLocaleLowerCase()}`;
    }

    query += `&vin=${this.vin}`;
    query += `&redirect_uri=${redirectUri}`;

    const uri = `${environment.hmOAuthUrl}?${query}`;
    try {
      const retValue = await OAuth2Helper.start(uri, redirectUri);
      this.overlayLoading.hide();
      var credentials = {
        code: retValue,
        redirectUri: redirectUri,
        isValid: true,
        vin: this.vin,
        brand: this.brandType,
      };
      this.credentialsChange.emit(credentials);
      this.oauth2Completed.emit(credentials);

      return retValue;
    } catch (error) {
      this.oauth2Failed.emit(error);
      return void 0;
    }
  }

  async openTeslaLogin(): Promise<void> {
    const challenge = this.generateString(86);

    const redirectUri = location.protocol + "//" + location.host;
    //const redirectUri = "https://auth.tesla.com/void/callback";
    const query = `client_id=ownerapi&code_challenge=${challenge}&code_challenge_method=S256&redirect_uri=${redirectUri}&response_type=code&scope=openid+email+offline_access&state=${challenge}`;
    const uri = `https://auth.tesla.com/oauth2/v3/authorize?${query}`;

    await OAuth2Helper.start(uri, redirectUri);
  }

  generateString(length) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    let result = "";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  }
}
