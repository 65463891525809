import {
  AfterViewInit,
  Component,
  ViewChild,
  Input,
  EventEmitter,
  Output,
  OnInit,
} from "@angular/core";

import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { SelectionModel } from "@angular/cdk/collections";

import { Observable, zip } from "rxjs";
import { flatMap } from "rxjs/operators";

import { BaseComponent } from "../../base/base.component";
import {
  GroupUserMembership,
  GroupMembershipUpdateRequest,
} from "../../_models/group.model";

import { DialogConfirmComponent } from "../../common/dialog-confirm/dialog-confirm.component";
import { CASE_INSENSITVE_SORTING_DATA_ACCESSOR } from "../../common/table-common.util";

class UserTableEntry {
  group_id: number;
  membership_id: number;
  user_id: number;
  user_email: string;
  user_first_name: string;
  user_last_name: string;
  membership_created_at: string;
  membership_group_admin: boolean;
  membership_auto_hotspot_sharing_enabled: boolean;

  user_locked: boolean;

  static fromGroupUserMembership(groupUser: GroupUserMembership) {
    const entry = new UserTableEntry();
    entry.group_id = groupUser.group.id;
    entry.membership_id = groupUser.membership.id;
    entry.user_id = groupUser.user.id;
    entry.user_email = groupUser.user.email;
    entry.user_first_name = groupUser.user.first_name;
    entry.user_last_name = groupUser.user.last_name;
    entry.membership_created_at = groupUser.membership.created_at;
    entry.membership_group_admin = groupUser.membership.group_admin;
    entry.membership_auto_hotspot_sharing_enabled =
      groupUser.membership.auto_hotspot_sharing_enabled;
    entry.user_locked = groupUser.user.locked;
    return entry;
  }
}

@Component({
  selector: "app-groups-list-group-users",
  templateUrl: "./groups-list-group-users.component.html",
  // styleUrls: [''],
})
export class GroupsListGroupUsersComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  loading: boolean = false;
  updating: boolean = false;

  userId: number;
  users: Array<GroupUserMembership> = [];

  @Input("groupId") groupId: number;
  @Input("groupOwnerUserId") groupOwnerUserId: number;
  @Output("valueChange") valueChange = new EventEmitter();

  displayedColumns: string[] = [
    "select",
    "user_email",
    "user_first_name",
    "user_last_name",
    "membership_created_at",
    "membership_auto_hotspot_sharing_enabled",
    "membership_group_admin",
    "user_enabled",
  ];
  dataSource = new MatTableDataSource<UserTableEntry>([]);
  selection = new SelectionModel<UserTableEntry>(true, []);

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  ngOnInit() {
    this.userId = this._apiService.user.userid;
    this.dataSource.sortingDataAccessor = CASE_INSENSITVE_SORTING_DATA_ACCESSOR;

    this.loadMemberships();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.data = this.users.map((u) =>
      UserTableEntry.fromGroupUserMembership(u)
    );
  }

  private fetchMemberships(): Observable<Array<GroupUserMembership>> {
    return this._apiService
      .getGroupMemberships(this.groupId)
      .do((memberships) => this.onMembershipsLoaded(memberships));
  }

  private loadMemberships() {
    this.loading = true;
    this.fetchMemberships().subscribe(
      (foo) => {},
      (err) => {
        this.loading = false;
        this.showError(err);
      },
      () => {
        this.loading = false;
      }
    );
  }

  private onMembershipsLoaded(memberships) {
    this.users = memberships.sort(this.orderMembershipByUserName);

    this.valueChange.emit(memberships);

    this.selection = new SelectionModel<UserTableEntry>(true, []);
    this.dataSource.data = this.users.map((h) =>
      UserTableEntry.fromGroupUserMembership(h)
    );
  }

  private orderMembershipByUserName(
    a: GroupUserMembership,
    b: GroupUserMembership
  ) {
    return a.user.last_name.localeCompare(b.user.last_name);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  removeSelectedUsers(selection: SelectionModel<UserTableEntry>) {
    if (selection.selected.length === 0) {
      this.showWarning("groups.manage.users.no_user_selected");
      return;
    }

    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: {
        title: "groups.manage.users.remove_selected_users.confirm.title",
        text: "groups.manage.users.remove_selected_users.confirm.text",
        submitButtonText:
          "groups.manage.users.remove_selected_users.confirm.submitButtonText",
        cancelButtonText:
          "groups.manage.users.remove_selected_users.confirm.cancelButtonText",
      },
    });

    dialogRef.afterClosed().subscribe((confirmed) => {
      if (!confirmed) {
        return;
      }

      const observables = selection.selected.map((b) =>
        this._apiService.removeMembershipFromGroup(b.group_id, b.membership_id)
      );

      this.updating = true;
      zip(...observables)
        .pipe(flatMap((foo) => this.fetchMemberships()))
        .subscribe(
          (foo) => {
            this.showSuccess(
              "groups.manage.users.remove_selected_users.success"
            );
          },
          (err) => {
            this.updating = false;
            this.showError(err);

            this.fetchMemberships();
          },
          () => {
            this.updating = false;
          }
        );
    });
  }

  toggleLocked(user: UserTableEntry) {
    const request = new GroupMembershipUpdateRequest();
    const newValue = !user.user_locked;
    request.locked = newValue;

    this.updating = true;
    this._apiService
      .updateGroupMembershipByAdmin(user.group_id, user.membership_id, request)
      .subscribe(
        (foo) => {
          user.user_locked = newValue;
        },
        (err) => {
          this.updating = false;
          this.showError(err);
        },
        () => {
          setTimeout(() => (this.updating = false), 400);
        }
      );
  }

  toggleAdminRights(user: UserTableEntry) {
    const request = new GroupMembershipUpdateRequest();
    const newValue = !user.membership_group_admin;
    request.group_admin = newValue;

    this.updating = true;
    this._apiService
      .updateGroupMembershipByAdmin(user.group_id, user.membership_id, request)
      .subscribe(
        (foo) => {
          user.membership_group_admin = newValue;
        },
        (err) => {
          this.updating = false;
          this.showError(err);
        },
        () => {
          setTimeout(() => (this.updating = false), 400);
        }
      );
  }
}
