import {
  Map,
  tileLayer,
  featureGroup,
  LatLng,
  latLng,
  marker,
  Marker,
  icon,
  control,
} from "leaflet";
import { environment } from "environments/environment";

const DEFAULT_INITIAL_ZOOM: number = 7;
const DEFAULT_INITIAL_CENTER: LatLng = latLng(48.2041557, 16.3532011);

export function leafletCreateOptions(_center = null, _zoom: number = -1) {
  const center = _center ? _center : DEFAULT_INITIAL_CENTER;
  const zoom = _zoom > 0 ? _zoom : DEFAULT_INITIAL_ZOOM;

  return {
    layers: [
      tileLayer(
        "https://api.maptiler.com/maps/basic/{z}/{x}/{y}.png?key=" +
          environment.mapTilerApiKey,
        {
          minZoom: 7,
          attribution:
            '<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>',
        }
      ),
      tileLayer("https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}.png", {
        minZoom: 7,
        attribution: "-",
      }),
    ],
    center: center,
    zoom: zoom,
  };
}

export function leafletPrepareMap(map: Map, interactive: boolean = true): Map {
  if (!map) {
    return map;
  }

  if (map.zoomControl) {
    map.removeControl(map.zoomControl);
  }

  if (interactive == false) {
    map.dragging.disable();
    map.touchZoom.disable();
    map.doubleClickZoom.disable();
    map.scrollWheelZoom.disable();
    map.boxZoom.disable();
    map.keyboard.disable();
    if (map.tap) map.tap.disable();
  } else {
    map.addControl(control.zoom({ position: "topright" }));
  }

  return map;
}

export function leafletFitMapToMarkerBounds(map: Map, markers: Array<Marker>) {
  if (!map || !markers) {
    return;
  }
  if (markers.length === 0) {
    return;
  }

  const group = featureGroup(markers);
  map.fitBounds(group.getBounds());
}

export function leafletCreateMarker(
  position,
  title: string,
  options = {}
): Marker {
  const _options = Object.assign(
    {
      riseOnHover: true,
      draggable: false,
    },
    options || {}
  );

  return marker(position, {
    title: title,
    icon: icon({
      iconSize: [25, 41],
      iconAnchor: [13, 41],
      iconUrl: "assets/leaflet/dist/images/marker-icon.png",
      shadowUrl: "assets/leaflet/dist/images/marker-shadow.png",
    }),
    riseOnHover: _options["riseOnHover"],
    draggable: _options["draggable"],
  });
}
