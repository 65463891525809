<mat-progress-bar *ngIf="loading" color="accent" mode="indeterminate">
</mat-progress-bar>

<div class="mt-1 alert alert-info" *ngIf="!loading && !(dataSource.data.length > 0)">
  <span translate>global.noResults</span>
</div>

<div class="mt-1" [hidden]="!(dataSource.data.length > 0)">
  <mat-form-field class="full-width">
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="{{ 'global.Search' | translate }}">
  </mat-form-field>


  <table mat-table matSort [dataSource]="dataSource" class="mt-1 mb-1 full-width">

    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
        </mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="user_email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'groups.manage.invitations.table.user_email' | translate }} </th>
      <td mat-cell *matCellDef="let element"> {{element.user_email}} </td>
    </ng-container>

    <ng-container matColumnDef="user_exists">
      <th mat-header-cell *matHeaderCellDef> {{ 'groups.manage.invitations.table.user_exists' | translate }} </th>
      <td mat-cell *matCellDef="let element" [ngClass]="{'color-success': element.user_exists, 'color-error': !element.user_exists }">
        <mat-icon class="vertical-align-middle" *ngIf="element.user_exists">check</mat-icon>
        <mat-icon class="vertical-align-middle" *ngIf="!element.user_exists">cancel</mat-icon>
        {{(element.user_exists ? 'global.yes' : 'global.no') | translate }}
      </td>
    </ng-container>
    <ng-container matColumnDef="group_admin">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'groups.manage.invitations.table.group_admin' | translate }} </th>
      <td mat-cell *matCellDef="let element" [ngClass]="{'color-success': element.group_admin, 'color-error': !element.group_admin }">
        <mat-icon class="vertical-align-middle" *ngIf="element.group_admin">check</mat-icon>
        <mat-icon class="vertical-align-middle" *ngIf="!element.group_admin">cancel</mat-icon>
        {{(element.group_admin ? 'global.yes' : 'global.no') | translate }}
      </td>
    </ng-container>
    <ng-container matColumnDef="auto_hotspot_sharing_enabled">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'groups.manage.invitations.table.auto_hotspot_sharing_enabled' | translate }} </th>
      <td mat-cell *matCellDef="let element" [ngClass]="{'color-success': element.auto_hotspot_sharing_enabled, 'color-error': !element.auto_hotspot_sharing_enabled }">
        <mat-icon class="vertical-align-middle" *ngIf="element.auto_hotspot_sharing_enabled">check</mat-icon>
        <mat-icon class="vertical-align-middle" *ngIf="!element.auto_hotspot_sharing_enabled">cancel</mat-icon>
        {{(element.auto_hotspot_sharing_enabled ? 'global.yes' : 'global.no') | translate }}
      </td>
    </ng-container>

    <ng-container matColumnDef="created_at">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'groups.manage.invitations.table.created_at' | translate }} </th>
      <td mat-cell *matCellDef="let element"> {{element.created_at | date:'medium'}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
    </tr>
  </table>

  <mat-paginator [pageSizeOptions]="[50, 100, 200]" showFirstLastButtons></mat-paginator>

  <button mat-button color="warn" title="{{ 'groups.manage.invitations.remove_selected_invitations.submit' | translate }}"
    (click)="removeSelectedInvitations(selection)" [disabled]="!selection.hasValue()">
    <mat-icon class="icon-custom">clear</mat-icon>
    <span translate>groups.manage.invitations.remove_selected_invitations.submit</span>
  </button>
</div>