import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { FormBuilder } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ApiService } from "app/api.service";
import { ConnectedHelper } from "app/AutologgConnected/connected-helper";
import { ConnectedLoginComponent } from "app/AutologgConnected/connected-login/connected-login.component";
import {
  ConnectedPayload,
  HighMobilityConnectedPayload,
  TeslaConnectedPayload,
} from "app/AutologgConnected/connected-payload";
import { RegisterService, Vehicle } from "app/register/register.service";
import { TrackingService } from "app/tracking/tracking-service";
import { OverlayLoadingService } from "app/utils/overlay-loading.service";
import { Box } from "app/_models/box.model";
import { environment } from "environments/environment";
import { BaseBoxesComponent } from "../base-boxes.component";
import {
  ProviderType,
  OemType,
  oemTypeFromString,
  apiProviderTypeFromString,
} from "app/base/provider-oem-type";

@Component({
  selector: "app-update-box-access",
  templateUrl: "./update-box-access.component.html",
  styleUrls: ["./update-box-access.component.css"],
})
export class UpdateBoxAccessComponent
  extends BaseBoxesComponent
  implements OnInit, AfterViewInit {
  loading: boolean = false;

  @ViewChild("connectedLogin", { static: false })
  connectedLogin: ConnectedLoginComponent;

  connectedCredentials: any;
  vehicleId: number;
  box: Box;

  vin: String;
  oemType: OemType;

  providerType: ProviderType;

  constructor(
    _apiService: ApiService,
    router: Router,
    snackBar: MatSnackBar,
    dialog: MatDialog,
    formBuilder: UntypedFormBuilder,
    translate: TranslateService,
    route: ActivatedRoute,
    _changeDetectorRef: ChangeDetectorRef,
    _tracker: TrackingService,
    private registerService: RegisterService,
    private overlayLoading: OverlayLoadingService,
    private connectedHelper: ConnectedHelper
  ) {
    super(
      _apiService,
      router,
      snackBar,
      dialog,
      formBuilder,
      translate,
      route,
      _changeDetectorRef,
      _tracker
    );
  }

  async ngOnInit() {
    if (!environment.updateCredentialsEnabled) {
      this.router.navigateByUrl("/boxes");
    }

    this.vehicleId = +this.route.snapshot.paramMap.get("id");

    this.loading = true;
    try {
      const box = await this._apiService
        .getUserBoxById(this.vehicleId)
        .toPromise();

      if (box.oem_type) {
        this.oemType = oemTypeFromString(box.oem_type);
        this.providerType = apiProviderTypeFromString(box.provider_type);
      }
      this.box = box;
    } catch (err) {
      this.loading = false;
      this.showError(err);
    } finally {
      this.loading = false;
    }
  }

  async onOauth2Completed($event) {
    if (
      this.providerType == ProviderType.HighMobility &&
      $event &&
      !!$event.isValid
    ) {
      await this.checkConnectedLoginInternal($event);
    }
  }
  async onVehiclesUpdated($event) {
    try {
      await this.updateCredentials($event[0], $event[0].payload.Data);
      this.router.navigate(["/boxes"]);
    } catch (error) {
      console.log(error);
      if (error.message == "MFA_CODE_REQUIRED") {
        this.connectedLogin.showMfaOverlay(error.data);
      } else if (error.errorType) {
        this.showError("register.error." + error.errorType, "OK", 15000);
      } else {
        this.showError(error);
      }
    }
  }

  async updateCredentials(firstVehicle: any, connectedPayload: any) {
    const vehicle: any = {
      referenceVehicleId: firstVehicle.referenceVehicleId,
      vehicleId: this.box.vehicle_id,
      oemType: this.box.oem_type,
      providerType: this.box.provider_type,
      name: this.box.name,
      payload: connectedPayload,
      brand: "",
      model: "",
      plate: "",
    };
    if (this.providerType == ProviderType.HighMobility) {
      var hmPayload: HighMobilityConnectedPayload = {
        accessPayload: firstVehicle.accessPayload,
        brand: firstVehicle.oemType,
        vehicleId: this.box.vehicle_id.toString(),
      };
      vehicle.vin = (<any>connectedPayload).vin;
      vehicle.payload = hmPayload;
    }

    await this.registerService.updateConnectedCredentials(vehicle);
  }

  async checkConnectedLoginInternal(connectedPayload: ConnectedPayload) {
    try {
      if (!this.connectedLogin.isValid()) {
        this.showError("the credentials must be complete");
        return;
      }

      this.overlayLoading.show();

      this.registerService.payload = connectedPayload;
      connectedPayload.vehicleId = this.vehicleId.toString();

      const vehicles = await this.connectedHelper.loadVehicles(
        this.providerType,
        this.oemType,
        connectedPayload
      );
      const firstVehicle = vehicles[0];

      await this.updateCredentials(firstVehicle, connectedPayload);

      this.router.navigate(["/boxes"]);
    } catch (error) {
      console.log(error);
      if (error.message == "MFA_CODE_REQUIRED") {
        this.connectedLogin.showMfaOverlay(error.data);
      } else if (error.errorType) {
        this.showError("register.error." + error.errorType, "OK", 15000);
      } else {
        this.showError(error);
      }
    } finally {
      this.overlayLoading.hide();
    }
  }

  ngAfterViewInit(): void { }

  async onMfaUpdated($event) {
    this.overlayLoading.show();
    try {
      var mfaData = { ...$event, VehicleId: this.box.vehicle_id };
      const vehicles = await this.connectedHelper.sendMfa(
        OemType.Unknown,
        mfaData
      );

      const connectedPayload: TeslaConnectedPayload = {
        username: "",
        password: "",
        vehicleId: this.box.vehicle_id.toString(),
        accessPayload: vehicles[0].accessPayload,
      };

      const vehicle: Vehicle = {
        reference_vehicle_id: null,
        vehicleId: this.box.vehicle_id,
        oemType: this.box.oem_type,
        providerType: this.box.provider_type,
        name: this.box.name,
        payload: connectedPayload,
        brand: "",
        model: "",
        plate: "",
      };
      await this.registerService.updateConnectedCredentials(vehicle);
      this.router.navigate(["/boxes"]);
    } catch (error) {
      this.showError(error);
    } finally {
      this.overlayLoading.hide();
    }
  }

  async onOauth2Failed($event) {
    console.log($event);
    if (this.providerType == ProviderType.HighMobility) {
      await this.hideLoadingIndicatorAndShowOauthFaildErrorMessage($event);
    }
  }

  async hideLoadingIndicatorAndShowOauthFaildErrorMessage($event) {
    this.overlayLoading.hide();
    this.showError("register.connected.couldNotLoadConnectedCars", "OK", 10000);
  }
}
