<div class="chart-container">
  <h5 *ngIf="isEmpty"
    style="text-align:center;color:#999; position:absolute; top:50%; width:100%"
  >
    {{ "statistics.barChartNoData" | translate }}
  </h5>

  <ngx-charts-bar-vertical
    [scheme]="colorScheme"
    [results]="barChartData"
    [gradient]="false"
    [xAxis]="true"
    [dataLabelFormatting]="dataLabelFormatting"
    [noBarWhenZero]="isEmpty"
    [tooltipDisabled]="true"
    [yAxis]="false"
    [showDataLabel]="true"
    [roundEdges]="false"
    [legend]="false"
    [showXAxisLabel]="true"
    [showYAxisLabel]="true"
    [barPadding]="24"
  >
  </ngx-charts-bar-vertical>

</div>
