<mat-progress-bar *ngIf="loading" color="accent" mode="indeterminate"></mat-progress-bar>

<div class="container-fluid">
  <div class="row">
    <div class="col-12" *ngIf="loading">
      <h1 translate>boxes.vehicles.title</h1>
    </div>

    <div class="col-12" *ngIf="!loading">
      <h1>{{ box.name }}</h1>
      <h3 translate>boxes.vehicles.title</h3>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <a class="mr-1" mat-button (click)="navigateBack()" title="{{ 'global.back' | translate }}">
        <mat-icon class="icon-custom">arrow_back</mat-icon>
        <span translate>global.back</span>
      </a>
      <a class="m-1" mat-raised-button color="primary" routerLink="create"
        title="{{ 'boxes.vehicles.addVehicle' | translate }}" *ngIf="!loading && allModels.length === 0">
        <mat-icon class="icon-custom">add_circle</mat-icon>
        <span translate>boxes.vehicles.addVehicle</span>
      </a>
      <ng-container *ngIf="box.oem_type === OemType.Box">
        <a class="m-1" mat-raised-button color="primary" routerLink="create"
          title="{{ 'boxes.vehicles.addVehicle' | translate }}" *ngIf="!loading && allModels.length > 0">
          <mat-icon class="icon-custom">archive</mat-icon>
          <span translate>boxes.vehicles.changeVehicle</span>
        </a>
      </ng-container>
    </div>
  </div>

  <hr />

  <div class="row" *ngIf="loading">
    <div class="col-12">
      <mat-spinner style="margin:0 auto;" mode="indeterminate"></mat-spinner>
    </div>
  </div>

  <div class="row" *ngIf="!loading">
    <div class="col-12 p-3">
      <h3 translate>boxes.vehicles.actives.title</h3>

      <div class="alert alert-info" *ngIf="!activeModel">
        <span translate>global.noResults</span>
      </div>

      <mat-card class="mb-4" *ngIf="activeModel">
        <mat-card-header>
          <mat-card-title>
            <h6>
              <b translate>boxes.vehicles.plate</b>: {{activeModel.plate}}
            </h6>
          </mat-card-title>
          <mat-card-subtitle>
            <span translate>boxes.vehicles.created_at</span>: {{activeModel.created_at |
            date:'short'}}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content *ngIf="box.oem_type == OemType.Box && activeModel.oemCode != 'UNKNOWN'">
          <ul>
            <li>
              <b translate>boxes.vehicles.oem</b>: {{ activeModel.oemCode }} /
              <b translate>boxes.vehicles.series</b>: {{ activeModel.seriesCode }} /
              <b translate>boxes.vehicles.model</b>: {{ activeModel.model_name }}
            </li>
          </ul>
          <ul>
            <li>
              <b translate>boxes.vehicles.mileage</b>: {{ (activeModel.km || 0) | number:0 }} km
            </li>
            <li>
              <b translate>boxes.vehicles.open_trip_count</b>: {{ activeModel.open_trip_count | number:0 }}
            </li>
            <li>
              <b translate>boxes.vehicles.total_trip_count</b>: {{ activeModel.total_trip_count | number:0 }}
            </li>
          </ul>
        </mat-card-content>
        <mat-card-actions>
          <!-- ng-container>
            <a mat-raised-button routerLink="/boxes/{{box.vehicle_id}}/vehicles/{{activeModel.model_id}}/trips" title="trips.editorLinkBtnText"
              [matBadge]="activeModel.open_trip_count | number:0" matBadgePosition="above after" [matBadgeColor]="!(activeModel.open_trip_count > 0) ? 'accent' : 'primary'">
              <mat-icon class="icon-custom">beenhere</mat-icon>
              <ng-container >trips.editorLinkBtnText</ng-container>
            </a>
          </ng-container-->
          <!--a mat-raised-button routerLink="/boxes/{{box.vehicle_id}}/vehicle/{{activeModel.model_id}}/export" title="{{ 'boxes.vehicles.export' | translate }}">
            <mat-icon class="icon-custom">description</mat-icon>
            <span>{{ 'boxes.vehicles.export' | translate }} </span>
          </a-->
        </mat-card-actions>
      </mat-card>
    </div>
  </div>

  <div class="row" *ngIf="archivedModels.length !== 0 && !loading">
    <div class="col-12">
      <h3 translate>boxes.vehicles.history.title</h3>

      <div *ngFor="let model of archivedModels">
        <mat-card class="mb-4">
          <mat-card-header>
            <mat-card-title>
              <h6>
                <b translate>boxes.vehicles.plate</b>: {{model.plate}}
              </h6>
            </mat-card-title>
            <mat-card-subtitle>
              <span translate>boxes.vehicles.created_at</span>: {{model.created_at | date:'short'}}</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <ul *ngIf="model.oemCode != 'UNKNOWN'">
              <li>
                <b translate>boxes.vehicles.oem</b>: {{ model.oemCode }} /
                <b translate>boxes.vehicles.series</b>: {{ model.seriesCode }} /
                <b translate>boxes.vehicles.model</b>: {{ model.model_name }}
              </li>
            </ul>
            <ul>
              <li>
                <b translate>boxes.vehicles.total_trip_count</b>: {{ model.total_trip_count | number:0 }}
              </li>
            </ul>
          </mat-card-content>
          <mat-card-actions>
            <span [matTooltipDisabled]="model.total_trip_count === 0"
              [matTooltip]="'boxes.vehicles.remove_vehicle.disabled_tooltip' | translate" matTooltipPosition="right">
              <button mat-button color="warn" title="{{ 'boxes.vehicles.remove_vehicle.submit' | translate }}"
                (click)="removeModel(model)" [disabled]="model.total_trip_count > 0">
                <mat-icon class="icon-custom">delete_forever</mat-icon>
                <span translate>boxes.vehicles.remove_vehicle.submit</span>
              </button>
            </span>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>
  </div>
</div>