import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  Input,
} from "@angular/core";
import { OauthLoginComponent } from "../oauth-login/oauth-login.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { Oauth2LoginComponent } from "../oauth2-login/oauth2-login.component";
import { ConnectedHint } from "app/_models/connected-hint.model";
import { ConnectedVehicle } from "app/register/register.service";
import { ProviderType, OemType } from "app/base/provider-oem-type"
import { Helper } from "app/base/helper";

@Component({
  selector: "app-connected-login",
  templateUrl: "./connected-login.component.html",
  styleUrls: ["./connected-login.component.css"],
})
export class ConnectedLoginComponent implements OnInit {
  ProviderType = ProviderType;
  OemType = OemType;

  @ViewChild("oauthLogin", { static: false }) oauthLogin: OauthLoginComponent;
  @ViewChild("oauth2Login", { static: false })
  oauth2Login: Oauth2LoginComponent;

  @Input()
  showHeader: boolean = true;

  private _credentials: any;

  @Input()
  public get connectedCredentials(): any {
    return this._credentials;
  }
  public set connectedCredentials(v: any) {
    this._credentials = v;
    this.connectedCredentialsChange.emit(v);
  }

  @Output()
  connectedCredentialsChange = new EventEmitter<any>();

  @Output()
  oauth2Completed: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  oauth2Failed: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  providerType: ProviderType = ProviderType.Native;

  @Input()
  oemType: OemType = OemType.Unknown;

  private _vin: string;
  @Input()
  public get vin(): string {
    return this._vin;
  }
  public set vin(v: string) {
    if (v) {
      this._vin = Helper.processVin(v);
    } else {
      this._vin = v;
    }
    this.vinChange.emit(this._vin);
  }

  @Input()
  isDialog: boolean = false;

  @Output()
  vinChange = new EventEmitter<any>();

  @Output()
  mfaUpdated = new EventEmitter<any>();

  @Output()
  onVehiclesUpdated = new EventEmitter<ConnectedVehicle[]>();

  mfaDialogVisible: boolean = false;
  mfaData: any;

  public get loginTitle(): string {
    return "register.stepTwo.desc_" + this.oemType.toLowerCase();
  }

  constructor(
    private modalService: NgbModal,
    private translate: TranslateService
  ) {}

  connectedHints: Array<ConnectedHint> = [];
  param = { providerType: this.providerType, oemType: this.oemType };

  ngOnInit() {
    this.initHints();

    this.param = {
      providerType: this.providerType,
      oemType: this.oemType,
    };
  }

  getTranslation(key) {
    return this.translate.instant(key);
  }

  isValid() {
    if (this.oemType == OemType.Dongle) {
      return this.vin != undefined && this.vin != "";
    }
    if (this.providerType == ProviderType.HighMobility) {
      return this.oauth2Login.isValid();
    }

    return this.oauthLogin.isValid();
  }

  showMfaOverlay(data: any) {
    this.mfaDialogVisible = true;

    this.mfaData = data;
  }

  sendMfa() {
    this.mfaUpdated.emit(this.mfaData);
    this.mfaDialogVisible = false;
  }

  onOauth2Completed($event) {
    this.oauth2Completed.emit($event);
  }

  onOauth2Failed($event) {
    console.log("oauth2 failed", $event);
    console.log(this.oauth2Failed);
    this.oauth2Failed.emit($event);
  }

  onDongleVehiclesUpdate($event) {
    this.onVehiclesUpdated.emit($event);
  }

  initHints(): void {
    let connectedHintsImagesPath = "/assets/images/high-mobility/";
    let connectionHintsTranslations = "connected_boxes.highMobilityHints.";

    if (this.providerType == ProviderType.HighMobility) {
      this.connectedHints = [
        {
          imagePath: connectedHintsImagesPath + "hm1.png",
          translationKey: connectionHintsTranslations + "1",
        },
        {
          imagePath: connectedHintsImagesPath + "hm2.png",
          translationKey: connectionHintsTranslations + "2",
        },
        {
          imagePath:
            connectedHintsImagesPath +
            "hm_" +
            this.oemType.toLowerCase() +
            "3.png",
          translationKey: connectionHintsTranslations + "3",
        },
        {
          imagePath: connectedHintsImagesPath + "hm4.png",
          translationKey: connectionHintsTranslations + "4",
        },
      ];

      var oemType = this.oemType.toLowerCase();

      if (
        this.oemType == OemType.Fiat ||
        this.oemType == OemType.AlfaRomeo ||
        this.oemType == OemType.Peugeot ||
        this.oemType == OemType.Citroen ||
        this.oemType == OemType.DsAuto ||
        this.oemType == OemType.Jeep ||
        this.oemType == OemType.Opel
      ) {
        oemType = "stellantis";
      }

      this.connectedHints.push({
        imagePath: connectedHintsImagesPath + oemType + "1.png",
        translationKey: `connected_boxes.${oemType}Hints.1`,
      });
      this.connectedHints.push({
        imagePath: connectedHintsImagesPath + oemType + "2.png",
        translationKey: `connected_boxes.${oemType}Hints.2`,
      });
    }
  }
}
