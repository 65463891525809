<mat-tab-group>
  <mat-tab label="Tabelle">
    <mat-progress-bar *ngIf="loading || updating" color="accent" mode="indeterminate"></mat-progress-bar>

    <div class="mt-1 alert alert-info" *ngIf="!loading && !(dataSource.data.length > 0)">
      <span translate>global.noResults</span>
    </div>

    <div class="mt-1" [hidden]="!(dataSource.data.length > 0)">
      <mat-form-field class="full-width">
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="{{ 'global.Search' | translate }}">
      </mat-form-field>

      <table mat-table matSort [dataSource]="dataSource" class="mt-1 mb-1 full-width">

        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="provider_type">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'groups.manage.boxes.table.provider_type' | translate }} </th>
          <td mat-cell *matCellDef="let element"> 
            <ng-container *ngIf="element.provider_type === ProviderType.Native">{{ 'groups.manage.boxes.box' | translate  }}</ng-container>
            <ng-container *ngIf="element.provider_type !== ProviderType.Native">{{ 'groups.manage.boxes.connected_box' | translate  }}</ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="box_vehicle_id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'groups.manage.boxes.table.box_vehicle_id' | translate }} </th>
          <td mat-cell *matCellDef="let element">{{ element.box_vehicle_id }} </td>
        </ng-container>

        <ng-container matColumnDef="box_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'groups.manage.boxes.table.box_name' | translate }} </th>
          <td mat-cell *matCellDef="let element">
            <a routerLink="/groups/{{groupId}}/manage/box/{{element.box_vehicle_id}}" title="{{ 'groups.manage.box.title' | translate }}">
              {{ element.box_name }}
            </a>
          </td>
        </ng-container>

        <ng-container matColumnDef="latest_trip_end_mileage">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'groups.manage.boxes.table.latest_trip_end_mileage' | translate }} </th>
          <td mat-cell *matCellDef="let element">
            {{ element.latest_trip_end_mileage ? (element.latest_trip_end_mileage | number:0) + ' km' : '-' }}&nbsp;
          </td>
        </ng-container>

        <ng-container matColumnDef="latest_trip_end_address">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'groups.manage.boxes.table.latest_trip_end_address' | translate }} </th>
          <td mat-cell *matCellDef="let element">
            {{ element.latest_trip_end_address || '-' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="user_fullname">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'groups.manage.boxes.table.user_fullname' | translate }} </th>
          <td mat-cell *matCellDef="let element">{{element.user_fullname}}</td>
        </ng-container>

        <ng-container matColumnDef="box_description">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'groups.manage.boxes.table.box_description' | translate }} </th>
          <td mat-cell *matCellDef="let element">
            {{ element.box_description || ('global.no_description' | translate) }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
        </tr>
      </table>

      <mat-paginator [pageSizeOptions]="[50, 100, 200]" showFirstLastButtons></mat-paginator>

      <button mat-button color="warn" title="{{ 'groups.manage.boxes.remove_selected_boxes.submit' | translate }}" (click)="removeSelectedBoxes(selection)"
        [disabled]="!selection.hasValue()">
        <mat-icon class="icon-custom">delete_sweep</mat-icon>
        <span translate>groups.manage.boxes.remove_selected_boxes.submit</span>
      </button>
    </div>


  </mat-tab>
  <mat-tab label="Karte" *ngIf="!_apiService?.user?.hasFeatureOrGeneralFeature('AUTOLOGG_HIDE_MAP') && boxesWithLatestTrip.length > 0">
    <ng-template matTabContent>
      <app-groups-list-group-boxes-map [boxesWithLatestTrip]="boxesWithLatestTrip"></app-groups-list-group-boxes-map>
    </ng-template>
  </mat-tab>
</mat-tab-group>