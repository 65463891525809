import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { BaseComponent } from "app/base/base.component";
import { Hotspot } from "app/_models/hotspot.model";
import { exportDataGrid } from "devextreme/excel_exporter";
import * as ExcelJS from "exceljs";
import saveAs from "file-saver";
import { FileSystemFileEntry, NgxFileDropEntry } from "ngx-file-drop";
import notify from "devextreme/ui/notify";
import { UntypedFormBuilder } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ApiService } from "app/api.service";
import { TrackingService } from "app/tracking/tracking-service";
import { OverlayLoadingService } from "app/utils/overlay-loading.service";
import { DxDataGridComponent } from "devextreme-angular";

@Component({
  selector: "app-import-hotspot",
  templateUrl: "./import-hotspot.component.html",
  styleUrls: ["./import-hotspot.component.scss"],
})
export class ImportHotspotComponent extends BaseComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  dataSource: Hotspot[] = [];
  popupVisible = false;

  hotspotSheetName: string = "Hotspots";
  fileLoading = false;
  selectedItemKeys: any[] = [];

  constructor(
    _apiService: ApiService,
    router: Router,
    snackBar: MatSnackBar,
    dialog: MatDialog,
    formBuilder: UntypedFormBuilder,
    translate: TranslateService,
    route: ActivatedRoute,
    _changeDetectorRef: ChangeDetectorRef,
    _tracker: TrackingService,
    private overlayLoading: OverlayLoadingService
  ) {
    super(
      _apiService,
      router,
      snackBar,
      dialog,
      formBuilder,
      translate,
      route,
      _changeDetectorRef,
      _tracker
    );
  }

  async ngOnInit() {
    await this.load();
  }

  async load() {
    this.dataSource = await this.fetchUserHotspots();
  }

  private fetchUserHotspots(): Promise<Hotspot[]> {
    return this._apiService
      .getUserHotspots()
      .map((hotspots) => hotspots.sort(this.orderHotspotByTitle))
      .toPromise();
  }

  private orderHotspotByTitle(a: Hotspot, b: Hotspot) {
    return a.title.localeCompare(b.title);
  }

  onExporting(e) {
    const that = this;
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(this.hotspotSheetName);

    const infoValue = this._translate.instant("hotspots.import.notice2");

    exportDataGrid({
      component: e.component,
      worksheet: worksheet,
      autoFilterEnabled: true,
    })
      .then((cellRange) => {
        // header
        const headerRow = worksheet.getRow(1);
        let cell = 1;
        headerRow.getCell(cell++).value = "id";
        headerRow.getCell(cell++).value = "title";
        headerRow.getCell(cell++).value = "latitude";
        headerRow.getCell(cell++).value = "longitude";
        headerRow.getCell(cell++).value = "address";
        headerRow.getCell(cell++).value = "partner";
        headerRow.getCell(cell++).value = "purpose";
        headerRow.getCell(cell++).value = "note";
        headerRow.getCell(cell++).value = "radius";

        let i = 2;
        if (that.dataSource.length == 0) {
          const row = worksheet.getRow(i);

          const item = new Hotspot();
          item.address = "Mühlstrasse 21, 4614 Marchtrenk, Österreich";
          item.partner = "AutoLogg";
          item.purpose = "AutoLogg";
          item.title = "AutoLogg";
          item.radius_in_meters = 100;
          item.itemid = "-1";

          cell = 1;
          row.getCell(cell++).value = item.itemid;
          row.getCell(cell++).value = item.title;
          row.getCell(cell++).value = item.latitude;
          row.getCell(cell++).value = item.longitude;
          row.getCell(cell++).value = item.address;
          row.getCell(cell++).value = item.partner;
          row.getCell(cell++).value = item.purpose;
          row.getCell(cell++).value = item.note;
          row.getCell(cell++).value = item.radius_in_meters;
        } else {
          for (const item of that.dataSource) {
            const row = worksheet.getRow(i);
            cell = 1;
            row.getCell(cell++).value = item.itemid;
            row.getCell(cell++).value = item.title;
            row.getCell(cell++).value = item.latitude;
            row.getCell(cell++).value = item.longitude;
            row.getCell(cell++).value = item.address;
            row.getCell(cell++).value = item.partner;
            row.getCell(cell++).value = item.purpose;
            row.getCell(cell++).value = item.note;
            row.getCell(cell++).value = item.radius_in_meters;

            i++;
          }
        }

        const footerRow = worksheet.getRow(i + 3);
        footerRow.getCell(1).value = "INFORMATION";
        footerRow.getCell(2).value = infoValue;
      })
      .then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            "hotspots.xlsx"
          );
        });
      });
    e.cancel = true;
  }

  selectionChanged(data: any) {
    this.selectedItemKeys = data.selectedRowKeys;
  }

  onToolbarPreparing(e) {
    const that = this;
    var removeHint = this._translate.instant("hotspots.import.remove");
    e.toolbarOptions.items.unshift({
      location: "after",
      widget: "dxButton",
      options: {
        icon: "trash",
        hint: removeHint,
        onClick: () => {
          if (this.selectedItemKeys.length == 0) {
            this.showWarning("hotspots.import.cannotDeleteWhenEmpty");
          } else {
            this.selectedItemKeys.forEach((key) => {
              const index = this.dataSource.indexOf(key);
              this.dataSource.splice(index, 1);
            });
            this.dataGrid.instance.refresh();
          }
        },
      },
    });
    let uploadHint = this._translate.instant("hotspots.import.upload");
    e.toolbarOptions.items.unshift({
      location: "after",
      widget: "dxButton",
      options: {
        icon: "upload",
        hint: uploadHint,
        onClick: () => {
          that.popupVisible = true;
        },
      },
    });

    let saveHint = this._translate.instant("hotspots.import.save");
    e.toolbarOptions.items.unshift({
      location: "after",
      widget: "dxButton",
      options: {
        icon: "save",
        hint: saveHint,
        onClick: async () => {
          that.overlayLoading.show();
          try {
            await that._apiService.saveHotpots(that.dataSource).toPromise();
            await this.load();
            this.showSuccess("hotspots.import.saved");
          } catch (e) {
            notify(
              this._translate.instant("hotspots.import.error") + ": " + e,
              "error",
              3000
            );
          } finally {
            that.overlayLoading.hide();
          }
        },
      },
    });
  }

  public dropped(files: NgxFileDropEntry[]) {
    const that = this;

    if (files.length <= 0) {
      return;
    }
    if (files.length > 1) {
      console.error("multiple files detected....this is not supported");
      notify(
        this._translate.instant("hotspots.import.multiple_files_not_supported"),
        "error",
        3000
      );
      return;
    }

    const fileEntry = files[0].fileEntry as FileSystemFileEntry;
    if (fileEntry.isFile) {
      this.fileLoading = true;

      fileEntry.file(async (file: File) => {
        var fileData = await file.arrayBuffer();
        var workbook = new ExcelJS.Workbook();

        workbook.xlsx.load(fileData).then(function () {
          var worksheet = workbook.getWorksheet(that.hotspotSheetName);

          if (!worksheet) {
            notify(
              that._translate.instant("hotspots.import.worksheet_not_found"),
              "error",
              3000
            );
            that.popupVisible = false;
            that.fileLoading = false;
            return;
          }

          var hotspots: Hotspot[] = [];

          worksheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
            if (rowNumber == 1) {
              return;
            }
            if (row.values.length >= 6) {
              let cell = 1;
              let hotspot = new Hotspot();
              hotspot.itemid = row.values[cell++];
              hotspot.title = row.values[cell++];
              hotspot.latitude = row.values[cell++];
              hotspot.longitude = row.values[cell++];
              hotspot.address = row.values[cell++];
              hotspot.partner = row.values[cell++];
              hotspot.purpose = row.values[cell++];
              hotspot.note = row.values[cell++];
              hotspot.radius_in_meters = row.values[cell++];

              if (hotspot.itemid != "-1") {
                hotspots.push(hotspot);
              }
            }
          });

          that.dataSource = hotspots;
          that.popupVisible = false;
          that.fileLoading = false;
        });
      });
    }
  }
}
