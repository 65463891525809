import {
  AfterViewInit,
  Component,
  ViewChild,
  OnInit,
  Input,
} from "@angular/core";
import { BaseComponent } from "../../../base/base.component";
import { MatDialogRef } from "@angular/material/dialog";
import { GroupCreateInvitationRequest } from "../../../_models/group-create-invitation-request.model";

@Component({
  selector: "app-dialog-group-create-invitation-success",
  template: `<h2 class="mb-3" translate>
      groups.invitations.create.success.title
    </h2>
    <mat-dialog-content>
      <p translate>groups.invitations.create.success.text</p>
    </mat-dialog-content>

    <mat-dialog-actions>
      <button mat-button type="button" (click)="closeDialog()">
        <span translate>global.ok</span>
      </button>
    </mat-dialog-actions>`,
})
export class DialogGroupCreateInvitationSuccessComponent {
  constructor(public dialogRef: MatDialogRef<CreateInvitationComponent>) {}

  closeDialog() {
    this.dialogRef.close();
  }
}

@Component({
  // selector: 'app-create-invitation',
  templateUrl: "./create-invitation.component.html",
  styleUrls: ["create-invitation.component.css"],
})
export class CreateInvitationComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  private static MAX_NUMBER_OF_EMAILS: number = 100;

  @ViewChild("addInvitationForm", { static: false }) form;

  loading: boolean = false;

  groupId: number;

  emails: string = "";
  groupAdmin: boolean = false;
  auto_hotspot_sharing_enabled: boolean = false;

  splitEmails: Array<string> = [];
  numberOfMailsLeft: number = CreateInvitationComponent.MAX_NUMBER_OF_EMAILS;

  ngOnInit() {
    this.groupId = +this.route.snapshot.paramMap.get("id");
  }

  ngAfterViewInit() {
    this.form.control.valueChanges.subscribe((values) => {
      this.formControlValueChanged();
    });
    this.setupNewForm();
  }

  formControlValueChanged() {
    this.splitEmails = this.splitEmailInput();
    this.numberOfMailsLeft =
      CreateInvitationComponent.MAX_NUMBER_OF_EMAILS - this.splitEmails.length;
  }

  splitEmailInput() {
    return !this.emails
      ? []
      : this.emails
          .split("\n")
          .join(",")
          .split(",")
          .filter((f) => !!f)
          .map((f) => f.trim())
          .filter((f) => f.length > 5)
          .filter((f) => f.indexOf("@") > -1);
  }

  isSubmitButtonEnabled() {
    return this.emails.length > 0;
  }

  setupNewForm() {
    this.form.resetForm();

    this.emails = "";
  }

  submitForm() {
    if (!this.isSubmitButtonEnabled()) {
      throw new Error("Form is in invalid state and cannot be submitted");
    }

    const splitEmails: Array<string> = this.splitEmailInput();
    if (splitEmails.length === 0) {
      const err = new Error("No emails have been entered");
      this.showError(err);
      throw err;
    }
    if (splitEmails.length > 100) {
      const err = new Error("You must not specify more than 100 email");
      this.showError(err);
      throw err;
    }

    const request = new GroupCreateInvitationRequest();
    request.emails = splitEmails;
    request.auto_hotspot_sharing_enabled = this.auto_hotspot_sharing_enabled;
    request.group_admin = this.groupAdmin;

    this.loading = true;
    this._apiService.createGroupInvitation(this.groupId, request).subscribe(
      (foo) => {
        this.setupNewForm();

        this.router.navigate(["/groups/" + this.groupId + "/manage"]);
        const dialogRef = this.dialog.open(
          DialogGroupCreateInvitationSuccessComponent
        );
      },
      (err) => {
        this.loading = false;
        this.showError(err);
      },
      () => {
        this.loading = false;
      }
    );
  }
}
