import { AfterViewInit, Component, OnInit, Input } from "@angular/core";
import { forkJoin } from "rxjs";

import { Car } from "../../../_models/car.model";
import { Box } from "../../../_models/box.model";
import { BaseBoxesComponent } from "app/boxes/base-boxes.component";

@Component({
  selector: "app-groups-manage-box-vehicles",
  templateUrl: "./vehicles.component.html",
})
export class ManageGroupBoxVehiclesComponent
  extends BaseBoxesComponent
  implements OnInit, AfterViewInit
{
  @Input("groupId") groupId: number;
  @Input("box") box: Box;

  allModels: Array<Car> = [];
  archivedModels: Array<Car> = [];
  activeModel: Car;

  loading: boolean = true;
  updating: boolean = false;

  ngOnInit() {
    this.loadModels();
  }

  ngAfterViewInit() {}

  private loadModels() {
    this.loading = true;
    this.fetchModels().subscribe(
      (foo) => {},
      (err) => {
        this.loading = false;
        this.showError(err);
      },
      () => {
        this.loading = false;
      }
    );
  }

  private fetchModels() {
    return this._apiService
      .getUserBoxModels(this.box.vehicle_id)
      .map((models) => {
        this.allModels = models;

        this.archivedModels = this.allModels.filter(
          (val) => val.model_id !== this.box.active_model_id
        );
        const activeModels = this.allModels.filter(
          (val) => val.model_id === this.box.active_model_id
        );
        this.activeModel = activeModels.length > 0 ? activeModels[0] : null;

        return this._apiService.getUserBoxModels(this.box.vehicle_id);
      });
  }
}
