export class AppFeature {
  public id: number;
  public code: string;
  public name: string;
  public description: string;
  public enable_by_user: boolean = false;
  public beta: boolean = false;
}

export class UserAppFeature {
  public code: string;
  public value: string;
}
