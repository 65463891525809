import {
  AfterViewInit,
  Component,
  ViewChild,
  Input,
  EventEmitter,
  Output,
  OnInit,
} from "@angular/core";
import { formatDate, formatNumber } from "@angular/common";

import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { SelectionModel } from "@angular/cdk/collections";

import { Observable, zip } from "rxjs";
import { flatMap } from "rxjs/operators";

import { Map, latLng, Marker, circle, Circle } from "leaflet";

import { BaseComponent } from "../../base/base.component";
import { GroupUserHotspot } from "../../_models/group.model";

import {
  leafletPrepareMap,
  leafletCreateOptions,
  leafletFitMapToMarkerBounds,
  leafletCreateMarker,
} from "../../common/leaflet-common.util";

import { DialogConfirmComponent } from "../../common/dialog-confirm/dialog-confirm.component";
import { CASE_INSENSITVE_SORTING_DATA_ACCESSOR } from "../../common/table-common.util";
import { Helper } from "app/base/helper";

@Component({
  selector: "app-groups-list-group-hotspots-map",
  templateUrl: "./groups-list-group-hotspots-map.component.html",
  styleUrls: ["groups-list-group-hotspots-map.component.css"],
})
export class GroupsListGroupHotspotsMapComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  @Input("hotspots") hotspots: Array<GroupUserHotspot> = [];

  loading: boolean = false;

  leafletOptions: any;
  leafletLayers: any[] = [];
  marker: Marker[] = [];

  map: Map;

  ngOnInit() {
    this.leafletOptions = leafletCreateOptions();

    this.recreateMarkers();

    this.fitMapToMarkerBounds();
  }

  ngAfterViewInit(): void {}

  onMapReady(_mapmap: Map) {
    this.map = leafletPrepareMap(_mapmap);

    this.fitMapToMarkerBounds();
  }

  private recreateMarkers() {
    const hotspotWithLatLng = this.hotspots.filter(
      (val) => val.hotspot.latitude && val.hotspot.longitude
    );

    this.marker = hotspotWithLatLng.map((val) =>
      this.createMarkerForHotspot(val)
    );

    const circles = hotspotWithLatLng.map((val) =>
      this.createCircleForHotspot(val)
    );

    this.leafletLayers = this.marker.concat(circles);
  }

  private fitMapToMarkerBounds() {
    leafletFitMapToMarkerBounds(this.map, this.marker);
  }

  private createCircleForHotspot(val: GroupUserHotspot): Circle {
    const hotspot = val.hotspot;
    return circle(
      latLng(hotspot.latitude, hotspot.longitude),
      hotspot.radius_in_meters
    );
  }

  private createMarkerForHotspot(val: GroupUserHotspot) {
    const hotspot = val.hotspot;
    const m = leafletCreateMarker(
      latLng(hotspot.latitude, hotspot.longitude),
      hotspot.title
    );

    const latLngString =
      formatNumber(hotspot.latitude, Helper.getBrowserLang(), "1.3-5") +
      "/" +
      formatNumber(hotspot.longitude, Helper.getBrowserLang(), "1.3-5");

    // somehow, css classes do not seem to work, hence 'style' is used - i am sorry :/
    m.bindPopup(
      '<span class="h6" style="font-weight: 600;">' +
        hotspot.title +
        "</span><br />" +
        '<span class="bold">Adresse</span>: ' +
        hotspot.address +
        "<br />" +
        '<span class="bold">Lat/Lng</span>: ' +
        latLngString +
        "<br />" +
        '<span class="bold">Geteilt von</span>: ' +
        val.user.last_name +
        ", " +
        val.user.first_name +
        "<br />" +
        '<span class="bold">Radius</span>: ' +
        formatNumber(
          hotspot.radius_in_meters,
          Helper.getBrowserLang(),
          "1.0-0"
        ) +
        "m"
    );

    m.bindTooltip("" + hotspot.title);

    return m;
  }
}

class HotspotTableEntry {
  group_id: number;
  hotspot_id: number;
  hotspot_created_at: string;
  hotspot_title: string;
  hotspot_address: string;
  hotspot_latitude: number;
  hotspot_longitude: number;
  hotspot_note: string;
  user_fullname: string;

  static from(groupUserHotspot: GroupUserHotspot) {
    const entry = new HotspotTableEntry();
    entry.group_id = groupUserHotspot.group.id;
    entry.hotspot_id = +groupUserHotspot.hotspot.itemid;
    entry.hotspot_created_at = groupUserHotspot.hotspot.created_at;
    entry.hotspot_title = groupUserHotspot.hotspot.title;
    entry.hotspot_address = groupUserHotspot.hotspot.address;
    entry.hotspot_latitude = groupUserHotspot.hotspot.latitude;
    entry.hotspot_longitude = groupUserHotspot.hotspot.longitude;
    entry.hotspot_note = groupUserHotspot.hotspot.note;
    entry.user_fullname =
      groupUserHotspot.user.last_name + ", " + groupUserHotspot.user.first_name;

    return entry;
  }
}

@Component({
  selector: "app-groups-list-group-hotspots",
  templateUrl: "./groups-list-group-hotspots.component.html",
  styleUrls: ["groups-list-group-hotspots.component.css"],
})
export class GroupsListGroupHotspotsComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  loading: boolean = false;
  updating: boolean = false;

  @Input("groupId") groupId: number;
  @Output("valueChange") valueChange = new EventEmitter();

  hotspots: Array<GroupUserHotspot> = [];

  displayedColumns: string[] = [
    "select",
    "hotspot_created_at",
    "hotspot_title",
    "hotspot_address",
    "user_fullname",
    "hotspot_note",
    "external_link",
  ];
  dataSource = new MatTableDataSource<HotspotTableEntry>([]);
  selection = new SelectionModel<HotspotTableEntry>(true, []);

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  ngOnInit() {
    this.dataSource.sortingDataAccessor = CASE_INSENSITVE_SORTING_DATA_ACCESSOR;

    this.loadHotspots();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.data = this.hotspots.map((h) => HotspotTableEntry.from(h));
  }

  private fetchHotspots(): Observable<Array<GroupUserHotspot>> {
    return this._apiService
      .getGroupHotspots(this.groupId)
      .do((val) => this.onHotspotsLoaded(val));
  }

  private loadHotspots() {
    this.loading = true;
    this.fetchHotspots().subscribe(
      (foo) => {},
      (err) => {
        this.loading = false;
        this.showError(err);
      },
      () => {
        this.loading = false;
      }
    );
  }

  private onHotspotsLoaded(hotspots: Array<GroupUserHotspot>) {
    this.hotspots = hotspots.sort(this.orderHotspotByTitle);

    this.valueChange.emit(hotspots);

    this.selection = new SelectionModel<HotspotTableEntry>(true, []);
    this.dataSource.data = this.hotspots.map((h) => HotspotTableEntry.from(h));
  }

  private orderHotspotByTitle(a: GroupUserHotspot, b: GroupUserHotspot) {
    return a.hotspot.title.localeCompare(b.hotspot.title);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  removeSelectedHotspots(selection: SelectionModel<HotspotTableEntry>) {
    if (selection.selected.length === 0) {
      this.showWarning("groups.manage.hotspots.no_hotspot_selected");
      return;
    }

    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: {
        title: "groups.manage.hotspots.remove_selected_hotspots.confirm.title",
        text: "groups.manage.hotspots.remove_selected_hotspots.confirm.text",
        submitButtonText:
          "groups.manage.hotspots.remove_selected_hotspots.confirm.submitButtonText",
        cancelButtonText:
          "groups.manage.hotspots.remove_selected_hotspots.confirm.cancelButtonText",
      },
    });

    dialogRef.afterClosed().subscribe((confirmed) => {
      if (!confirmed) {
        return;
      }

      const observables = selection.selected.map((b) =>
        this._apiService.removeHotspotFromGroup(b.group_id, b.hotspot_id)
      );

      this.updating = true;
      zip(...observables)
        .pipe(flatMap((foo) => this.fetchHotspots()))
        .subscribe(
          (foo) => {
            this.showSuccess(
              "groups.manage.hotspots.remove_selected_hotspots.success"
            );
          },
          (err) => {
            this.updating = false;
            this.showError(err);
          },
          () => {
            this.updating = false;
          }
        );
    });
  }
}
