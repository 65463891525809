import { AfterViewInit, Component, ViewChild, OnInit } from "@angular/core";
import { DialogConfirmComponent } from "../../common/dialog-confirm/dialog-confirm.component";
import { BaseComponent } from "../../base/base.component";
import { Hotspot } from "../../_models/hotspot.model";

@Component({
  templateUrl: "./update-hotspot.component.html",
  styleUrls: ["update-hotspot.component.css"],
})
export class UpdateHotspotComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  private static NOTE_MAX_CHAR_COUNT: number = 1024;
  private static DEFAULT_RADIUS_IN_METERS: number = 100;

  @ViewChild("addHotspotForm", { static: false }) form;

  loading: boolean = true;

  hotspotId: number;

  hotspot: Hotspot;
  hotspots: Array<Hotspot> = [];

  title: string = "";
  address: string = "";
  note: string = "";
  radius_in_meters: number = UpdateHotspotComponent.DEFAULT_RADIUS_IN_METERS;

  partner: string = "";
  purpose: string = "";

  noteMaxCharacterCount: number = UpdateHotspotComponent.NOTE_MAX_CHAR_COUNT;
  noteRemainingCharacterCount: number = 0;

  ngOnInit() {
    this.hotspotId = +this.route.snapshot.paramMap.get("id");

    this.loading = true;
    this._apiService.getUserHotspotById(this.hotspotId).subscribe(
      (val) => {
        this.hotspot = val;

        this.title = val.title || "";
        this.address = val.address || "";
        this.note = val.note || "";
        this.radius_in_meters = val.radius_in_meters || 100;
        this.partner = val.partner || "";
        this.purpose = val.purpose || "";

        this.hotspots = [this.hotspot];
      },
      (err) => {
        this.loading = false;
        this.showError(err);
      },
      () => {
        this.loading = false;
      }
    );
  }

  ngAfterViewInit() {
    this.form.control.valueChanges.subscribe((values) => {
      this.formControlValueChanged();
    });
    this.setupNewForm();
  }

  formControlValueChanged() {
    this.updateNoteRemainingCharacterCount();
  }

  isSubmitButtonEnabled() {
    return this.title.length > 0 && this.address.length > 0;
  }

  submitForm() {
    if (!this.isSubmitButtonEnabled()) {
      throw new Error("Form is in invalid state and cannot be submitted");
    }

    this.hotspot.title = this.title;
    this.hotspot.note = this.note;
    this.hotspot.address = this.address;
    this.hotspot.radius_in_meters = this.radius_in_meters;
    this.hotspot.partner = this.partner;
    this.hotspot.purpose = this.purpose;

    this.loading = true;

    this._apiService.updateUserHotspot(this.hotspotId, this.hotspot).subscribe(
      (foo) => {
        this.showSuccess("Hotspot updated");
        this.router.navigate(["/hotspots"]);
      },
      (err) => {
        this.loading = false;
        this.showError(err);
      },
      () => {
        this.loading = false;
      }
    );
  }

  onNoteChange() {
    this.updateNoteRemainingCharacterCount();
  }

  onModelRadiusInMetersChanged() {
    this.hotspot.radius_in_meters = this.radius_in_meters;
    this.onNewHotspot(this.hotspot);
  }

  private onNewHotspot(hotspot) {
    this.hotspot = hotspot;
    this.hotspots = [];
    setTimeout(() => (this.hotspots = [this.hotspot]), 10);
  }

  openDeleteHotspotDialog() {
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: {
        title: "hotspots.update.delete.confirm.title",
        text: "hotspots.update.delete.confirm.text",
        submitButtonText: "hotspots.update.delete.confirm.submitButtonText",
        cancelButtonText: "hotspots.update.delete.confirm.cancelButtonText",
      },
    });

    dialogRef.afterClosed().subscribe((confirmed) => {
      if (!confirmed) {
        return;
      }
      this._apiService.deleteUserHotspot(this.hotspotId).subscribe(
        (foo) => {
          this.router.navigate(["/hotspots"]);
          this.showSuccess("hotspots.update.delete.success");
        },
        (err) => {
          this.loading = false;
          this.showError(err);
        },
        () => {
          this.loading = false;
        }
      );
    });
  }

  private updateNoteRemainingCharacterCount() {
    this.noteRemainingCharacterCount =
      this.noteMaxCharacterCount - (this.note || "").length;
  }

  private setupNewForm() {
    this.form.resetForm();

    this.title = "";
    this.note = "";
    this.address = "";
    this.radius_in_meters = UpdateHotspotComponent.DEFAULT_RADIUS_IN_METERS;
    this.partner = "";
    this.purpose = "";

    this.updateNoteRemainingCharacterCount();
  }
}
