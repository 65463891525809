import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ApiService } from "app/api.service";
import { ConnectedHelper } from "app/AutologgConnected/connected-helper";
import { AutoaidConnectedPayload } from "app/AutologgConnected/connected-payload";
import { BaseComponent } from "app/base/base.component";
import { Helper } from "app/base/helper";
import { ConnectedVehicle } from "app/register/register.service";
import { TrackingService } from "app/tracking/tracking-service";
import { OverlayLoadingService } from "app/utils/overlay-loading.service";
import { ProviderType, OemType } from "app/base/provider-oem-type"

@Component({
  selector: "app-autoaid-login",
  templateUrl: "./autoaid-login.component.html",
  styleUrls: ["./autoaid-login.component.css"],
})
export class AutoaidLoginComponent extends BaseComponent implements OnInit {
  private _vin: string;
  @Input()
  public get vin(): string {
    return this._vin;
  }
  public set vin(v: string) {
    this._vin = Helper.processVin(v);
    this.vinChange.emit(this._vin);
  }

  public imei: String;

  public useImeiInput = false;

  @Output()
  vinChange = new EventEmitter<any>();

  @Output()
  onVehiclesUpdated = new EventEmitter<ConnectedVehicle[]>();

  constructor(
    _apiService: ApiService,
    public router: Router,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    public formBuilder: UntypedFormBuilder,
    translate: TranslateService,
    public route: ActivatedRoute,
    private overlayLoading: OverlayLoadingService,
    private connectedHelper: ConnectedHelper,
    _changeDetectorRef: ChangeDetectorRef,
    _tracker: TrackingService
  ) {
    super(
      _apiService,
      router,
      snackBar,
      dialog,
      formBuilder,
      translate,
      route,
      _changeDetectorRef,
      _tracker
    );
  }

  ngOnInit(): void {}

  public get loginTitle(): string {
    if (this.useImeiInput) {
      return this._translate.instant(
        "register.stepTwo.desc_" + OemType.Dongle.toLowerCase() + "_imei"
      );
    }
    return this._translate.instant(
      "register.stepTwo.desc_" + OemType.Dongle.toLowerCase()
    );
  }

  async checkAutoAidLogin() {
    this.overlayLoading.show();

    try {
      if (this.useImeiInput) {
        await this.checkAutoAidImeiLogin();
      } else {
        await this.checkAutoAidVinLogin();
      }
    } finally {
      this.overlayLoading.hide();
    }
  }

  async checkAutoAidVinLogin() {
    const connectedPayload: AutoaidConnectedPayload = {
      vin: this.vin,
    };

    const vehicles = await this.connectedHelper.loadVehicles(
      ProviderType.Native,
      OemType.Dongle,
      connectedPayload
    );

    if (vehicles && vehicles.length == 1 && vehicles[0].virtual) {
      this.useImeiInput = true;
    } else {
      this.onVehiclesUpdated.emit(vehicles);
    }
  }

  async checkAutoAidImeiLogin() {
    const connectedPayload: AutoaidConnectedPayload = {
      imei: this.imei,
    };

    try {
      const vehicles = await this.connectedHelper.loadVehicles(
        ProviderType.Native,
        OemType.Dongle,
        connectedPayload
      );

      this.overlayLoading.hide();
      this.onVehiclesUpdated.emit(vehicles);
    } catch (exception) {
      if (exception.status == 404) {
        this.showError("register.connected.dongleNotFound");
      }
    }
  }
}
