<div class="row m-0">
  <div
    class="h-48 w-48"
    (click)="onMapClick()"
    #map
    leaflet
    [leafletOptions]="leafletOptions"
    [leafletLayers]="leafletLayers"
    (leafletMapReady)="onMapReady($event)"
  ></div>

  <div class="col pt-2">
    <div class="p-2">
      <h5>{{ box.name }}</h5>

      <h7 class="overlineLabel" translate>home.totalDistance</h7>
      <p *ngIf="lastTrip.lat">{{ lastTrip.km || 0 | number: 0 }}&nbsp;km</p>
      <p *ngIf="!lastTrip.lat">{{ "global.noResults" | translate }}</p>

      <h7 class="overlineLabel" translate>home.latestPosition</h7>
      <p *ngIf="lastTrip.lat">
        {{ lastTrip.address }}
      </p>
      <p *ngIf="!lastTrip.lat">{{ "global.noResults" | translate }}</p>
    </div>
  </div>
</div>
