<mat-progress-bar *ngIf="loading" color="accent" mode="indeterminate"></mat-progress-bar>

<div class="container-fluid create">

  <div class="row">
    <div class="col-12">
      <h1 class="title" translate> {{ getTranslation('create.title') }}
      </h1>
    </div>
  </div>

  <!-- back BTN-->
  <div class="row">
    <div class="col-12">
      <div class="button-row">
        <a [routerLink]="['/', { outlets: {dialog: 'add-vehicle' } }]">
          <a mat-button [routerLink]="['/', { outlets: { primary: 'add-vehicle'} }]"
            title="{{ 'global.back' | translate }}">
            <mat-icon class="icon-custom">arrow_back</mat-icon>
            <span translate>global.back</span>
          </a>
        </a>
      </div>
    </div>
  </div>

  <form #addBoxForm="ngForm" (ngSubmit)="submitForm(addBoxForm)" class="login" autocomplete="off">
    <ng-container *ngIf="oemType === OemType.Box" class="right">

      <div class="row">
        <div class="col-12 p-3 pt-2">
          <mat-form-field floatPlaceholder="always" class="full-width" appearance="fill"
            [ngClass]="{'mat-form-field-invalid': !vehicleKeyField.valid}">

            <mat-label translate>boxes.create.fields.vehicleKey.label</mat-label>
            <input matInput id="autologg-key" #vehicleKeyField="ngModel" type="text" [(ngModel)]="vehicleKey" name="vehicleKey"
              placeholder="{{ 'boxes.create.fields.vehicleKey.placeholder' | translate }}" pattern="(?=^.{1,}$).*$"
              required>

            <mat-hint translate>boxes.create.fields.vehicleKey.hint</mat-hint>
            <mat-error *ngIf="vehicleKeyField.dirty" align="end">
              <span *ngIf="!vehicleKeyField.valid" translate>errors.invalidAutologgKey</span>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-6 p-3 pt-2">
          <mat-form-field floatPlaceholder="always" class="m-auto" class="full-width"
            [ngClass]="{'mat-form-field-invalid': nameField.dirty && !nameField.valid }">
            <mat-label>{{ 'boxes.create.fields.name.label' | translate }}</mat-label>
            <input matInput id="box-name"  #nameField="ngModel" type="text" [(ngModel)]="name" name="name"
              placeholder="{{ 'boxes.create.fields.name.placeholder' | translate }}" required>
            <mat-hint translate>boxes.create.fields.name.hint</mat-hint>
            <mat-error *ngIf="nameField.dirty" align="end">
              <span *ngIf="!nameField.valid" translate>errors.invalidName</span>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-12 p-3 pt-2">
          <mat-form-field class="full-width">
            <mat-label translate>boxes.create.fields.description.label</mat-label>
            <textarea matInput #descriptionField="ngModel" [(ngModel)]="description" name="description"
              placeholder="{{ 'boxes.create.fields.description.placeholder' | translate }}" rows="3"
              [maxlength]="descriptionMaxCharacterCount" (ngModelChange)="updateDescriptionRemainingCharacterCount()">
        </textarea>
            <mat-hint>
              <span translate>boxes.create.fields.description.hint</span>{{ descriptionRemainingCharacterCount |
              number:0 }}
            </mat-hint>
          </mat-form-field>
        </div>
      </div>

      <!-- complete BTN for box-->
      <div class="d-flex flex-row justify-content-around flex-wrap m-auto">
        <div class="d-flex mt-3 mb-5">
          <!-- next Step -->
          <button mat-raised-button color="accent" type="submit" [disabled]="!isSubmitButtonEnabled(addBoxForm)"
            class="m-auto">
            <span translate>boxes.create.submit</span>
          </button>
        </div>
      </div>
    </ng-container>


    <ng-container *ngIf="oemType !== OemType.Box">

      <ng-container *ngIf="!connectedVehicles || connectedVehicles.length === 0">
        <app-connected-login #connectedLogin [(connectedCredentials)]="connectedCredentials" [oemType]="oemType"
          [providerType]="providerType" [(vin)]="vin" [isDialog]="isDialog"
          (mfaUpdated)="onMfaUpdated($event)"
          (oauth2Completed)="onOauth2Completed($event)" (oauth2Failed)="onOauth2Failed($event)" (onVehiclesUpdated)="onVehiclesUpdated($event)"></app-connected-login>
      </ng-container>

      <ng-container *ngIf="connectedVehicles && connectedVehicles.length > 0">
        <div class="row mt-3 mb-3 justify-content-center">
          <div class="col-6">
            <mat-form-field floatPlaceholder="always" class="full-width"
              [ngClass]="{'mat-form-field-invalid': !plateField.valid  && plateField.dirty}">
              <mat-label translate>boxes.vehicles.create.fields.plate.label</mat-label>
              <input matInput id='license-plate' #plateField="ngModel" type="text" [(ngModel)]="plate" name="plate"
                placeholder="{{ 'boxes.vehicles.create.fields.plate.placeholder' | translate }}" maxlength="100"
                required autocomplete="off">
              <mat-error *ngIf="plateField.dirty" align="end">
                <span *ngIf="!plateField.valid">{{ 'errors.invalidPlate' | translate}}</span>
              </mat-error>
              <mat-hint align="end" translate>boxes.vehicles.create.fields.plate.hint</mat-hint>
            </mat-form-field>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-6">
            <mat-form-field floatPlaceholder="always" class="full-width"
              [ngClass]="{'mat-form-field-invalid': !plateField.valid  && plateField.dirty}">
              <mat-label translate>boxes.vehicles.connected.selectVehicle.name</mat-label>
              <mat-select placeholder="{{ 'boxes.vehicles.connected.selectVehicle.placeholder' | translate }}"
                name="connectedVehicle" [(ngModel)]="connectedVehicle" required>
                <mat-option *ngFor="let vehicle of connectedVehicles" [value]="vehicle">
                  {{vehicle.name}} <ng-container *ngIf="vehicle.alreadyUsed">{{ 'boxes.vehicles.connected.alreadyUsed' |
                    translate }}</ng-container>
                  <ng-container *ngIf="!vehicle.compatible">{{ 'boxes.vehicles.connected.propablyNotCompatible' |
                    translate }}</ng-container>
                </mat-option>
              </mat-select>

              <ng-container *ngIf="!connectedVehicles || connectedVehicles.length == 0">
                <mat-label translate>boxes.vehicles.connected.noVehiclesFound</mat-label>
              </ng-container>
            </mat-form-field>

            <ng-container *ngIf="connectedVehicle && !connectedVehicle.compatible">
              <div class="alert alert-warning" style="margin: 0; border-radius: 0;">
                <p [innerHTML]="vehicleNotCompatibleMessage"></p>
              </div>
            </ng-container>

          </div>
        </div>
        <div class="row">
          <div class="col-12 text-center mt-2">
            <button mat-raised-button color="accent" type="submit">
              <span translate>connected_boxes.create.submit</span>
            </button>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </form>


  <div class="row mt-5"></div>
</div>