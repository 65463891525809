<mat-spinner class="mt-3" *ngIf="loading" style="margin:0 auto;" mode="indeterminate"></mat-spinner>

<div class="container-fluid" *ngIf="!loading">

  <!-- put cars above buttons to prevent datepickers from being "cut off" -->

  <div class="row mt-3">
    <div class="col-12">
      <div class="alert alert-info" *ngIf="!(dataSource.data.length > 0)">
        <span translate>global.noResults</span>
      </div>

      <div [hidden]="!(dataSource.data.length > 0)">

        <mat-form-field class="full-width">
          <input matInput (keyup)="applyFilter($event.target.value)" placeholder="{{ 'global.Search' | translate }}">
        </mat-form-field>

        <table mat-table matSort [dataSource]="dataSource" class="mt-1 mb-1 full-width">

          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()" data-testid="checkbox-select-all">
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
              <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                [checked]="selection.isSelected(row)">
              </mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="oem_type">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'groups.manage.boxes.table.provider_type' | translate }} </th>
            <td mat-cell *matCellDef="let element"> 
              <ng-container *ngIf="element.oem_type === OemType.Box">{{ 'groups.manage.boxes.box' | translate  }}</ng-container>
              <ng-container *ngIf="element.oem_type !== OemType.Box">{{ 'groups.manage.boxes.connected_box' | translate  }}</ng-container>
            </td>
          </ng-container>
          <ng-container matColumnDef="box_vehicle_id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'groups.manage.boxes.table.box_vehicle_id' |
              translate }} </th>
            <td mat-cell *matCellDef="let element"> {{ element.box_vehicle_id }} </td>
          </ng-container>
          <ng-container matColumnDef="box_name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'groups.manage.boxes.table.box_name' | translate
              }} </th>
            <td mat-cell *matCellDef="let element">
              <a routerLink="/groups/{{groupId}}/manage/box/{{element.box_vehicle_id}}" title="{{ 'groups.manage.box.title' | translate }}">
                {{ element.box_name }}
              </a>
            </td>
          </ng-container>
          <ng-container matColumnDef="user_fullname">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'groups.manage.boxes.table.user_fullname' |
              translate }} </th>
            <td mat-cell *matCellDef="let element"> {{ element.user_fullname }}</td>
          </ng-container>
          <ng-container matColumnDef="box_description">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'groups.manage.boxes.table.box_description' |
              translate }} </th>
            <td mat-cell *matCellDef="let element">
              {{element.box_description}}
              <i *ngIf="!element.box_description" translate>global.no_description</i>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;">
          </tr>
        </table>

        <mat-paginator [pageSizeOptions]="[50, 100, 200]" showFirstLastButtons></mat-paginator>
      </div>
    </div>
  </div>

  <form #exportForm="ngForm" class="form-container" [formGroup]="exportOptions">
    <div class="row mt-3">
      <div class="col-12 col-md-6">
        <mat-form-field class="full-width" appearance="standard" (click)="dateStart.open()">
          <mat-label translate>export.dateFrom</mat-label>
          <input matInput formControlName="dateStart" [matDatepicker]="dateStart" [max]="dateStartMaxDate"
            (ngModelChange)="dateEndMinDate = $event" required data-testid="date-start">
          <mat-datepicker-toggle matSuffix [for]="dateStart"></mat-datepicker-toggle>
          <mat-datepicker type="datetime" touchUi #dateStart></mat-datepicker>

        </mat-form-field>
      </div>

      <div class="col-12 col-md-6">
        <mat-form-field class="full-width" appearance="standard" (click)="dateEnd.open()">
          <mat-label translate>export.dateTo</mat-label>
          <input matInput formControlName="dateEnd" [matDatepicker]="dateEnd" [min]="dateEndMinDate" [max]="dateEndMaxDate"
            (ngModelChange)="dateStartMaxDate = $event" required data-testid="date-end">
          <mat-datepicker-toggle matSuffix [for]="dateEnd"></mat-datepicker-toggle>
          <mat-datepicker type="datetime" touchUi #dateEnd></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </form>

  <div class="row">
    <div class="col-12 col-md-4 offset-md-4 p-3">
      <mat-label translate>export.type</mat-label>
      <mat-select name="tripType" [(ngModel)]="tripType">
        <mat-option *ngFor="let type of tripTypes" [value]="type.id">
          {{ type.translation_key | translate }}
        </mat-option>
      </mat-select>
    </div>
  </div>

  <div class="container">
    <div class="row justify-content-md-center">
      <div class="col col-lg-2"></div>
      <div class="col-md-auto">
        <mat-checkbox [(ngModel)]="showDriver" name="showDriver" data-testid="checkbox-show-driver-in-group"> {{ 'export.showDriver' | translate }}</mat-checkbox>
      </div>
      <div class="col col-lg-2"></div>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-12 col-md-4 offset-md-2 p-2 p-md-5 text-center">
      <button mat-raised-button color="primary" (click)="startExport('csv')" [disabled]="!isDownloadButtonEnabled()">
        <span translate>export.downloadCsv</span>
      </button>
    </div>
    <div class="col-12 col-md-4 p-2 p-md-5 text-center">
      <button mat-raised-button color="primary" (click)="startExport('pdf')" [disabled]="!isDownloadButtonEnabled()">
        <span translate>export.downloadPdf</span>
      </button>
    </div>
  </div>
</div>

<div class="minheight-10rem">
  <!-- this element exists so the loader does not display flickering scrollbar -->
</div>