import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-oauth-login",
  templateUrl: "./oauth-login.component.html",
  styleUrls: ["./oauth-login.component.css"],
})
export class OauthLoginComponent implements OnInit {
  private _email: string;
  private _password: string;

  @Input()
  public get emailInput(): string {
    return this._email;
  }
  public set emailInput(v: string) {
    this._email = v;
    this.emailChange.emit(v);

    this.credentialsChange.emit({
      email: this._email,
      password: this._password,
      isValid: this.isValid,
    });
  }

  public isValid() {
    return this._email && this._password;
  }

  @Output()
  emailChange: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  public get passwordInput(): string {
    return this._password;
  }
  public set passwordInput(v: string) {
    this._password = v;
    this.passwordChange.emit(v);

    this.credentialsChange.emit({
      email: this._email,
      password: this._password,
      isValid: this.isValid(),
    });
  }

  @Output()
  passwordChange: EventEmitter<string> = new EventEmitter<string>();

  private _credentials: any;
  @Input()
  public get credentials(): any {
    return this._credentials;
  }
  public set credentials(v: any) {
    this._credentials = v;

    if (v) {
      if (v.email) {
        this._email = v.email;
      }
      if (v.password) {
        this._password = v.password;
      }
    }
  }

  @Output()
  credentialsChange: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {
    this.credentialsChange.emit({
      email: this._email,
      password: this._password,
      isValid: this.isValid(),
    });
  }
}
