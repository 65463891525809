import {
  AfterViewInit,
  Component,
  ViewChild,
  OnInit,
  ChangeDetectorRef,
} from "@angular/core";
import { CarModel } from "../../../_models/car-model.model";
import { CarSeries } from "../../../_models/car-series.model";
import { CarOem } from "../../../_models/car-oem.model";
import { Box } from "../../../_models/box.model";
import { BaseBoxesComponent } from "app/boxes/base-boxes.component";
import { ProviderType, OemType } from "app/base/provider-oem-type"
import { ApiService } from "app/api.service";
import { Router, ActivatedRoute } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { UntypedFormBuilder } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { RegisterService, VehicleBase } from "app/register/register.service";
import { OverlayLoadingService } from "app/utils/overlay-loading.service";
import { TrackingService } from "app/tracking/tracking-service";
import { TrackingConstants } from "app/tracking/tracking-constants";
import { DialogService } from "../../../services/dialog.service";

import { Steps } from "../../../services/first-steps";
@Component({
  templateUrl: "./create-vehicle.component.html",
})
export class CreateVehicleComponent
  extends BaseBoxesComponent
  implements OnInit, AfterViewInit {
  @ViewChild("addVehicleForm", { static: false }) form;
  vehicleId: number;
  box: Box;

  loading: boolean = true;

  public carOem: string;
  public carSeries: string;
  public carModel: CarModel = new CarModel();
  public plate: string;

  public vehicleFreeField: string;
  vehicleKey: string;

  public carsOemList: Array<CarOem>;
  public carsSeriesList: Array<CarSeries>;
  public carsModelList: Array<CarModel>;

  myVehicleIsNotInList: boolean = false;

  public get isFirstVehicle(): boolean {
    if (this.route.snapshot.data.isFirst === undefined) {
      return false;
    }
    return !!this.route.snapshot.data.isFirst;
  }

  constructor(
    _apiService: ApiService,
    router: Router,
    snackBar: MatSnackBar,
    dialog: MatDialog,
    formBuilder: UntypedFormBuilder,
    translate: TranslateService,
    route: ActivatedRoute,
    _changeDetectorRef: ChangeDetectorRef,
    _tracker: TrackingService,
    private registerService: RegisterService,
    private overlayLoading: OverlayLoadingService,
    private _dialogService: DialogService
  ) {
    super(
      _apiService,
      router,
      snackBar,
      dialog,
      formBuilder,
      translate,
      route,
      _changeDetectorRef,
      _tracker
    );
  }

  ngOnInit() {
    this.vehicleId = +this.route.snapshot.paramMap.get("id");
    this.vehicleFreeField = "";

    this.loading = true;
    this._apiService.getUserBoxById(this.vehicleId).subscribe(
      (box) => {
        this.box = box;
        this.vehicleKey = box.vehicle_key;
      },
      (err) => {
        this.loading = false;
        this.showError(err);
      },
      () => {
        this.loading = false;
      }
    );
  }

  ngAfterViewInit() {
    this.form.control.valueChanges.subscribe((values) => {
      this.formControlValueChanged();
    });
    this.setupNewVehicleForm();
  }

  navigateBack() {
    this.router.navigate([".."], { relativeTo: this.route });
  }

  formControlValueChanged() { }

  isSubmitButtonEnabled() {
    if (this.box && this.box.isConnected) {
      return !!this.plate;
    }

    const isModelSelected = this.hasUserSelectedValidModelOption();
    return isModelSelected && !!this.plate && !!this.vehicleKey;
  }

  setupNewVehicleForm() {
    this.form.resetForm();

    this.plate = "";

    this.getOems();
  }

  async submitNewVehicle() {
    if (!this.isSubmitButtonEnabled()) {
      throw new Error("Form is in invalid state and cannot be submitted");
    }
    if (this.box && !this.box.isConnected) {
      if (!this.hasUserSelectedValidModelOption()) {
        throw new Error("User has not selected a valid model");
      }
    }

    if (this.box && this.box.isConnected) {
      this.overlayLoading.show();

      try {
        const payload: VehicleBase = {
          plate: this.plate,
          name: this.box.name,
        };

        const mogreeResponse = await this.registerService.updateModel(
          payload,
          this.vehicleId
        );
        this.router.navigate(["/boxes/" + this.vehicleId + "/vehicles"]);
        this._dialogService.changeMessage(Steps.thirdStep);

        this.setupNewVehicleForm();

        this._apiService.onAuthDataReceived(mogreeResponse);

        this._apiService.refreshProfile();
      } catch (error) {
        this.overlayLoading.hide();
        this.showError(error);
      } finally {
        this.overlayLoading.hide();
      }

      return;
    }

    const selectedModel = this.getSelectedModelOrThrow();

    if (this.myVehicleIsNotInList) {
      await this._tracker.track(TrackingConstants.Events.RegisterModelType, {
        [TrackingConstants.EventProperties.ModelType]: this.vehicleFreeField,
        [TrackingConstants.EventProperties.IsFreeText]: true,
      });
    } else {
      await this._tracker.track(TrackingConstants.Events.RegisterModelType, {
        [TrackingConstants.EventProperties.ModelType]: selectedModel.model_name,
        [TrackingConstants.EventProperties.IsFreeText]: false,
      });
    }

    this._apiService
      .postUserVehicle(
        selectedModel.itemid,
        selectedModel.model_name,
        this.vehicleKey,
        this.plate,
        this.vehicleFreeField
      )
      .subscribe(
        (foo) => {
          this.showSuccess("Vehicle created");
          this.setupNewVehicleForm();
          this.router.navigate(["/boxes/" + this.vehicleId + "/vehicles"]);
          this._dialogService.changeMessage(Steps.thirdStep);
        },
        (err) => {
          this.showError(err);
        }
      );
  }

  getOems() {
    this._apiService.getCarsOem().subscribe(
      (cars) => {
        this.resetOemSeriesModelSelection();
        this.carsOemList = cars;
      },
      (err) => {
        this.showError(err);
      }
    );
  }

  onChangeOem() {
    if (this.carOem) {
      this._apiService.getCarsSeries(this.carOem).subscribe(
        (cars) => {
          this.carSeries = null;
          this.carModel = null;
          this.carsSeriesList = cars;
        },
        (err) => {
          this.showError(err);
        }
      );
    }
  }

  onChangeSeries() {
    if (this.carOem && this.carSeries) {
      this._apiService.getCarsModels(this.carOem, this.carSeries).subscribe(
        (cars) => {
          this.carModel = null;
          this.carsModelList = cars;
        },
        (err) => {
          this.showError(err);
        }
      );
    }
  }

  onChangeModel() { }

  formValueMyVehicleIsNotInListChanged() {
    this.resetOemSeriesModelSelection();
  }

  resetOemSeriesModelSelection() {
    this.carOem = null;
    this.carSeries = null;
    this.carModel = null;
  }

  isModelSelectedFromList() {
    return !!(this.carOem && this.carSeries && this.carModel);
  }

  isMyVehicleIsNotInListChecked() {
    return this.myVehicleIsNotInList === true && !!this.vehicleFreeField;
  }

  hasUserSelectedValidModelOption() {
    if (!this.vehicleKey) {
      // connected
      return true;
    }

    return (
      this.isModelSelectedFromList() || this.isMyVehicleIsNotInListChecked()
    );
  }

  getSelectedModelOrThrow() {
    if (!this.hasUserSelectedValidModelOption()) {
      throw new Error("User has not selected a valid model");
    }

    if (this.isMyVehicleIsNotInListChecked()) {
      return {
        itemid: "UNKNOWN_UNKNOWN_UNKNOWN",
        model_name: "- Nicht gelistetes Modell -",
      };
    }

    return this.carModel;
  }
}
