import { NgModule } from '@angular/core';
import { AutoLoggCommonModule } from '../common/autologg-common.module';
import { AutoLoggBoxesRouting, AutoLoggConnectedBoxesRouting } from './boxes.routing';

import { BoxesComponent } from './boxes.component';
import { CreateBoxComponent } from './create-box/create-box.component';
import { UpdateBoxComponent } from './update-box/update-box.component';
import { UpdateMileageComponent } from './update-mileage/update-mileage.component';
import { VehiclesComponent } from './vehicles/vehicles.component';
import { CreateVehicleComponent } from './vehicles/create-vehicle/create-vehicle.component';
import { ConnectedLoginModule } from 'app/AutologgConnected/connected-login/connected-login.module';
import { UpdateBoxAccessComponent } from './update-box-access/update-box-access.component';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    AutoLoggCommonModule,
    AutoLoggBoxesRouting,
    AutoLoggConnectedBoxesRouting,
    ConnectedLoginModule,
    FormsModule,
    TranslateModule
  ],
  declarations: [
    BoxesComponent,
    CreateBoxComponent,
    UpdateBoxComponent,
    UpdateMileageComponent,
    VehiclesComponent,
    CreateVehicleComponent,
    UpdateBoxAccessComponent,
  ],
  exports: []
})
export class AutoLoggBoxesModule { }
