// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  backendUrl: "https://app-test.autologg.com/api/v1/",
  backendV2Url: "https://api-test.autologg.com/v2/",
  mapTilerApiKey: "HPOTqYcYhsH0xlGjyTnz",
  gaTrackingId: "G-WM1X0NYLER",
  updateCredentialsEnabled: true,

  hmOAuthUrl: "https://owner-panel.high-mobility.com/oauth/new",
  hmClientId: "63fc598a-3679-4db1-9393-8f2e65ed95ee",
  hmAppId: "D7E2A1AD3A65FAE533C3E095",

  enableEmulator: false,
  enableAutoaid: true,
  enableExperiment: true,

  idServer: "https://id.autologg.com",
  realm: "staging-autologg",
};
