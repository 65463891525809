<mat-tab-group>
  <mat-tab label="{{ 'user.account.settings.section.app.title' | translate }}">
    <form #formSettingsEdit="ngForm" (submit)="submitForm()">

      <div class="container-fluid">
        <div class="row p-4">
          <div class="col-12">
            <h3 translate>user.account.settings.section.app.sorting.title</h3>
          </div>

          <div class="col-12 col-md-6">
            <mat-form-field class="full-width hide-underline">
              <mat-select class="w-100" placeholder="{{ 'user.account.settings.openTripSortDirectionAsc.label' | translate }}" [(ngModel)]="settings.openTripSortDirectionAsc"
                name="openTripSortDirectionAsc">
                <mat-option *ngFor="let val of [false, true]" [value]="val">
                  {{( val ? 'user.account.settings.openTripSortDirectionAsc.option.true' : 'user.account.settings.openTripSortDirectionAsc.option.false'
                  ) | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-12 col-md-6">
            <mat-form-field class="full-width hide-underline">
              <mat-select class="w-100" placeholder="{{ 'user.account.settings.closedTripSortDirectionAsc.label' | translate }}" [(ngModel)]="settings.closedTripSortDirectionAsc"
                name="closedTripSortDirectionAsc">
                <mat-option *ngFor="let val of [false, true]" [value]="val">
                  {{( val ? 'user.account.settings.closedTripSortDirectionAsc.option.true' : 'user.account.settings.closedTripSortDirectionAsc.option.false'
                  ) | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-12">
            <div class="p-2 text-center">
              <button mat-raised-button color="accent" type="submit" [disabled]="!formSettingsEdit.form.valid" translate>
                <span translate>user.account.settings.submit.title</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </mat-tab>

  <mat-tab label="{{ 'global.features' | translate }}">
    <app-settings-features-form></app-settings-features-form>
  </mat-tab>
</mat-tab-group>
<!--mat-tab label="{{ 'user.account.settings.section.beta.title' | translate }}">
  <div class="container-fluid">
    <div class="row">
      {{ settings | json }}

      <div class="col-12 col-md-6">
        <mat-form-field class="full-width hide-underline">
          <mat-select class="w-100" placeholder="{{ 'user.account.settings.enableBetaMode.label' | translate }}" [(ngModel)]="settings.enableBetaMode"
            name="enableBetaMode">
            <mat-option *ngFor="let val of [true, false]" [value]="val">
              {{( val ? 'user.account.settings.enableBetaMode.option.true' : 'user.account.settings.enableBetaMode.option.false' ) | translate
              }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-12 col-md-6">
        <mat-form-field class="full-width hide-underline">
          <mat-select class="w-100" placeholder="{{ 'user.account.settings.enablePushNotification.label' | translate }}" [(ngModel)]="settings.enablePushNotification"
            name="enablePushNotification">
            <mat-option *ngFor="let val of [true, false]" [value]="val">
              {{( val ? 'user.account.settings.enablePushNotification.option.true' : 'user.account.settings.enablePushNotification.option.false'
              ) | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
</mat-tab-->