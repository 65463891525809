import { NgModule } from '@angular/core';
import { AutoLoggCommonModule } from '../common/autologg-common.module';
import { AutoLoggAccountRouting } from './account.routing';

import { AccountComponent } from './account.component';
import { SettingsComponent } from './settings.component';
import { FeaturesComponent } from './settings/features.component';
import { DialogPasswordChangeComponent, ProfileComponent } from './profile.component';

@NgModule({
    imports: [
        AutoLoggCommonModule,
        AutoLoggAccountRouting,
    ],
    declarations: [
        AccountComponent,
        ProfileComponent,
        SettingsComponent,
        FeaturesComponent,
        DialogPasswordChangeComponent
    ],
    exports: []
})
export class AutoLoggAccountModule { }
