import {
  Component,
  OnInit,
  AfterViewInit,
  Input,
  Output,
  ViewChild,
  EventEmitter,
} from "@angular/core";
import { BaseComponent } from "app/base/base.component";
import { GroupUserHotspot } from "app/_models/group.model";
import { Hotspot } from "app/_models/hotspot.model";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { SelectionModel } from "@angular/cdk/collections";
import { forkJoin, zip } from "rxjs";
import { CASE_INSENSITVE_SORTING_DATA_ACCESSOR } from "app/common/table-common.util";
import { flatMap } from "rxjs/operators";
import { HotspotTableEntry } from "app/_models/hotspot-table-entry";

@Component({
  selector: "app-manage-group-membership-hotspots",
  templateUrl: "./manage-group-membership-hotspots.component.html",
})
export class ManageGroupMembershipHotspotsComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  loading = false;
  updating = false;

  @Input("userId") userId: number;
  @Input("groupId") groupId: number;
  @Output("onSharedHotspotsCount") onSharedHotspotsCount = new EventEmitter();
  @Output("onShareableHotspotsCount") onShareableHotspotsCount =
    new EventEmitter();

  groupUserHotspots: Array<GroupUserHotspot> = [];
  userHotspots: Array<Hotspot> = [];

  sharedUserHotspots: Array<Hotspot> = [];
  shareableUserHotspots: Array<Hotspot> = [];

  displayedColumns: string[] = [
    "select",
    "shared",
    "created_at",
    "title",
    "address",
    "note",
    "external_link",
  ];
  dataSource = new MatTableDataSource<HotspotTableEntry>([]);
  selection = new SelectionModel<HotspotTableEntry>(true, []);

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  loadHotspots = () =>
    forkJoin(
      this._apiService.getUserGroupHotspot(this.groupId),
      this._apiService.getUserHotspots()
    ).do((array) => {
      this.groupUserHotspots = array[0];
      this.userHotspots = (array[1] || []).sort(this.orderHotspotByTitle);

      this.sharedUserHotspots = this.groupUserHotspots.map((gh) => gh.hotspot);

      const sharedHotspotIds = this.groupUserHotspots.map(
        (gh) => +gh.hotspot.itemid
      );
      this.shareableUserHotspots = this.userHotspots.filter(
        (box) => sharedHotspotIds.indexOf(+box.itemid) < 0
      );

      this.onSharedHotspotsCount.emit(this.sharedUserHotspots.length);
      this.onShareableHotspotsCount.emit(this.shareableUserHotspots.length);

      this.dataSource.data = this.userHotspots.map((h) => {
        const entry = HotspotTableEntry.fromHotspot(h);
        entry.shared = sharedHotspotIds.indexOf(entry.id) >= 0;

        return entry;
      });

      this.selection = new SelectionModel<HotspotTableEntry>(true, []);
      setTimeout(() => {
        // only the lord knows, why this must be wrapped within setTimeout - otherwise it does not work.
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }, 0);
    });

  ngOnInit() {
    this.loading = true;
    this.dataSource.sortingDataAccessor = CASE_INSENSITVE_SORTING_DATA_ACCESSOR;
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.data = [];

    this.load();
  }

  load() {
    this.loading = true;
    this.loadHotspots().subscribe(
      (foo) => {},
      (err) => {
        this.loading = false;
        this.showError(err);
      },
      () => {
        this.loading = false;
      }
    );
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  private reloadHotspots() {
    this.updating = false;
    return this.loadHotspots().subscribe(
      (foo) => {},
      (err) => {
        this.updating = false;
        this.showError(err);
      },
      () => {
        this.updating = false;
      }
    );
  }

  shareSelectedHotspots(selection: SelectionModel<HotspotTableEntry>) {
    if (selection.selected.length === 0) {
      this.showWarning("groups.membership.manage.hotspots.no_hotspot_selected");
      return;
    }

    const observables = selection.selected
      .filter((h) => !h.shared)
      .map((h) =>
        this._apiService.userShareHotspotWithGroup(this.groupId, h.id)
      );

    this.updating = true;
    zip(...observables)
      .pipe(flatMap((foo) => this.loadHotspots()))
      .subscribe(
        (foo) => {
          this.showSuccess(
            "groups.membership.manage.hotspots.share_selected_hotspots.success"
          );
        },
        (err) => {
          this.updating = false;
          this.showError(err);

          this.reloadHotspots();
        },
        () => {
          this.updating = false;
        }
      );
  }

  unshareSelectedHotspots(selection: SelectionModel<HotspotTableEntry>) {
    if (selection.selected.length === 0) {
      this.showWarning(
        "groups.memebership.manage.hotspots.no_hotspot_selected"
      );
      return;
    }

    const observables = selection.selected
      .filter((h) => !!h.shared)
      .map((h) =>
        this._apiService.userUnshareHotspotWithGroup(this.groupId, h.id)
      );

    this.updating = true;
    zip(...observables)
      .pipe(flatMap((foo) => this.loadHotspots()))
      .subscribe(
        (foo) => {
          this.showSuccess(
            "groups.membership.manage.hotspots.unshare_selected_hotspots.success"
          );
        },
        (err) => {
          this.updating = false;
          this.showError(err);

          this.reloadHotspots();
        },
        () => {
          this.updating = false;
        }
      );
  }

  isShareHotspotButtonEnabled(selection: SelectionModel<HotspotTableEntry>) {
    return selection.selected.filter((h) => !h.shared).length;
  }

  isUnshareHotspotButtonEnabled(selection: SelectionModel<HotspotTableEntry>) {
    return selection.selected.filter((h) => !!h.shared).length;
  }

  private orderHotspotByTitle(a: Hotspot, b: Hotspot) {
    return a.title.localeCompare(b.title);
  }
}
