import { Component, Input } from "@angular/core";

@Component({
  selector: "app-chart-bar",
  templateUrl: "./chart-bar.component.html",
  styleUrls: ["./chart-bar.component.css"],
})
export class ChartBarComponent {
  @Input() isEmpty: boolean;

  colorScheme = {
    domain: ["rgba(29, 160, 240, 1)"],
  };

  dataLabelFormatting = (value) => {
    if (value == 0) return "";

    return `${value} km`;
  };

  @Input() barChartData: any[];
}
