import { AfterViewInit, Component, ViewChild, OnInit } from "@angular/core";
import { BaseComponent } from "../../base/base.component";
import { Hotspot } from "../../_models/hotspot.model";
import "rxjs/add/operator/debounceTime";
import { Marker } from "leaflet";
import * as L from "leaflet";

import { formatNumber } from "@angular/common";
import { Helper } from "app/base/helper";
import { TrackingConstants } from "app/tracking/tracking-constants";

@Component({
  templateUrl: "./create-hotspot.component.html",
  styleUrls: ["create-hotspot.component.css"],
})
export class CreateHotspotComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  private static NOTE_MAX_CHAR_COUNT: number = 1024;
  private static DEFAULT_RADIUS_IN_METERS: number = 100;

  @ViewChild("addHotspotForm", { static: false }) form;

  loading: boolean = false;
  updating: boolean = false;

  hotspot: Hotspot;

  hotspots: Array<Hotspot> = [];

  title: string = "";
  address: string = "";
  note: string = "";
  latitude: number = 0;
  longitude: number = 0;
  radius_in_meters: number = CreateHotspotComponent.DEFAULT_RADIUS_IN_METERS;

  partner: string = "";
  purpose: string = "";

  noteMaxCharacterCount: number = CreateHotspotComponent.NOTE_MAX_CHAR_COUNT;
  noteRemainingCharacterCount: number = 0;

  ngOnInit() {
    this.onNewHotspot(new Hotspot());
  }

  ngAfterViewInit() {
    this.form.control.valueChanges.subscribe((values) => {
      this.formControlValueChanged();
    });
    this.setupNewForm();
  }

  formControlValueChanged() {
    this.updateNoteRemainingCharacterCount();
  }

  onModelRadiusInMetersChanged() {
    this.hotspot.radius_in_meters = this.radius_in_meters;
    this.onNewHotspot(this.hotspot);
  }

  isSubmitButtonEnabled() {
    return (
      this.title.length > 0 &&
      this.address.length > 0 &&
      this.latitude !== 0 &&
      this.longitude !== 0
    );
  }

  onMapClicked(event) {
    const position = event.latlng;

    this.updateFromPositionEvent(position);
  }

  onMarkerDragEnd(event) {
    const marker: Marker = event.target;
    const position = marker.getLatLng();

    this.updateFromPositionEvent(position);
  }

  private updateFromPositionEvent(position: L.LatLng) {
    this.latitude = position.lat;
    this.longitude = position.lng;

    this.hotspot.latitude = this.latitude;
    this.hotspot.longitude = this.longitude;

    this.onNewHotspot(this.hotspot);

    this.tryGeocodeAddressOfPosition(position);
  }

  private tryGeocodeAddressOfPosition(position: L.LatLng) {
    this.updating = true;
    L.Control.Geocoder.nominatim().reverse(position, 13, (result) => {
      if (result && result[0]) {
        const geocodeResult = result[0];
        this.createHotspotFromGeocodeResult(geocodeResult, {
          useCenter: false,
        });
      }

      this.updating = false;
      this.detectChanges();
    });
  }

  private onNewHotspot(hotspot) {
    this.hotspot = hotspot;
    this.hotspots = [hotspot];

    this.detectChanges();
  }

  onGeocoderMarkgeocode(geocodeResult: object) {
    this.createHotspotFromGeocodeResult(geocodeResult, { useCenter: true });
  }

  private createHotspotFromGeocodeResult(geocodeResult: object, options = {}) {
    const _options = Object.assign(
      {
        useAddress: true,
        useCenter: false,
      },
      options
    );

    const hotspot = new Hotspot();
    hotspot.title = this.hotspot.title || "";
    hotspot.title = this.hotspot.address || "";
    hotspot.latitude = this.hotspot.latitude;
    hotspot.longitude = this.hotspot.longitude;
    hotspot.radius_in_meters = this.hotspot.radius_in_meters || 100;
    hotspot.partner = this.hotspot.partner;
    hotspot.purpose = this.hotspot.purpose;

    // see https://github.com/perliedman/leaflet-control-geocoder#properties
    try {
      /*
      country: "Österreich"
      country_code: "at"
      county: "Wels-Land"
      house_number: "27"
      postcode: "4600"
      road: "Mühlstraße"
      state: "Oberösterreich"
      village: "Marchtrenk"
      */
      if (_options.useAddress) {
        const address = geocodeResult["properties"].address;
        this.address = [
          [address.road, address.house_number].filter((val) => !!val).join(" "),
          [address.postcode, address.village].filter((val) => !!val).join(" "),
          address.country,
        ]
          .filter((val) => !!val)
          .join(", ");

        hotspot.address = this.address;
      }
    } catch (e) {
      // empty on purpose
    }

    try {
      if (_options.useCenter) {
        this.latitude = geocodeResult["center"].lat;
        this.longitude = geocodeResult["center"].lng;
        hotspot.latitude = this.latitude;
        hotspot.longitude = this.longitude;
      }
    } catch (e) {
      // empty on purpose
    }

    this.onNewHotspot(hotspot);
  }

  submitForm() {
    if (!this.isSubmitButtonEnabled()) {
      throw new Error("Form is in invalid state and cannot be submitted");
    }

    const hotspot = new Hotspot();
    hotspot.title = this.title;
    hotspot.note = this.note;
    hotspot.address = this.address;
    hotspot.latitude = this.latitude;
    hotspot.longitude = this.longitude;
    hotspot.radius_in_meters = this.radius_in_meters;
    hotspot.partner = this.partner;
    hotspot.purpose = this.purpose;

    this.loading = true;

    this._apiService.createUserHotspot(hotspot).subscribe(
      (foo) => {
        this.showSuccess("Hotspot created");
        this.router.navigate(["/hotspots"]);
        this._tracker.track(TrackingConstants.Events.HotspotCreated);
      },
      (err) => {
        this.loading = false;
        this.showError(err);
      },
      () => {
        this.loading = false;
      }
    );
  }

  onNoteChange() {
    this.updateNoteRemainingCharacterCount();
  }

  onRadiusChange() {
    // redraw map debounced!
  }

  private updateNoteRemainingCharacterCount() {
    this.noteRemainingCharacterCount =
      this.noteMaxCharacterCount - (this.note || "").length;
  }

  private setupNewForm() {
    this.form.resetForm();

    this.title = "";
    this.note = "";
    this.address = "";
    this.latitude = 0;
    this.longitude = 0;
    this.radius_in_meters = CreateHotspotComponent.DEFAULT_RADIUS_IN_METERS;
    this.partner = "";
    this.purpose = "";

    this.updateNoteRemainingCharacterCount();
  }
}
