import { ModuleWithProviders } from "@angular/core";

import { RouterModule, Routes } from '@angular/router';
import { KeyCloakAutologgAuthGuard } from './keycloak-auth-guard.service'
import { HomeComponent } from './home/home.component';

import { ExportComponent } from "./export/export.component";
import { AppModule } from "./app.module";
import { CreateConnectedComponent } from "./AutologgConnected/create-connected/create-connected.component";

import { OAuth2RedirectComponent } from "./oauth2-redirect/oauth2-redirect.component";
import { LockedAutologgAuthGuard } from "./locked-auth-guard.service";

const routes: Routes = [
  { path: 'oauth2', component: OAuth2RedirectComponent },
  { path: 'export', component: ExportComponent, canActivate: [KeyCloakAutologgAuthGuard] },
  { path: 'statistics', component: HomeComponent, canActivate: [LockedAutologgAuthGuard] },
  { path: 'add-vehicle', component: CreateConnectedComponent, canActivate: [LockedAutologgAuthGuard] },
  {
    path: 'unassigned-trips',
    loadChildren: () => import('./unassigned-trips/unassigned-trips.module').then(m => m.AutoLoggUnassignedTripsModule)
  }, {
    path: 'trips', canActivate: [KeyCloakAutologgAuthGuard],
    loadChildren: () => import('./trips/trips.module').then(m => m.AutoLoggTripsModule)
  },
  {
    path: "fleet-trips",
    loadChildren: () => import('./fleet-trips/fleet-trips.module').then(m => m.AutoLoggFleetTripsModule)
  },
  { path: 'add-vehicle', outlet: "dialog", component: CreateConnectedComponent, canActivate: [LockedAutologgAuthGuard] },
  { path: '', redirectTo: '/trips', pathMatch: 'full' },
  { path: '**', redirectTo: '/trips' },

];

export const AppRoutingModule: ModuleWithProviders<AppModule> =
  RouterModule.forRoot(routes, {
    useHash: false,
  });
