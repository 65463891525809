<mat-progress-bar *ngIf="loading" color="accent" mode="indeterminate"></mat-progress-bar>

<div class="container-fluid" *ngIf="loading">
  <div class="row">
    <div class="col-12">
      <h1 translate>groups.membership.manage.title</h1>
    </div>
    <div class="col-12">
      <div class="button-row">
        <a mat-raised-button routerLink="/groups" title="{{ 'groups.title' | translate }}">
          <mat-icon class="icon-custom">arrow_back</mat-icon>
          <span class="mat-button-span" class="mat-button-span" translate>groups.title</span>
        </a>
      </div>
    </div>
    <div class="col-12">
      <mat-spinner style="margin:0 auto;" mode="indeterminate"></mat-spinner>
    </div>
  </div>

</div>

<div class="container-fluid" *ngIf="!loading">
  <div class="row">
    <div class="col-12">
      <h1>{{ group.name }}</h1>
      <h3 translate>groups.membership.manage.title</h3>
    </div>
    <div class="col-12">
      <div class="button-row">
        <a mat-raised-button routerLink="/groups" title="{{ 'groups.title' | translate }}">
          <mat-icon class="icon-custom">arrow_back</mat-icon>
          <span class="mat-button-span" class="mat-button-span" translate>groups.title</span>
        </a>

        <a [hidden]="!isGroupAdmin()" mat-raised-button color="primary" routerLink="/groups/{{group.id}}/manage"
          title="{{ 'groups.manageGroup' | translate }}">
          <mat-icon class="icon-custom">toc</mat-icon>
          <span class="mat-button-span" translate>groups.manageGroup</span>
        </a>

        <span style="float: right;" class="m-1" [matTooltipDisabled]="!isGroupOwner()"
          [matTooltip]="'groups.membership.leave.disabled_tooltip' | translate" matTooltipPosition="left">
          <button mat-button color="warn" title="{{ 'groups.membership.leave.title' | translate }}"
            (click)="openLeaveGroupDialog()" [disabled]="isGroupOwner()">
            <mat-icon class="icon-custom">person_add_disabled</mat-icon>
            <span translate>groups.membership.leave.title</span>
          </button>
        </span>
      </div>
    </div>
    <div class="col-12">

      <mat-card class="mb-4">
        <mat-card-content>
          <ul>
            <li *ngIf="isGroupOwner()">
              <span translate>groups.youAreOwner</span>
            </li>
            <li *ngIf="!isGroupOwner() && isGroupAdmin()">
              <span translate>groups.youAreAdmin</span>
            </li>
            <li>
              <b translate>groups.accepted_at</b> {{groupUser.membership.created_at | date:'longDate'}}
            </li>
          </ul>

          <p class="alert alert-info" *ngIf="sharedBoxesCount === 0 && shareableBoxesCount >= 1">
            <span translate>groups.membership.manage.you_have_not_shared_box_yet_notice</span>
          </p>
        </mat-card-content>
        <mat-card-content>
          <mat-tab-group>
            <mat-tab
              label="{{ 'groups.membership.manage.boxes.title' | translate }} ({{sharedBoxesCount | number:0}}/{{ sharedBoxesCount + shareableBoxesCount | number:0}})">
              <app-manage-group-membership-boxes [groupId]="groupId" [userId]="userId"
                (onSharedBoxesCount)="onSharedBoxesCount($event)"
                (onShareableBoxesCount)="onShareableBoxesCount($event)"></app-manage-group-membership-boxes>
            </mat-tab>

            <mat-tab
              label="{{ 'groups.membership.manage.hotspots.title' | translate }} ({{sharedHotspotsCount | number:0}}/{{ sharedHotspotsCount + shareableHotspotsCount | number:0}})">

              <app-manage-group-membership-hotspots [groupId]="groupId" [userId]="userId"
                (onSharedHotspotsCount)="onSharedHotspotsCount($event)"
                (onShareableHotspotsCount)="onShareableHotspotsCount($event)"></app-manage-group-membership-hotspots>

            </mat-tab>

            <mat-tab label="{{ 'groups.membership.manage.settings.title' | translate }}">

              <form #editMembershipForm="ngForm" (ngSubmit)="submitForm()">
                <div class="mt-3">
                  <mat-checkbox [(ngModel)]="groupUser.membership.auto_hotspot_sharing_enabled"
                    name="auto_hotspot_sharing_enabled">
                    <span translate>groups.membership.manage.settings.fields.auto_hotspot_sharing_enabled.label</span>
                  </mat-checkbox>
                  <br />
                  <mat-hint translate>groups.membership.manage.settings.fields.auto_hotspot_sharing_enabled.hint
                  </mat-hint>
                </div>
                <div class="d-flex flex-row justify-content-around flex-wrap m-auto">
                  <div class="d-flex mt-3 mb-5">
                    <button mat-raised-button color="accent" type="submit" class="m-auto"
                      [disabled]="!isSettingsSubmitButtonEnabled()">
                      <span translate>groups.membership.manage.settings.submit.tilte</span>
                    </button>
                  </div>
                </div>
              </form>
            </mat-tab>
          </mat-tab-group>

        </mat-card-content>
      </mat-card>

    </div>
  </div>

  <div class="row pt-5">
    <div class="col-12">

    </div>
  </div>

  <div class="row mt-5"></div>
</div>