import {
  GroupUserBox,
  GroupUserBoxWithLatestTrip,
} from "../../_models/group.model";

export class BoxTableEntry {
  group_id: number;
  box_vehicle_id: number;
  box_created_at: string;
  box_name: string;
  box_description: string;
  user_fullname: string;
  latest_trip_end_mileage: number;
  latest_trip_end_address: string;
  oem_type: string;

  static fromGroupUserBox(groupUserBox: GroupUserBox) {
    const entry = new BoxTableEntry();
    entry.group_id = groupUserBox.group.id;
    entry.box_vehicle_id = groupUserBox.box.vehicle_id;
    entry.box_created_at = groupUserBox.box.created_at;
    entry.box_name = groupUserBox.box.name;
    entry.box_description = groupUserBox.box.description;
    entry.user_fullname =
      groupUserBox.user.last_name + ", " + groupUserBox.user.first_name;
    entry.oem_type = groupUserBox.box.oem_type;
    return entry;
  }

  static fromGroupUserBoxWithLatestTrip(
    groupUserBoxWithLatestTrip: GroupUserBoxWithLatestTrip
  ) {
    const entry = BoxTableEntry.fromGroupUserBox(
      groupUserBoxWithLatestTrip.group_user_box
    );

    const latestTrip = groupUserBoxWithLatestTrip.latest_trip;
    if (latestTrip) {
      entry.latest_trip_end_mileage = latestTrip.end_km;
      entry.latest_trip_end_address = latestTrip.end_address;
    }

    return entry;
  }
}
