import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ApiVersionHolder {
  public currentApiVersion: String;
  public latestApiVersion: String;

  public update(apiVersion: string) {
    if (!this.currentApiVersion) {
      this.currentApiVersion = apiVersion;
    }
    this.latestApiVersion = apiVersion;
  }

  public isNewerApiVersionAvailable() {
    return (
      this.currentApiVersion &&
      this.latestApiVersion &&
      this.currentApiVersion !== this.latestApiVersion
    );
  }
}
