<mat-progress-bar class="position-absolute" *ngIf="updating" color="accent" mode="indeterminate"></mat-progress-bar>

<div class="container-fluid">

  <div class="row mt-3" *ngIf="loading">
    <div class="col-12">
      <mat-spinner style="margin:0 auto;" mode="indeterminate"></mat-spinner>
    </div>
  </div>

  <div class="row" *ngIf="!loading">
    <div class="col-12 p-3" *ngIf="!userGroupBox.group_box.display_open_trips_enabled">
      <div class="alert alert-info">
        {{ 'groups.manage.box.open_trips.display_open_trips_disabled' | translate:userGroupBox.user }}
      </div>
    </div>
    <div class="col-12" *ngIf="userGroupBox.group_box.display_open_trips_enabled">
      <div class="button-row">

        <button mat-button (click)="reload()">
          <mat-icon class="icon-custom">sync</mat-icon>
          <span translate>global.reload</span>
        </button>

      </div>
      <mat-card>
        <ul>
          <li>
            <b translate>boxes.trips.open_trip_count</b>: {{ openTrips.length | number:0 }}
          </li>
        </ul>

        <hr />
        
        <table class="mt-1 table table-sm table-striped" appSortableTable (sorted)="onSorted($event)">
          <thead>
            <tr class="smaller">
              <th class="text-segment pb-3 text-center" translate>export.weekday</th>
              <th class="text-segment pb-3 text-center" app-sortable-column="start_time" translate>export.date</th>
              <th class="text-segment pb-3 text-center" translate>export.startTime</th>
              <th class="text-segment pb-3 text-center" translate>export.endTime</th>
              <th class="text-segment pb-3 text-center" app-sortable-column="duration" translate>export.duration</th>
              <th class="text-segment pb-3 text-center" translate>export.startKm</th>
              <th class="text-segment pb-3 text-center" translate>export.endKm</th>
              <th class="text-segment pb-3 text-center" app-sortable-column="distance" translate>export.distance</th>
              <th *ngIf="!_apiService?.user?.hasFeatureOrGeneralFeature('AUTOLOGG_HIDE_MAP')" class="text-segment pb-3" translate>export.start</th>
              <th *ngIf="!_apiService?.user?.hasFeatureOrGeneralFeature('AUTOLOGG_HIDE_MAP')" class="text-segment pb-3" translate>export.end</th>
              <th class="text-segment pb-3 text-center" translate>export.driverName</th>
              <th class="text-segment pb-3" app-sortable-column="type_trip" translate>export.type</th>
            </tr>
          </thead>
          <tbody>
            <tr style="display: none;"></tr>
            <tr *ngIf="openTrips.length === 0">
              <td colspan="11">
                <span translate>global.noResults</span>
              </td>
            </tr>
            <tr *ngFor="let trip of openTrips" class="smaller" [ngClass]="{'crossed': trip.crossed}">
              <td>{{ trip.weekday_display }}</td>
              <td style="white-space: nowrap">{{ trip.date }}</td>
              <td>{{ trip.start_time_display }}</td>
              <td>{{ trip.end_time_display}}</td>
              <td class="text-right">{{ (trip.duration || 0) < 1 ? '<1' : ((trip.duration || 0) | number:0) }} min</td>
              <td class="text-right">{{ trip.start_km | number:0}}</td>
              <td class="text-right">{{ trip.end_km | number:0}}</td>
              <td class="text-right">{{ (trip.distance || 0) < 1 ? '<1' : ((trip.distance || 0) | number:0) }} km</td>
              <td *ngIf="!_apiService?.user?.hasFeatureOrGeneralFeature('AUTOLOGG_HIDE_MAP')">{{ trip.start_address}}</td>
              <td *ngIf="!_apiService?.user?.hasFeatureOrGeneralFeature('AUTOLOGG_HIDE_MAP')">{{ trip.end_address}}</td>
              <td>{{ trip.driver_name }}</td>
              <td>{{ 'boxes.trips.types.open' | translate}}</td>
            </tr>
          </tbody>
        </table>
      </mat-card>
    </div>
  </div>
</div>

<div class="minheight-10rem">
  <!-- this element exists so the loader does not display flickering scrollbar -->
</div>