import {
  AfterViewInit,
  Component,
  Inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import {
  leafletCreateMarker,
  leafletCreateOptions,
  leafletFitMapToMarkerBounds,
  leafletPrepareMap,
} from "app/common/leaflet-common.util";
import { latLng, Map } from "leaflet";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "map",
  templateUrl: "./map.component.html",
  styleUrls: ["./map.component.css"],
})
export class MapComponent implements OnInit, OnChanges, AfterViewInit {
  leafletOptions: any;
  leafletLayers: any[];

  @Input("markerPosition") markerPosition: any = {
    lat: null,
    lng: null,
  };

  map: Map;
  coordinates: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    if (data) {
      this.markerPosition = data.markerPosition;
    }
  }

  ngOnInit() {
    this.leafletOptions = leafletCreateOptions();

    this.recreateMarkers();
    this.fitMapToMarkerBounds();
  }

  ngAfterViewInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes["markerPosition"]) {
      this.onTripInfosChanged();
    }
  }

  onMapReady(_mapmap: Map) {
    this.map = leafletPrepareMap(_mapmap);

    this.fitMapToMarkerBounds();
  }

  private onTripInfosChanged() {
    this.recreateMarkers();
    this.fitMapToMarkerBounds();
  }

  private fitMapToMarkerBounds() {
    leafletFitMapToMarkerBounds(this.map, this.leafletLayers);
  }

  private recreateMarkers() {
    this.leafletLayers = this.createMarkerArrayForTrip();
  }

  private createMarkerArrayForTrip() {
    if (!this.markerPosition || !this.markerPosition.lat) {
      return [];
    }

    const m = leafletCreateMarker(
      latLng(this.markerPosition.lat, this.markerPosition.lng),
      "Position"
    );
    return [m];
  }
}
