<div class="alert alert-info" *ngIf="groups.length === 0">
  <span translate>groups.no_result_notice</span>&nbsp;

  <a class="hover-underline" routerLink="/groups/create" title="{{ 'groups.create_group_now' | translate }}">
    <span translate>groups.create_group_now</span>
  </a>

</div>

<div *ngFor="let group of groups">
  <mat-card class="mb-4">
    <div style="float:right; font-size: 0.8em;">
      <ul>
        <li *ngIf="group.group_user.user.id === group.group_user.group.group_owner_user_id">
          <span translate>groups.youAreOwner</span>
        </li>
        <li
          *ngIf="group.group_user.user.id !== group.group_user.group.group_owner_user_id && group.group_user.membership.group_admin">
          <span translate>groups.youAreAdmin</span>
        </li>
      </ul>
    </div>
    <mat-card-header>
      <mat-card-title>
        <h4>
          <span *ngIf="!group.group_user.membership.group_admin">
            {{group.group_user.group.name}}
          </span>
          <a routerLink="/groups/{{group.group_user.group.id}}/manage" title="{{ 'groups.manageGroup' | translate }}"
            *ngIf="group.group_user.membership.group_admin">
            {{group.group_user.group.name}}
          </a>
          &nbsp;
          <a *ngIf="group.group_user.user.id === group.group_user.group.group_owner_user_id"
            routerLink="/groups/{{group.group_user.group.id}}/edit" title="{{ 'groups.edit' | translate }}">
            <mat-icon class="icon-custom" style="font-size: 18px !important;" [matTooltip]="'groups.edit' | translate"
              matTooltipPosition="right">edit</mat-icon>
          </a>
        </h4>
      </mat-card-title>
      <mat-card-subtitle>
        <p [hidden]="!group.group_user.group.description">{{ group.group_user.group.description }}</p>
        <a *ngIf="group.group_user.membership.group_admin && !group.group_user.group.description"
          routerLink="/groups/{{group.group_user.group.id}}/edit" title="{{ 'groups.edit' | translate }}">
          <span translate>groups.membership.manage.add_a_brief_description</span>
        </a>
      </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <ul>
        <li>
          <b>{{ 'groups.user_count' | translate}}</b>: {{group.group_statistics.user_count | number:0 }}
        </li>
        <!--li>
          <b>{{ 'groups.box_count' | translate}}</b>: {{group.group_statistics.box_count | number:0 }}
        </li>
        <li [hidden]="!group.group_user.membership.group_admin">
          <b translate>groups.invitation_open_count</b>: {{group.group_statistics.invitation_open_count | number:0 }}
        </li-->
      </ul>

      <!--ul>
          <li>
            <b>{{ 'groups.box_count' | translate}}</b>: {{group.group_statistics.box_count | number:0 }}
          </li>
          <li>
            <b>{{ 'groups.hotspot_count' | translate}}</b>: {{group.group_statistics.hotspot_count | number:0 }}
          </li>
          <li>
            <b>{{ 'groups.invitation_open_count' | translate}}</b>: {{group.group_statistics.invitation_open_count | number:0
            }}
          </li>
          <li>
            <b>{{ 'groups.accepted_at' | translate}}</b> {{group.group_user.membership.created_at | date:'short'}}
          </li>
          <li>
            <b>{{ 'groups.share_my_hotspots' | translate}}</b>: {{(group.group_user.membership.auto_hotspot_sharing_enabled ? 'global.yes'
            : 'global.no') | translate }}
          </li>
      </ul-->

      <p *ngIf="group.group_user.membership.group_admin && !(group.group_statistics.user_count > 1)"
        class="pt-2 pb-2 alert alert-warning">
        <span translate>groups.group_without_invitations_notice</span>
        &nbsp;
        <a class="hover-underline" routerLink="/groups/{{group.group_user.group.id}}/invitations/create"
          title="{{ 'groups.create_invitation_now' | translate }}">
          <span translate>groups.create_invitation_now</span>
        </a>
      </p>
    </mat-card-content>
    <mat-card-actions>
      <a class="m-1" mat-raised-button routerLink="/groups/{{group.group_user.group.id}}/membership"
        title="{{ 'groups.manage.my_membership' | translate }}">
        <mat-icon class="icon-custom">supervised_user_circle</mat-icon>
        <span class="mat-button-span" translate>groups.manage.my_membership</span>
      </a>

      <a class="m-1" mat-raised-button color="primary" routerLink="/groups/{{group.group_user.group.id}}/manage"
        title="{{ 'groups.manageGroup' | translate }}" [hidden]="!group.group_user.membership.group_admin">
        <mat-icon class="icon-custom">toc</mat-icon>
        <span class="mat-button-span" translate>groups.manageGroup</span>
      </a>
      <!--a mat-raised-button routerLink="/groups/{{group.group_user.group.id}}/invitations" title="{{ 'groups.showOpenInvitations' | translate }}"
        [matBadge]="group.group_statistics.invitation_open_count | number:0" matBadgePosition="above after" 
        [matBadgeColor]="!(group.invitation_open_count > 0) ? 'primary' : 'accent'"
        [hidden]="!group.group_user.membership.group_admin">
        <mat-icon class="icon-custom">person_add</mat-icon>
        <span translate>groups.showOpenInvitations</span>
      </a-->
    </mat-card-actions>
  </mat-card>
</div>