import { AfterViewInit, Component, OnInit } from "@angular/core";

import { BaseComponent } from "../../../base/base.component";

@Component({
  templateUrl: "./manage-user.component.html",
})
export class ManageGroupUserComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  loading: boolean = false;

  groupId: number;
  userId: number;

  ngOnInit() {
    this.groupId = +this.route.snapshot.paramMap.get("id");
    this.userId = +this.route.snapshot.paramMap.get("userId");

    this.loading = true;
  }

  ngAfterViewInit() {}
}
