<mat-sidenav-container fullscreen autosize>
  <mat-sidenav #sidenav mode="side" [ngClass]="{ expanded: isExpanded }">
    <button
      mat-icon-button
      (click)="isExpanded = !isExpanded"
      class="m-1"
      matTooltip="{{ 'menu.hide' | translate }}"
      matTooltipPosition="right"
    >
      <mat-icon *ngIf="isExpanded">chevron_left</mat-icon>
      <mat-icon *ngIf="!isExpanded">chevron_right</mat-icon>
    </button>

    <div
      class="portrait"
      *ngIf="_apiService.isLoggedIn"
      (click)="file.click()"
      [ngStyle]="{
        'background-image': 'url(' + getAvatar() + ')'
      }"
      style="background-size: cover; background-position: center center"
      (mouseenter)="show_overlay = 1"
      (mouseleave)="show_overlay = 0"
    >
      <span class="overlay-icon" [hidden]="!show_overlay" translate
        >menu.changeAvatar</span
      >
    </div>

    <div
      *ngIf="isExpanded && _apiService.isLoggedIn"
      class="text-center"
      style="background-position: center"
    >
      <mat-nav-list title="{{ 'menu.account' | translate }}">
        <a
          mat-list-item
          routerLink="/account"
          routerLinkActive="active"
          class="profile"
        >
          <span matLine *ngIf="!isUserNameDefined()">User</span>
          <span matLine *ngIf="isUserNameDefined()">{{
            _apiService?.user?.firstname + " " + _apiService?.user?.lastname ||
              "profile" | translate
          }}</span>
        </a>
      </mat-nav-list>
    </div>

    <mat-nav-list class="main-menu">
      <a
        mat-list-item
        routerLink="/account"
        routerLinkActive="active"
        [hidden]="isExpanded"
        [matTooltipDisabled]="isExpanded"
        [matTooltip]="'menu.account' | translate"
        matTooltipPosition="right"
        title="{{ 'menu.account' | translate }}"
      >
        <mat-icon class="icon-custom">face</mat-icon>
      </a>
      <a
        mat-list-item
        routerLink="/trips"
        routerLinkActive="active"
        [matTooltipDisabled]="isExpanded"
        [matTooltip]="'My Trips' | translate"
        matTooltipPosition="right"
        title="{{ 'My Trips' | translate }}"
        *ngIf="_apiService?.user?.hasGeneralFeature('AUTOLOGG_TRIP_EDITOR')"
      >
        <mat-icon class="icon-custom">import_contacts</mat-icon>
        <span class="sideBarLabel" [hidden]="!isExpanded" translate
          >My Trips</span
        >
      </a>

      <ng-container *ngIf="!_apiService?.user?.locked">
        <a
          *ngIf="
            !_apiService?.user?.hasFeature('AUTOLOGG_USE_DRIVER_USER_ID') ||
            (_apiService?.user?.hasFeature('AUTOLOGG_USE_DRIVER_USER_ID') &&
              isAdmin)
          "
          mat-list-item
          routerLink="/statistics"
          routerLinkActive="active"
          [matTooltipDisabled]="isExpanded"
          [matTooltip]="'menu.statistics' | translate"
          matTooltipPosition="right"
          title="{{ 'menu.statistics' | translate }}"
        >
          <mat-icon class="icon-custom">analytics</mat-icon>
          <span class="sideBarLabel" [hidden]="!isExpanded">{{
            "menu.statistics" | translate
          }}</span>
        </a>

        <a
          mat-list-item
          routerLink="/unassigned-trips"
          routerLinkActive="active"
          [matTooltipDisabled]="isExpanded"
          [matTooltip]="'menu.unassigned_trips' | translate"
          matTooltipPosition="right"
          title="{{ 'menu.unassigned_trips' | translate }}"
          *ngIf="
            _apiService?.user?.hasFeature('AUTOLOGG_UNASSIGNED_TRIP_EDITOR') &&
            isAdmin
          "
        >
          <mat-icon class="icon-custom">person_search</mat-icon>
          <span class="sideBarLabel" [hidden]="!isExpanded" translate
            >menu.unassigned_trips</span
          >
        </a>

        <a
          mat-list-item
          routerLink="/fleet-trips"
          routerLinkActive="active"
          [matTooltipDisabled]="isExpanded"
          [matTooltip]="'menu.fleet_trips' | translate"
          matTooltipPosition="right"
          title="{{ 'menu.fleet_trips' | translate }}"
          *ngIf="
            _apiService?.user?.hasFeature(
              'AUTOLOGG_ADMIN_ALLOWED_TO_EDIT_TRIPS'
            ) && isAdmin
          "
        >
          <mat-icon class="icon-custom">person_search</mat-icon>
          <span class="sideBarLabel" [hidden]="!isExpanded" translate
            >menu.fleet_trips</span
          >
        </a>

        <a
          mat-list-item
          routerLink="/boxes"
          routerLinkActive="active"
          [matTooltipDisabled]="isExpanded"
          *ngIf="
            !_apiService?.user?.hasFeature('AUTOLOGG_USE_DRIVER_USER_ID') ||
            (_apiService?.user?.hasFeature('AUTOLOGG_USE_DRIVER_USER_ID') &&
              isAdmin)
          "
          [matTooltip]="'menu.vehicles' | translate"
          matTooltipPosition="right"
          title="{{ 'menu.vehicles' | translate }}"
        >
          <span
            [matBadge]="
              _apiService?.user?.vehicles_mileage_needed_count || 0 | number : 0
            "
            [matBadgeHidden]="
              !(_apiService?.user?.vehicles_mileage_needed_count > 0)
            "
            matBadgeColor="accent"
            matBadgePosition="above after"
          >
            <mat-icon class="icon-custom">directions_car</mat-icon>
            <span class="sideBarLabel" [hidden]="!isExpanded">{{
              "menu.vehicles" | translate
            }}</span>
          </span>
        </a>

        <a
          mat-list-item
          routerLink="/add-vehicle"
          routerLinkActive="active"
          *ngIf="
            !_apiService?.user?.hasFeature('AUTOLOGG_USE_DRIVER_USER_ID') ||
            (_apiService?.user?.hasFeature('AUTOLOGG_USE_DRIVER_USER_ID') &&
              isAdmin)
          "
          [matTooltipDisabled]="isExpanded"
          [matTooltip]="'menu.add_vehicle' | translate"
          matTooltipPosition="right"
          title="{{ 'menu.add_vehicle' | translate }}"
        >
          <mat-icon class="icon-custom">add</mat-icon>
          <span class="sideBarLabel" [hidden]="!isExpanded">{{
            "menu.add_vehicle" | translate
          }}</span>
        </a>

        <a
          mat-list-item
          routerLink="/hotspots"
          routerLinkActive="active"
          [matTooltipDisabled]="isExpanded"
          [matTooltip]="'menu.hotspots' | translate"
          matTooltipPosition="right"
          title="{{ 'menu.hotspots' | translate }}"
        >
          <mat-icon class="icon-custom">outlined_flag</mat-icon>
          <span class="sideBarLabel" [hidden]="!isExpanded" translate
            >menu.hotspots</span
          >
        </a>

        <a
          mat-list-item
          routerLink="/groups"
          routerLinkActive="active"
          [matTooltipDisabled]="isExpanded"
          [matTooltip]="'menu.groups' | translate"
          matTooltipPosition="right"
          title="{{ 'menu.groups' | translate }}"
          *ngIf="_apiService?.user?.hasFeature('AUTOLOGG_GROUPS')"
        >
          <span
            [matBadge]="
              _apiService?.user?.open_invitations_count || 0 | number : 0
            "
            [matBadgeHidden]="!(_apiService?.user?.open_invitations_count > 0)"
            matBadgeColor="accent"
            matBadgePosition="above after"
          >
            <mat-icon class="icon-custom">group</mat-icon>
            <span class="sideBarLabel" [hidden]="!isExpanded">{{
              "menu.groups" | translate
            }}</span>
          </span>
        </a>
      </ng-container>

      <a
        mat-list-item
        routerLink="/export"
        routerLinkActive="active"
        [matTooltipDisabled]="isExpanded"
        *ngIf="
          !_apiService?.user?.hasFeature('AUTOLOGG_USE_DRIVER_USER_ID') ||
          (_apiService?.user?.hasFeature('AUTOLOGG_USE_DRIVER_USER_ID') &&
            isAdmin)
        "
        [matTooltip]="'menu.export' | translate"
        matTooltipPosition="right"
        title="{{ 'menu.export' | translate }}"
      >
        <mat-icon class="icon-custom">description</mat-icon>
        <span class="sideBarLabel" [hidden]="!isExpanded">{{
          "menu.export" | translate
        }}</span>
      </a>

      <a
        mat-list-item
        (click)="logout()"
        [matTooltipDisabled]="isExpanded"
        [matTooltip]="'menu.logout' | translate"
        matTooltipPosition="right"
        title="{{ 'menu.logout' | translate }}"
      >
        <mat-icon class="icon-custom">exit_to_app</mat-icon>
        <span class="sideBarLabel" [hidden]="!isExpanded">{{
          "menu.logout" | translate
        }}</span>
      </a>

      <ng-container *ngIf="isExpanded">
        <a
          mat-list-item
          target="blank"
          style="margin-top: 20px"
          href="https://play.google.com/store/apps/details?id=at.autologg.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
        >
          <img
            alt="Get it on Google Play"
            src="assets/images/google-play-badge-1.png"
            width="135"
          />
        </a>

        <a
          mat-list-item
          target="blank"
          style="margin-top: 10px"
          href="https://apps.apple.com/at/app/autologg-fahrtenbuch-2-0/id1600232347"
        >
          <img
            alt="Get it on Google Play"
            src="assets/images/Download_on_the_App_Store_Badge_US-UK_135x40.png"
            width="135"
          />
        </a>
      </ng-container>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content>
    <div fxLayout="column" style="height: 100vh">
      <div fxFlex>
        <div
          class="alert alert-info"
          style="
            margin: 0;
            border-radius: 0;
            background-color: #c7e7fb !important;
          "
        >
          <span translate>FlutterAppLink</span>&nbsp;<br />
          <u
            ><a
              href="https://logbook.autologg.com?source=my.autologg.com"
              translate
              >FlutterAppLinkHref</a
            ></u
          >
        </div>
        <!-- <div
          class="alert alert-info"
          style="margin: 0; border-radius: 0"
          *ngIf="_apiService?.isNewerApiVersionAvailable()"
        >
          <span translate>api.newerVersionAvailableNotice</span>&nbsp;
          <a href="javascript:window.location.reload(true)" translate
            >api.loadNewVersionNowText</a
          >
        </div>
        <div
          class="alert alert-warning"
          style="margin: 0; border-radius: 0"
          *ngIf="_apiService?.user?.isDemoUser()"
        >
          <span>{{ "demo.notice" | translate : _apiService.user }}</span>
        </div>
        <div
          class="alert alert-warning"
          style="margin: 0; border-radius: 0"
          *ngIf="hasGaps"
        >
          <span>{{ "api.hasGaps" | translate }}</span>
        </div>

        <div
          class="alert alert-danger"
          style="margin: 0; border-radius: 0"
          *ngIf="_apiService?.user?.locked"
        >
          <span>{{ "user.locked" | translate }}</span>
        </div>

        <div
          class="alert alert-warning"
          style="margin: 0; border-radius: 0"
          *ngIf="isNotSupported"
        >
          <span translate>browserNotSupported</span>
        </div> -->

        <div
          class="container-fluid p-3 pt-5"
          [ngClass]="{ 'p-3 pt-5': _apiService.isLoggedIn }"
          style="overflow: hidden"
        >
          <router-outlet></router-outlet>
        </div>
      </div>

      <footer
        fxFlex="80px"
        fxLayoutAlign="center center"
        class="app-footer"
        fxLayout="column"
      >
        <div fxFlex="initial">
          <a
            [attr.href]="'menu.autologgImpress.url' | translate"
            title="{{ 'menu.autologgImpress.text' | translate }}"
            target="{{ 'menu.autologgImpress.target' | translate }}"
            rel="noopener"
          >
            <span translate>menu.autologgImpress.text</span>
          </a>
          &nbsp;
          <span class="vertical-text-spacer"></span>
          &nbsp;
          <a
            [attr.href]="'menu.autologgPrivacy.url' | translate"
            title="{{ 'menu.autologgPrivacy.text' | translate }}"
            target="{{ 'menu.autologgPrivacy.target' | translate }}"
            rel="noopener"
          >
            <span translate>menu.autologgPrivacy.text</span>
          </a>
          &nbsp;
          <span class="vertical-text-spacer"></span>
          &nbsp;
          <a
            [attr.href]="'menu.autologgTermsAndConditions.url' | translate"
            title="{{ 'menu.autologgTermsAndConditions.text' | translate }}"
            target="{{ 'menu.autologgTermsAndConditions.target' | translate }}"
            rel="noopener"
          >
            <span translate>menu.autologgTermsAndConditions.text</span>
          </a>

          <span *ngIf="_apiService.isLoggedIn && _apiService.user">
            &nbsp;
            <span class="vertical-text-spacer"></span>
            &nbsp;
            <a
              [attr.href]="_apiService.user.manual_link"
              title="{{ 'menu.autologgManual.text' | translate }}"
              target="{{ 'menu.autologgManual.target' | translate }}"
            >
              <span translate>menu.autologgManual.text</span>
            </a>
          </span>
        </div>
        <div *ngIf="_apiService.isLoggedIn" fxFlex="initial">
          <span style="color: #aaa">
            <span translate>menu.supporthotline.text</span>:
            <a href="mailto:{{ supportContact }}" translate
              >menu.supporthotline.contact</a
            >
          </span>
        </div>
      </footer>
    </div>

    <div style="display: none">
      <input
        type="file"
        #file
        id="filePicker"
        (change)="handleAvatarFileSelect($event)"
      />
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
