import { Component, OnInit, ViewChild, Input } from "@angular/core";

import { BaseComponent } from "../base/base.component";
import { UserSettings } from "../_models/user.model";

@Component({
  selector: "app-settings-form",
  templateUrl: "./settings.component.html",
})
export class SettingsComponent extends BaseComponent implements OnInit {
  loading: boolean = false;
  updating: boolean = false;
  settings: UserSettings = new UserSettings();

  ngOnInit() {
    this._apiService.getUserSettings().subscribe(
      (settings) => {
        Object.assign(this.settings, settings);
      },
      (err) => {
        this.showError(err);
      }
    );
  }

  submitForm() {
    this._apiService.postUserSettings(this.settings).subscribe(
      (settings) => {
        Object.assign(this.settings, settings);
        this.showSuccess("user.account.settings.submit.success");
      },
      (err) => {
        this.showError(err);
      }
    );
  }
}
