import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'app/common/material.module';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ConnectedLoginComponent as ConnectedLoginComponent } from './connected-login.component';
import { OauthLoginModule } from '../oauth-login/oauth-login.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Oauth2LoginModule } from '../oauth2-login/oauth2-login.module';
import { DxButtonModule, DxPopupModule, DxTextBoxModule } from 'devextreme-angular';
import { AutoaidLoginComponent } from './autoaid-login/autoaid-login.component';
import { ConnectedMercedesLoginComponent } from './connected-mercedes-login/connected-mercedes-login.component';

@NgModule({
  declarations: [ConnectedLoginComponent, AutoaidLoginComponent, ConnectedMercedesLoginComponent],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    TranslateModule,
    OauthLoginModule,
    Oauth2LoginModule,
    DxPopupModule,
    DxTextBoxModule,
    DxButtonModule,
    NgbModule,
  ],
  exports: [ConnectedLoginComponent],
})
export class ConnectedLoginModule {}
