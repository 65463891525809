
export enum ProviderType {
    Native = "Native",
    HighMobility = "HighMobility",
  }
  
  export enum OemType {
    Tesla = "Tesla",
    Box = "Box",
    Bmw = "BMW",
    Ford = "Ford",
    Porsche = "Porsche",
    Unknown = "Unknown",
    Dongle = "Dongle",
    AlfaRomeo = "AlfaRomeo",
    Fiat = "Fiat",
    Peugeot = "Peugeot",
    Citroen = "Citroen",
    DsAuto = "DsAuto",
    Jeep = "Jeep",
    Opel = "Opel",
    Mercedes = "Mercedes",
    Audi = "Audi",
    Mini = "Mini",
    Stellantis = "Stellantis",
  }
  
  export function apiProviderTypeFromString(apiProvider: String) {
    if (apiProvider) {
      switch (apiProvider.toLowerCase()) {
        case "highmobility":
          return ProviderType.HighMobility;
        default:
          return ProviderType.Native;
      }
    }
    return ProviderType.Native;
  }
  
  export function oemTypeFromString(oem: String) {
    if (oem) {
      switch (oem.toLowerCase()) {
        case "box":
          return OemType.Box;
        case "tesla":
          return OemType.Tesla;
        case "bmw":
        case "bmwasync":
          return OemType.Bmw;
        case "dongle":
          return OemType.Dongle;
        case "ford":
          return OemType.Ford;
        case "porsche":
          return OemType.Porsche;
        case "alfaromeo":
          return OemType.AlfaRomeo;
        case "fiat":
          return OemType.Fiat;
        case "peugeot":
          return OemType.Peugeot;
        case "citroen":
          return OemType.Citroen;
        case "dsauto":
          return OemType.DsAuto;
        case "jeep":
          return OemType.Jeep;
        case "opel":
          return OemType.Opel;
        case "mercedes":
          return OemType.Mercedes;
        case "audi":
          return OemType.Audi;
        case "mini":
          return OemType.Mini;
        default:
          return OemType.Unknown;
      }
    }
    return OemType.Unknown;
  }