<mat-progress-bar *ngIf="loading" color="accent" mode="indeterminate"></mat-progress-bar>

<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <h1 translate>{{ getTranslation('update.title') }}</h1>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="button-row">
        <a mat-button (click)="navigateBack()" title="{{ 'global.back' | translate }}">
          <mat-icon class="icon-custom">arrow_back</mat-icon>
          <span translate>global.back</span>
        </a>
      </div>
    </div>
  </div>

  <form #addBoxForm="ngForm" (ngSubmit)="submitForm()">
    <div class="row">

      <ng-container *ngIf="oemType === OemType.Box">
        <div class="col-12 p-3 pt-2">
          <mat-form-field floatPlaceholder="always" class="full-width" appearance="fill"
            [ngClass]="{'mat-form-field-invalid': !vehicleKeyField.valid}">
            <mat-label translate>boxes.update.fields.vehicleKey.label</mat-label>
            <input matInput #vehicleKeyField="ngModel" type="text" [(ngModel)]="vehicleKey" name="vehicleKey"
              placeholder="{{ 'boxes.update.fields.vehicleKey.placeholder' | translate }}" pattern="(?=^.{1,}$).*$"
              required readonly>

            <mat-hint translate>boxes.update.fields.vehicleKey.hint</mat-hint>
            <mat-error *ngIf="vehicleKeyField.dirty" align="end">
              <span *ngIf="!vehicleKeyField.valid" translate>errors.invalidAutologgKey</span>
            </mat-error>
          </mat-form-field>
        </div>
      </ng-container>
      <div class="col-6 p-3 pt-2">
        <mat-form-field floatPlaceholder="always" class="m-auto" class="full-width"
          [ngClass]="{'mat-form-field-invalid': !nameField.valid && nameField.dirty}">
          <mat-label translate>{{ getTranslation('update.fields.name.label') }}</mat-label>
          <input matInput #nameField="ngModel" type="text" [(ngModel)]="name" name="name"
            placeholder="{{ getTranslation('update.fields.name.placeholder') }}" required>
          <mat-hint translate>{{ getTranslation('update.fields.name.hint') }}</mat-hint>
          <mat-error *ngIf="nameField.dirty" align="end">
            <span *ngIf="!nameField.valid" translate>errors.invalidName</span>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-12 p-3 pt-2">
        <mat-form-field class="full-width">
          <mat-label translate>boxes.update.fields.description.label</mat-label>
          <textarea matInput #descriptionField="ngModel" [(ngModel)]="description" name="description"
            [maxlength]="descriptionMaxCharacterCount" rows="3"
            placeholder="{{ 'boxes.update.fields.description.placeholder' | translate }}"
            (ngModelChange)="updateDescriptionRemainingCharacterCount()"></textarea>
          <mat-hint>{{ 'boxes.update.fields.description.hint' | translate }}: {{ descriptionRemainingCharacterCount |
            number:0 }}</mat-hint>
        </mat-form-field>
      </div>
    </div>


    <div class="d-flex flex-row justify-content-around flex-wrap m-auto">
      <div class="d-flex mt-3 mb-5">
        <button mat-raised-button color="accent" type="submit" [disabled]="!isSubmitButtonEnabled()" class="m-auto">
          <span translate>boxes.update.submit</span>
        </button>
      </div>
    </div>
  </form>

  <div class="row mt-5"></div>
</div>