<h5 class="card-title" translate> register.stepTwo.desc_mercedes</h5>

<div style="min-width: 50%">
    <mat-form-field class="w-100 mt-2">
        <input matInput maxlength="17" minlength="17" type="text" [(ngModel)]="vin" name="vin" placeholder="{{ 'login.vin' | translate  }}" required
            autofocus>
        <mat-error *ngIf="vin">{{ 'login.vinHint' | translate }}</mat-error>
    </mat-form-field>
    <mat-form-field class="w-100 mt-2" >
        <input matInput type="number" [(ngModel)]="mileage" name="mileage" placeholder="{{ 'login.mileage' | translate  }} "
            required autofocus>
    </mat-form-field>

    <div class="col-12 text-center mt-4">
        <button mat-raised-button color="accent" type="button" (click)="checkMercedesLogin()" class="w-25"
         [disabled]="!vin || vin.length !== 17 || !mileage || mileage <= 0">
            <span translate>global.next</span>
        </button>
    </div>


</div>