<div class="container-fluid">
  <div class="row">

    <div class="col-12">
      <h1 translate class="center step-two title">
        add_vehicle.title

      </h1>
    </div>
  </div>
  <div class="row desktop">

    <div class="col-12 hide-dialog">
      <span translate>add_vehicle.add_vehicle_onboarding</span>
    </div>
  </div>
  <div class="row hide-dialog">
    <div class="col-12">
      <span translate>add_vehicle.add_vehicle_onboarding2</span>
    </div>
  </div>

  <div class="row center p-3 pt-3 dialog-box">
  <!--
      DONGLE
    -->
    <ng-container *ngIf="autoaidEnabled">
      <div class="card text-center  margin" style="width: 20rem;">
        <a [routerLink]="['/', { outlets: { dialog: 'connectedboxes/create/add/native/dongle' } }]">
          <a [routerLink]="['/', { outlets: { primary: 'connectedboxes/create/add/native/dongle'} }]"
            style="margin: 10px">
            <img src="/assets/images/logos/autologg-dongle.png" />

            <div class="card-body">
              <h5 aria-labelledby="teslaBrandInfo" class="card-title">AutoLogg Dongle</h5>
              <br />
              <a aria-labelledby="teslaTrailInfo" matTooltipPosition="right" class="btn btn-primary"
                translate>home.onboarding_connect_autoaid</a>
            </div>
          </a>
        </a>
      </div>
    </ng-container>


    <!--
      BOX
    -->
    <ng-container *ngIf="nativeEnabled">
      <div class="card text-center margin create-connected">
        <a [routerLink]="['/', { outlets: { dialog: 'boxes/create/add/native/box'} }]">
          <a [routerLink]="['/', { outlets: { primary: 'boxes/create/add/native/box'} }]" style="margin-top: 10px">

            <img src="/assets/images/logos/autologg-box.png" style="padding-top: 17px;" />

            <div class="card-body">
              <h5 class="card-title" translate>register.oemTypes.native.title</h5>

              <br />
              <a class="btn btn-primary" translate>home.onboarding_box_cta</a>
            </div>
          </a>
        </a>
      </div>
    </ng-container>


   

    <!--
      TESLA
    -->

    <ng-container *ngIf="(_apiService?.user?.hasFeatureOrGeneralFeature('AUTOLOGG_CONNECTED_TESLA'))">
      <div class="card text-center create-connected margin" style="width: 20rem;">
        <a [routerLink]="['/', { outlets: { dialog: 'connectedboxes/create/add/tesla' } }]">
          <a [routerLink]="['/', { outlets: { primary: 'connectedboxes/create/add/tesla'} }]" style="margin: 10px">

            <img src="/assets/images/logos/tesla.png" />


            <div class="card-body">
              <h5 aria-labelledby="teslaBrandInfo" class="card-title" translate>Tesla</h5>
              <br />
              <a aria-labelledby="teslaTrailInfo" matTooltipPosition="right" class="btn btn-primary"
                translate>home.onboarding_connect_tesla_cta</a>
            </div>
          </a>
        </a>
      </div>

    </ng-container>

    <!--
      HM
    -->
    <ng-container *ngIf="(_apiService?.user?.hasFeatureOrGeneralFeature('AUTOLOGG_CONNECTED_HM_BMW'))">
    <div class="card text-center  margin" style="width: 20rem;">
      <a [routerLink]="['/', { outlets: { dialog: 'connectedboxes/create/add/highmobility/bmw' } }]">
        <a [routerLink]="['/', { outlets: { primary: 'connectedboxes/create/add/highmobility/bmw'} }]"
          style="margin: 10px">
          <img src="/assets/images/logos/bmw.png" />

          <div class="card-body">
            <h5 aria-labelledby="teslaBrandInfo" class="card-title">Connected BMW</h5>
            <br />
            <a aria-labelledby="teslaTrailInfo" matTooltipPosition="right" class="btn btn-primary"
              translate>home.onboarding_connect_bmw_cta<sub style="font-size: 50%;"> BETA</sub></a>
          </div>
        </a>
      </a>
    </div>
    </ng-container>

    <ng-container *ngIf="(_apiService?.user?.hasFeatureOrGeneralFeature('AUTOLOGG_CONNECTED_MERCEDES'))">
      <div class="card text-center  margin" style="width: 20rem;">
        <a [routerLink]="['/', { outlets: { dialog: 'connectedboxes/create/add/native/mercedes' } }]">
          <a [routerLink]="['/', { outlets: { primary: 'connectedboxes/create/add/native/mercedes'} }]"
            style="margin: 20px">
            <img src="/assets/images/logos/mercedes.png" />
    
            <div class="card-body">
              <h5 aria-labelledby="teslaBrandInfo" class="card-title">Connected Mercedes</h5>
              <br />
              <a aria-labelledby="teslaTrailInfo" matTooltipPosition="right" class="btn btn-primary"
                translate>home.onboarding_connect_mercedes_cta<sub style="font-size: 50%;"> BETA</sub></a>
            </div>
          </a>
        </a>
      </div>
    </ng-container>


    <ng-container *ngIf="emulatorEnabled">
      <div class="card text-center  margin" style="width: 20rem;">
        <a [routerLink]="['/', { outlets: { dialog: 'connectedboxes/create/add/highmobility/emulator' } }]">
          <a [routerLink]="['/', { outlets: { primary: 'connectedboxes/create/add/highmobility/emulator'} }]"
            style="margin: 10px">
            <img src="/assets/images/logos/bmw.png" />

            <div class="card-body">
              <h5 aria-labelledby="teslaBrandInfo" class="card-title">Emulator</h5>
              <br />
              <a aria-labelledby="teslaTrailInfo" matTooltipPosition="right" class="btn btn-primary"
                translate>home.onboarding_connect_bmw_cta<sub style="font-size: 50%;"> BETA</sub></a>
            </div>
          </a>
        </a>
      </div>
    </ng-container>

    <ng-container *ngIf="(_apiService?.user?.hasFeatureOrGeneralFeature('AUTOLOGG_CONNECTED_HM_FORD'))">
      <div class="card text-center  margin" style="width: 20rem;">
        <a [routerLink]="['/', { outlets: { dialog: 'connectedboxes/create/add/highmobility/ford' } }]">
          <a [routerLink]="['/', { outlets: { primary: 'connectedboxes/create/add/highmobility/ford'} }]"
            style="margin: 20px">
            <img src="/assets/images/logos/ford.png" />

            <div class="card-body">
              <h5 aria-labelledby="teslaBrandInfo" class="card-title">Connected Ford</h5>
              <br />
              <a aria-labelledby="teslaTrailInfo" matTooltipPosition="right" class="btn btn-primary"
                translate>home.onboarding_connect_ford_cta<sub style="font-size: 50%;"> BETA</sub></a>
            </div>
          </a>
        </a>
      </div>
    </ng-container>

    <ng-container *ngIf="(_apiService?.user?.hasFeatureOrGeneralFeature('AUTOLOGG_CONNECTED_HM_PORSCHE'))">
      <div class="card text-center  margin" style="width: 20rem;">
        <a [routerLink]="['/', { outlets: { dialog: 'connectedboxes/create/add/highmobility/porsche' } }]">
          <a [routerLink]="['/', { outlets: { primary: 'connectedboxes/create/add/highmobility/porsche'} }]"
            style="margin: 20px">
            <img src="/assets/images/logos/porsche.png" />

            <div class="card-body">
              <h5 aria-labelledby="teslaBrandInfo" class="card-title">Connected Porsche</h5>
              <br />
              <a aria-labelledby="teslaTrailInfo" matTooltipPosition="right" class="btn btn-primary"
                translate>home.onboarding_connect_porsche_cta<sub style="font-size: 50%;"> BETA</sub></a>
            </div>
          </a>
        </a>
      </div>
    </ng-container>


    <ng-container
      *ngIf="(_apiService?.user?.hasFeatureOrGeneralFeature('AUTOLOGG_CONNECTED_HM_STELLANTIS')) || experimentEnabled">
      <div class="card text-center  margin" style="width: 20rem;">
        <a [routerLink]="['/', { outlets: { dialog: 'connectedboxes/create/add/highmobility/fiat' } }]">
          <a [routerLink]="['/', { outlets: { primary: 'connectedboxes/create/add/highmobility/fiat'} }]"
            style="margin: 20px">
            <img src="/assets/images/logos/fiat.png" />

            <div class="card-body">
              <h5 aria-labelledby="teslaBrandInfo" class="card-title">Connected Fiat</h5>
              <br />
              <a aria-labelledby="teslaTrailInfo" matTooltipPosition="right" class="btn btn-primary"
                translate>home.onboarding_connect_fiat_cta<sub style="font-size: 50%;"> BETA</sub></a>
            </div>
          </a>
        </a>
      </div>
    </ng-container>

  </div>

  <div class="row mt-16 p-3 pt-3" style="margin-top: 20px">
    <span translate>add_vehicle.buy_license</span>
    <span style="margin-left: 5px"> </span>
    <a [attr.href]="'add_vehicle.shop_link' | translate" title="{{ 'add_vehicle.order_license' | translate }}"
      target="{{ 'boxes.orderNewBoxLink.target' | translate }}" rel="noopener" style="float:right;">
      <span translate>add_vehicle.order_license</span>
    </a>
  </div>
</div>