import * as urlParse from "url-parse";

export class OAuth2Helper {
  public static start(oAuthUri: string, redirectUri: string): Promise<string> {
    let loopCount = 600;
    const intervalLength = 10;
    let intervalId: any = null;

    return new Promise<string>((success, reject) => {
      const windowHandle = this.createWindow(oAuthUri, "Login");
      windowHandle.focus();

      intervalId = setInterval(() => {
        if (windowHandle.closed) {
          clearInterval(intervalId);
          reject("closed");
        }
        if (loopCount-- < 0) {
          clearInterval(intervalId);
          //    this.emitAuthStatus(false);
          windowHandle.close();
          reject();
        } else {
          let href: string;
          try {
            href = windowHandle.location.href;
          } catch (e) { }
          if (href != null) {
            const re = /code=(.*)/;
            const found = href.match(re);
            if (found) {
              clearInterval(intervalId);
              windowHandle.close();

              const urlObj = urlParse(href, true);
              success(urlObj.query["code"]);
            } else {
              if (href.indexOf(redirectUri) === 0) {
                clearInterval(intervalId);
                windowHandle.close();
                reject(href);
              }
            }
          }
        }
      }, intervalLength);
    });
  }

  private static createWindow(
    url: string,
    name: string = "Window",
    width: number = 500,
    height: number = 700,
    left: number = 0,
    top: number = 0
  ) {
    if (url == null) {
      return null;
    }
    return window.open(url, "_blank");
  }
}
