<mat-progress-bar *ngIf="loading" color="accent" mode="indeterminate"></mat-progress-bar>

<div *ngIf="hotspots.length > 0">
  <app-hotspot-map [hotspots]="hotspots"></app-hotspot-map>
</div>

<div class="container-fluid">
  <div style="padding-top: 1.5%;" class="row">
    <div class="col-12">
      <h1 translate>hotspots.title</h1>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-md-6">
      <div class="button-row">
        <a mat-raised-button color="primary" routerLink="/hotspots/create"
          title="{{ 'hotspots.addHotspot' | translate }}" [disabled]="loading">
          <mat-icon class="icon-custom">add_circle</mat-icon>
          <span class="mat-button-span" translate>hotspots.addHotspot</span>
        </a>

        <a mat-raised-button color="primary" routerLink="/hotspots/import"
          title="{{ 'hotspots.importHotspot' | translate }}" [disabled]="loading">
          <mat-icon class="icon-custom">cloud_upload</mat-icon>
          <span class="mat-button-span" translate>hotspots.importHotspot</span>
        </a>
      </div>
    </div>
  </div>

  <hr />

  <div [hidden]="hotspots.length < 2">
    <mat-form-field class="full-width">
      <input matInput [(ngModel)]="userSearchTerm" (ngModelChange)="onUserSearchTermChanged($event)"
        placeholder="{{ 'global.search' | translate }}">
      <button mat-button *ngIf="userSearchTerm" matSuffix mat-icon-button aria-label="Clear"
        (click)="userSearchTerm=''; onUserSearchTermChanged('');">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <hr />
  </div>

  <div class="row">
    <div class="col-12">
      <mat-spinner *ngIf="loading" style="margin:0 auto;" mode="indeterminate"></mat-spinner>
      <mat-progress-bar *ngIf="updating" color="accent" mode="indeterminate"
        style="position: absolute; left: 0; right: 0;"></mat-progress-bar>
    </div>
  </div>

  <div class="row" [hidden]="loading">
    <div class="col-12 p-3">
      <div class="alert alert-info" *ngIf="hotspots.length === 0">
        <span translate>hotspots.no_result_notice</span>&nbsp;

        <a class="hover-underline" routerLink="/hotspots/create"
          title="{{ 'hotspots.create_hotspot_now' | translate }}">
          <span translate>hotspots.create_hotspot_now</span>
        </a>
      </div>

      <div class="alert alert-info" *ngIf="hotspots.length > 0 && filterdHotspots.length === 0">
        <span translate>global.noResults</span>
      </div>

      <div *ngFor="let hotspot of filterdHotspots">
        <mat-card class="mb-4">
          <mat-card-header>
            <mat-card-title>
              <h4>{{hotspot.title}} &nbsp;
                <a routerLink="/hotspots/{{hotspot.itemid}}/edit" title="{{ 'hotspots.edit' | translate }}">
                  <mat-icon class="icon-custom" style="font-size: 18px !important;"
                    [matTooltip]="'hotspots.edit' | translate" matTooltipPosition="right">edit</mat-icon>
                </a>
              </h4>
            </mat-card-title>
            <mat-card-subtitle>
              <p [hidden]="!hotspot.note">{{ hotspot.note }}</p>
              <a *ngIf="!hotspot.note" routerLink="/hotspots/{{hotspot.itemid}}/edit"
                title="{{ 'hotspots.edit' | translate }}">
                <span translate>hotspots.add_a_brief_description</span>
              </a>
            </mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <ul>
              <li>
                <b translate>hotspots.address</b>: {{ hotspot.address }}
              </li>
              <li>
                <b translate>hotspots.latLng</b>: {{ hotspot.latitude }} / {{ hotspot.longitude }}
              </li>
              <li>
                <b translate>hotspots.radius_in_meters</b>: {{ hotspot.radius_in_meters | number:0 }}m
              </li>
              <li *ngIf="hotspot.partner">
                <b translate>hotspots.partner</b>: {{ hotspot.partner }}
              </li>
              <li *ngIf="hotspot.purpose">
                <b translate>hotspots.purpose</b>: {{ hotspot.purpose }}
              </li>
              <li *ngIf="hotspot.note">
                <b translate>hotspots.note</b>: {{ hotspot.note }}
              </li>
            </ul>
          </mat-card-content>
          <!-- mat-card-actions>
            <a mat-raised-button routerLink="/boxes/{{box.vehicle_id}}/vehicles" title="{{ 'boxes.showVehicles' | translate }}" [matBadge]="box.vehicle_count | number:0"
              matBadgePosition="above after" [matBadgeColor]="!(box.vehicle_count > 0) ? 'accent' : 'primary'">
              <mat-icon class="icon-custom">directions_car</mat-icon>
              <span translate>boxes.showVehicles</span>
            </a>
          </mat-card-actions-->
          <!-- <mat-card-footer>
            <app-hotspot-map class="app-card-map-container" [hotspots]="[hotspot]"></app-hotspot-map>
          </mat-card-footer-->
        </mat-card>
      </div>
    </div>
  </div>

  <div class="row mt-5"></div>
</div>