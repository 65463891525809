import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-oauth2-redirect",
  templateUrl: "./oauth2-redirect.component.html",
  styleUrls: ["./oauth2-redirect.component.css"],
})
export class OAuth2RedirectComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
