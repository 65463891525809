import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { BaseComponent } from "app/base/base.component";
import { ProviderType, OemType } from "app/base/provider-oem-type"
import { DialogConfirmComponent } from "app/common/dialog-confirm/dialog-confirm.component";
import { Box } from "app/_models/box.model";
import {
  CreateGroupBoxRequest,
  GroupUserBox,
  UpdateGroupBoxRequest,
} from "app/_models/group.model";
import { forkJoin } from "rxjs";
import { flatMap } from "rxjs/operators";

@Component({
  selector: "app-manage-group-membership-boxes",
  templateUrl: "./manage-group-membership-boxes.component.html",
})
export class ManageGroupMembershipBoxesComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  ProviderType = ProviderType;
  OemType = OemType;
  loading = false;
  updating = false;

  @Input("userId") userId: number;
  @Input("groupId") groupId: number;
  @Output("onSharedBoxesCount") onSharedBoxesCount = new EventEmitter();
  @Output("onShareableBoxesCount") onShareableBoxesCount = new EventEmitter();

  groupUserBoxes: Array<GroupUserBox> = [];
  groupUserBoxMapById: object = {};
  userBoxes: Array<Box> = [];
  sharedVehicleIds: Array<number> = [];

  loadBoxes = () =>
    forkJoin(
      this._apiService.getUserGroupBox(this.groupId),
      this._apiService.getAllUserBoxes()
    ).map((array) => {
      this.groupUserBoxes = array[0];
      this.groupUserBoxMapById = this.groupUserBoxes.reduce((map, obj) => {
        map[obj.group_box.vehicle_id] = obj;
        return map;
      }, {});

      this.userBoxes = (array[1] || [])
        .sort(this.orderBoxByName)
        .map((box) => Object.assign(new Box(), box));

      this.sharedVehicleIds = this.groupUserBoxes.map(
        (gb) => gb.box.vehicle_id
      );

      const shareableUserBoxes = this.userBoxes.filter(
        (box) => this.sharedVehicleIds.indexOf(box.vehicle_id) < 0
      );

      this.onSharedBoxesCount.emit(this.sharedVehicleIds.length);
      this.onShareableBoxesCount.emit(shareableUserBoxes.length);

      return array;
    });

  ngOnInit() {
    this.loading = true;
  }

  ngAfterViewInit() {
    this.load();
  }

  isShared(box: Box) {
    return this.sharedVehicleIds.indexOf(box.vehicle_id) >= 0;
  }

  load() {
    this.loading = true;
    forkJoin(this.loadBoxes()).subscribe(
      (array) => {},
      (err) => {
        this.loading = false;
        this.showError(err);
      },
      () => {
        this.loading = false;
      }
    );
  }

  reloadBoxes() {
    this.updating = false;
    return this.loadBoxes().subscribe(
      (array) => {},
      (err) => {
        this.updating = false;
        this.showError(err);
      },
      () => {
        this.updating = false;
      }
    );
  }

  toggleDisplayOpenTripsEnabled(box: Box) {
    const groupUserBox: GroupUserBox = this.groupUserBoxMapById[box.vehicle_id];
    if (!groupUserBox) {
      return;
    }

    const currentValue = groupUserBox.group_box.display_open_trips_enabled;

    const request = this.prepareUpdateGroupBoxRequest(box);
    request.display_open_trips_enabled = !currentValue;

    this.updating = true;
    forkJoin(
      this._apiService.updateUserGroupBox(this.groupId, box.vehicle_id, request)
    )
      .pipe(flatMap(this.loadBoxes))
      .subscribe(
        (foo) => {},
        (err) => {
          this.updating = false;
          this.showError(err);
        },
        () => {
          this.updating = false;
        }
      );
  }

  toggleDisplayPrivateTripsEnabled(box: Box) {
    const groupUserBox: GroupUserBox = this.groupUserBoxMapById[box.vehicle_id];
    if (!groupUserBox) {
      return;
    }

    const currentValue = groupUserBox.group_box.display_private_trips_enabled;

    const request = this.prepareUpdateGroupBoxRequest(box);
    request.display_private_trips_enabled = !currentValue;

    this.updating = true;
    forkJoin(
      this._apiService.updateUserGroupBox(this.groupId, box.vehicle_id, request)
    )
      .pipe(flatMap(this.loadBoxes))
      .subscribe(
        (foo) => {},
        (err) => {
          this.updating = false;
          this.showError(err);
        },
        () => {
          this.updating = false;
        }
      );
  }

  shareBoxWithGroup(box: Box) {
    const request = new CreateGroupBoxRequest();
    request.group_id = this.groupId;
    request.vehicle_id = box.vehicle_id;
    request.display_open_trips_enabled = false;
    request.display_private_trips_enabled = false;

    this.updating = true;
    forkJoin(this._apiService.userShareBoxWithGroup(this.groupId, request))
      .pipe(flatMap(this.loadBoxes))
      .subscribe(
        (foo) => {},
        (err) => {
          this.updating = false;
          this.showError(err);
        },
        () => {
          this.updating = false;

          let key = box.translationKey;

          this.showSuccess(
            "groups.membership.manage." + key + ".share.success"
          );
        }
      );
  }

  unshareBoxWithGroup(box: Box) {
    let key = box.translationKey;
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: {
        title: "groups.membership.manage." + key + ".unshare.confirm.title",
        text: "groups.membership.manage." + key + ".unshare.confirm.text",
        submitButtonText:
          "groups.membership.manage." +
          key +
          ".unshare.confirm.submitButtonText",
        cancelButtonText:
          "groups.membership.manage." +
          key +
          ".unshare.confirm.cancelButtonText",
      },
    });

    dialogRef.afterClosed().subscribe((confirmed) => {
      if (!confirmed) {
        return;
      }

      this.updating = true;
      forkJoin(
        this._apiService.userUnshareBoxWithGroup(this.groupId, box.vehicle_id)
      )
        .pipe(flatMap(this.loadBoxes))
        .subscribe(
          (foo) => {},
          (err) => {
            this.updating = false;
            this.showError(err);
          },
          () => {
            this.updating = false;
            this.showSuccess(
              "groups.membership.manage." + key + ".unshare.success"
            );
          }
        );
    });
  }

  private prepareUpdateGroupBoxRequest(box: Box) {
    const groupUserBox: GroupUserBox = this.groupUserBoxMapById[box.vehicle_id];
    if (!groupUserBox) {
      throw new Error("Box not found");
    }
    const request = new UpdateGroupBoxRequest();
    request.group_id = this.groupId;
    request.vehicle_id = box.vehicle_id;
    request.display_open_trips_enabled =
      groupUserBox.group_box.display_open_trips_enabled;
    request.display_private_trips_enabled =
      groupUserBox.group_box.display_private_trips_enabled;

    return request;
  }

  private orderBoxByName(a: Box, b: Box) {
    return a.name.localeCompare(b.name);
  }
}
