import { HttpParameterCodec } from "@angular/common/http";

// this is added due to a bug in angular where '+' are not decoded
// see https://github.com/angular/angular/issues/11058#issuecomment-357861890
export class CustomQueryHttpParameterCodec implements HttpParameterCodec {
  encodeKey(k: string): string {
    return k.replace(/\+/gi, "%2B");
  }
  encodeValue(v: string): string {
    return v.replace(/\+/gi, "%2B");
  }
  decodeKey(k: string): string {
    return k.replace(/%2B/gi, "+");
  }
  decodeValue(v: string): string {
    return v.replace(/'%2B/gi, "+");
  }
}

export class MoreHttpHeaders {
  public static HEADER_PARAM_UID = "mogree-Access-Id";
  public static HEADER_PARAM_UID_TOKEN = "mogree-Access-Token";
}
