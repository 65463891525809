<mat-tab-group>
  <mat-tab label="Tabelle">
    <mat-progress-bar *ngIf="loading || updating" color="accent" mode="indeterminate"></mat-progress-bar>

    <div class="mt-1 alert alert-info" *ngIf="!loading && !(dataSource.data.length > 0)">
      <span translate>global.noResults</span>
    </div>

    <div class="mt-1" [hidden]="!(dataSource.data.length > 0)">
      <mat-form-field class="full-width">
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="{{ 'global.Search' | translate }}">
      </mat-form-field>

      <table mat-table matSort [dataSource]="dataSource" class="mt-1 mb-1 full-width">
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
            </mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="hotspot_title">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'groups.manage.hotspots.table.hotspot_title' | translate }} </th>
          <td mat-cell *matCellDef="let element"> {{element.hotspot_title}} </td>
        </ng-container>
        <ng-container matColumnDef="user_fullname">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'groups.manage.hotspots.table.user_fullname' | translate }} </th>
          <td mat-cell *matCellDef="let element"> {{element.user_fullname }} </td>
        </ng-container>
        <ng-container matColumnDef="hotspot_created_at">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'groups.manage.hotspots.table.hotspot_created_at' | translate }} </th>
          <td mat-cell *matCellDef="let element"> {{element.hotspot_created_at | date:'mediumDate'}} </td>
        </ng-container>
        <ng-container matColumnDef="external_link">
          <th mat-header-cell *matHeaderCellDef> {{ 'groups.manage.hotspots.table.external_link' | translate }} </th>
          <td mat-cell *matCellDef="let element">
            <a href="https://www.google.com/maps/?q={{element.hotspot_latitude}},{{element.hotspot_longitude}}" rel="noopener" target="blank">
              <mat-icon class="vertical-align-middle">location_on</mat-icon> Google Maps
            </a>
          </td>
        </ng-container>
        <ng-container matColumnDef="hotspot_address">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'groups.manage.hotspots.table.hotspot_address' | translate }} </th>
          <td mat-cell *matCellDef="let element">
            {{element.hotspot_address}}
          </td>
        </ng-container>
        <ng-container matColumnDef="hotspot_note">
          <th mat-header-cell *matHeaderCellDef> {{ 'groups.manage.hotspots.table.hotspot_note' | translate }} </th>
          <td mat-cell *matCellDef="let element"> {{element.hotspot_note}} </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
        </tr>
      </table>

      <mat-paginator [pageSizeOptions]="[50, 100, 200]" showFirstLastButtons></mat-paginator>


      <button mat-button color="warn" title="{{ 'groups.manage.hotspots.remove_selected_hotspots.submit' | translate }}" (click)="removeSelectedHotspots(selection)"
        [disabled]="!selection.hasValue()">
        <mat-icon class="icon-custom">delete_sweep</mat-icon>
        <span translate>groups.manage.hotspots.remove_selected_hotspots.submit</span>
      </button>
    </div>

  </mat-tab>

  <mat-tab label="Karte" *ngIf="hotspots.length > 0">
    <ng-template matTabContent>
      <app-groups-list-group-hotspots-map [hotspots]="hotspots"></app-groups-list-group-hotspots-map>
    </ng-template>
  </mat-tab>
</mat-tab-group>