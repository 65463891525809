import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Oauth2LoginComponent } from "./oauth2-login.component";
import { MaterialModule } from "app/common/material.module";
import { FormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  declarations: [Oauth2LoginComponent],
  imports: [CommonModule, FormsModule, MaterialModule, TranslateModule],
  exports: [Oauth2LoginComponent],
})
export class Oauth2LoginModule {}
