import { BaseComponent } from "app/base/base.component";
import { ApiService } from "app/api.service";
import { Router, ActivatedRoute } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TrackingService } from "app/tracking/tracking-service";
import { Box } from "app/_models/box.model";
import { VehicleAuthState } from "app/_models/register.model";
import { ChangeDetectorRef, Directive } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { OemType, ProviderType } from "app/base/provider-oem-type"



@Directive()
export class BaseBoxesComponent extends BaseComponent {
  OemType = OemType;
  ProviderType = ProviderType;
  VehicleAuthState = VehicleAuthState;

  private missingKeys = [];

  public getVehicleImage(box: Box) {
    if (!box.oem_type) {
      return "autologg-box.png";
    }

    switch (box.oem_type.toLowerCase()) {
      case "bmw":
      case "bmwasync":
        return "bmw.png";
      case "tesla":
        return "tesla.png";
      case "ford":
        return "ford.png";
      case "porsche":
        return "porsche.png";
      case "dongle":
        return "autologg-dongle.png";
      case "mercedes":
        return "mercedes.png";
      default:
        return "autologg-box.png";
    }
  }

  constructor(
    _apiService: ApiService,
    router: Router,
    snackBar: MatSnackBar,
    dialog: MatDialog,
    formBuilder: UntypedFormBuilder,
    translate: TranslateService,
    route: ActivatedRoute,
    _changeDetectorRef: ChangeDetectorRef,
    _tracker: TrackingService
  ) {
    super(
      _apiService,
      router,
      snackBar,
      dialog,
      formBuilder,
      translate,
      route,
      _changeDetectorRef,
      _tracker
    );
  }

  getTranslation(key: string, oemType: OemType = OemType.Box) {
    var translationKey = "";
    if (oemType === OemType.Box) {
      translationKey = "boxes." + key;
    } else {
      translationKey = "connected_boxes." + key;
    }

    const translate = this._translate.instant(translationKey);

    if (
      translate === translationKey &&
      !this.missingKeys.find((a) => a === translationKey)
    ) {
      this.missingKeys = [...this.missingKeys, translationKey];
    }
    return translate;
  }
}
