import { ChangeDetectorRef, Directive } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ApiService } from "../api.service";

import { TrackingService } from "app/tracking/tracking-service";
import { DialogInfoComponent } from "../common/dialog-info/dialog-info.component";
import { ProviderType } from "app/base/provider-oem-type"

import { locale } from "devextreme/localization";

@Directive()
export class BaseComponent {
  public _apiService: ApiService;
  public _translate: TranslateService;
  public _changeDetectorRef: ChangeDetectorRef;
  _tracker: TrackingService;


  public get autologgConnectedActive(): boolean {
    if (
      this._apiService.user &&
      (this._apiService.user.hasFeature("AUTOLOGG_CONNECTED") ||
        this._apiService.user.hasGeneralFeature("AUTOLOGG_CONNECTED"))
    ) {
      return true;
    }
    return false;
  }

  ProviderType = ProviderType;

  constructor(
    _apiService: ApiService,
    public router: Router,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    public formBuilder: UntypedFormBuilder,
    translate: TranslateService,
    public route: ActivatedRoute,
    _changeDetectorRef: ChangeDetectorRef,
    _tracker: TrackingService
  ) {
    this._apiService = _apiService;

    translate.setDefaultLang("de");
    translate.use(translate.getBrowserLang());
    locale(translate.getBrowserLang());

    this._translate = translate;
    this._changeDetectorRef = _changeDetectorRef;
    this._tracker = _tracker;
  }

  detectChanges() {
    this._changeDetectorRef.detectChanges();
  }

  showWarning(messageOrError: any, action: string = "OK") {
    const translatedMessage = this.checkTranslate(messageOrError);
    this.snackBar.open(translatedMessage, action, {
      duration: 7000,
      panelClass: ["warn-message"],
    });
  }

  translate(message: string) {
    return this._translate.instant(message);
  }

  showError(
    messageOrError: any,
    action: string = "OK",
    duration: number = 7000
  ) {
    const translatedMessage = this.checkTranslate(messageOrError);
    this.snackBar.open(translatedMessage, action, {
      duration: duration,
      panelClass: ["error-message"],
    });
  }

  showSuccess(messageOrError: any, action: string = "OK") {
    const translatedMessage = this.checkTranslate(messageOrError);
    this.snackBar.open(translatedMessage, action, {
      duration: 7000,
      panelClass: ["success-message"],
    });
  }

  openInfoDialog(translationKeyBase: string) {
    const dialogRef = this.dialog.open(DialogInfoComponent, {
      data: {
        title: `${translationKeyBase}.title`,
        text: `${translationKeyBase}.text`,
      },
    });

    dialogRef.afterClosed().subscribe((foo) => { });
  }

  checkTranslate(messageOrError: any): string {
    const isError = messageOrError instanceof Error;
    const isString = typeof messageOrError === "string";

    const message = isString
      ? messageOrError
      : isError
        ? messageOrError.message
        : JSON.parse(JSON.stringify(messageOrError));

    const translatedMessageOrKey = this._translate.instant(message);
    const translated = !translatedMessageOrKey.includes(message);
    if (translated) {
      return translatedMessageOrKey;
    }

    const translatedBackendErrorOrKey = this._translate.instant(
      "backend_errors." + message
    );
    const translatedError =
      !translatedBackendErrorOrKey.includes("backend_errors.");
    if (translatedError) {
      return translatedBackendErrorOrKey;
    }

    return message;
  }
}
