import { NgModule } from '@angular/core';
import { AutoLoggCommonModule } from '../common/autologg-common.module';
import { AutoLoggGroupsRouting } from './groups.routing';
import { AutoLoggGroupsManageModule } from './manage-group/manage-groups.module';

import {
  GroupsComponent,
  GroupsListUserGroupsComponent,
  GroupsListUserInvitationsComponent
} from './groups.component';

import { CreateGroupComponent } from './create-group/create-group.component';
import {
  DialogGroupDeleteComponent,
  UpdateGroupComponent
} from './update-group/update-group.component';

import {
  DialogGroupCreateInvitationSuccessComponent,
  CreateInvitationComponent
} from './invitations/create-invitation/create-invitation.component';

import {
  ManageGroupMembershipComponent,
} from './manage-membership/manage-group-membership.component';
import { ManageGroupMembershipBoxesComponent } from './manage-membership/manage-group-membership-boxes.component';
import { ManageGroupMembershipHotspotsComponent } from './manage-membership/manage-group-membership-hotspots.component';
import { DialogGroupLeaveComponent } from './manage-membership/dialog-group-leave.component';

@NgModule({
    imports: [
        AutoLoggCommonModule,
        AutoLoggGroupsManageModule,
        AutoLoggGroupsRouting,
    ],
    declarations: [
        DialogGroupCreateInvitationSuccessComponent,
        CreateInvitationComponent,
        GroupsListUserGroupsComponent,
        GroupsListUserInvitationsComponent,
        GroupsComponent,
        CreateGroupComponent,
        UpdateGroupComponent,
        ManageGroupMembershipBoxesComponent,
        ManageGroupMembershipHotspotsComponent,
        ManageGroupMembershipComponent,
        DialogGroupDeleteComponent,
        DialogGroupLeaveComponent
    ],
    exports: []
})
export class AutoLoggGroupsModule { }
