<mat-progress-bar *ngIf="loading" color="accent" mode="indeterminate"></mat-progress-bar>



<div class="container-fluid" *ngIf="(!_apiService?.user?.hasFeatureOrGeneralFeature('AUTOLOGG_HIDE_MAP'))">

  <div class=" mb-3">
    <app-groups-list-group-boxes-map class="app-manage-group-header-map-container"
      [boxesWithLatestTrip]="boxesWithLatestTrip">
    </app-groups-list-group-boxes-map>
  </div>

  <div class="row">
    <div class="col-12" *ngIf="loading">
      <h1>
        <span translate>groups.manage.title</span>
      </h1>
    </div>

    <div class="col-12" *ngIf="!loading">
      <h1>
        {{ group.name }}
      </h1>
      <h3>
        <span translate>groups.manage.title</span>
      </h3>
    </div>
  </div>

  <div class="row" *ngIf="loading">
    <div class="col-12">
      <mat-spinner style="margin:0 auto;" mode="indeterminate"></mat-spinner>
    </div>
  </div>
</div>

<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <div class="button-row">
        <a mat-raised-button routerLink="/groups" title="{{ 'groups.title' | translate }}">
          <mat-icon class="icon-custom">arrow_back</mat-icon>
          <span class="mat-button-span" class="mat-button-span" translate>groups.title</span>
        </a>

        <a *ngIf="!loading" mat-raised-button routerLink="/groups/{{group.id}}/membership"
          title="{{ 'groups.manage.my_membership' | translate }}">
          <mat-icon class="icon-custom">supervised_user_circle</mat-icon>
          <span class="mat-button-span" translate>groups.manage.my_membership</span>
        </a>

        <a *ngIf="!loading" mat-raised-button color="primary" routerLink="/groups/{{groupId}}/invitations/create"
          title="{{ 'groups.invitations.create.title' | translate }}">
          <mat-icon class="icon-custom">group_add</mat-icon>
          <span class="mat-button-span" translate>groups.invitations.create.title</span>
        </a>
      </div>
    </div>
  </div>

  <div class="row pt-2">
    <div class="col-12">

      <mat-tab-group #tabGroup [selectedIndex]="tabIndex" (selectedIndexChange)="onTabSelectedIndexChange($event)">
        <!--mat-tab label="Dashboard">
          <ng-template matTabContent>

          </ng-template>
        </mat-tab-->
        <mat-tab label="{{ 'groups.manage.users.title' | translate }} ({{ usersLoaded ? users.length  : '...' }})">
          <app-groups-list-group-users [groupId]="groupId" [groupOwnerUserId]="groupOwnerUserId"
            (valueChange)="onMembershipsChanged($event)"></app-groups-list-group-users>
        </mat-tab>
        <mat-tab
          label="{{ 'groups.manage.boxes.title' | translate }} ({{ boxesWithLatestTripLoaded ? boxesWithLatestTrip.length : '...' }})">
          <app-groups-list-group-boxes [groupId]="groupId" (valueChange)="onBoxesChanged($event)">
          </app-groups-list-group-boxes>
        </mat-tab>
        <mat-tab
          label="{{ 'groups.manage.invitations.title' | translate }} ({{ invitationsLoaded ? invitations.length : '...' }})">
          <app-groups-list-group-invitations [groupId]="groupId" (valueChange)="onInvitationsChanged($event)">
          </app-groups-list-group-invitations>
        </mat-tab>
        <mat-tab
          label="{{ 'groups.manage.hotspots.title' | translate }} ({{ hotspotsLoaded ? hotspots.length : '...' }})">
          <app-groups-list-group-hotspots [groupId]="groupId" (valueChange)="onHotspotsChanged($event)">
          </app-groups-list-group-hotspots>
        </mat-tab>
        <mat-tab label="{{ 'groups.manage.export.title' | translate }}">
          <ng-template matTabContent>
            <app-groups-export [groupId]="groupId"></app-groups-export>
          </ng-template>
        </mat-tab>
        <!--mat-tab label="{{ 'groups.manage.events.title' | translate }}">
          <ng-template matTabContent>
            <app-groups-list-group-events [events]="events"></app-groups-list-group-events>
          </ng-template>
        </mat-tab-->
        <!--mat-tab label="Einstellungen">
          {{ group | json }}


        </mat-tab-->
      </mat-tab-group>
    </div>
  </div>

  <div class="row mt-5"></div>
</div>