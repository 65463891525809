import { Injectable } from "@angular/core";
import mixpanel from 'mixpanel-browser/src/loader-module';
import { environment } from "environments/environment";
import { Trip } from "app/_models/trip.model";
import { TrackingConstants } from "./tracking-constants";
import { LogbookType } from "app/export/export.component";

@Injectable({
  providedIn: "root",
})
export class TrackingService {
  readonly MIXPANEL_KEY_PRODUCTION = "0891c53fb4d18c98fe73a8be1f1844d8";
  readonly MIXPANEL_KEY_STAGING = "a7d7e5922176b6c59c2edb40ae0b7547";

  constructor() {
    let token = environment.production
      ? this.MIXPANEL_KEY_PRODUCTION
      : this.MIXPANEL_KEY_STAGING;
    mixpanel.init(token, { debug: false });
  }

  /**
   * @param {string} userToken
   * @memberof TrackingService
   */
  login(userToken: string): void {
    mixpanel.identify(userToken);
  }

  logout(): void {
    mixpanel.reset();
  }

  register(userToken: string): void {
    mixpanel.identify(userToken);
  }

  /**
   * Push new action to mixpanel.
   *
   * @param {string} eventName Name of the action to track.
   * @param {*} [properties={}] Actions object with custom properties.
   * @memberof MixpanelService
   */
  track(eventName: string, properties: any = {}): void {
    mixpanel.track(eventName, properties);
  }

  setUserAttributes(attributes = {}): void {
    mixpanel.people.set(attributes);
  }

  trackTripAssignment(trip: Trip) {
    let timeToAssign = new Date().getTime() - new Date(trip.end_time).getTime();

    this.track(TrackingConstants.Events.AssignTrip, {
      [TrackingConstants.EventProperties.TimeToAssign]: timeToAssign,
    });
  }

  trackTripConnection(numberOfSubtrips: number) {
    this.track(TrackingConstants.Events.ConnectTrip, {
      [TrackingConstants.EventProperties.NumberOfSubtrips]: numberOfSubtrips,
    });
  }

  trackExport(type: LogbookType) {
    this.track(TrackingConstants.Events.DownloadLogbook, {
      [TrackingConstants.EventProperties.LogbookType]: type,
    });
  }
}
