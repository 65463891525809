<mat-tab-group>
  <mat-tab label="{{ 'groups.invitations.openInvitations' | translate }} ({{groupedInvitations.open.length}})">
    <app-groups-list-group-invitations-open [invitations]="groupedInvitations.open" (valueChange)="onInvitationsChanged($event)"></app-groups-list-group-invitations-open>
  </mat-tab>
  <mat-tab label="{{ 'groups.invitations.acceptedInvitations' | translate }} ({{groupedInvitations.accepted.length}})">
    <app-groups-list-group-invitations-open [invitations]="groupedInvitations.accepted" (valueChange)="onInvitationsChanged($event)"></app-groups-list-group-invitations-open>
  </mat-tab>
  <mat-tab label="{{ 'groups.invitations.declinedInvitations' | translate }} ({{groupedInvitations.declined.length}})">
    <app-groups-list-group-invitations-open [invitations]="groupedInvitations.declined" (valueChange)="onInvitationsChanged($event)"></app-groups-list-group-invitations-open>
  </mat-tab>
</mat-tab-group>