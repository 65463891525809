import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { BoxUpdateRequest } from "../../_models/box-update-request.model";
import { BaseBoxesComponent } from "../base-boxes.component";
import { OemType } from "app/base/provider-oem-type"

@Component({
  templateUrl: "./update-box.component.html",
  styleUrls: ["update-box.component.css"],
})
export class UpdateBoxComponent
  extends BaseBoxesComponent
  implements OnInit, AfterViewInit
{
  private static DESCRIPTION_MAX_CHAR_COUNT: number = 1024;

  @ViewChild("addBoxForm", { static: false }) form;

  loading: boolean = true;

  vehicleId: number;

  name: string = "";
  description: string = "";
  vehicleKey: string = "";

  oemType: OemType = OemType.Unknown;

  descriptionMaxCharacterCount: number =
    UpdateBoxComponent.DESCRIPTION_MAX_CHAR_COUNT;
  descriptionRemainingCharacterCount: number = 0;

  ngOnInit() {
    this.vehicleId = +this.route.snapshot.paramMap.get("id");

    this.loading = true;
    this._apiService.getUserBoxById(this.vehicleId).subscribe(
      (box) => {
        this.name = box.name || "";
        this.description = box.description || "";
        this.vehicleKey = box.vehicle_key;
        this.oemType = box.oem_type;
      },
      (err) => {
        this.loading = false;
        this.showError(err);
      },
      () => {
        this.loading = false;
      }
    );
  }

  navigateBack() {
    this.router.navigate(["../.."], { relativeTo: this.route });
  }

  ngAfterViewInit() {
    this.form.control.valueChanges.subscribe((values) => {
      this.formControlValueChanged();
    });
    this.setupNewForm();
  }

  formControlValueChanged() {
    this.updateDescriptionRemainingCharacterCount();
  }

  isSubmitButtonEnabled() {
    return this.vehicleKey.length > 0 && this.name.length > 0;
  }

  submitForm() {
    if (!this.isSubmitButtonEnabled()) {
      throw new Error("Form is in invalid state and cannot be submitted");
    }

    const boxRequest = new BoxUpdateRequest();
    boxRequest.description = this.description;
    boxRequest.name = this.name;

    this.loading = true;

    this._apiService.updateUserBox(this.vehicleId, boxRequest).subscribe(
      (foo) => {
        if (this.oemType === OemType.Box) {
          this.showSuccess("Box updated");
        } else {
          this.showSuccess("Connected vehicle updated");
        }
        this.router.navigate(["../.."], { relativeTo: this.route });
      },
      (err) => {
        this.loading = false;
        this.showError(err);
      },
      () => {
        this.loading = false;
      }
    );
  }

  updateDescriptionRemainingCharacterCount() {
    this.descriptionRemainingCharacterCount =
      this.descriptionMaxCharacterCount - (this.description || "").length;
  }

  private setupNewForm() {
    this.form.resetForm();

    this.vehicleKey = "";
    this.name = "";
    this.description = "";

    this.updateDescriptionRemainingCharacterCount();
  }
}
