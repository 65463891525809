import { Gap } from "./gap.model";

export class Statistics {
  public year_statistics: Array<SingleStat>;

  public statustrips: number;
  public opentrips: number;
  public private_trips: number;
  public business_trips: number;
  public distance_week: number;
  public distance_month: number;

  public gap: Gap;

  constructor() {
    this.gap = new Gap();
  }
}

export class SingleStat {
  public label: string;
  public distance: number;
  public itemid: string;
}
