import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "environments/environment";
import { VehicleAuthState } from "app/_models/register.model";
import { ApiService } from "app/api.service";
import { ConnectedPayload } from "app/AutologgConnected/connected-payload";
import { ProviderType, OemType } from "app/base/provider-oem-type"
import { BaseService } from "app/base.service"

export interface User {
  email: string;
  first_name: string;
  last_name: string;
  password: string;
}

export interface VehicleBase {
  plate: string;
  name: string;
}

export interface Vehicle extends VehicleBase {
  reference_vehicle_id: string;
  payload: any;
  model: string;
  brand: string;
  vehicleId?: number;

  oemType: string;
  providerType: string;
}

export interface RegisterVehicleRequest {
  user: User;
  vehicles: Vehicle[];
}

export interface ConnectedVehicle {
  name: string;
  referenceVehicleId: string;

  modelName: string;
  brand: string;

  alreadyUsed: boolean;
  compatible: boolean;
  accessPayload: string;

  virtual: boolean;

  oemType: OemType;

  vehicleAuthState: VehicleAuthState;
}

export interface ConnectedVehiclesRequest {
  oemType: OemType;
  providerType: ProviderType;
  data: any;
}

// I don't want to put new api calls in the apiService monolith. This service will contain all new methods for autologg.connected.
@Injectable({
  providedIn: "root",
})
export class RegisterService  extends BaseService {
  vehicles: ConnectedVehicle[] = [];

  payload: ConnectedPayload;

  constructor(
    httpService: HttpClient
  ) {
    super(httpService, environment.backendUrl);
  }

  async getConnectedVehicles(
    data: ConnectedVehiclesRequest
  ): Promise<ConnectedVehicle[]> {
    return await this.postMultiple<ConnectedVehicle>(
      "autologg_connected/delegate?path=AutologgConnected-GetConnectedVehicles",
      data,
      false
    );
  }

  async getConnectedVehicleInformation(
    id: number
  ): Promise<ConnectedVehicle[]> {
    var data = {
      VehicleId: id,
    };
    return await this.postMultiple<ConnectedVehicle>(
      "autologg_connected/delegate?path=AutologgConnected-GetVehicleInformation",
      data,
      false
    );
  }

  async sendMfaCode(data: any): Promise<ConnectedVehicle[]> {
    return await this.postMultiple<ConnectedVehicle>(
      "autologg_connected/delegate?path=AutologgConnected-GetConnectedVehicles",
      data,
      true,
      [{ key: "MFA_REQUEST", value: "MFA_REQUEST" }]
    );
  }

  async registerVehicles(data: RegisterVehicleRequest): Promise<any> {
    return this.post<any>("autologg_connected/register", data, true);
  }

  async addVehicle(data: Vehicle): Promise<any> {
    return this.post<any>("autologg_connected/vehicle", data, true);
  }
  async updateModel(data: VehicleBase, vehicleId: number): Promise<any> {
    return this.post<any>(
      "autologg_connected/vehicle/" + vehicleId + "/model",
      data,
      true
    );
  }

  async updateConnectedCredentials(vehicle: Vehicle) {
    return this.post(
      "autologg_connected/delegate?path=AutologgConnected-RegisterVehicle",
      { vehicles: [vehicle] },
      true
    );
  }
}
