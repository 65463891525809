import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { OauthLoginModule } from "./oauth-login/oauth-login.module";
import { Oauth2LoginModule } from "./oauth2-login/oauth2-login.module";
import { ConnectedLoginModule } from "./connected-login/connected-login.module";
import { CreateConnectedComponent } from "./create-connected/create-connected.component";
import { TranslateModule } from "@ngx-translate/core";
import { FormsModule } from "@angular/forms";
import { AutoLoggCommonModule } from "app/common/autologg-common.module";
import { RouterModule } from "@angular/router";

@NgModule({
  declarations: [CreateConnectedComponent],
  imports: [
    CommonModule,
    OauthLoginModule,
    Oauth2LoginModule,
    AutoLoggCommonModule,
    ConnectedLoginModule,
    FormsModule,
    TranslateModule,
    RouterModule,
  ],
  exports: [OauthLoginModule, ConnectedLoginModule, CreateConnectedComponent],
  providers: [],
})
export class AutologgConnectedModule {}
