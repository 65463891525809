<mat-list>
  <h3 mat-subheader translate>home.hotspotsTitle</h3>
  <mat-list-item *ngFor="let hotspot of hotspots">
    <div class="d-flex w-75">{{hotspot.title}}</div>
    <div class="d-flex w-25 justify-content-end">{{hotspot.nr | number:0 }}x</div>
  </mat-list-item>
  <mat-list-item *ngIf="hotspots.length == 0 && !loading">
    {{ 'global.noResults' | translate }}
  </mat-list-item>
  <mat-list-item *ngIf="loading">
    {{ 'global.loading' | translate }}...
  </mat-list-item>
</mat-list>
