<mat-progress-bar class="position-absolute" *ngIf="loading || updating" color="accent" mode="indeterminate"></mat-progress-bar>

<div class="mt-3">
  <div class="alert alert-info" *ngIf="!loading && sharedUserHotspots.length === 0 && shareableUserHotspots.length === 0">
    <span translate>global.noResults</span>
  </div>

  <div *ngIf="(sharedUserHotspots.length > 0 || shareableUserHotspots.length > 0)">
    <mat-form-field class="full-width">
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="{{ 'global.Search' | translate }}">
    </mat-form-field>

    <table mat-table matSort [dataSource]="dataSource" class="mt-1 mb-1 full-width mat-elevation-z2-DISABLED">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
          </mat-checkbox>
        </td>
      </ng-container>


      <ng-container matColumnDef="shared">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'groups.membership.manage.hotspots.table.shared' | translate }} </th>
        <td mat-cell *matCellDef="let element" [ngClass]="{'color-success': element.shared, 'color-error': !element.shared }">
          <mat-icon class="vertical-align-middle" *ngIf="element.shared">check</mat-icon>
          <mat-icon class="vertical-align-middle" *ngIf="!element.shared">cancel</mat-icon>
          {{(element.shared ? 'groups.membership.manage.hotspots.shared' : 'groups.membership.manage.hotspots.not_shared') | translate
          }}
        </td>
      </ng-container>
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'groups.membership.manage.hotspots.table.title' | translate }} </th>
        <td mat-cell *matCellDef="let element"> {{ element.title }} </td>
      </ng-container>
      <ng-container matColumnDef="created_at">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'groups.membership.manage.hotspots.table.created_at' | translate }} </th>
        <td mat-cell *matCellDef="let element"> {{ element.created_at | date:'mediumDate' }} </td>
      </ng-container>
      <ng-container matColumnDef="external_link">
        <th mat-header-cell *matHeaderCellDef> {{ 'groups.membership.manage.hotspots.table.external_link' | translate }} </th>
        <td mat-cell *matCellDef="let element">
          <a href="https://www.google.com/maps/?q={{element.latitude}},{{element.longitude}}" rel="noopener" target="blank">
            <mat-icon class="vertical-align-middle">location_on</mat-icon> Google Maps
          </a>
        </td>
      </ng-container>
      <ng-container matColumnDef="address">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'groups.membership.manage.hotspots.table.address' | translate }} </th>
        <td mat-cell *matCellDef="let element">
          {{ element.address }}
        </td>
      </ng-container>
      <ng-container matColumnDef="note">
        <th mat-header-cell *matHeaderCellDef> {{ 'groups.membership.manage.hotspots.table.note' | translate }} </th>
        <td mat-cell *matCellDef="let element"> {{ element.note }} </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;">
      </tr>
    </table>

    <mat-paginator [pageSizeOptions]="[50, 100, 200]" showFirstLastButtons></mat-paginator>

    <div class="button-row">
      <button mat-raised-button color="primary" title="{{ 'groups.membership.manage.hotspots.share_selected_hotspots.submit' | translate }}"
        (click)="shareSelectedHotspots(selection)" [disabled]="!isShareHotspotButtonEnabled(selection)">
        <mat-icon class="icon-custom">add</mat-icon>
        <span class="mat-button-span" translate>groups.membership.manage.hotspots.share_selected_hotspots.submit</span>
      </button>
      <button mat-button color="warn" title="{{ 'groups.membership.manage.hotspots.unshare_selected_hotspots.submit' | translate }}"
        (click)="unshareSelectedHotspots(selection)" [disabled]="!isUnshareHotspotButtonEnabled(selection)">
        <mat-icon class="icon-custom">clear</mat-icon>
        <span class="mat-button-span" translate>groups.membership.manage.hotspots.unshare_selected_hotspots.submit</span>
      </button>
    </div>
  </div>
</div>