import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { BaseBoxesComponent } from "../base-boxes.component";
import { OemType } from "app/base/provider-oem-type"

@Component({
  templateUrl: "./update-mileage.component.html",
  styleUrls: ["update-mileage.component.css"],
})
export class UpdateMileageComponent
  extends BaseBoxesComponent
  implements OnInit, AfterViewInit
{
  @ViewChild("updateMileageForm", { static: false }) form;

  loading: boolean = true;

  vehicleId: number;

  name: string = "";
  plate: string = "";
  currentMileage: number = 0;
  mileage: number = 0;
  oemType: OemType = OemType.Unknown;

  ngOnInit() {
    this.vehicleId = +this.route.snapshot.paramMap.get("id");

    this.loading = true;
    this._apiService.getUserBoxById(this.vehicleId).subscribe(
      (loadedBox) => {
        this.name = loadedBox.active_model_name || "";
        this.plate = loadedBox.active_model_plate || "";
        this.currentMileage = loadedBox.active_model_mileage || 0;
        this.mileage = this.currentMileage;
        this.oemType = loadedBox.oem_type;
      },
      (err) => {
        this.loading = false;
        this.showError(err);
      },
      () => {
        this.loading = false;
      }
    );
  }

  navigateBack() {
    this.router.navigate(["../.."], { relativeTo: this.route });
  }

  ngAfterViewInit() {
    this.setupNewForm();
  }

  isSubmitButtonEnabled() {
    return this.mileage > 0;
  }

  submitForm() {
    if (!this.isSubmitButtonEnabled()) {
      throw new Error("Form is in invalid state and cannot be submitted");
    }

    const mileageRequest = this.mileage;
    this.loading = true;

    this._apiService
      .updateUserBoxMileage(this.vehicleId, mileageRequest)
      .subscribe(
        (foo) => {
          this._apiService.refreshProfile();
          if (this.oemType === OemType.Box) {
            this.showSuccess("Box updated");
          } else {
            this.showSuccess("Connected vehicle updated");
          }
          this.router.navigate(["../.."], { relativeTo: this.route });
        },
        (err) => {
          this.loading = false;
          this.showError(err);
        },
        () => {
          this.loading = false;
        }
      );
  }

  private setupNewForm() {
    this.form.resetForm();

    this.name = "";
    this.mileage = 0;
  }
}
