<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <h1 translate>hotspots.import.title</h1>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <div class="button-row">
                <a mat-button routerLink="/hotspots" title="{{ 'global.back' | translate }}">
                    <mat-icon class="icon-custom">arrow_back</mat-icon>
                    <span translate>global.back</span>
                </a>
            </div>
        </div>

        <div class="col-12">
            <p class="alert alert-info" translate>hotspots.import.notice1</p>
            <p class="alert alert-info" translate>hotspots.import.notice2</p>
        </div>
    </div>



    <div class="row">


        <dx-data-grid id="gridContainer" [dataSource]="dataSource" (onExporting)="onExporting($event)"
            (onToolbarPreparing)="onToolbarPreparing($event)" (onSelectionChanged)="selectionChanged($event)">
            <dxo-editing mode="cell" [allowUpdating]="true" [allowAdding]="true" [allowDeleting]="true">
            </dxo-editing>
            <dxo-selection mode="multiple"></dxo-selection>

            <dxi-column caption="{{ 'global.itemid' | translate }}" dataField="itemid"></dxi-column>
            <dxi-column caption="{{ 'global.title' | translate }}" dataField="title"></dxi-column>
            <dxi-column caption="{{ 'global.latitude' | translate }}" dataField="latitude"></dxi-column>
            <dxi-column caption="{{ 'global.longitude' | translate }}" dataField="longitude"></dxi-column>
            <dxi-column caption="{{ 'global.address' | translate }}" dataField="address"></dxi-column>
            <dxi-column caption="{{ 'global.partner' | translate }}" dataField="partner"></dxi-column>
            <dxi-column caption="{{ 'global.purpose' | translate }}" dataField="purpose"></dxi-column>
            <dxi-column caption="{{ 'global.note' | translate }}" dataField="note"></dxi-column>
            <dxi-column caption="{{ 'global.radius' | translate }}" dataField="radius_in_meters"></dxi-column>

            <dxo-export [enabled]="true"></dxo-export>
        </dx-data-grid>

    </div>


    <div class="row mt-5"></div>
</div>


<dx-popup [width]="500" [height]="250" [showTitle]="true" title="{{ 'hotspots.import.upload' | translate }}"
    [dragEnabled]="false" [showCloseButton]="true" [(visible)]="popupVisible">
    <div *dxTemplate="let data of 'content'">
        <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)" [multiple]="false">
            <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">

                <div class="container-fluid">
                    <div class="row justify-content-center">
                        <p translate>hotspots.import.drop_files</p>
                    </div>
                    <div class="row justify-content-center">
                        <dx-button type="success" text="{{ 'hotspots.import.browse_files' | translate }}"
                            (onClick)="openFileSelector()">
                        </dx-button>
                    </div>
                </div>
            </ng-template>
        </ngx-file-drop>
    </div>
</dx-popup>