import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ApiService } from "app/api.service";
import { ConnectedHelper } from "app/AutologgConnected/connected-helper";
import { MercedesConnectedPayload } from "app/AutologgConnected/connected-payload";
import { BaseComponent } from "app/base/base.component";
import { Helper } from "app/base/helper";
import { ConnectedVehicle } from "app/register/register.service";
import { TrackingService } from "app/tracking/tracking-service";
import { OverlayLoadingService } from "app/utils/overlay-loading.service";
import { ProviderType, OemType } from "app/base/provider-oem-type"

@Component({
  selector: "app-connected-mercedes-login",
  templateUrl: "./connected-mercedes-login.component.html",
  styleUrls: ["./connected-mercedes-login.component.css"],
})
export class ConnectedMercedesLoginComponent
  extends BaseComponent
  implements OnInit
{
  private _vin: string;
  @Input()
  public get vin(): string {
    return this._vin;
  }
  public set vin(v: string) {
    this._vin = Helper.processVin(v);
    this.vinChange.emit(this._vin);
  }

  public mileage: number;

  @Output()
  vinChange = new EventEmitter<any>();

  @Output()
  onVehiclesUpdated = new EventEmitter<ConnectedVehicle[]>();

  constructor(
    _apiService: ApiService,
    public router: Router,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    public formBuilder: UntypedFormBuilder,
    translate: TranslateService,
    public route: ActivatedRoute,
    private overlayLoading: OverlayLoadingService,
    private connectedHelper: ConnectedHelper,
    _changeDetectorRef: ChangeDetectorRef,
    _tracker: TrackingService
  ) {
    super(
      _apiService,
      router,
      snackBar,
      dialog,
      formBuilder,
      translate,
      route,
      _changeDetectorRef,
      _tracker
    );
  }

  ngOnInit(): void {}

  async checkMercedesLogin() {
    const connectedPayload: MercedesConnectedPayload = {
      vin: this.vin,
      mileage: this.mileage,
    };

    try {
      this.overlayLoading.show();
      const vehicles = await this.connectedHelper.loadVehicles(
        ProviderType.Native,
        OemType.Mercedes,
        connectedPayload
      );

      this.onVehiclesUpdated.emit(vehicles);
    } catch (exception) {
      if (exception.status == 404) {
        this.showError("register.connected.dongleNotFound");
      }
    } finally {
      this.overlayLoading.hide();
    }
  }
}
