import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { ExportComponent } from "./export.component";

@Component({
  selector: "app-dialog-export-warning",
  template: `<h5 class="mb-3" translate>export.modalWarning.title</h5>
    <p translate>export.modalWarning.openTripsOrGaps</p>
    <button mat-button color="warn" type="button" (click)="exportAnyway()">
      <span translate>export.modalWarning.exportButton</span>
    </button>
    <button mat-button type="button" (click)="closeDialog()">
      <span translate>global.cancel</span>
    </button>`,
})
export class DialogExportWarningComponent {
  gaps: { [vehicleId: number]: boolean } = {};
  openTripCount: { [vehicleId: number]: number } = {};

  constructor(public dialogRef: MatDialogRef<ExportComponent>) {}

  exportAnyway() {
    this.dialogRef.close({ exportAnyway: true });
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
