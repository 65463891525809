import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class KeyCloakAutologgAuthGuard extends KeycloakAuthGuard {
  constructor(
    protected readonly router: Router,
    protected readonly keycloak: KeycloakService,
    protected readonly apiService: ApiService
  ) {
    super(router, keycloak);
  }

  protected checkAccessAllowed?(): Promise<boolean>;

  public async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {


    // Force the user to log in if currently unauthenticated.
    if (!this.authenticated) {
      console.log("User not authenticated...");
      await this.keycloak.login({
        redirectUri: window.location.origin + state.url
      });
    }

    // Get the roles required from the route.
    const requiredRoles = route.data.roles;

    if(this.checkAccessAllowed) {
      var isSubAllowed = await this.checkAccessAllowed();

      if(!isSubAllowed) {
        return false;
      }
    }

    // Allow the user to to proceed if no additional roles are required to access the route.
    if (!(requiredRoles instanceof Array) || requiredRoles.length === 0) {
      return true;
    }

    // Allow the user to proceed if all the required roles are present.
    return requiredRoles.every((role) => this.roles.includes(role));
  }
}