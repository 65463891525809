<mat-progress-bar *ngIf="loading" color="accent" mode="indeterminate"></mat-progress-bar>

<!-- app-groups-list-group-boxes-map class="app-manage-group-header-map-container" [boxesWithLatestTrip]="boxesWithLatestTrip">
</app-groups-list-group-boxes-map -->

<div class="container-fluid">
  <div class="row">
    <div class="col-12" *ngIf="loading || !userGroupBox">
      <h1>
        <span translate>groups.manage.box.title</span>&nbsp;
      </h1>
    </div>

    <div class="col-12" *ngIf="!loading && userGroupBox">
      <h1>{{ box.name }}</h1>
      <h3>{{ 'groups.manage.box.shared_by' | translate:userGroupBox.user }}</h3>
    </div>
  </div>
</div>

<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <div class="button-row">
        <a mat-raised-button routerLink="/groups/{{groupId}}/manage/_tabs/boxes" title="{{ 'groups.manage.title' | translate }}">
          <mat-icon class="icon-custom">arrow_back</mat-icon>
          <span class="mat-button-span" class="mat-button-span" translate>groups.manage.title</span>
        </a>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="loading">
    <div class="col-12">
      <mat-spinner style="margin:0 auto;" mode="indeterminate"></mat-spinner>
    </div>
  </div>

  <div class="row pt-2" *ngIf="!loading && userGroupBox">
    <div class="col-12">
      <mat-tab-group>
        <mat-tab label="{{ 'groups.manage.box.vehicles.title' | translate }}">
          <app-groups-manage-box-vehicles [groupId]="groupId" [box]="box"></app-groups-manage-box-vehicles>
        </mat-tab>
        <mat-tab label="{{ 'groups.manage.box.open_trips.title' | translate }}">
          <ng-template matTabContent>
            <app-groups-manage-box-open-trips [groupId]="groupId" [userGroupBox]="userGroupBox"></app-groups-manage-box-open-trips>
          </ng-template>
        </mat-tab>
        <mat-tab label="{{ 'groups.manage.box.export.title' | translate }}">
          <app-groups-manage-box-export [groupId]="groupId" [box]="box"></app-groups-manage-box-export>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>

  <div class="row mt-5"></div>
</div>