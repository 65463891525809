<ng-container *ngIf="providerType === ProviderType.HighMobility">
    <div>
        <mat-form-field class="w-100 mt-2">
            <input matInput type="text" minlength="17" maxlength="17" [(ngModel)]="vinInput" name="vin" placeholder="{{ 'login.vin' | translate  }} "
                required autofocus>
                <mat-error *ngIf="vin">{{ 'login.vinHint' | translate }}</mat-error>
        </mat-form-field>

    </div>
</ng-container>

    <button mat-raised-button color="accent" type="button" (click)="openLogin()" class="w-25" [disabled]="isLoginDisabled()">
        <span translate>register.oauth2.login</span>
    </button>