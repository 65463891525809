import { Component, OnInit, ViewChild, Input, OnDestroy, ChangeDetectorRef } from '@angular/core';

import { MatDialogRef } from '@angular/material/dialog';
import { BaseComponent } from '../base/base.component';
import { Subscription } from 'rxjs';
import { DialogService } from '../services/dialog.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

import { ApiService } from 'app/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';


import { TrackingService } from 'app/tracking/tracking-service';
import { MatStepperModule } from '@angular/material/stepper';


import { Steps } from '../services/first-steps';
import { RegisterService } from 'app/register/register.service';



@Component({
  selector: "app-dialog-password-change",
  template: `<h2 class="mb-3" translate>user.changePassword</h2>
    <form #changePassword="ngForm" (submit)="submitForm()" autocomplete="off">
      <mat-form-field floatPlaceholder="always" class="w-100 mb-2">
        <input
          matInput
          #oldPasswordField="ngModel"
          type="password"
          [(ngModel)]="oldPassword"
          name="oldpassword"
          placeholder="{{ 'user.oldPassword' | translate }}"
          required
          autofocus
          autocomplete="off"
          pattern="(?=^.{8,}$)((?=.*\\d)|(?=.*\\W+))(?![.\\n])(?=.*[A-Z])(?=.*[a-z]).*$"
        />
        <mat-error
          *ngIf="oldPasswordField.touched && oldPasswordField.dirty"
          align="end"
        >
          <span *ngIf="!oldPasswordField.valid">{{
            "errors.passwordValidation" | translate
          }}</span>
        </mat-error>
      </mat-form-field>
      <mat-form-field floatPlaceholder="always" class="w-100 mb-2">
        <input
          matInput
          #password="ngModel"
          type="password"
          [(ngModel)]="newPassword"
          name="password"
          placeholder="{{ 'user.password' | translate }}"
          required
          autocomplete="off"
          pattern="(?=^.{8,}$)((?=.*\\d)|(?=.*\\W+))(?![.\\n])(?=.*[A-Z])(?=.*[a-z]).*$"
          (keyup)="validatePasswords()"
        />
        <mat-error *ngIf="password.touched && password.dirty" align="end">
          <span *ngIf="!password.valid"
            ><span translate>errors.passwordValidation</span></span
          >
        </mat-error>
      </mat-form-field>
      <mat-form-field floatPlaceholder="always" class="w-100 mb-2">
        <input
          matInput
          #passwordAgain="ngModel"
          type="password"
          [(ngModel)]="newPasswordAgain"
          name="passwordAgain"
          placeholder="{{ 'user.repeatPassword' | translate }}"
          required
          autocomplete="off"
          (keyup)="validatePasswords()"
        />
        <mat-hint align="end" *ngIf="!passwordMatch">
          <span translate>errors.passwordsNoMatch</span>
        </mat-hint>
      </mat-form-field>
      <mat-dialog-actions>
        <button
          mat-raised-button
          [disabled]="!isSubmitButtonEnabled()"
          type="submit"
          color="primary"
        >
          <span translate>user.changePassword</span>
        </button>
        <button mat-button (click)="close()">
          <span translate>global.cancel</span>
        </button>
      </mat-dialog-actions>
    </form>`,
})
export class DialogPasswordChangeComponent {
  @ViewChild("changePassword", { static: false }) form;

  newPassword: string;
  newPasswordAgain: string;
  passwordMatch = false;
  oldPassword: string;

  constructor(public dialogRef: MatDialogRef<ProfileComponent>) {}

  isSubmitButtonEnabled() {
    return this.passwordMatch && this.form.valid;
  }

  close() {
    this.dialogRef.close(false);
  }

  submitForm() {
    if (this.passwordMatch) {
      this.dialogRef.close({ new: this.newPassword, old: this.oldPassword });
    }
  }

  validatePasswords() {
    if (this.newPassword === this.newPasswordAgain) {
      this.passwordMatch = true;
      return true;
    } else {
      this.passwordMatch = false;
      return false;
    }
  }
}

@Component({
  selector: "app-profile-form",
  templateUrl: "./profile.component.html",
})
export class ProfileComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  message: Steps;
  subscription: Subscription;

  constructor(
    private _dialogService: DialogService,
    _apiService: ApiService,
    router: Router,
    snackBar: MatSnackBar,
    dialog: MatDialog,
    formBuilder: UntypedFormBuilder,
    translate: TranslateService,
    route: ActivatedRoute,
    _changeDetectorRef: ChangeDetectorRef,
    _tracker: TrackingService,
    stepper: MatStepperModule,
    public registerService: RegisterService
  ) {
    super(
      _apiService,
      router,
      snackBar,
      dialog,
      formBuilder,
      translate,
      route,
      _changeDetectorRef,
      _tracker
    );
  }

  ngOnInit() {
    this.subscription = this._dialogService.currentMessage.subscribe(
      (message) => (this.message = message)
    );

    this._apiService.getUserProfile().subscribe(
      (user) => {
        return true;
      },
      (err) => {
        this.showError(err);
      }
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  submitForm() {
    this._apiService.postUserEdit().subscribe(
      (ok) => {
        this.showSuccess("Profile saved");
        //first steps dialog next step
        this._dialogService.changeMessage(Steps.secondStep);
      },
      (err) => {
        this.showError(err);
      }
    );
  }

  private changePassword(newPassword: string, oldPassword: string) {
    this._apiService.postPasswordChange(newPassword, oldPassword).subscribe(
      (ok) => {
        this.showSuccess("Password change success");
      },
      (err) => {
        this.showError(err);
      }
    );
  }

  openChangePasswordDialog() {
    const dialogRef = this.dialog.open(DialogPasswordChangeComponent);
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.changePassword(result.new, result.old);
      }
    });
  }
}
