import "rxjs/add/operator/do";
import "rxjs/add/operator/filter";
import "rxjs/add/operator/map";

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClient } from "@angular/common/http";

import { LeafletModule } from "@asymmetrik/ngx-leaflet";

import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

import { MaterialModule } from "./material.module";
import { DialogConfirmComponent } from "../common/dialog-confirm/dialog-confirm.component";
import { DialogInfoComponent } from "../common/dialog-info/dialog-info.component";

import { SortService } from "../sortable-table/sort.service";
import { SortableTableDirective } from "../sortable-table/sortable-table.directive";
import { SortableColumnComponent } from "../sortable-table/sortable-column.component";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        LeafletModule
    ],
    providers: [
        SortService,
    ],
    declarations: [
        DialogConfirmComponent,
        DialogInfoComponent,
        SortableTableDirective,
        SortableColumnComponent
    ],
    exports: [
        CommonModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        LeafletModule,
        TranslateModule,
        SortableTableDirective,
        SortableColumnComponent
    ]
})
export class AutoLoggCommonModule {}
