import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { FormBuilder, NgForm } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ApiService } from "app/api.service";
import { ConnectedHelper } from "app/AutologgConnected/connected-helper";
import { ConnectedLoginComponent } from "app/AutologgConnected/connected-login/connected-login.component";
import {
  AutoaidConnectedPayload,
  BmwConnectedPayload,
  ConnectedPayload,
  HighMobilityConnectedPayload,
  TeslaConnectedPayload,
} from "app/AutologgConnected/connected-payload";
import {
  ConnectedVehicle,
  RegisterService,
  Vehicle,
} from "app/register/register.service";
import { DialogService } from "app/services/dialog.service";
import { Steps } from "app/services/first-steps";
import { TrackingService } from "app/tracking/tracking-service";
import { OverlayLoadingService } from "app/utils/overlay-loading.service";
import { Box } from "app/_models/box.model";
import { BoxCreateRequest } from "../../_models/box-create-request.model";
import {
  BaseBoxesComponent,
} from "../base-boxes.component";
import { ProviderType, OemType, apiProviderTypeFromString, oemTypeFromString } from "app/base/provider-oem-type"

@Component({
  templateUrl: "./create-box.component.html",
  styleUrls: ["create-box.component.css"],
})
export class CreateBoxComponent
  extends BaseBoxesComponent
  implements OnInit, AfterViewInit {
  OemType = OemType;
  ProviderType = ProviderType;
  private static DESCRIPTION_MAX_CHAR_COUNT: number = 1024;

  @ViewChild("addBoxForm", { static: false }) form;
  @ViewChild("connectedLogin", { static: false })
  connectedLogin: ConnectedLoginComponent;

  loading: boolean = false;

  name: string = "";
  description: string = "";
  vehicleKey: string = "";

  descriptionMaxCharacterCount: number =
    CreateBoxComponent.DESCRIPTION_MAX_CHAR_COUNT;
  descriptionRemainingCharacterCount: number = 0;

  connectedCredentials: any = {};
  connectedVehicles = [];
  boxes: Array<Box> = [];
  connectedVehicle: ConnectedVehicle;
  plate: string;

  submitEnabled: boolean = true;

  providerType: ProviderType;

  vin: String;

  oemType: OemType;
  vehicleNotCompatibleMessage: string;

  isDialog: boolean = false;

  constructor(
    _apiService: ApiService,
    router: Router,
    snackBar: MatSnackBar,
    dialog: MatDialog,
    formBuilder: UntypedFormBuilder,
    translate: TranslateService,
    route: ActivatedRoute,
    _changeDetectorRef: ChangeDetectorRef,
    _tracker: TrackingService,
    private dialogService: DialogService,
    private registerService: RegisterService,
    private overlayLoading: OverlayLoadingService,
    private connectedHelper: ConnectedHelper
  ) {
    super(
      _apiService,
      router,
      snackBar,
      dialog,
      formBuilder,
      translate,
      route,
      _changeDetectorRef,
      _tracker
    );
  }

  async ngOnInit() {
    this.route.params.subscribe((params) => {
      let providerType = params["providerType"];
      this.providerType = apiProviderTypeFromString(providerType);

      let oemType = params["oemType"];
      this.oemType = oemTypeFromString(oemType);
    });
    this.route.data.subscribe((data) => {
      this.isDialog = !!data.isDialog;
    });
  }

  async onOauth2Completed($event) {
    if (
      this.providerType == ProviderType.HighMobility &&
      $event &&
      !!$event.isValid
    ) {
      await this.checkConnectedLoginInternal($event);
    }
  }

  onVehiclesUpdated($event) {
    this.connectedVehicles = $event;

    if (this.connectedVehicles && this.connectedVehicles.length === 1) {
      this.connectedVehicle = this.connectedVehicles[0];
      this.registerService.payload = (<any>this.connectedVehicle).payload.Data;
    }
  }

  async onOauth2Failed($event) {
    console.log($event);
    if (this.providerType == ProviderType.HighMobility) {
      await this.hideLoadingIndicatorAndShowOauthFaildErrorMessage($event);
    }
  }

  ngAfterViewInit() {
    this.form.control.valueChanges.subscribe((values) => {
      this.formControlValueChanged();
    });
    this.setupNewForm();
  }

  formControlValueChanged() {
    this.updateDescriptionRemainingCharacterCount();
  }

  isSubmitButtonEnabled(form: NgForm) {
    if (!form.valid) {
      return false;
    }

    if (this.oemType !== OemType.Box) {
      return this.submitEnabled;
    }
    if (this.vehicleKey.length > 10 && this.name.length > 0) {
      return this.submitEnabled;
    }
    return false;
  }

  async registerVehicle() {
    const payload: Vehicle = {
      reference_vehicle_id: this.connectedVehicle.referenceVehicleId,
      providerType: this.providerType,
      oemType: this.oemType,
      payload: this.registerService.payload,
      brand: this.connectedVehicle.brand,
      model: this.connectedVehicle.modelName,
      plate: this.plate,
      name: this.connectedVehicle.name,
    };

    const mogreeResponse = await this.registerService.addVehicle(payload);

    if (!mogreeResponse.successful) {
      throw new Error(mogreeResponse.errormessage);
    }

    this._apiService.onAuthDataReceived(mogreeResponse);
    this._apiService.refreshProfile();

    this.showSuccess("Connected Vehicle created");
    this.router.navigate(["/boxes"]);
  }

  async submitForm(form: NgForm) {
    if (!this.isSubmitButtonEnabled(form)) {
      throw new Error("Form is in invalid state and cannot be submitted");
    }
    this.submitEnabled = false;

    if (this.oemType !== OemType.Box) {
      this.overlayLoading.show();

      if (this.oemType == OemType.Tesla) {
        var teslaPayload: TeslaConnectedPayload = {
          accessPayload: this.connectedVehicle.accessPayload,
          vehicleId: this.registerService.payload.vehicleId,
          password: "",
          username: "",
        };

        this.registerService.payload = teslaPayload;
      } else if (
        this.oemType == OemType.Bmw &&
        this.providerType == ProviderType.Native
      ) {
        var bmwPayload: BmwConnectedPayload = {
          password: (<any>this.registerService.payload).password,
          username: (<any>this.registerService.payload).username,
          vehicleId: this.registerService.payload.vehicleId,
        };
        this.registerService.payload = bmwPayload;
      } else if (this.providerType == ProviderType.HighMobility) {
        var hmPayload: HighMobilityConnectedPayload = {
          accessPayload: this.connectedVehicle.accessPayload,
          brand: this.oemType,
        };
        this.registerService.payload = hmPayload;
      } else if (this.oemType == OemType.Dongle) {
        var autoaidPayload: AutoaidConnectedPayload = {
          vin: (<any>this.connectedVehicle).payload.Data.Vin ?? "",
          imei: (<any>this.connectedVehicle).payload.Data.Imei ?? "",
          vehicleId: this.registerService.payload.vehicleId,
        };
        this.connectedVehicle.brand = "Dongle";
        this.registerService.payload = autoaidPayload;
      }

      try {
        await this.registerVehicle();
        this.dialogService.changeMessage(Steps.thirdStep);
      } catch (error) {
        if (error && error.message === "Bad Request") {
          error.message = "register.connected.couldNotRegisterVehicle";
        }
        if (error && error.status !== 404) {
          this.showError(error);
        } else if (error.status === 401) {
          this.showError("register.connected.invalidCredentials");
        } else {
          this.showError("unknown error occured");
          this.router.navigate(["/boxes"]);
        }
      } finally {
        this.overlayLoading.hide();
      }
    } else {
      const boxCreateRequest = new BoxCreateRequest();
      boxCreateRequest.description = this.description;
      boxCreateRequest.name = this.name;
      boxCreateRequest.vehicle_key = this.vehicleKey;
      boxCreateRequest.provider_type = this.providerType;
      boxCreateRequest.oem_type = this.oemType;

      this.loading = true;
      try {
        const box = await this._apiService
          .createUserBox(boxCreateRequest)
          .toPromise();
        const vehicleId = (<any>box).vehicleId;
        console.log("next page");
        this.router.navigate([
          "/",
          {
            outlets: {
              dialog: ["boxes", vehicleId, "vehicles", "createFirst"],
              primary: ["boxes", vehicleId, "vehicles", "createFirst"],
            },
          },
        ]);
      } catch (error) {
        this.showError(error);
      }

      this.loading = false;
    }

    this.submitEnabled = true;
  }

  updateDescriptionRemainingCharacterCount() {
    this.descriptionRemainingCharacterCount =
      this.descriptionMaxCharacterCount - (this.description || "").length;
  }

  private setupNewForm() {
    this.form.resetForm();

    this.vehicleKey = "";
    this.name = "";
    this.description = "";

    this.updateDescriptionRemainingCharacterCount();
  }

  async checkConnectedLogin() {
    const connectedPayload: TeslaConnectedPayload = {
      username: this.connectedCredentials.email,
      password: this.connectedCredentials.password,
      accessPayload: {},
    };
    await this.checkConnectedLoginInternal(connectedPayload);
  }

  async checkConnectedLoginInternal(connectedPayload: ConnectedPayload) {
    try {
      if (!this.connectedLogin.isValid()) {
        this.showError("the credentials must be complete");
        return;
      }

      this.overlayLoading.show();

      this.registerService.payload = connectedPayload;

      const vehicles = await this.connectedHelper.loadVehicles(
        this.providerType,
        this.oemType,
        connectedPayload
      );

      this.vehicleNotCompatibleMessage = await this._translate
        .get("boxes.vehicles.connected.propablyNotCompatibleInfo")
        .toPromise();

      this.connectedVehicles = vehicles;

      if (this.connectedVehicles && this.connectedVehicles.length === 1) {
        this.connectedVehicle = this.connectedVehicles[0];
      }
    } catch (error) {
      console.log(error);
      if (error.message == "MFA_CODE_REQUIRED") {
        this.connectedLogin.showMfaOverlay(error.data);
      } else if (error.errorType) {
        this.showError("register.error." + error.errorType, "OK", 15000);
      } else {
        this.showError(error);
      }
    } finally {
      this.overlayLoading.hide();
    }
  }

  async hideLoadingIndicatorAndShowOauthFaildErrorMessage($event) {
    this.overlayLoading.hide();
    this.showError("register.connected.couldNotLoadConnectedCars", "OK", 10000);
  }

  async onMfaUpdated($event) {
    this.overlayLoading.show();
    try {
      const vehicles = await this.connectedHelper.sendMfa(this.oemType, $event);

      this.connectedVehicles = vehicles;

      if (this.connectedVehicles && this.connectedVehicles.length === 1) {
        this.connectedVehicle = this.connectedVehicles[0];
      }
    } catch (error) {
      this.showError(error);
    } finally {
      this.overlayLoading.hide();
    }
  }
}
