<mat-progress-bar *ngIf="loading" color="accent" mode="indeterminate"></mat-progress-bar>

<div class="container-fluid">

  <div class="row" [hidden]="loading || (invitations && invitations.length === 0)">
    <div class="col-12">
      <app-groups-list-user-invitations [invitations]="invitations" [loading]="loading" (valueChange)="onInvitationChanged($event)"></app-groups-list-user-invitations>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <h1>
        {{ 'groups.title' | translate }}
      </h1>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
        <div class="button-row">
          <a  mat-raised-button color="primary" routerLink="/groups/create" title="{{ 'groups.addGroup' | translate }}"
            [disabled]="loading">
            <mat-icon class="icon-custom">group_add</mat-icon>
            <span class="mat-button-span" translate>groups.addGroup</span>
          </a>
        </div>
    </div>
  </div>

  <hr />

  <div class="row" *ngIf="loading">
    <div class="col-12">
      <mat-spinner style="margin:0 auto;" mode="indeterminate"></mat-spinner>
    </div>
  </div>

  <div class="row" [hidden]="loading">
    <div class="col-12 p-3">
      <app-groups-list-user-groups [groups]="groups" [loading]="loading"></app-groups-list-user-groups>
    </div>
  </div>

  <div class="row mt-5"></div>
</div>