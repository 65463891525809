import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { BaseComponent } from "../base/base.component";

import { FirstStepsComponent } from "../first-steps/first-steps.component";

@Component({
  selector: "test-account",
  templateUrl: "./account.component.html",
})
export class AccountComponent extends BaseComponent implements OnInit {
  loading: boolean = false;

  ngOnInit() {}
}
