import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiService } from "app/api.service";
import { environment } from "environments/environment";

@Injectable({
  providedIn: "root",
})
export class GapService {
  private baseUrl: string;

  constructor(private httpService: HttpClient, private apiService: ApiService) {
    this.baseUrl = environment.backendUrl;
  }

  private headers(
    withCredentials: boolean = false,
    additionalHeaders = []
  ): HttpHeaders {

    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");

    additionalHeaders.forEach((a) => {
      headers.append(a.key, a.value);
    });

    return headers;
  }

  public async hasGap(): Promise<boolean> {
    try {
      if (!this.apiService.isLoggedIn) {
        return false;
      }
      const response = await this.httpService
        .get(this.baseUrl + "trips/next_gap_of_active_model", {
          withCredentials: true,
          headers: this.headers(true),
          responseType: "json",
        })
        .toPromise();

      return (<any>response).detailresponse.gapExists;
    } catch {
      return false;
    }
  }
}
